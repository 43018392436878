import { Button } from '@chakra-ui/react'
import {
	Document,
	Page,
	PDFDownloadLink,
	PDFViewer,
	StyleSheet,
	Text,
	View,
} from '@react-pdf/renderer'
import React from 'react'

const styles = StyleSheet.create({
	page: {
		paddingVertical: 28,
		paddingHorizontal: 32,
	},
	title: {
		fontSize: 20,
		fontFamily: 'Open Sans',
		fontWeight: 600,
		textAlign: 'center',
		marginBottom: 28,
	},
	section: {
		display: 'flex',
		flexDirection: 'column',
		marginBottom: 24,
	},
	row: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
		// paddingVertical: 4,
	},
	text: {
		fontSize: 14,
	},
})

interface Props {
	title: string
	sections: { data: { title: string; body: React.ReactNode }[] }[]
}

export function ReportPdf({ title, sections }: Props) {
	return (
		<Document>
			<Page size="A4" style={styles.page}>
				<Text style={styles.title}>{title}</Text>
				<View>
					{sections.map((section, i) => (
						<View
							key={`pdf:${i}`}
							style={styles.section}
							wrap={false}
						>
							{section.data.map((it) => (
								<View key={it.title} style={styles.row}>
									<Text
										style={{
											fontFamily: 'Open Sans',
											fontWeight: 600,
											...styles.text,
										}}
									>
										{it.title}
									</Text>
									<Text style={styles.text}>{it.body}</Text>
								</View>
							))}
						</View>
					))}
				</View>
			</Page>
		</Document>
	)
}

export function ReportPdfDownload(
	props: React.ComponentProps<typeof PDFDownloadLink>
) {
	return (
		<PDFDownloadLink {...props}>
			<Button
				as="span"
				colorScheme="primary"
				size="sm"
				textTransform="uppercase"
				fontSize="xs"
			>
				Exportar PDF
			</Button>
		</PDFDownloadLink>
	)
}
