import React from 'react'
import {
	Table,
	Thead,
	Td,
	Flex,
	IconButton,
	Text,
	Heading,
	Popover,
	PopoverTrigger,
	PopoverContent,
	FlexProps,
	Portal,
	useColorModeValue,
} from '@chakra-ui/react'
import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons'
import { Calendar } from 'app/components/Calendar'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { CalendarDate } from '@internationalized/date'
import { useSettingsByProfessional } from 'modules/schedule/queries/useSettingsByProfessional'
import { useSettingsDefault } from 'modules/schedule/queries/useSettingsDefault'

interface ScheduleTableRootProps {
	children?: React.ReactNode
}

interface TableControlProps extends FlexProps {
	onPrevious: () => void
	onNext: () => void
	current: string
}

export function ScheduleTableRoot({ children }: ScheduleTableRootProps) {
	return (
		<Table
			px={{ md: '24px' }}
			style={{ tableLayout: 'fixed' }}
			borderRadius={10}
			data-border
		>
			{children}
		</Table>
	)
}

function TableHead({ children }: { children: React.ReactNode }) {
	const bg = useColorModeValue('#f4f4f4', '#182533')

	return (
		<Thead bg={bg} borderTopRadius="inherit">
			{children}
		</Thead>
	)
}

function getValidDate(input?: string | null | undefined) {
	if (!input) return new Date()
	const date = new Date(input)
	const isValid = date instanceof Date && !Number.isNaN(date)
	return isValid ? date : new Date()
}

function TableControl({
	current,
	onPrevious,
	onNext,
	...props
}: TableControlProps) {
	const navigate = useNavigate()
	const params = useParams()
	const [searchParams] = useSearchParams()

	const dateString = searchParams.get('date')
	const date = React.useMemo(() => getValidDate(dateString), [dateString])
	const defaultDateValue = React.useMemo(
		() =>
			new CalendarDate(
				date.getFullYear(),
				date.getMonth() + 1,
				date.getDate()
			),
		[date]
	)

	const { professionalId } = useParams()

	const settingsByProfessional = useSettingsByProfessional(professionalId)
	const settingsDefault = useSettingsDefault()
	const settings = settingsByProfessional.data ?? settingsDefault.data
	const btnBg = useColorModeValue('#FFF', '#223344')

	return (
		<Flex alignItems="center" gap="16px" {...props}>
			<IconButton
				bg={btnBg}
				aria-label="Anterior"
				icon={<ChevronLeftIcon fontSize={18} />}
				size="xs"
				onClick={onPrevious}
			/>

			<Popover>
				{(props) => (
					<>
						<PopoverTrigger>
							<Text fontWeight={600}>{current}</Text>
						</PopoverTrigger>
						<Portal>
							<PopoverContent>
								<Calendar
									key={`calendar-${defaultDateValue.toString()}`}
									defaultValue={defaultDateValue}
									onChange={(value) => {
										const nextDate = value
											.toDate('America/Sao_Paulo')
											.toISOString()
										navigate(
											`/agendamentos/${params.professionalId}?date=${nextDate}`,
											{ replace: true }
										)
										sessionStorage.setItem(
											'agenda:date',
											nextDate
										)
										props.onClose()
									}}
									onToday={() => {
										const today = new Date().toJSON()
										navigate(
											`/agendamentos/${params.professionalId}?date=${today}`,
											{ replace: true }
										)
										sessionStorage.setItem(
											'agenda:date',
											String(today)
										)
										props.onClose()
									}}
									isDateUnavailable={(value) =>
										!settings
											? true
											: !settings.agenda.availableDays.includes(
													[
														'sunday',
														'monday',
														'tuesday',
														'wednesday',
														'thursday',
														'friday',
														'saturday',
													][
														value
															.toDate(
																'America/Sao_Paulo'
															)
															.getDay()
													]
											  )
									}
								/>
							</PopoverContent>
						</Portal>
					</>
				)}
			</Popover>

			<IconButton
				bg={btnBg}
				aria-label="Próximo"
				icon={<ChevronRightIcon fontSize={18} />}
				size="xs"
				onClick={onNext}
			/>
		</Flex>
	)
}

function TableCell({ ...props }: any) {
	return (
		<Td
			{...props}
			overflow="hidden"
			textOverflow="ellipsis"
			whiteSpace="nowrap"
			style={{ wordWrap: 'break-word' }}
		/>
	)
}

ScheduleTableRoot.Control = TableControl
ScheduleTableRoot.Head = TableHead
ScheduleTableRoot.Cell = TableCell
ScheduleTableRoot.renderProfessional = (name: string) => (
	<div>
		<Heading as="span" size="sm" fontWeight={600}>
			Profissional:{' '}
		</Heading>
		{name && <span>{name}</span>}
	</div>
)
