import { keepPreviousData, useQuery } from '@tanstack/react-query'
import { BillsRepository } from 'data/BillsRepository'

interface Props {
	page: number
}

export function useBillsQuery({ page }: Props) {
	return useQuery({
		queryKey: ['bills', { page }],
		async queryFn({ signal }) {
			const repository = new BillsRepository()
			return await repository.getBills({ page, signal })
		},
		placeholderData: keepPreviousData,
	})
}
