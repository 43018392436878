/* eslint-disable no-unused-expressions */
import { useFormContext } from 'react-hook-form'
import { GeneralData } from './GeneralData'
import { RegistrationInformation } from './RegistrationInformation'
import { Address } from './Address'
import { RecordProfilePicture } from './RecordProfilePicture'
import { RegistrationInformationOfResponsible } from './RegistrationInformationOfResponsible'
import { AddressOfResponsible } from './AddressOfResponsible'

export const Form = () => {
	const { watch } = useFormContext()

	const hasResponsible =
		watch('legalRepresentative.config.hasResponsible') === 'Yes'

	return (
		<>
			<GeneralData />
			<RegistrationInformation />
			{hasResponsible && <RegistrationInformationOfResponsible />}
			<Address />
			{hasResponsible && <AddressOfResponsible />}
			<RecordProfilePicture />
		</>
	)
}
