import {
	Center,
	Flex,
	IconButton,
	Spinner,
	Table,
	TableContainer,
	Tbody,
	Td,
	Text,
	Th,
	Thead,
	Tr,
} from '@chakra-ui/react'
import { useParams } from 'react-router-dom'
import dayjs from 'dayjs'
import { EditIcon } from '@chakra-ui/icons'

import { Can } from 'ability'
import { useTreatmentPaymentsQuery } from '../queries/useTreatmentPaymentsQuery'
import { useTreatmentQuery } from '../queries/useTreatmentQuery'
import { DownloadIcon } from 'app/icons/DownloadIcon'
import { PaymentModal } from 'modules/financial'
import { DeletePayment } from 'modules/financial/components/DeletePayment'

export function TreatmentPayments() {
	const params = useParams()

	const treatmentQuery = useTreatmentQuery(params.id)
	const treatment = treatmentQuery.data

	const query = useTreatmentPaymentsQuery(params.id)
	const payments = query.data

	if (!treatment) return null

	return (
		<TableContainer
			bg="white"
			_dark={{ bg: '#182533' }}
			p="4"
			rounded="12"
			h="100%"
			mb="6"
			position="relative"
		>
			<Table variant="striped">
				<Thead>
					<tr>
						<Th>Tipo</Th>
						<Th>Valor</Th>
						<Th textAlign="end">Desconto (R$)</Th>
						<Th textAlign="end">Valor Final</Th>
						<Th textAlign="end">Vencimento</Th>
						<Th>Status</Th>
						<Th>Comprovante</Th>
					</tr>
				</Thead>
				<Tbody>
					{payments?.map((it, i, self) => {
						const isDownPayment = it.type === 'downpayment'
						const hasDownPayment = self.some(
							(itt) => itt.type === 'downpayment'
						)
						const installmentsCount = self.filter(
							(itt) => itt.type !== 'downpayment'
						).length

						return (
							<tr key={`payment_${i}`}>
								<Td textTransform="uppercase">
									{isDownPayment
										? 'Entrada'
										: `Parcela ${
												i + (hasDownPayment ? 0 : 1)
										  }/${installmentsCount}`}
								</Td>
								<Td>{formatAsCurrency(it.value)}</Td>
								<Td textAlign="end">{formatAsCurrency(0)}</Td>
								<Td textAlign="end">
									{formatAsCurrency(it.value)}
								</Td>
								<Td textAlign="end">
									{it.dueDate
										? dayjs(it.dueDate.slice(0, 10)).format(
												'DD/MM/YYYY'
										  )
										: '-'}
								</Td>
								<Td>
									{it.status === 'paid' ? (
										<Text
											textTransform="uppercase"
											color="#00CC66"
										>
											Pago
										</Text>
									) : (
										<Can I="update" this={it} passThrough>
											{(allowed) =>
												allowed ? (
													<PaymentModal
														payment={it}
														trigger={
															<Text
																color="#EE4444"
																textTransform="uppercase"
																_hover={{
																	textDecoration:
																		'underline',
																	cursor: 'pointer',
																}}
															>
																Pendente
															</Text>
														}
													/>
												) : (
													<Text
														color="#EE4444"
														textTransform="uppercase"
													>
														Pendente
													</Text>
												)
											}
										</Can>
									)}
								</Td>

								<Td>
									<Flex gap="3" justifyContent="center">
										{it.file?.url && (
											<IconButton
												display="flex"
												variant="unstyled"
												color="#0C6"
												bg="#f9f9f9"
												_dark={{ bg: '#223344' }}
												aria-label="Baixar recibo"
												icon={
													<DownloadIcon size={20} />
												}
												as="a"
												download={it.file.url
													.split('/')
													.at(-1)
													.split('?')
													.at(0)}
												href={it.file.url}
												target="_blank"
											/>
										)}
										<Can I="update" this={it}>
											<PaymentModal
												payment={it}
												trigger={
													<IconButton
														colorScheme="primary"
														variant="unstyled"
														bg="#f9f9f9"
														_dark={{
															bg: '#223344',
														}}
														aria-label="Editar pagamento"
														icon={<EditIcon />}
													/>
												}
											/>
										</Can>
										<Can I="delete" this={it}>
											<DeletePayment payment={it} />
										</Can>
									</Flex>
								</Td>
							</tr>
						)
					})}

					{!query.data && query.isLoading && (
						<Tr display="block">
							<Td p="0" border="none">
								<Center
									position="absolute"
									h="100%"
									top="50%"
									left="50%"
									transform="translate(-50%,-50%)"
								>
									<Spinner />
								</Center>
							</Td>
						</Tr>
					)}

					{query.isLoadingError && (
						<Tr display="block">
							<td>
								<Center
									flexDirection="column"
									position="absolute"
									h="100%"
									top="50%"
									left="50%"
									transform="translate(-50%,-50%)"
								>
									<Text>
										Algo deu errado ao carregar esses
										dados...
									</Text>
									<Text>{String(query.error)}</Text>
								</Center>
							</td>
						</Tr>
					)}
				</Tbody>
			</Table>
		</TableContainer>
	)
}

function formatAsCurrency(rawValue: number) {
	const currency = 'BRL'
	const formattedValue = new Intl.NumberFormat(undefined, {
		style: 'currency',
		currency,
	})
		.format(rawValue)
		.trim()
	return formattedValue
}
