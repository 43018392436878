import React from 'react'
import { GridItem, Flex, Box } from '@chakra-ui/react'
import { ButtonToOpenRecordFormModal } from '../../Table/ButtonToOpenRecordFormModal'
import { InputToSearch } from '../InputToSearch'
import { SelectFilterByTypeInfo } from '../SelectFilterByTypeInfo'
import { SelectFilterByTypeRecord } from '../SelectFilterByTypeRecord'

export const DesktopTableFilters = () => {
	return (
		<GridItem
			colSpan={1}
			rowSpan={1}
			height="48px"
			width="90%"
			margin="auto"
		>
			<Flex>
				<Flex width="100%">
					<Flex width="100%">
						<InputToSearch />
						<SelectFilterByTypeInfo />
					</Flex>
					<Box margin="0px 20px 0px 31px">
						<SelectFilterByTypeRecord />
					</Box>
				</Flex>
				<ButtonToOpenRecordFormModal />
			</Flex>
		</GridItem>
	)
}
