/* eslint-disable no-underscore-dangle */
/* eslint-disable no-use-before-define */
import dayjs from 'dayjs'
import { Box, IconButton, Text, useColorModeValue } from '@chakra-ui/react'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm, FormProvider } from 'react-hook-form'
import { AddIcon } from '@chakra-ui/icons'
import { useParams } from 'react-router-dom'
import React from 'react'

import { useModalState } from 'app/utils/useModalState'
import { scheduleFormSchema } from 'modules/schedule/validations/scheduleFormSchema'
import { useUpdateSchedule } from 'modules/schedule/queries/useUpdateSchedule'
import { FileIcon } from 'app/icons/FileIcon'
import { Settings } from 'modules/schedule/validations/settingsSchema'
import { useCreateSchedule } from 'modules/schedule/queries/useCreateSchedule'

import type { Schedule } from 'modules/schedule/queries/useProfessionalSchedule'
import { ScheduleTableRoot } from './ScheduleTableRoot'
import EditScheduleModal from '../EditScheduleModal'
import ScheduleModal from '../ScheduleModal'
import { useAbility } from 'ability'

function ScheduleFormProvider({
	children,
	defaultValues,
}: {
	children: React.ReactNode
	defaultValues: Record<string, unknown>
}) {
	const form = useForm({
		defaultValues,
		resolver: yupResolver(scheduleFormSchema),
	})

	return <FormProvider {...form}>{children}</FormProvider>
}

interface Props {
	events: Schedule['events']
	hasAnnotation?: boolean
	onAnnotationIconPress?: () => void
	disabled?: boolean
	settings: Settings
	allEvents: Schedule['events']
	start?: string
	end?: string

	useCellWidth?: boolean
}

const ENABLE_ADD_BUTTON = false

export function ScheduleCell({
	events,
	hasAnnotation,
	disabled,
	onAnnotationIconPress,
	settings,
	allEvents,
	start,
	end,
	useCellWidth,
}: Props) {
	const createScheduleModal = useModalState()

	const createSchedule = useCreateSchedule()

	const getCellBg = () => {
		if (disabled) return useColorModeValue('#f0f0f0', '#2a3f54')
		return useColorModeValue('#fff', 'transparent')
	}

	const canCreateSchedule = useAbility().can('create', 'Appointment')

	return (
		<ScheduleTableRoot.Cell
			bg={getCellBg()}
			fontWeight={600}
			fontSize={{ sm: 'xs', md: 'sm' }}
			width={0}
			height="48px"
			py="9px"
			px="10px"
			onClick={() => {
				if (events.length < 2 && !disabled && canCreateSchedule) {
					createScheduleModal.open()
				}
			}}
		>
			<Box
				as="ul"
				display="grid"
				gridAutoFlow="column"
				alignItems="center"
				gap={3}
				listStyleType="none"
			>
				{ENABLE_ADD_BUTTON && !disabled && (
					<IconButton
						rounded="full"
						bg="white"
						size="xs"
						icon={<AddIcon />}
						aria-label="Novo agendamento"
					/>
				)}

				{events.map((event: any) => {
					return (
						<ScheduleEvent
							key={event.id}
							event={event}
							settings={settings}
							allEvents={allEvents}
							useCellWidth={useCellWidth}
						/>
					)
				})}

				{hasAnnotation && (
					<Box
						ml="auto"
						color="red.400"
						onClick={onAnnotationIconPress}
					>
						<FileIcon size={24} />
					</Box>
				)}

				<ScheduleFormProvider
					key={start + createScheduleModal.isOpen} // recreates form on date change
					defaultValues={{
						start,
						end,
					}}
				>
					<ScheduleModal
						isOpen={createScheduleModal.isOpen}
						onClose={createScheduleModal.close}
						onSubmit={(values) =>
							createSchedule.mutate(values, {
								onSuccess: createScheduleModal.close,
							})
						}
					/>
				</ScheduleFormProvider>
			</Box>
		</ScheduleTableRoot.Cell>
	)
}

interface ScheduleEventProps {
	event: Schedule['events'][number]
	settings: Settings
	allEvents: Schedule['events']
	useCellWidth?: boolean
}

function ScheduleEvent({
	event,
	settings,
	allEvents,
	useCellWidth,
}: ScheduleEventProps) {
	const editScheduleModal = useModalState()
	const updateSchedule = useUpdateSchedule()

	const canUpdateSchedule = useAbility().can('update', 'Appointment')

	const statusBgMap: Record<string, string> = {
		waiting: useColorModeValue('yellow.100', 'yellow.400'),
		cancelled: useColorModeValue('red.100', 'red.400'),
		finished: useColorModeValue('green.100', 'green.400'),
		scheduled: useColorModeValue('blue.100', 'blue.400'),
	}
	const getBg = () => {
		return statusBgMap[event.status] ?? undefined
	}

	const eventEndDayjs = dayjs(event.end)
	const eventStartDayjs = dayjs(event.start)
	const sessionDurationHours = settings.agenda.sessionDurationMinutes / 60

	const [agendaStartHourString, agendaStartMinuteString] =
		settings.agenda.startTime.split(':')
	const agendaStartDayjs = dayjs(event.start)
		.hour(Number(agendaStartHourString))
		.minute(Number(agendaStartMinuteString))

	function getTop() {
		const layoutTop = 0

		// formula (hora_inicial_do_evento - hora_inicial_da_agenda ) * (tempo_de_sessao_em_hora) ** -1
		const eventStartHours =
			eventStartDayjs.hour() + eventStartDayjs.minute() / 60
		const agendaStartHours =
			agendaStartDayjs.hour() + agendaStartDayjs.minute() / 60

		const v =
			(eventStartHours - agendaStartHours) * sessionDurationHours ** -1
		const value = v * 48 /* cell height */ + 10 /* vertical padding */
		return `${layoutTop + value}px`
	}

	function getHeight() {
		const diff = eventEndDayjs.diff(eventStartDayjs)
		const hoursDiff = diff / 1000 / 60 / 60
		const v = hoursDiff - sessionDurationHours
		if (v > 0 && v < 1) {
			const value =
				v ** -1 * 28 /* cell height */ +
				v ** -1 * 10 /* vertical padding */
			return `${value}px`
		}
		if (v === 1) {
			const value =
				hoursDiff * (1 / sessionDurationHours) * 28 /* cell height */ +
				hoursDiff *
					(1 / sessionDurationHours) *
					10 /* vertical padding */
			return `${value}px`
		}
		if (v >= 1) {
			const k = hoursDiff * (1 / sessionDurationHours)
			const value =
				k * 28 /* cell height */ +
				10 /* start padding */ +
				(k - 2) * 20 /* vertical middle padding */ +
				10 /* end padding */
			// (k - 2) * 1 /* border between cells */
			return `${value}px`
		}

		return '28px'
	}
	function getLeft() {
		let afterCurrentEvent = false
		const matchesWithOverlap = allEvents.filter((item) => {
			if (item.id === event.id || afterCurrentEvent) {
				afterCurrentEvent = true
				return false
			}
			const hasSameOrBeforeStart = dayjs(eventStartDayjs).isSameOrBefore(
				item.start
			)
			const hasSameOrAfterEnd =
				dayjs(item.end).isSame(eventEndDayjs) ||
				dayjs(eventEndDayjs).isAfter(item.end)
			return hasSameOrBeforeStart && hasSameOrAfterEnd
		})

		const canvas = document.createElement('canvas')
		const context = canvas.getContext('2d')

		const relativeLeft = matchesWithOverlap.reduce((value, match) => {
			const metrics = context.measureText(match.customerInfo?.name ?? '')
			return value + metrics.width /* customer name length */ + 60
		}, 0)

		const value =
			54 /* time cell width */ +
			10 * (matchesWithOverlap.length + 1) /* padding left */ +
			relativeLeft

		return `${value}px`
	}

	const { type } = useParams()
	const isWeekTable = type === 'week'

	const scheduleBorder = useColorModeValue('#e0e0e0', 'transparent')

	return (
		<Text
			ref={(instance: HTMLElement) => {
				if (instance && useCellWidth) {
					const parent = instance.parentElement.parentElement
					const py = 10
					instance.style.width = `${parent.clientWidth - py * 2}px`
					instance.style.paddingRight = '4px'
					instance.style.paddingLeft = '4px'
				}
			}}
			flex={1}
			px="10px"
			py="4px"
			borderRadius={3}
			border={`1px solid ${scheduleBorder}`}
			bg={getBg()}
			as="li"
			width="min-content"
			maxWidth="fit-content"
			onClick={(e) => {
				e.stopPropagation()
				if (canUpdateSchedule) {
					editScheduleModal.open()
				}
			}}
			textOverflow="ellipsis"
			whiteSpace="nowrap"
			overflow="hidden"
			position={'absolute'}
			height={getHeight()}
			top={getTop()}
			left={isWeekTable ? undefined : getLeft()}
			right={isWeekTable ? undefined : 0}
		>
			{event?.customerInfo.name}
			<ScheduleFormProvider
				defaultValues={{
					start: event?.start
						? dayjs(event.start).format('YYYY-MM-DDTHH:mm:ss')
						: undefined,
					end: event?.end
						? dayjs(event.end).format('YYYY-MM-DDTHH:mm:ss')
						: undefined,
					customerId: event?.customerInfo._id,
					id: event?.id,
					cellphoneNumber: event?.customerInfo.cellphoneNumber,
					status: event?.status,
					notes: event?.notes,
					title: event?.customerInfo.cellphoneNumber,
				}}
			>
				<EditScheduleModal
					isOpen={editScheduleModal.isOpen}
					onClose={editScheduleModal.close}
					onSubmit={(values) => {
						updateSchedule.mutate(values, {
							onSuccess() {
								editScheduleModal.close()
							},
						})
					}}
					event={event}
				/>
			</ScheduleFormProvider>
		</Text>
	)
}
