import { Box, Flex, Heading, useToast } from '@chakra-ui/react'
import { useFormContext } from 'react-hook-form'
import { useNavigate, useParams } from 'react-router-dom'
import Swal from 'sweetalert2'

import { useGetCustomer } from 'modules/history/queries/useGetCustomer'
import { ImageUpload } from '../ProcedureForm/ImagesUpload'
import { FormFooter } from './FormFooter'
import { useCreateDocument } from 'modules/history/queries/useCreateDocument'
import { InputTextController } from 'app/components/InputTextController'

export function DocumentForm() {
	const form = useFormContext()
	const { customerId, type } = useParams()
	const query = useGetCustomer({ params: { customerId } })
	const customer = query.data

	const navigate = useNavigate()

	const toast = useToast()

	const close = () => navigate('..', { replace: true })

	const mutation = useCreateDocument()

	return (
		<Box height="100%">
			<Heading as="h3" fontSize="20px" fontWeight="700" color="#1188EE">
				{customer?.name}
			</Heading>
			<form
				method="post"
				style={{ height: '90%' }}
				onSubmit={form.handleSubmit((values) => {
					const formData = new FormData()
					formData.set('customer', customerId)
					formData.set('type', type)
					if (values.title) formData.set('title', values.title)
					if (values.files) {
						const filesArray = Array.from(values.files)
						filesArray?.forEach((file) => {
							formData.append('files', file as Blob)
						})
					}

					mutation.mutate(formData, {
						onSuccess() {
							Swal.fire({
								title: 'Sucesso!',
								text: 'Registro criado com sucesso!',
								icon: 'success',
								confirmButtonText: 'Ok',
							})
							close()
						},
						onError() {
							toast({
								title: 'Ops...',
								description: 'Erro ao cadastrar registro!',
								status: 'error',
								duration: 9000,
								isClosable: false,
								position: 'top-right',
							})
						},
					})
				})}
				autoComplete="off"
			>
				<Flex
					flexDirection="column"
					height="95%"
					marginTop="16px"
					justifyContent="space-between"
				>
					<InputTextController
						name="title"
						title="Título"
						type="string"
						width="100%"
						mb={4}
						control={form.control}
						isReadOnly={false}
						isRequired
					/>
					<ImageUpload />
					<FormFooter loading={mutation.isPending} />
				</Flex>
			</form>
		</Box>
	)
}
