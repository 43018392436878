import { useDebugValue } from 'react'
import { useMediaQuery } from '@chakra-ui/react'

export const useBreakpointsMediaQuery = () => {
	const [isMobile] = useMediaQuery('(min-width: 0px) and (max-width: 767px)')
	const [isTablet] = useMediaQuery(
		'(min-width: 768px) and (max-width: 959px)'
	)
	const [isDesktop] = useMediaQuery('(min-width: 960px)')

	useDebugValue({
		isMobile: { isMobile, value: 'abaixo de 767px' },
		isTablet: { isTablet, value: 'entre 768px e 959px' },
		isDesktop: { isDesktop, value: 'a partir de 960px' },
	})

	return {
		isMobile,
		isTablet,
		isDesktop,
	}
}
