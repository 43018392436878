import { Item } from 'react-stately'
import {
	Input,
	InputGroup,
	InputProps,
	InputRightElement,
	Spinner,
} from '@chakra-ui/react'
import { useComboBoxState } from 'react-stately'
import { useComboBox, useFilter, AriaComboBoxProps } from 'react-aria'
import { useFormContext } from 'react-hook-form'
import React from 'react'
import { ListBox } from './ListBox'
import { Popover } from './Popover'

type Props<T> = AriaComboBoxProps<T> &
	Omit<InputProps, 'children'> & {
		name?: string

		loading?: boolean
		loadingMore?: boolean
		onLoadMore?: () => void
	}

export function Combobox<T extends object>({
	name,

	items,
	children,
	inputValue,
	onInputChange,

	loading,
	loadingMore,
	onLoadMore,
	...props
}: Props<T>) {
	const form = useFormContext()

	const filter = useFilter({ sensitivity: 'base' })
	const state = useComboBoxState({
		...props,
		items,
		children,
		inputValue,
		onInputChange,
		onSelectionChange(key) {
			form?.setValue(name, key)
		},
		defaultFilter: filter.contains,
	})
	const inputRef = React.useRef<HTMLInputElement>(null)
	const listBoxRef = React.useRef(null)
	const popoverRef = React.useRef(null)
	const combobox = useComboBox(
		{ ...props, items, inputRef, listBoxRef, popoverRef },
		state
	)

	React.useEffect(() => {
		if (!loading && inputRef.current === document.activeElement) {
			state.open()
		}
	}, [loading])

	React.useLayoutEffect(() => {
		form?.register(name, { required: true })?.ref(inputRef.current)
	}, [name])

	return (
		<InputGroup position="relative" size="md">
			<Input
				{...combobox.inputProps}
				ref={inputRef}
				size="md"
				onFocus={() => state.open()}
				{...props}
			/>
			{loading && (
				<InputRightElement>
					<Spinner color="primary.800" size="sm" />
				</InputRightElement>
			)}

			{state.isOpen && (
				<Popover
					popoverRef={popoverRef}
					triggerRef={inputRef}
					state={state}
					isNonModal
					placement="bottom start"
				>
					<ListBox
						{...combobox.listBoxProps}
						listBoxRef={listBoxRef}
						state={state}
						loadingMore={loadingMore}
						onLoadMore={onLoadMore}
					/>
				</Popover>
			)}
		</InputGroup>
	)
}

Combobox.Item = Item
