import { Modal } from 'app/components/Modal'
import { useBreakpointsMediaQuery } from 'app/hooks/useMediaQuery'
import React from 'react'
import { useFormContext } from 'react-hook-form'
import ScheduleBodyModal from './ScheduleBodyModal'
import ScheduleFooterModal from './ScheduleFooterModal'

export interface ScheduleModalProps {
	isOpen: boolean
	onClose: () => void
	onSubmit: (data: any) => void
}

const ScheduleModal: React.FC<ScheduleModalProps> = ({
	isOpen,
	onClose,
	onSubmit,
}) => {
	const { isDesktop } = useBreakpointsMediaQuery()
	const SIZE_MODAL = isDesktop ? 'md' : 'full'

	const { handleSubmit } = useFormContext()

	return (
		<Modal
			isOpen={isOpen}
			onClose={onClose}
			title="Agendamento"
			body={
				<form
					id="create-schedule-form"
					onSubmit={handleSubmit(onSubmit)}
					autoComplete="off"
				>
					<ScheduleBodyModal />
				</form>
			}
			footer={<ScheduleFooterModal onClose={onClose} />}
			handleForm={() => undefined}
			dividers
			size={SIZE_MODAL}
		/>
	)
}

export default ScheduleModal
