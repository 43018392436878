import { Document, Page, StyleSheet, Text, View } from '@react-pdf/renderer'
import dayjs from 'dayjs'
import { PdfLink } from 'app/components/PdfLink'

interface Schedule {
	date: string
	dentist?: { name: string }
	events: {
		id: string
		customerInfo?: { name: string; cellphoneNumber: string }
		start: string
		end: string
		notes?: string
	}[]
}

interface Props {
	data: Schedule
}

const styles = StyleSheet.create({
	container: { paddingHorizontal: 20, paddingVertical: 24 },
	title: {
		fontSize: 20,
		fontFamily: 'Open Sans',
		fontWeight: 600,
		textAlign: 'center',
		marginBottom: 24,
	},
	list: { gap: 12 },
	line: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
		fontSize: 14,
	},
	highlight: { fontFamily: 'Open Sans', fontWeight: 600 },
	value: { textAlign: 'right', maxWidth: '70%' },
})

export function SchedulePdfLink({ data }: Props) {
	return (
		<PdfLink
			name="agenda.pdf"
			document={
				<Document>
					<Page style={styles.container}>
						<Text style={styles.title}>
							Agenda do dia {data.date} de {data.dentist.name}
						</Text>

						{data && (
							<View style={styles.list}>
								{data.events.map((event) => {
									return (
										<View key={event.id}>
											<View style={styles.line}>
												<Text style={styles.highlight}>
													{event.customerInfo?.name ??
														'(Excluído)'}
												</Text>
												<Text>
													{dayjs(event.start).format(
														'HH:mm'
													)}{' '}
													a{' '}
													{dayjs(event.end).format(
														'HH:mm'
													)}
												</Text>
											</View>

											{event.notes && (
												<View style={styles.line}>
													<Text
														style={styles.highlight}
													>
														Observação
													</Text>
													<Text style={styles.value}>
														{event.notes ?? ''}
													</Text>
												</View>
											)}
										</View>
									)
								})}
							</View>
						)}
					</Page>
				</Document>
			}
		/>
	)
}
