import { Center, Text } from '@chakra-ui/react'

interface InfoCardProps {
	title: string
	icon: JSX.Element
	content: string
	color: string
}

export function InfoCard({ title, icon, content, color }: InfoCardProps) {
	return (
		<Center
			h="32"
			w="208px"
			flexDirection="column"
			gap="10px"
			px="3"
			bg="#f4f4f4"
			_dark={{ bg: '#263849' }}
			borderLeft={`3px solid ${color}`}
			rounded="md"
		>
			<Text fontWeight="600">{title}</Text>
			<Text color={color}>{icon}</Text>
			<Text
				color={color}
				fontSize="22px"
				height="33px"
				fontWeight="600"
				whiteSpace="nowrap"
			>
				{content}
			</Text>
		</Center>
	)
}
