import { SpinnerIcon } from '@chakra-ui/icons'
import { Button } from '@chakra-ui/react'
import { useIsMutating } from '@tanstack/react-query'

interface ScheduleFooterModalProps {
	onClose: () => void
}
const ScheduleFooterModal: React.FC<ScheduleFooterModalProps> = ({
	onClose,
}) => {
	const isLoading = useIsMutating({
        mutationKey: ['create_schedule']
    })

	return (
		<>
			<Button colorScheme="gray" mr={3} size="sm" onClick={onClose}>
				Cancelar
			</Button>
			<Button
				form="create-schedule-form"
				colorScheme="blue"
				size="sm"
				type="submit"
				isLoading={!!isLoading}
			>
				Agendar
			</Button>
		</>
	)
}

export default ScheduleFooterModal
