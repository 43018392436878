import { useMutation, useQueryClient } from '@tanstack/react-query'
import api from 'services/api'

export function useSaveProductMutation() {
	const client = useQueryClient()
	return useMutation({
		async mutationFn(variables: { _id?: string; name: string }) {
			const product = variables
			if (product._id) {
				await api.put(`/products/${product._id}`, product)
			} else {
				await api.post(`/products`, product)
			}
		},
		onSuccess: () => client.invalidateQueries({ queryKey: ['products'] }),
	})
}
