import {
	Flex,
	Skeleton,
	Table,
	TableContainer,
	Text,
	useColorModeValue,
} from '@chakra-ui/react'
import { Footer } from '../PacientTable/Footer'
import { useHistoryQuery } from 'modules/history/queries/useHistoryQuery'
import { THeadMobile } from './THeadMobile'
import { TBodyMobile } from './TBodyMobile'

export function MobileTableContainer() {
	const bg = useColorModeValue('#FFF', '#223344')
	const border = useColorModeValue('#F2F2F2', 'transparent')

	const query = useHistoryQuery()
	const history = query.data?.data

	return (
		<>
			{query.isLoading && (
				<Flex
					paddingY="24px"
					paddingX="28px"
					flexDirection="column"
					rowGap="16px"
					alignItems="center"
					justifyContent="center"
					as="main"
					bg={bg}
					borderRadius="12px"
					border={`1px solid ${border}`}
				>
					<Skeleton
						height="60vh"
						width="100%"
						mt={{ sm: '0px', md: '32px', lg: '32px' }}
					/>
				</Flex>
			)}
			{query.data && (
				<Flex
					paddingY="24px"
					paddingX="28px"
					flexDirection="column"
					rowGap="16px"
					alignItems="center"
					justifyContent="center"
					as="main"
					bg={bg}
					borderRadius="12px"
					border={`1px solid ${border}`}
				>
					{history?.length > 0 && (
						<>
							{history?.map((item) => (
								<TableContainer
									key={`history:${item._id}`}
									width="100%"
									border={`1px solid`}
									borderColor="#C6C6C6"
									_dark={{ borderColor: '#777777' }}
									borderRadius="4px"
									opacity={
										item.procedure?.deleted ? '0.3' : '1'
									}
								>
									<Table display="flex">
										<THeadMobile />
										<TBodyMobile
											customer={item.customer}
											dentist={item.dentist}
											date={
												item.treatment?.createdAt ??
												item.procedure?.date
											}
											name={
												item.treatment
													? 'Orçamento'
													: item.procedure?.name
											}
										/>
									</Table>
								</TableContainer>
							))}
							<Footer />
						</>
					)}
					{history?.length === 0 && (
						<Text
							as="p"
							width="75%"
							color="#3388CC"
							fontSize="20px"
							fontWeight="700"
							textAlign="center"
							marginX="auto"
						>
							Não há procedimentos para serem mostrados
						</Text>
					)}
				</Flex>
			)}
		</>
	)
}
