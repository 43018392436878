import React from 'react'
import {
	Accordion,
	AccordionButton,
	AccordionIcon,
	AccordionItem,
	AccordionPanel,
	Button,
	Center,
	Flex,
	FormControl,
	FormLabel,
	Heading,
	Input,
	Modal,
	ModalCloseButton,
	ModalContent,
	ModalOverlay,
	Select,
	Stack,
	Text,
	Textarea,
	useModalContext,
	useToast,
} from '@chakra-ui/react'
import { Slot } from '@radix-ui/react-slot'
import { FormProvider, useForm } from 'react-hook-form'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { useSearchParams } from 'react-router-dom'

import {
	CreateTreatmentArgs,
	TreatmentsRepository,
	UpdateTreatmentArgs,
} from 'data/TreatmentsRepository'
import { Treatment } from 'data/Treatment'
import {
	proceduresQueryOptions,
	useProceduresQuery,
} from '../../queries/useProceduresQuery'
import {
	professionalsQueryOptions,
	useProfessionalsQuery,
} from '../../queries/useProfessionalsQuery'
import {
	infiniteCustomersQueryOptions,
	useInfiniteCustomersQuery,
} from '../../queries/useInfiniteCustomersQuery'
import { ProceduresFields } from './ProceduresFields'
import { CurrencyInput } from './CurrencyInput'
import { Combobox } from './Combobox'
import { Payments } from './Payments'
import { useTreatmentsQuery } from '../../queries/useTreatmentsQuery'
import dayjs from 'dayjs'
import { getUserInfoInStorage } from 'services/api/auth'
import api from 'services/api'

interface TreatmentModalProps {
	trigger: JSX.Element
	treatment?: Treatment
	isApproval?: boolean
}

export function TreatmentModal({
	trigger,
	treatment,
	isApproval,
}: TreatmentModalProps) {
	const [isOpen, setIsOpen] = React.useState(false)
	const onClose = () => setIsOpen(false)

	const isUpdate = !!treatment

	const client = useQueryClient()

	const treatmentsQuery = useTreatmentsQuery()
	const treatments = treatmentsQuery.data?.data
	const preselectedCustomer = treatment?.customer ?? treatments?.[0]?.customer

	return (
		<>
			<Slot
				onClick={() => {
					window.requestAnimationFrame(async () => {
						if (isUpdate)
							// ensure all necessary data is loaded to show form
							await Promise.all([
								client.ensureQueryData(
									professionalsQueryOptions
								),
								client.ensureQueryData(proceduresQueryOptions),
								client.getQueryData(
									infiniteCustomersQueryOptions(
										preselectedCustomer && {
											name: preselectedCustomer?.name,
										}
									).queryKey
								) ??
									client.prefetchInfiniteQuery(
										infiniteCustomersQueryOptions(
											preselectedCustomer && {
												name: preselectedCustomer?.name,
											}
										)
									),
							])
						setIsOpen(true)
					})
				}}
			>
				{trigger}
			</Slot>

			<Modal isOpen={isOpen} onClose={onClose} isCentered size="xl">
				<ModalOverlay />
				<ModalContent px="6" py="6">
					<Flex alignItems="center" justify="space-between" mb="5">
						<Heading fontSize="xl">
							{isApproval
								? 'Aprovar Orçamento'
								: isUpdate
								? 'Editar Orçamento'
								: 'Novo Orçamento'}
						</Heading>
						<ModalCloseButton size="md" top="5" />
					</Flex>

					<TreatmentForm
						treatment={treatment}
						isApproval={isApproval}
					/>
				</ModalContent>
			</Modal>
		</>
	)
}

interface Props {
	treatment?: Treatment | undefined
	isApproval?: boolean
}

function TreatmentForm({ treatment, isApproval }: Props) {
	const isUpdate = !!treatment
	const [searchParams] = useSearchParams()

	//#region Data Fetching
	const treatmentsQuery = useTreatmentsQuery()
	const treatments = treatmentsQuery.data?.data
	const urlCustomerId = searchParams.get('customer')
	const preselectedCustomer =
		treatment?.customer ?? (urlCustomerId && treatments?.[0]?.customer)

	const [customerSearch, setCustomerSearch] = React.useState(
		preselectedCustomer?.name ?? ''
	)
	const customersQuery = useInfiniteCustomersQuery({ name: customerSearch })

	const professionalsQuery = useProfessionalsQuery()
	const professionals = professionalsQuery.data
	const preselectedDentistId =
		treatment?.professional?.id ??
		(sessionStorage.getItem('agenda:professionalId') ||
			professionals?.find(
				(it) => it.userId === getUserInfoInStorage()?.id
			)?.id)
	const proceduresQuery = useProceduresQuery()

	//#endregion

	//#region Form Handling
	const modal = useModalContext()
	const form = useForm({
		defaultValues: {
			dentist: preselectedDentistId ?? '',
			customer: preselectedCustomer?.id ?? urlCustomerId ?? '',
			startDate:
				treatment?.startDate?.split('T')[0] ??
				dayjs().format('YYYY-MM-DD'),
			description: treatment?.description ?? '',
			items: treatment?.items.map((it) => ({
				quantity: it.quantity,
				teeth: it.teeth,
				procedure: it.procedure.id,
			})) ?? [{ quantity: 1, teeth: '', procedure: '' }],
			discount: treatment?.discount ?? 0,
		},
	})

	const selectedCustomerId = form.watch('customer')
	const { data: selectedCustomer } = useQuery({
		queryKey: ['current_customer', urlCustomerId],
		queryFn: async () => {
			const r = await api.get(`/customers/${urlCustomerId}`)
			return { id: r.data._id, name: r.data.name }
		},
		initialData:
			urlCustomerId &&
			customersQuery.data?.find((it) => it.id === selectedCustomerId),
		enabled: !!urlCustomerId,
	})

	React.useLayoutEffect(() => {
		if (selectedCustomer && selectedCustomer.name !== customerSearch) {
			setCustomerSearch(selectedCustomer.name)
		}
	}, [customerSearch, selectedCustomer])

	if (professionals && preselectedDentistId && !form.getValues('dentist')) {
		form.setValue('dentist', preselectedDentistId)
	}

	const { errors } = form.formState

	const createTreatmentMutation = useCreateTreatmentMutation()
	const updateTreatmentMutation = useUpdateTreatmentMutation()
	const approveTreatmentMutation = useApproveTreatmentMutation()

	const mutation = isApproval
		? approveTreatmentMutation
		: isUpdate
		? updateTreatmentMutation
		: createTreatmentMutation

	const toast = useToast()
	const onSave = form.handleSubmit(
		(values) => {
			const showErrorMessage = (error: Error) =>
				toast({
					status: 'error',
					title: 'Erro de servidor',
					description: error.message,
				})
			if (isApproval) {
				approveTreatmentMutation.mutate(
					{ id: treatment.id, ...values },
					{
						onSuccess() {
							toast({
								status: 'success',
								title: 'Aprovado',
								description:
									'Orçamento foi aprovado com sucesso',
							})
							modal.onClose()
						},
						onError: showErrorMessage,
					}
				)
			} else if (isUpdate) {
				updateTreatmentMutation.mutate(
					{ ...values, id: treatment.id },
					{
						onSuccess() {
							toast({
								status: 'success',
								title: 'Salvo',
								description:
									'Orçamento foi atualizado com sucesso',
							})
							modal.onClose()
						},
						onError: showErrorMessage,
					}
				)
			} else {
				createTreatmentMutation.mutate(values, {
					onSuccess() {
						toast({
							status: 'success',
							title: 'Salvo',
							description: 'Orçamento foi criado com sucesso',
						})
						modal.onClose()
					},
					onError: showErrorMessage,
				})
			}
		},
		() => {
			toast({
				status: 'error',
				title: 'Erro de validação',
				description: 'Preencha todos os campos para salvar',
			})
		}
	)
	//#endregion

	const items = form.watch('items')
	const subtotal = items.reduce((result, item) => {
		const procedure = proceduresQuery.data?.find(
			(it) => it.id === item.procedure
		)
		if (!procedure) return result
		result += procedure.value * (item.quantity || 0)
		return result
	}, 0)

	const discount = form.watch('discount')
	const discountPercentage = Math.floor((discount / subtotal) * 100)
	const total = subtotal - discount

	return (
		<FormProvider {...form}>
			<form onSubmit={onSave} noValidate>
				<Accordion defaultIndex={[0, 1, 2]} allowMultiple mb="6">
					<FormAccordionItem title="Dados Gerais">
						<FormControl
							mb="5"
							isRequired
							isInvalid={!!errors.dentist}
						>
							<FormLabel fontSize="sm" fontWeight="600">
								Selecione o profissional
							</FormLabel>
							<Select
								key={
									professionalsQuery.isPending
										? 'dpending'
										: 'dsuccess'
								}
								{...form.register('dentist', {
									required: true,
								})}
								size="sm"
								placeholder="Selecione"
							>
								{professionalsQuery.data?.map((it) => (
									<option key={it.id} value={it.id}>
										{it.name}
									</option>
								))}
							</Select>
						</FormControl>

						<FormControl
							mb="5"
							isRequired
							isInvalid={!!errors.customer}
						>
							<FormLabel
								htmlFor="customer_combobox"
								fontSize="sm"
								fontWeight="600"
							>
								Selecione o paciente
							</FormLabel>
							<Combobox
								id="customer_combobox"
								name="customer"
								items={customersQuery.data ?? []}
								loading={
									(!customersQuery.data ||
										customersQuery.isPlaceholderData) &&
									customersQuery.isFetching
								}
								inputValue={customerSearch}
								onInputChange={setCustomerSearch}
								loadingMore={customersQuery.isFetchingNextPage}
								onLoadMore={React.useCallback(() => {
									if (
										customersQuery.hasNextPage &&
										!customersQuery.isFetching
									)
										customersQuery.fetchNextPage()
								}, [
									customersQuery.hasNextPage,
									customersQuery.isFetching,
								])}
							>
								{(item) => (
									<Combobox.Item key={item.id}>
										{item.name}
									</Combobox.Item>
								)}
							</Combobox>
						</FormControl>

						<FormControl mb="5" isInvalid={!!errors.startDate}>
							<FormLabel fontSize="sm" fontWeight="600">
								Data início
							</FormLabel>
							<Input
								size="sm"
								type="date"
								{...form.register('startDate')}
							/>
						</FormControl>

						<FormControl mb="5">
							<FormLabel fontSize="sm" fontWeight="600">
								Descrição
							</FormLabel>
							<Textarea
								{...form.register('description')}
								placeholder="Escreva aqui..."
								size="sm"
							/>
						</FormControl>
					</FormAccordionItem>

					<FormAccordionItem title="Procedimentos">
						<ProceduresFields />
					</FormAccordionItem>

					<Stack w="210px" gap="0" ml="auto" mb="8">
						<Heading as="h4" fontSize="sm" mb="4">
							Resumo
						</Heading>

						<Stack
							borderY="1px solid"
							borderColor="gray.200"
							py="4"
						>
							<Flex
								justify="space-between"
								alignItems="center"
								mb="4"
							>
								<Heading as="h4" fontSize="sm">
									Subtotal
								</Heading>
								<Text fontSize="sm">
									{formatAsCurrency(subtotal)}
								</Text>
							</Flex>
							<Flex
								justify="space-between"
								alignItems="center"
								mb="4"
							>
								<Heading as="h4" fontSize="sm">
									Desconto (R$)
								</Heading>
								<CurrencyInput
									name="discount"
									maxW="24"
									size="sm"
									textAlign="right"
								/>
							</Flex>
							<Flex
								justify="space-between"
								alignItems="center"
								mb="4"
							>
								<Heading as="h4" fontSize="sm">
									Desconto (%)
								</Heading>
								<Input
									value={discountPercentage || ''}
									onChange={(e) => {
										const newValue = e.target.value
										if (!newValue) {
											form.setValue('discount', 0)
											return
										}
										const valueAsNumber =
											Number(
												newValue.replace(/[^\d]/g, '')
											) || 0

										if (valueAsNumber > 100) return

										form.setValue(
											'discount',
											(valueAsNumber / 100) * subtotal
										)
									}}
									textAlign="right"
									maxW="24"
									size="sm"
								/>
							</Flex>
						</Stack>

						<Flex
							justify="space-between"
							alignItems="center"
							pt="4"
						>
							<Heading fontSize="lg" fontWeight="500">
								Total
							</Heading>
							<Text>{formatAsCurrency(total)}</Text>
						</Flex>
					</Stack>

					{isApproval && (
						<FormAccordionItem title="Pagamentos">
							<Payments />
						</FormAccordionItem>
					)}
				</Accordion>

				<Flex justify="space-evenly" flexDir="row-reverse">
					<Button
						type="submit"
						size="sm"
						w="130px"
						h="32px"
						colorScheme="primary"
						textTransform="uppercase"
						isLoading={mutation.isPending}
					>
						{isApproval ? 'Aprovar' : 'Salvar'}
					</Button>
					<Button
						size="sm"
						w="130px"
						h="32px"
						colorScheme="primary"
						variant="outline"
						textTransform="uppercase"
						onClick={modal.onClose}
					>
						Cancelar
					</Button>
				</Flex>
			</form>
		</FormProvider>
	)
}

function FormAccordionItem({
	children,
	title,
}: React.PropsWithChildren<{ title: string }>) {
	return (
		<AccordionItem rounded="6px" mb="3" border="none">
			<AccordionButton
				border="1px solid"
				borderColor="gray.100"
				roundedTop="6px"
				color="primary.800"
				fontWeight="600"
				justifyContent="space-between"
				_dark={{ color: 'primary.200', borderColor: 'gray.600' }}
			>
				{title}
				<Center
					w="6"
					h="6"
					p="2"
					rounded="md"
					bg="primary.800"
					color="white"
					_dark={{ bg: 'primary.200', color: 'gray.800' }}
				>
					<AccordionIcon />
				</Center>
			</AccordionButton>
			<AccordionPanel
				border="1px solid"
				borderColor="gray.100"
				roundedBottom="6px"
				px={5}
				py={5}
				_dark={{ borderColor: 'gray.600' }}
			>
				{children}
			</AccordionPanel>
		</AccordionItem>
	)
}

function useCreateTreatmentMutation() {
	const client = useQueryClient()
	return useMutation({
		mutationFn(data: CreateTreatmentArgs) {
			return new TreatmentsRepository().createTreatment(data)
		},
		onSettled() {
			return client.invalidateQueries({ queryKey: ['treatments'] })
		},
	})
}

function useUpdateTreatmentMutation() {
	const client = useQueryClient()
	return useMutation({
		async mutationFn(variables: { id: string } & UpdateTreatmentArgs) {
			await new TreatmentsRepository().updateTreatment(
				variables.id,
				variables
			)
		},
		onSettled() {
			return client.invalidateQueries({ queryKey: ['treatments'] })
		},
	})
}

function useApproveTreatmentMutation() {
	const client = useQueryClient()
	return useMutation({
		async mutationFn(variables: { id: string } & UpdateTreatmentArgs) {
			await new TreatmentsRepository().approveTreatment(
				variables.id,
				variables
			)
		},
		onSettled() {
			return client.invalidateQueries({ queryKey: ['treatments'] })
		},
	})
}

function formatAsCurrency(rawValue: number) {
	const currency = 'BRL'
	const formattedValue = new Intl.NumberFormat(undefined, {
		style: 'currency',
		currency,
	})
		.format(rawValue)
		.trim()
	return formattedValue
}
