import { useQuery } from '@tanstack/react-query'
import api from 'services/api'
import { settingsSchema } from '../validations/settingsSchema'

export function useSettingsDefault() {
	return useQuery({
		queryKey: ['settings_default'],

		async queryFn() {
			const response = await api.get('/settings/default')
			const data = await settingsSchema.validate(response.data, {
				stripUnknown: true,
			})
			return data
		},

		refetchOnWindowFocus: false,
	})
}
