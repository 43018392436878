import toast from 'react-hot-toast'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import api from 'services/api'

import { Settings } from '../validations/settingsSchema'

export function useSaveSettingsDefault() {
	const client = useQueryClient()

	return useMutation({
        mutationKey: ['save_settings_default'],

        async mutationFn(data: Settings) {
			await api.put('/settings/default', data)
		},

        onSuccess() {
			client.invalidateQueries({
                queryKey: ['settings_default']
            })
			toast.success('Salvo!')
		},

        onError() {
			toast.error('Erro ao salvar!')
		}
    });
}
