/* eslint-disable no-plusplus */
import { Stack } from '@chakra-ui/react'
import { FormProvider, useForm } from 'react-hook-form'
import React from 'react'
import { yupResolver } from '@hookform/resolvers/yup'

import {
	Settings,
	settingsSchema,
} from 'modules/schedule/validations/settingsSchema'
import { Select } from 'app/components/Select'
import { useProfessionals } from 'modules/schedule/queries/useProfessionals'
import { useSettingsByProfessional } from 'modules/schedule/queries/useSettingsByProfessional'
import { useSaveSettingsByProfessional } from 'modules/schedule/queries/useSaveSettingsByProfessional'

import { SettingsForm } from './SettingsForm'

interface Props {
	onCancel: () => void
}

export function SettingsByProfessional({ onCancel }: Props) {
	const [professionalId, setProfessionalId] = React.useState('')
	const professionals = useProfessionals()
	const settings = useSettingsByProfessional(professionalId)
	const form = useForm<Settings>({
		resolver: yupResolver(settingsSchema),
	})
	const { reset, control, handleSubmit } = form

	const resetCountRef = React.useRef(0)
	React.useEffect(() => {
		reset(settings.data ?? {})
		resetCountRef.current++
	}, [settings.data])

	const saveSettings = useSaveSettingsByProfessional(professionalId)

	return (
		<FormProvider {...form} key={resetCountRef.current}>
			<Stack>
				<Select
					name="s"
					placeholder="Selecione um profissional"
					value={professionalId}
					title="Selecione o Profissional"
					width="100%"
					items={(professionals.data || []).map((d) => ({
						value: d.id,
						label: d.name,
					}))}
					onChange={(e) => setProfessionalId(e.target.value)}
				/>

				<SettingsForm
					onSubmit={handleSubmit((values) => {
						saveSettings.mutate(values)
					})}
					control={control}
					onCancel={onCancel}
				/>
			</Stack>
		</FormProvider>
	)
}
