/* eslint-disable no-underscore-dangle */
import dayjs from 'dayjs'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useParams } from 'react-router-dom'
import api from 'services/api'

interface AnnotationForm {
	body: string
	start: string
	end: string
}

export function useCreateAnnotation() {
	const { professionalId } = useParams()

	const client = useQueryClient()

	return useMutation({
        mutationKey: ['save_annotation'],

        mutationFn({ body, start, end }: AnnotationForm) {
			return api.post(`/dentists/${professionalId}/annotations`, {
				body,
				start: dayjs(start).tz('Etc/Greenwich').toISOString(),
				end: dayjs(end).tz('Etc/Greenwich').toISOString(),
			})
		},

        onSuccess() {
			client.invalidateQueries({
				queryKey: ['professional_schedule'],
			})
		}
    });
}
