import React from 'react'
import {
	Flex,
	Heading,
	Modal,
	ModalCloseButton,
	ModalContent,
	ModalOverlay,
	useDisclosure,
	FormControl,
	FormLabel,
	Select,
	Input,
	Textarea,
	Checkbox,
	Button,
	useModalContext,
	useToast,
} from '@chakra-ui/react'
import { Slot } from '@radix-ui/react-slot'
import { FormProvider, useForm } from 'react-hook-form'
import IMask from 'imask'
import { useParams } from 'react-router-dom'

import { useInfiniteCustomersQuery } from '../../queries/useInfiniteCustomersQuery'
import { useProfessionalsQuery } from '../../queries/useProfessionalsQuery'
import { getUserInfoInStorage } from 'services/api/auth'
import { Combobox } from 'app/components/Combobox'
import { Payment } from 'data/Payment'
import { useCreatePaymentMutation } from 'modules/financial/queries/useCreatePaymentMutation'
import { useUpdatePaymentMutation } from 'modules/financial/queries/useUpdatePayment'
import { CurrencyInput } from '../../../../app/components/CurrencyInput'
import { useCustomerQuery } from 'modules/financial/queries/useCustomerQuery'
import { UploadInput } from 'app/components/UploadInput'

interface PaymentModalProps {
	payment?: Payment
	trigger: JSX.Element
}

export function PaymentModal({ payment, trigger }: PaymentModalProps) {
	const state = useDisclosure()

	const params = useParams()
	const preselectedCustomerId = payment?.customer?.id ?? params.id
	const customerQuery = useCustomerQuery(preselectedCustomerId)
	const customer = customerQuery.data

	return (
		<>
			<Slot onClick={state.onOpen}>{trigger}</Slot>

			<Modal
				isOpen={state.isOpen}
				onClose={state.onClose}
				isCentered
				size="xl"
			>
				<ModalOverlay />
				<ModalContent px="6" py="6">
					<Flex alignItems="center" justify="space-between" mb="5">
						<Heading fontSize="xl">Pagamento</Heading>
						<ModalCloseButton size="md" top="5" />
					</Flex>

					{(!preselectedCustomerId || !!customer) && (
						<PaymentForm payment={payment} customer={customer} />
					)}
				</ModalContent>
			</Modal>
		</>
	)
}

interface Props {
	payment?: Payment
	customer?: { name: string }
}

function PaymentForm({ payment, customer }: Props) {
	const params = useParams()
	const modal = useModalContext()
	const toast = useToast()
	const [customerSearch, setCustomerSearch] = React.useState(
		payment?.customer?.name ?? customer?.name ?? ''
	)
	const customersQuery = useInfiniteCustomersQuery({ name: customerSearch })
	const professionalsQuery = useProfessionalsQuery()
	const createPaymentMutation = useCreatePaymentMutation()
	const updatePaymentMutation = useUpdatePaymentMutation()

	const professionals = professionalsQuery.data
	const preselectedCustomerId = payment?.customer?.id ?? params.id
	const preselectedDentistId =
		payment?.professional?.id ??
		(sessionStorage.getItem('agenda:professionalId') ||
			professionals?.find(
				(it) => it.userId === getUserInfoInStorage()?.id
			)?.id)

	const form = useForm({
		defaultValues: {
			...payment,
			dueDate: payment?.dueDate?.slice(0, 10),
			paymentDate: payment?.paymentDate?.slice(0, 10),
			customer: preselectedCustomerId,
			dentist: preselectedDentistId,
		},
	})

	const mutation = payment?.id ? updatePaymentMutation : createPaymentMutation
	const onSave = form.handleSubmit(
		(data) => {
			if (payment) Object.assign(data, { id: payment.id })
			mutation.mutate(data, { onSuccess: () => modal.onClose() })
		},
		() =>
			toast({
				status: 'error',
				title: 'Erro de validação',
				description: 'Preencha corretamente todos os campos inválidos',
			})
	)

	const { errors } = form.formState

	const currentCustomer = customersQuery.data?.find(
		(it) => it.id === form.getValues('customer')
	)
	if (currentCustomer && !form.getValues('payerCpf')) {
		form.setValue(
			'payerCpf',
			currentCustomer.legalRepresentatives?.[0]?.cpf ??
				currentCustomer.cpf
		)
	}

	return (
		<FormProvider {...form}>
			<form onSubmit={onSave} noValidate>
				<FormControl mb="5" isRequired isInvalid={!!errors.dentist}>
					<FormLabel fontSize="sm" fontWeight="600">
						Profissional
					</FormLabel>
					<Select
						{...form.register('dentist', { required: true })}
						size="sm"
						placeholder="Selecione"
						key={`select_dentist:${
							professionals ? 'loaded' : 'loading'
						}`}
					>
						{professionalsQuery.data?.map((it) => (
							<option key={it.id} value={it.id}>
								{it.name}
							</option>
						))}
					</Select>
				</FormControl>

				<FormControl mb="5" isRequired isInvalid={!!errors.customer}>
					<FormLabel
						htmlFor="customer_combobox"
						fontSize="sm"
						fontWeight="600"
					>
						Paciente
					</FormLabel>
					<Combobox
						size="sm"
						id="customer_combobox"
						name="customer"
						items={customersQuery.data ?? []}
						loading={
							(!customersQuery.data ||
								customersQuery.isPlaceholderData) &&
							customersQuery.isFetching
						}
						inputValue={customerSearch}
						onInputChange={setCustomerSearch}
						loadingMore={customersQuery.isFetchingNextPage}
						onLoadMore={React.useCallback(() => {
							if (
								customersQuery.hasNextPage &&
								!customersQuery.isFetching
							)
								customersQuery.fetchNextPage()
						}, [
							customersQuery.hasNextPage,
							customersQuery.isFetching,
						])}
					>
						{(item) => (
							<Combobox.Item key={item.id}>
								{item.name}
							</Combobox.Item>
						)}
					</Combobox>
				</FormControl>

				<FormControl
					mb="5"
					isRequired
					isInvalid={!!errors.paymentMethod}
				>
					<FormLabel fontSize="sm" fontWeight="600">
						Método de pagamento
					</FormLabel>
					<Select
						{...form.register('paymentMethod', {
							required: true,
						})}
						size="sm"
						placeholder="Selecione"
					>
						{PAYMENT_METHODS.map((it) => (
							<option key={it.value} value={it.value}>
								{it.name}
							</option>
						))}
					</Select>
				</FormControl>

				<FormControl mb="5" isRequired isInvalid={!!errors.dueDate}>
					<FormLabel fontSize="sm" fontWeight="600">
						Data de vencimento
					</FormLabel>
					<Input
						{...form.register('dueDate', {
							required: true,
							setValueAs(value) {
								if (!value) return
								return new Date(value)
							},
						})}
						size="sm"
						type="date"
					/>
				</FormControl>

				<FormControl mb="5" isRequired isInvalid={!!errors.value}>
					<FormLabel fontSize="sm" fontWeight="600">
						Valor
					</FormLabel>

					<CurrencyInput
						name="value"
						rules={{ required: true }}
						size="sm"
					/>
				</FormControl>

				<FormControl mb="5">
					<FormLabel fontSize="sm" fontWeight="600">
						Observação
					</FormLabel>
					<Textarea size="sm" />
				</FormControl>

				<FormControl mb="5" isRequired isInvalid={!!errors.payerCpf}>
					<FormLabel fontSize="sm" fontWeight="600">
						CPF Pagador
					</FormLabel>
					<Input
						{...form.register('payerCpf', { required: true })}
						size="sm"
						onInput={(e) =>
							IMask(e.currentTarget, { mask: '000.000.000-00' })
						}
					/>
				</FormControl>
				<FormControl mb="5">
					<FormLabel fontSize="sm" fontWeight="600">
						Multa
					</FormLabel>
					<CurrencyInput name="fine" size="sm" />
				</FormControl>
				<FormControl mb="5" isRequired isInvalid={!!errors.paymentDate}>
					<FormLabel fontSize="sm" fontWeight="600">
						Data de Pagamento
					</FormLabel>
					<Input
						{...form.register('paymentDate', { required: true })}
						size="sm"
						type="date"
					/>
				</FormControl>

				<Checkbox
					{...form.register('withNote')}
					colorScheme="primary"
					mb="5"
				>
					Registrar nota
				</Checkbox>

				{form.watch('withNote') && (
					<FormControl mb="5">
						<FormLabel fontSize="sm" fontWeight="600">
							Upload recibo
						</FormLabel>
						<UploadInput name="file" />
					</FormControl>
				)}

				<Flex justify="space-evenly" flexDir="row-reverse" mt="8">
					<Button
						type="submit"
						size="sm"
						w="130px"
						h="32px"
						colorScheme="primary"
						textTransform="uppercase"
						isLoading={mutation.isPending}
					>
						Salvar
					</Button>
					<Button
						size="sm"
						w="130px"
						h="32px"
						colorScheme="primary"
						variant="outline"
						textTransform="uppercase"
						onClick={modal.onClose}
					>
						Cancelar
					</Button>
				</Flex>
			</form>
		</FormProvider>
	)
}

const PAYMENT_METHODS = [
	{ name: 'Pix', value: 'pix' },
	{ name: 'Crédito', value: 'credit_card' },
	{ name: 'Débito', value: 'debit_card' },
	{ name: 'Dinheiro', value: 'cash' },
	{ name: 'Boleto', value: 'bank_slip' },
	{ name: 'Depósito', value: 'deposit' },
	{ name: 'Cheque', value: 'check' },
]
