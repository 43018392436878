import api from 'services/api'
import { Bill } from './Bill'

export class BillsRepository {
	async getBills({
		signal,
		...args
	}: GetBillsProps): Promise<GetBillsResponse> {
		const search = new URLSearchParams(
			Object.entries(args).filter(([k, v]) => v !== undefined)
		)
		const response = await api.get(`/bills?${search}`, { signal })
		const json = response.data
		return { ...json, data: json.data.map(Bill.fromJson) }
	}

	async createBill(data: BillPayload) {
		const body = this.generateFormData(data)
		await api.post('/bills', body)
	}

	async updateBill(data: BillPayload) {
		const body = this.generateFormData(data)
		await api.put(`/bills/${data.id}`, body)
	}

	async deleteBill(billId: string) {
		await api.delete(`/bills/${billId}`)
	}

	private generateFormData(data: Record<string, any>) {
		const body = new FormData()
		for (const key in data) {
			const value = data[key]
			if (value instanceof FileList) {
				Array.from(value, (file) => body.append(key, file))
			} else if (value !== undefined) {
				body.set(key, value ?? '')
			}
		}
		return body
	}
}

interface BillPayload {
	id?: string
	file?: File
	isTaxable?: boolean
	name: string
	value: number
	dueDate?: string
	paymentDate?: string
}

interface GetBillsProps {
	page: number
	search?: string
	startDate?: string
	endDate?: string
	status?: string
	isTaxable?: boolean
	signal?: AbortSignal
}

interface GetBillsResponse {
	data: Bill[]
	meta: { perPage: number; total: number }
	total: number
}
