import { useQuery } from '@tanstack/react-query'
import { getUserInfoInStorage } from 'services/api/auth'

export function useUserQuery() {
	return useQuery({
		queryKey: ['user'],
		queryFn: getUserInfoInStorage,
		initialData: getUserInfoInStorage,
	})
}
