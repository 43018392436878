import React from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useForm, FormProvider } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { toast } from 'react-hot-toast'

import { useGetOneRecord } from 'modules/records/queries/useGetOneRecord'
import { getDefaultValues } from 'modules/records/contexts/ManageRegistrationFormContext/defaultValues'
import { recordsFormSchema } from 'modules/records/validations/recordsFormSchema'
import { FormProps } from 'modules/records/contexts/ManageRegistrationFormContext/types'
import { useUpdateRecord } from 'modules/records/queries/useUpdateRecord'
import { Modal } from 'app/components/Modal'
import { parseRegistrationType } from '../../utils/parseRegistrationType'
import { Form } from '../Form'
import { FormActions } from '../FormActions'

function parsePersonType(data: any) {
	if (data.cnpj) return 'PJ'
	if (data.cpf) return 'PF'
	return undefined
}

export function EditRecord() {
	const params = useParams()
	const navigate = useNavigate()

	const query = useGetOneRecord({
		recordType: params.typeRegistration,
		recordId: params.registrationTypeId,
	})

	const methods = useForm<FormProps>({
		mode: 'all',
		resolver: yupResolver(recordsFormSchema),
	})

	React.useLayoutEffect(() => {
		if (query.data) {
			const personType = parsePersonType(query.data) ?? 'PF'

			const firstLegalRepresentative =
				query.data.legalRepresentatives?.[0]
			const legalRepresentative = firstLegalRepresentative
				? {
						...firstLegalRepresentative,
						birthday:
							firstLegalRepresentative.birthday?.split('T')[0],
						config: {
							hasResponsible: 'Yes',
							responsibleTypeOfPerson:
								firstLegalRepresentative.cnpj ? 'PJ' : 'PF',
						},
						gender: firstLegalRepresentative.gender?.toLowerCase(),
						maritalStatus: (
							firstLegalRepresentative.maritalStatus ??
							firstLegalRepresentative.marital_status
						)?.toLowerCase(),
				  }
				: { config: { hasResponsible: 'No' } }

			const values: FormProps = {
				...getDefaultValues(),
				...query.data,
				personType,
				isLegalEntity: personType === 'PJ',
				registrationType: parseRegistrationType(
					params.typeRegistration
				),
				legalRepresentative,
				birthday: query.data.birthday?.split('T')[0],
				gender: query.data?.gender?.toLowerCase(),
				maritalStatus: (
					query.data?.maritalStatus ?? query.data?.marital_status
				)?.toLowerCase(),
			}
			methods.reset(values)
		}
	}, [query.data])

	const updateRecord = useUpdateRecord({
		recordType: params.typeRegistration,
		recordId: params.registrationTypeId,
	})

	const onSubmit = (values: any) => {
		updateRecord.mutate(values, {
			onSuccess() {
				toast.success('Atualizado!')
				navigate('..')
			},
			onError() {
				toast.error('Erro ao atualizar!')
			},
		})
	}

	return (
		<FormProvider {...methods}>
			<Modal
				isOpen
				onClose={() => {
					navigate('..')
				}}
				title="Cadastro"
				body={
					<form
						id="edit-register-form"
						onSubmit={methods.handleSubmit(onSubmit)}
					>
						<Form />
					</form>
				}
				footer={
					<FormActions
						form="edit-register-form"
						isLoading={updateRecord.isPending}
					/>
				}
				dividers
			/>
		</FormProvider>
	)
}
