import { useQuery } from '@tanstack/react-query'
import { TreatmentsRepository } from 'data/TreatmentsRepository'
import { useSearchParams } from 'react-router-dom'
import { useUserQuery } from 'useUserQuery'

export function useTreatmentsQuery() {
	const user = useUserQuery().data
	const [searchParams] = useSearchParams()
	const page = Math.abs(Number(searchParams.get('page')) || 1)
	const search = searchParams.get('search') ?? ''
	const startDate = searchParams.get('startDate') ?? ''
	const endDate = searchParams.get('endDate') ?? ''

	const customer = searchParams.get('customer') ?? ''

	const clinic = user?.activeClinic

	return useQuery({
		queryKey: [
			'treatments',
			{ page, search, customer, startDate, endDate },
			clinic,
		],
		async queryFn({ signal }) {
			const treatmentsRepository = new TreatmentsRepository()
			return await treatmentsRepository.getTreatments({
				page,
				search,
				startDate,
				endDate,
				customer,
				signal,
			})
		},
		placeholderData: (previous, query) =>
			query?.queryKey.includes(clinic) ? previous : undefined,
	})
}
