import { Button, Flex, Stack, Checkbox } from '@chakra-ui/react'
import { useFormContext } from 'react-hook-form'
import { InputTextController } from 'app/components/InputTextController'
import { Settings } from 'modules/schedule/validations/settingsSchema'
import { Heading } from 'app/components/Heading'
import { SelectController } from 'app/components/SelectController'

interface SettingsFormProps {
	onCancel: () => void
	onSubmit: any
	control: any
}

const WEEK_CHECKBOXES = [
	{ label: 'Dom', value: 'sunday' },
	{ label: 'Seg', value: 'monday' },
	{ label: 'Ter', value: 'tuesday' },
	{ label: 'Qua', value: 'wednesday' },
	{ label: 'Qui', value: 'thursday' },
	{ label: 'Sex', value: 'friday' },
	{ label: 'Sab', value: 'saturday' },
]

export function SettingsForm({
	onSubmit,
	control,
	onCancel,
}: SettingsFormProps) {
	const { formState, register } = useFormContext<Settings>()

	return (
		<form onSubmit={onSubmit}>
			<Stack as="section" mb={3}>
				<Heading as="h2" type="h3" size="base" color="primary.500" mb={3}>
					Agenda
				</Heading>

				<Flex
					as="fieldset"
					direction="column"
					style={{ marginBottom: 16 }}
				>
					<Heading as="legend" type="h4" style={{ marginBottom: 8 }}>
						Disponibilidade
					</Heading>
					<Flex flexWrap="wrap" gap={5}>
						{WEEK_CHECKBOXES.map((item) => {
							return (
								<Checkbox
									key={item.value}
									fontWeight="bold"
									as="label"
									value={item.value}
									{...register('agenda.availableDays')}
								>
									{item.label}
								</Checkbox>
							)
						})}
					</Flex>
				</Flex>

				<Flex flexWrap="wrap" rowGap="24px" columnGap="16px">
					<SelectController
						control={control}
						name="agenda.sessionDurationMinutes"
						title="Tempo de sessão"
						flex={{ sm: '300px 1 1', md: '170px 1 1' }}
						items={[
							{ label: '15 minutos', value: 15 },
							{ label: '30 minutos', value: 30 },
							{ label: '45 minutos', value: 45 },
							{ label: '60 minutos', value: 60 },
						]}
					/>
					<InputTextController
						flex="170px 1 1"
						control={control}
						name="agenda.startTime"
						title="Horário de início"
						type="time"
					/>
					<InputTextController
						flex="170px 1 1"
						control={control}
						name="agenda.endTime"
						title="Horário final"
						type="time"
					/>
					<InputTextController
						flex="170px 1 1"
						control={control}
						name="agenda.lunchStartTime"
						title="Horário de início de almoço"
						type="time"
					/>
					<InputTextController
						flex="170px 1 1"
						control={control}
						name="agenda.lunchEndTime"
						title="Horário final de almoço"
						type="time"
					/>
				</Flex>
			</Stack>

			<section>
				<Heading as="h2" type="h3" size="base" color="primary.500" mb={3}>
					Anotações
				</Heading>
				<Flex flexWrap="wrap" rowGap="24px" columnGap="16px">
					<InputTextController
						flex="170px 1 1"
						control={control}
						name="annotations.sessionDurationMinutes"
						title="Tempo de sessão"
						type="number"
						min={1}
					/>
					<InputTextController
						flex="170px 1 1"
						control={control}
						name="annotations.startTime"
						title="Horário de início"
						type="time"
					/>
					<InputTextController
						flex="170px 1 1"
						control={control}
						name="annotations.endTime"
						title="Horário final"
						type="time"
					/>
				</Flex>
			</section>

			<Flex mt={8} justifyContent="center">
				<Button
					w="130px"
					colorScheme="gray"
					mr={3}
					size="md"
					onClick={onCancel}
				>
					Cancelar
				</Button>
				<Button
					w="130px"
					colorScheme="linkedin"
					size="md"
					type="submit"
					isLoading={formState.isSubmitting}
				>
					Salvar
				</Button>
			</Flex>
		</form>
	)
}
