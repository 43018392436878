import { DownloadIcon, ViewIcon } from '@chakra-ui/icons'
import {
	Tbody,
	Tr,
	Td,
	Button,
	useColorModeValue,
	Spinner,
} from '@chakra-ui/react'
import { useNavigate, useParams } from 'react-router-dom'
import dayjs from 'dayjs'
import { useFormContext } from 'react-hook-form'
import * as Pdf from '@react-pdf/renderer'

import { useProceduresManagement } from 'modules/history/hooks/useProceduresManagement'
import { FormModal } from '../ProcedureForm/FormModal'
import { HistoryPdfLink } from '../HistoryPdfLink'
import { useHistoryQuery } from 'modules/history/queries/useHistoryQuery'
import { TreatmentPdf } from 'modules/treatments/components/TreatmentPdf'

export function PacientTBodyDesktop() {
	const { customerId } = useParams()
	const {
		setFilterByTypeInfo,
		setInfoFilter,
		setIsOpenProcedureForm,
		setProcedureId,
	} = useProceduresManagement()
	setFilterByTypeInfo('customer')
	setInfoFilter(customerId)
	const { reset } = useFormContext()
	const btnBg = useColorModeValue('#F9F9F9', 'whiteAlpha.50')

	const navigate = useNavigate()

	const query = useHistoryQuery()
	const history = query.data?.data

	return (
		<>
			<Tbody>
				{history?.map((item) => (
					<>
						<Tr opacity={item.procedure?.deleted ? '0.3' : '1'}>
							<Td>
								{dayjs(
									item.procedure?.date ||
										item.treatment?.createdAt
								).format('DD/MM/YYYY')}
							</Td>
							<Td>
								{item.treatment
									? 'Orçamento'
									: item.procedure?.name}
							</Td>
							<Td>{item.dentist?.name}</Td>
							<Td>
								<Button
									width="32px"
									height="32px"
									borderRadius="4px"
									bg={btnBg}
									onClick={() => {
										const procedure = item.procedure
										const treatment = item.treatment
										if (procedure) {
											setIsOpenProcedureForm(true)
											setProcedureId(procedure._id)
											reset({
												name: procedure?.name,
												date: procedure?.date?.split(
													'T'
												)[0],
												observation:
													procedure?.observation,
												files: procedure?.files,
											})
										}
										if (treatment) {
											navigate(
												`/tratamentos/${treatment._id}`
											)
										}
									}}
								>
									<ViewIcon />
								</Button>
							</Td>
							<Td pl={0}>
								{item.treatment && (
									<Pdf.PDFDownloadLink
										fileName="orcamento.pdf"
										document={
											<TreatmentPdf
												treatment={
													item.treatment as any
												}
											/>
										}
									>
										{(state) => (
											<Button
												type="button"
												boxSizing="border-box"
												width="24px"
												height="30px"
												p="0"
												borderRadius="5px"
												display="flex"
												alignItems="center"
												justifyContent="center"
												bg="white"
												_dark={{ bg: 'whiteAlpha.50' }}
											>
												{state.loading ? (
													<Spinner
														size="sm"
														color="#1188EE"
													/>
												) : (
													<DownloadIcon color="#1188EE" />
												)}
											</Button>
										)}
									</Pdf.PDFDownloadLink>
								)}

								{item.procedure && (
									<HistoryPdfLink
										data={{
											...item.procedure,
											id: item.procedure?._id,
										}}
									/>
								)}
							</Td>

							{item.procedure && (
								<FormModal procedureId={item.procedure?._id} />
							)}
						</Tr>
					</>
				))}
			</Tbody>
		</>
	)
}
