import {
	Divider,
	Modal as ModalChakraUI,
	ModalBody as ModalBodyChakraUI,
	ModalCloseButton,
	ModalContent,
	ModalFooter as ModalFooterChakraUI,
	ModalHeader,
	ModalOverlay,
	useColorModeValue,
} from '@chakra-ui/react'
import { useBreakpointsMediaQuery } from 'app/hooks/useMediaQuery'

interface ModalProps {
	title: string
	body: JSX.Element
	footer: JSX.Element
	dividers: boolean
	handleForm?: () => void
	onClose: () => void
	isOpen: boolean
	// eslint-disable-next-line react/require-default-props
	size?: string
}

export const Modal = ({
	title,
	body,
	footer,
	dividers,
	handleForm,
	isOpen,
	onClose,
	size,
}: ModalProps) => {
	const { isDesktop } = useBreakpointsMediaQuery()

	const DEFAULT_SIZE_MODAL = isDesktop ? 'base' : 'full'
	const value = useColorModeValue('white', 'black')
	return (
		<>
			<ModalChakraUI
				onClose={onClose}
				size={size || DEFAULT_SIZE_MODAL}
				isOpen={isOpen}
				scrollBehavior="inside"				
			>
				<ModalOverlay />
				<ModalContent>
					<ModalHeader>{title}</ModalHeader>
					<ModalCloseButton />
					{dividers && <Divider orientation="horizontal" />}
					<ModalBodyChakraUI>{body}</ModalBodyChakraUI>
					{dividers && <Divider orientation="horizontal" />}
					{footer && (
						<ModalFooterChakraUI>{footer}</ModalFooterChakraUI>
					)}
				</ModalContent>
			</ModalChakraUI>
		</>
	)
}
