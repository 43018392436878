import api from 'services/api'
import { Procedure } from './Procedure'

interface PaginatedProcedures {
	data: Procedure[]
	meta: { total: number; perPage: number }
}

interface GetProceduresArgs {
	page: number
	limit?: number
	name?: string
}

export class ProceduresRepository {
	async getProcedures(args?: GetProceduresArgs) {
		const searchParams = new URLSearchParams(
			args && Object.entries(args).filter(([, v]) => Boolean(v))
		)
		const response = await api.get(`/procedure-types?${searchParams}`)
		const result: PaginatedProcedures = {
			data: response.data?.data?.map(Procedure.fromJson),
			meta: response.data?.meta,
		}
		return result
	}

	async createProcedure(data: Omit<Procedure, 'id'>) {
		await api.post('/procedure-types', data)
	}

	async updateProcedure(id: Procedure['id'], data: Partial<Procedure>) {
		await api.put(`/procedure-types/${id}`, {
			name: data.name,
			value: data.value,
		})
	}
}
