import { AddIcon, EditIcon } from '@chakra-ui/icons'
import {
	TableContainer,
	Table,
	Thead,
	Tbody,
	Th,
	Td,
	Text,
	IconButton,
	Flex,
	Center,
	Spinner,
	Button,
	Heading,
} from '@chakra-ui/react'
import dayjs from 'dayjs'
import { useSearchParams } from 'react-router-dom'

import { TablePagination } from 'app/components/TablePagination'
import { useBillsQuery } from '../queries/useBillsQuery'
import { BillModal } from './BillModal'
import { DownloadIcon } from 'app/icons/DownloadIcon'
import { Can } from 'ability'
import { DeleteBill } from './DeleteBill'
import { ReportPdf, ReportPdfDownload } from './ReportPdf'
import { Bill } from 'data/Bill'

export function BillsRoute() {
	const [searchParams, setSearchParams] = useSearchParams()
	const page = Math.abs(Number(searchParams.get('page')) || 1)
	const onPageChange = (nextPage: number) => {
		setSearchParams(
			(init) => new URLSearchParams({ ...init, page: String(nextPage) })
		)
	}

	const query = useBillsQuery({ page })
	const bills = query.data?.data
	const paginationInfo = query.data?.meta

	const isEmpty = bills?.length <= 0

	type Column = {
		title: string
		render: (it: Bill, mode?: 'html' | 'pdf') => string | JSX.Element
	}
	const columns: Column[] = [
		{ title: 'Conta', render: (it) => it.name },
		{
			title: 'Data de Pagamento',
			render: (it) =>
				it.paymentDate
					? dayjs(it.paymentDate).utc().format('DD/MM/YYYY')
					: '-',
		},
		{
			title: 'Data de Vencimento',
			render: (it) =>
				it.dueDate ? dayjs(it.dueDate).utc().format('DD/MM/YYYY') : '-',
		},
		{
			title: 'Valor',
			render: (it) =>
				it.value?.toLocaleString([], {
					style: 'currency',
					currency: 'BRL',
				}),
		},
		{
			title: 'Tributável?',
			render: (it, mode) => {
				const value = it.isTaxable ? 'Sim' : 'Não'
				if (mode === 'html')
					return (
						<Text
							textTransform="uppercase"
							color={it.isTaxable ? '#00CC66' : undefined}
						>
							{value}
						</Text>
					)
				return value
			},
		},
	]

	return (
		<>
			<Flex justifyContent="space-between">
				<Heading fontSize="2xl">Contas</Heading>

				<Can I="create" a="Bill">
					<BillModal
						trigger={
							<Button
								ml="auto"
								size="sm"
								fontSize="xs"
								colorScheme="primary"
								textTransform="uppercase"
								leftIcon={<AddIcon />}
							>
								Conta
							</Button>
						}
					/>
				</Can>
			</Flex>

			{Boolean(bills && bills.length) && (
				<ReportPdfDownload
					document={
						<ReportPdf
							title="Contas"
							sections={bills.map((it) => ({
								data: columns.map((col) => ({
									title: col.title,
									body: col.render(it),
								})),
							}))}
						/>
					}
					fileName={`contas.pdf`}
					style={{ marginLeft: 'auto' }}
				/>
			)}

			<TableContainer
				bg="white"
				_dark={{ bg: '#182533' }}
				p="4"
				rounded="12"
				h="100%"
				mb="6"
				position="relative"
			>
				<Table variant="striped">
					<Thead>
						<tr>
							{columns.map((col) => (
								<Th key={`bills:${col.title}`} fontSize="sm">
									{col.title}
								</Th>
							))}
							<Th fontSize="sm"></Th>
						</tr>
					</Thead>
					<Tbody>
						{bills &&
							!isEmpty &&
							bills.map((it) => {
								return (
									<tr key={it.id}>
										{columns.map((col) => (
											<Td
												key={`bills:${it.id}:${col.title}`}
											>
												{col.render(it, 'html')}
											</Td>
										))}
										<Td>
											<Flex gap="3" justifyContent="end">
												{it.file?.url && (
													<IconButton
														display="flex"
														variant="unstyled"
														color="#0C6"
														bg="#f9f9f9"
														_dark={{
															bg: '#223344',
														}}
														aria-label="Baixar recibo"
														icon={
															<DownloadIcon
																size={20}
															/>
														}
														as="a"
														download={it.file.url
															.split('/')
															.at(-1)
															.split('?')
															.at(0)}
														href={it.file.url}
														target="_blank"
													/>
												)}
												<Can I="update" this={it}>
													<BillModal
														bill={it}
														trigger={
															<IconButton
																colorScheme="primary"
																variant="unstyled"
																bg="#f9f9f9"
																_dark={{
																	bg: '#223344',
																}}
																aria-label="Editar pagamento"
																icon={
																	<EditIcon />
																}
															/>
														}
													/>
												</Can>
												<Can I="delete" this={it}>
													<DeleteBill bill={it} />
												</Can>
											</Flex>
										</Td>
									</tr>
								)
							})}

						{!query.data && query.isLoading && (
							<tr style={{ display: 'block' }}>
								<td>
									<Center
										position="absolute"
										h="100%"
										top="50%"
										left="50%"
										transform="translate(-50%,-50%)"
									>
										<Spinner />
									</Center>
								</td>
							</tr>
						)}

						{isEmpty && (
							<tr style={{ display: 'block' }}>
								<td>
									<Center
										flexDirection="column"
										position="absolute"
										h="100%"
										top="50%"
										left="50%"
										transform="translate(-50%,-50%)"
									>
										<Text>Nada aqui ainda</Text>
										<Text>
											Clique no botão {'"+ Conta"'} para
											adicionar
										</Text>
									</Center>
								</td>
							</tr>
						)}
						{query.isLoadingError && (
							<tr style={{ display: 'block' }}>
								<td>
									<Center
										flexDirection="column"
										position="absolute"
										h="100%"
										top="50%"
										left="50%"
										transform="translate(-50%,-50%)"
									>
										<Text>
											Algo deu errado ao carregar esses
											dados...
										</Text>
										<Text>{String(query.error)}</Text>
									</Center>
								</td>
							</tr>
						)}
					</Tbody>
				</Table>
			</TableContainer>
			{paginationInfo && paginationInfo.total > 0 && (
				<Flex
					alignItems="center"
					justify="end"
					flexDirection={{ base: 'column', md: 'row' }}
					columnGap="5"
					rowGap="3"
				>
					<TablePagination
						total={paginationInfo.total}
						perPage={paginationInfo.perPage}
						page={page}
						onPageChange={onPageChange}
					/>

					<Text
						fontSize="xs"
						fontWeight="medium"
						textTransform="uppercase"
					>
						{paginationInfo.total} registros
					</Text>
				</Flex>
			)}
		</>
	)
}
