import { useColorModeValue } from '@chakra-ui/react'
import { ScheduleTableRoot } from './ScheduleTableRoot'

export function ScheduleTime({
	scheduleString,
	style,
}: {
	scheduleString: string
	style?: any
}) {
	const bg = useColorModeValue('#F4F4F4', '#182533')

	return (
		<ScheduleTableRoot.Cell
			bg={bg}
			fontWeight="medium"
			fontSize="xs"
			w="54px"
			h="40px"
			textAlign="center"
			p={0}
			style={style}
		>
			{scheduleString}
		</ScheduleTableRoot.Cell>
	)
}
