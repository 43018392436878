import {
	Box,
	Center,
	Flex,
	Grid,
	Heading,
	Spinner,
	Stack,
	Tab,
	Table,
	TableContainer,
	TabList,
	TabPanel,
	TabPanels,
	Tabs,
	Tbody,
	Td,
	Text,
	Th,
	Thead,
	Tr,
} from '@chakra-ui/react'
import { Link, useParams } from 'react-router-dom'
import React from 'react'
import dayjs from 'dayjs'

import { TreatmentPayments } from './TreatmentPayments'
import { useTreatmentPaymentsQuery } from '../queries/useTreatmentPaymentsQuery'
import { useTreatmentQuery } from '../queries/useTreatmentQuery'
import { Can } from 'ability'

export function TreatmentRoute() {
	const params = useParams()

	const query = useTreatmentQuery(params.id)
	const treatment = query.data

	const total = treatment?.items?.reduce(
		(acc, it) => acc + it.procedure.value * it.quantity,
		-(treatment?.discount ?? 0)
	)

	const paymentsQuery = useTreatmentPaymentsQuery(params.id)
	const payments = paymentsQuery.data
	const totalPaid = payments?.reduce(
		(acc, it) => acc + (it.status === 'paid' ? it.value : 0),
		0
	)

	const [index, setIndex] = React.useState(function getInitialIndex() {
		if (location.hash === '#pagamentos') return 1
		return 0
	})
	const renderTabsTitle = () => {
		if (index === 0) return 'Procedimentos'
		return 'Pagamentos do Tratamento'
	}

	return (
		<Stack px="6" py="10" h="100%" gap="6">
			<Flex
				id="page_content_heading"
				justify="space-between"
				flexWrap="wrap"
				gap="5"
			>
				<div>
					<Heading fontSize="2xl" mb="7">
						Tratamento
					</Heading>
					<Text
						mb="7"
						display="inline-flex"
						alignItems="center"
						gap="4"
					>
						<Text as="span" fontWeight="600">
							Data início:
						</Text>{' '}
						{treatment?.startDate
							? dayjs(treatment.startDate).format('DD/MM/YYYY')
							: '-'}
					</Text>
					<Flex>
						<Stack>
							<Text fontWeight="600">Paciente</Text>
							<Grid
								templateColumns="32px 1fr"
								alignItems="center"
								bg="white"
								gap="3"
								px="4"
								py="3"
								rounded="md"
								border="1px solid"
								borderColor="inherit"
								_dark={{ bg: '#182533' }}
								w="240px"
								maxW="100%"
							>
								<Box
									w="8"
									h="8"
									rounded="full"
									bg="gray.300"
									_dark={{ bg: 'gray.700' }}
								/>
								<Text
									fontWeight="600"
									fontSize="sm"
									whiteSpace="nowrap"
									overflow="hidden"
									textOverflow="ellipsis"
									_hover={{ textDecoration: 'underline' }}
									as={Link}
									to={`/registros/customers/${treatment?.customer?.id}`}
								>
									{treatment?.customer?.name}
								</Text>
							</Grid>
						</Stack>
					</Flex>
				</div>
				<div>
					<Stack mb="5">
						<Text fontWeight="600">Profissional</Text>
						<Flex
							bg="white"
							alignItems="center"
							gap="3"
							px="4"
							py="3"
							rounded="md"
							border="1px solid"
							borderColor="inherit"
							_dark={{ bg: '#182533' }}
						>
							<Box
								w="8"
								h="8"
								rounded="full"
								bg="gray.300"
								_dark={{ bg: 'gray.700' }}
							/>
							<Text fontWeight="600" fontSize="sm">
								{treatment?.professional?.name}
							</Text>
						</Flex>
					</Stack>

					<Flex
						flexDirection="column"
						px="4"
						py="3"
						bg="white"
						_dark={{ bg: '#182533' }}
						rounded="md"
					>
						<Text
							display="inline-flex"
							alignItems="center"
							justifyContent="space-between"
							gap="4"
						>
							<Text as="span" fontSize="lg" fontWeight="500">
								Valor pago
							</Text>{' '}
							{payments && formatAsCurrency(totalPaid ?? 0)}
						</Text>
						<Text
							display="inline-flex"
							alignItems="center"
							justifyContent="space-between"
							gap="4"
						>
							<Text as="span" fontSize="lg" fontWeight="500">
								Total
							</Text>{' '}
							{treatment && formatAsCurrency(total ?? 0)}
						</Text>
					</Flex>
				</div>
			</Flex>

			<Tabs
				index={index}
				onChange={setIndex}
				colorScheme="primary"
				display="flex"
				flexDirection="column"
				flex={1}
			>
				<Flex
					alignItems="center"
					justify="space-between"
					gap="5"
					mb="6"
					flexDirection={{ base: 'column-reverse', md: 'row' }}
					h={{ base: 'auto', md: '12' }}
				>
					<Flex gap="5" alignItems="center" w="100%">
						<Heading
							fontSize="2xl"
							display={{ base: 'none', md: 'block' }}
						>
							{renderTabsTitle()}
						</Heading>

						<TabList>
							<Tab fontWeight="600">Procedimentos</Tab>
							<Can I="read" a="Payment">
								<Tab fontWeight="600">Pagamentos</Tab>
							</Can>
						</TabList>

						<Text
							ml="auto"
							textAlign="right"
							fontWeight="500"
							fontSize="sm"
							whiteSpace="nowrap"
							overflow="hidden"
							textOverflow="ellipsis"
							color="primary.600"
							display="block"
							_hover={{ textDecoration: 'underline' }}
							as={Link}
							to={`/historico/${treatment?.customer?.id}`}
						>
							Ver no histórico
						</Text>
					</Flex>

					{/* <Button
						size="sm"
						fontSize="xs"
						colorScheme="primary"
						textTransform="uppercase"
						leftIcon={<AddIcon />}
						ml="auto"
					>
						Pagamento
					</Button> */}
				</Flex>

				<TabPanels flex={1}>
					<TabPanel id="procedures_panel" p="0" h="100%">
						<TableContainer
							bg="white"
							_dark={{ bg: '#182533' }}
							p="4"
							rounded="12"
							h="100%"
							mb="6"
							position="relative"
						>
							<Table variant="striped">
								<Thead>
									<tr>
										<Th>Procedimento</Th>
										<Th>Dente/Arcada/Face</Th>
										<Th textAlign="end">Qtd</Th>
										<Th textAlign="center">Valor</Th>
									</tr>
								</Thead>
								<Tbody>
									{treatment?.items?.map((it, i) => (
										<tr key={`item_procedure_${i}`}>
											<Td>{it.procedure.name}</Td>
											<Td>{it.teeth}</Td>
											<Td textAlign="end">
												{it.quantity}
											</Td>
											<Td textAlign="center">
												{formatAsCurrency(
													it.procedure.value
												)}
											</Td>
										</tr>
									))}

									{!query.data && query.isLoading && (
										<Tr display="block">
											<Td p="0" border="none">
												<Center
													position="absolute"
													h="100%"
													top="50%"
													left="50%"
													transform="translate(-50%,-50%)"
												>
													<Spinner />
												</Center>
											</Td>
										</Tr>
									)}

									{query.isLoadingError && (
										<Tr display="block">
											<td>
												<Center
													flexDirection="column"
													position="absolute"
													h="100%"
													top="50%"
													left="50%"
													transform="translate(-50%,-50%)"
												>
													<Text>
														Algo deu errado ao
														carregar esses dados...
													</Text>
													<Text>
														{String(query.error)}
													</Text>
												</Center>
											</td>
										</Tr>
									)}
								</Tbody>
							</Table>
						</TableContainer>
					</TabPanel>

					<TabPanel id="payments_panel" p="0" h="100%">
						<TreatmentPayments />
					</TabPanel>
				</TabPanels>
			</Tabs>
		</Stack>
	)
}

function formatAsCurrency(rawValue: number) {
	const currency = 'BRL'
	const formattedValue = new Intl.NumberFormat(undefined, {
		style: 'currency',
		currency,
	})
		.format(rawValue)
		.trim()
	return formattedValue
}
