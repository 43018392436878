import { useQuery } from '@tanstack/react-query'
import api from 'services/api'

interface ScheduleQueryArgs {
	professionalId: string
	start?: string
	end?: string
	search?: string
	page?: number
}

export function useScheduleQuery({
	professionalId,
	search,
	start,
	end,
	page,
}: ScheduleQueryArgs) {
	return useQuery({
		queryKey: [
			'schedule_search',
			{ professionalId, start, end, search, page },
		],
		async queryFn(ctx) {
			const searchParams = new URLSearchParams([
				['start', start ?? ''],
				['end', end ?? ''],
				['search', search ?? ''],
				['dentist', professionalId ?? ''],
				['page', String(page) ?? '1'],
			])
			const r = await api.get<AppointmentsResponse>(
				`/appointments?${searchParams}`,
				{ signal: ctx.signal }
			)
			return r.data
		},
		enabled: Boolean(professionalId),
		placeholderData: (prev) => prev,
	})
}

interface AppointmentsResponse {
	data: {
		customer?: { _id: string; name: string }
		dentist?: { _id: string; name: string }
		start: string
		end: string
		status: string
		_id: string
		notes?: string
	}[]
	meta: {
		total: number
		perPage: number
	}
}
