export class Bill {
	static modelName = 'Bill'

	id: string
	name: string
	value: number
	fine?: number
	totalValue: number
	isTaxable?: boolean
	file?: { url: string }
	paymentMethod?: string
	paymentDate?: string
	dueDate?: string
	clinic?: string

	constructor(data: Bill) {
		Object.assign(this, data)
	}

	static fromJson(object: any) {
		return new Bill({ ...object, id: object._id })
	}
}
