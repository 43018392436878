/* eslint-disable react/no-array-index-key */
/* eslint-disable no-use-before-define */
/* eslint-disable react/button-has-type */
import React from 'react'
import {
	useCalendar,
	useCalendarGrid,
	useCalendarCell,
	useLocale,
} from 'react-aria'
import {
	CalendarState,
	CalendarStateOptions,
	useCalendarState,
} from 'react-stately'
import {
	CalendarDate,
	createCalendar,
	DateValue,
	getWeeksInMonth,
} from '@internationalized/date'
import {
	IconButton,
	Flex,
	Stack,
	Text,
	useColorModeValue,
	Button,
} from '@chakra-ui/react'
import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons'

interface Props
	extends Omit<CalendarStateOptions<DateValue>, 'locale' | 'createCalendar'> {
	onToday?: () => void
}

export function Calendar({ onToday, ...props }: Props) {
	const { locale } = useLocale()
	const state = useCalendarState({
		...props,
		locale,
		createCalendar,
	})

	const { calendarProps, prevButtonProps, nextButtonProps, title } =
		useCalendar(props, state)

	const { onPress: onPressPrevButton, ...prevButtonPropsWithoutOnPress } =
		prevButtonProps
	const { onPress: onPressNextButton, ...nextButtonPropsWithoutOnPress } =
		nextButtonProps

	return (
		<Stack {...calendarProps} px={4} py={4}>
			<Flex justify="space-between">
				<h2>{title}</h2>
				<Flex gap={2}>
					<IconButton
						aria-label="Anterior"
						icon={<ChevronLeftIcon fontSize={18} />}
						size="xs"
						onClick={(e: any) => onPressPrevButton(e)}
						{...prevButtonPropsWithoutOnPress}
					/>
					<IconButton
						aria-label="Próximo"
						icon={<ChevronRightIcon fontSize={18} />}
						size="xs"
						onClick={(e: any) => onPressNextButton(e)}
						{...nextButtonPropsWithoutOnPress}
					/>
				</Flex>
			</Flex>
			<CalendarGrid state={state} />

			<div>
				<Button
					display="block"
					variant="link"
					colorScheme="blue"
					ml="auto"
					fontSize="sm"
					onClick={onToday}
				>
					Hoje
				</Button>
			</div>
		</Stack>
	)
}

function CalendarGrid({ state, ...props }: { state: CalendarState }) {
	const { locale } = useLocale()
	const { gridProps, headerProps, weekDays } = useCalendarGrid(props, state)

	// Get the number of weeks in the month so we can render the proper number of rows.
	const weeksInMonth = getWeeksInMonth(state.visibleRange.start, locale)

	return (
		<table {...gridProps}>
			<thead {...headerProps}>
				<tr>
					{weekDays.map((day, index) => (
						<th
							key={index}
							style={{ fontSize: 14, color: 'darkgray' }}
						>
							{day}
						</th>
					))}
				</tr>
			</thead>
			<tbody>
				{Array.from({ length: weeksInMonth }, (_, weekIndex) => (
					<tr key={weekIndex} style={{ borderSpacing: 12 }}>
						{state
							.getDatesInWeek(weekIndex)
							.map((date, i) =>
								date ? (
									<CalendarCell
										key={i}
										state={state}
										date={date}
									/>
								) : (
									<td key={i} />
								)
							)}
					</tr>
				))}
			</tbody>
		</table>
	)
}

function CalendarCell({ state, date }: any) {
	const ref = React.useRef(null)
	const {
		cellProps,
		buttonProps,
		isSelected,
		isOutsideVisibleRange,
		formattedDate,
		isUnavailable,
	} = useCalendarCell({ date }, state, ref)

	function getTextProps() {
		if (isOutsideVisibleRange) {
			return { color: 'lightgray' }
		}
		if (isSelected) {
			return { bg: 'primary.500', color: 'white' }
		}
		return {}
	}
	const unavailable = useColorModeValue('#f0f0f0', '#2a3f54')
	return (
		<td
			{...cellProps}
			style={{ background: isUnavailable ? unavailable : undefined }}
		>
			<Text
				{...buttonProps}
				ref={ref}
				margin="0 auto"
				width="24px"
				height="24px"
				rounded="full"
				textAlign="center"
				fontSize="12px"
				style={{
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center',
				}}
				cursor={isUnavailable ? 'default' : 'pointer'}
				{...getTextProps()}
			>
				{formattedDate}
			</Text>
		</td>
	)
}
