import { Control, Controller } from 'react-hook-form'
import { Radios } from '../Radios'

interface ItemsProps {
	[key: string]: string | boolean
}

interface RadiosControllerProps {
	items: ItemsProps[]
	title: string
	name: string
	control: Control
}

export const RadiosController = (props: RadiosControllerProps) => {
	const { control, name, ...rest } = props
	return (
		<Controller
			control={control}
			name={name}
			render={({ field }) => <Radios {...rest} {...field} />}
		/>
	)
}
