import { useQuery } from '@tanstack/react-query'
import { Product } from 'data/Product'
import api from 'services/api'

interface Props {
	page: number
	search: string
}

export function useProductsQuery({ page, search }: Props) {
	return useQuery({
		queryKey: ['products', { page, search }],
		async queryFn(): Promise<ProductsResponse> {
			const searchParams = new URLSearchParams([
				['page', String(page)],
				['search', search],
			])
			const response = await api.get(`/products?${searchParams}`)
			const json = response.data
			json.data = json.data.map(Product.fromJson)
			return response.data
		},
	})
}

interface ProductsResponse {
	data: Product[]
	meta: { total: number; perPage: number }
}
