export function parseRegistrationType(value: string) {
	switch (value) {
		case 'customers':
		case 'dentists':
		case 'workers':
			return value
		default:
			return undefined
	}
}
