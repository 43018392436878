import { useEffect, useState } from 'react'
import { Box, Checkbox, Flex, Stack } from '@chakra-ui/react'
import { Accordion } from 'app/components/Accordion'
import useCep from 'app/hooks/useCep'
import useDebounce from 'app/hooks/useDebounce'
import { useFormContext } from 'react-hook-form'
import { InputTextController } from 'app/components/InputTextController'
import { MaskedInputController } from 'app/components/MaskedInputController'

export const AddressOfResponsible = () => {
	const {
		control,
		formState: { errors },
		watch,
		setValue,
		clearErrors,
		getValues,
	} = useFormContext()

	const [copyMasterRecordAddress, setCopyMasterRecordAddress] = useState(
		() => {
			const values = getValues()
			return (
				values.zipCode &&
				values.legalRepresentative?.zipCode === values.zipCode
			)
		}
	)

	const hasMainRegisterZipCode = Boolean(watch('zipCode'))
	const zipCode = watch('legalRepresentative.zipCode')
	const debouncedCep = useDebounce(zipCode, 800)
	const data = useCep(debouncedCep)

	const clearAllAddressValues = () => {
		setValue('legalRepresentative.zipCode', '')
		setValue('legalRepresentative.street', '')
		setValue('legalRepresentative.neighborhood', '')
		setValue('legalRepresentative.city', '')
		setValue('legalRepresentative.uf', '')
		setValue('legalRepresentative.complement', '')
		setValue('legalRepresentative.number', '')
	}

	const clearAllAddressErrors = () => {
		clearErrors('legalRepresentative.zipCode')
		clearErrors('legalRepresentative.street')
		clearErrors('legalRepresentative.neighborhood')
		clearErrors('legalRepresentative.city')
		clearErrors('legalRepresentative.uf')
	}

	useEffect(() => {
		if (data) {
			if (!getValues('legalRepresentative.street'))
				setValue('legalRepresentative.street', data.logradouro)
			if (!getValues('legalRepresentative.neighborhood'))
				setValue('legalRepresentative.neighborhood', data.bairro)
			if (!getValues('legalRepresentative.city'))
				setValue('legalRepresentative.city', data.localidade)
			if (!getValues('legalRepresentative.uf'))
				setValue('legalRepresentative.uf', data.uf)
			if (!getValues('legalRepresentative.complement'))
				setValue('legalRepresentative.complement', data.complemento)

			clearAllAddressErrors()
		}
	}, [data])

	useEffect(() => {
		if (zipCode === '') {
			clearAllAddressValues()
		}
	}, [zipCode])

	useEffect(() => {
		if (copyMasterRecordAddress) {
			setValue('legalRepresentative.zipCode', getValues('zipCode'))
			setValue('legalRepresentative.street', getValues('street'))
			setValue('legalRepresentative.number', getValues('number'))
			setValue(
				'legalRepresentative.neighborhood',
				getValues('neighborhood')
			)
			setValue('legalRepresentative.city', getValues('city'))
			setValue('legalRepresentative.uf', getValues('uf'))
			setValue('legalRepresentative.complement', getValues('complement'))
			clearAllAddressErrors()
		} else {
			clearAllAddressValues()
		}
	}, [copyMasterRecordAddress])

	return (
		<Box mt="24px">
			<Accordion title="Endereço do Responsável" isOpenAccordion>
				<Flex mb="15px">
					<Checkbox
						isChecked={copyMasterRecordAddress}
						onChange={() =>
							setCopyMasterRecordAddress(
								(currentState) => !currentState
							)
						}
						disabled={!hasMainRegisterZipCode}
					>
						Utilizar mesmo endereço do cadastro principal
					</Checkbox>
				</Flex>
				<Flex direction={{ sm: 'column', md: 'row', lg: 'row' }}>
					<Stack
						spacing={5}
						direction={{ sm: 'column', md: 'row', lg: 'row' }}
					>
						<MaskedInputController
							title="CEP"
							placeholder=""
							size="md"
							width="100%"
							name="legalRepresentative.zipCode"
							control={control}
							hasError={errors?.legalRepresentative?.zipCode}
							mask="99999-999"
							disabled={copyMasterRecordAddress}
						/>

						<InputTextController
							title="Logradouro"
							placeholder=""
							size="md"
							type="text"
							width="100%"
							name="legalRepresentative.street"
							control={control}
							hasError={errors?.legalRepresentative?.street}
							disabled={copyMasterRecordAddress}
						/>
						<InputTextController
							title="Número"
							placeholder=""
							size="md"
							type="text"
							width="100%"
							name="legalRepresentative.number"
							control={control}
							hasError={errors?.legalRepresentative?.number}
							errorMessage={errors?.legalRepresentative?.number}
							disabled={copyMasterRecordAddress}
						/>
						<InputTextController
							title="Complemento"
							placeholder=""
							size="md"
							type="text"
							width="100%"
							name="legalRepresentative.complement"
							control={control}
							hasError={errors?.legalRepresentative?.complement}
							disabled={copyMasterRecordAddress}
						/>

						<InputTextController
							title="Bairro"
							placeholder=""
							size="md"
							type="text"
							width="100%"
							name="legalRepresentative.neighborhood"
							control={control}
							hasError={errors?.legalRepresentative?.neighborhood}
							disabled={copyMasterRecordAddress}
						/>
					</Stack>
				</Flex>
				<Flex
					mt="16px"
					direction={{ sm: 'column', md: 'row', lg: 'row' }}
				>
					<Stack
						spacing={5}
						direction={{ sm: 'column', md: 'row', lg: 'row' }}
					>
						<InputTextController
							title="Cidade"
							placeholder=""
							size="md"
							type="text"
							width="100%"
							name="legalRepresentative.city"
							control={control}
							hasError={errors?.legalRepresentative?.city}
							disabled={copyMasterRecordAddress}
						/>
						<InputTextController
							title="UF"
							placeholder=""
							size="md"
							type="text"
							width="100%"
							name="legalRepresentative.uf"
							control={control}
							hasError={errors?.legalRepresentative?.uf}
							disabled={copyMasterRecordAddress}
						/>
					</Stack>
				</Flex>
			</Accordion>
		</Box>
	)
}
