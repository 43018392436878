import { AddIcon, DownloadIcon, EditIcon, Search2Icon } from '@chakra-ui/icons'
import {
	Box,
	Button,
	Center,
	Flex,
	Grid,
	Heading,
	IconButton,
	Input,
	InputGroup,
	InputLeftElement,
	Spinner,
	Stack,
	Table,
	TableContainer,
	Tbody,
	Td,
	Text,
	Th,
	Thead,
} from '@chakra-ui/react'
import { Link, useSearchParams } from 'react-router-dom'
import dayjs from 'dayjs'
import * as Pdf from '@react-pdf/renderer'
import { BiDollar } from 'react-icons/bi'

import { TablePagination } from 'app/components/TablePagination'
import { TreatmentModal } from './TreatmentModal'
import { EyeIcon } from 'app/icons/EyeIcon'
import { TreatmentPdf } from './TreatmentPdf'
import { useTreatmentsQuery } from '../queries/useTreatmentsQuery'
import { Can } from 'ability'
import { DeleteTreatment } from './DeleteTreatment'
import { useQuery } from '@tanstack/react-query'
import api from 'services/api'

export function TreatmentsRoute() {
	const [searchParams, setSearchParams] = useSearchParams()
	const page = Math.abs(Number(searchParams.get('page')) || 1)
	const onPageChange = (nextPage: number) => {
		setSearchParams(
			(init) => new URLSearchParams({ ...init, page: String(nextPage) })
		)
	}

	const customer = searchParams.get('customer') ?? ''
	const hasSelectedCustomer = !!customer

	const query = useTreatmentsQuery()

	const treatments = query.data?.data
	const paginationInfo = query.data?.meta

	const isEmpty = treatments?.length <= 0

	const { data: selectedCustomer } = useQuery({
		queryKey: ['current_customer', customer],
		queryFn: async () => {
			const r = await api.get('/customers/' + customer)
			return { id: r.data._id, name: r.data.name }
		},
		placeholderData: customer && treatments?.[0]?.customer,
		enabled: !!customer,
	})

	return (
		<Stack px="6" py="10" h="100%" gap="6">
			<Flex justify="space-between" alignItems="center">
				<Heading fontSize="2xl">Tratamentos</Heading>

				<Can I="create" a="Treatment">
					<TreatmentModal
						trigger={
							<Button
								size="sm"
								fontSize="xs"
								colorScheme="primary"
								textTransform="uppercase"
								leftIcon={<AddIcon />}
							>
								Orçamento
							</Button>
						}
					/>
				</Can>
			</Flex>

			<Grid
				gap="6"
				templateColumns={{ base: 'auto', md: '572fr 274fr 274fr' }}
				templateRows={{ base: 'repeat(3,1fr)', md: 'auto' }}
				as="form"
				onChange={(e) => {
					const form: unknown = e.currentTarget
					const formData = new FormData(form as HTMLFormElement)

					const entries = Object.entries({
						...Object.fromEntries(searchParams),
						...Object.fromEntries(formData),
					})
						.filter(([, v]) => Boolean(v))
						.map(([k, v]) => [k, String(v)])
					setSearchParams(new URLSearchParams(entries), {
						replace: !!Array.from(searchParams).length,
					})
				}}
				onSubmit={(e) => e.preventDefault()}
			>
				<InputGroup flex={1} size="sm" maxW="543px">
					<InputLeftElement>
						<Search2Icon />
					</InputLeftElement>
					<Input
						name="search"
						defaultValue={searchParams.get('search')}
						bg="white"
						_dark={{ bg: '#182533' }}
						placeholder="Busque por profissional ou paciente"
						// onChange={onSearchChange}
					/>
				</InputGroup>

				<InputGroup
					size="sm"
					display="flex"
					alignItems="center"
					gap="3"
				>
					<Text
						fontSize="sm"
						fontWeight="600"
						whiteSpace="nowrap"
						textTransform="uppercase"
					>
						Data Início
					</Text>
					<Input
						size="sm"
						name="startDate"
						defaultValue={searchParams.get('startDate')}
						bg="white"
						_dark={{ bg: '#182533' }}
						type="date"
					/>
				</InputGroup>

				<InputGroup
					size="sm"
					display="flex"
					alignItems="center"
					gap="3"
				>
					<Text
						fontSize="sm"
						fontWeight="600"
						whiteSpace="nowrap"
						textTransform="uppercase"
					>
						Data Fim
					</Text>
					<Input
						size="sm"
						name="endDate"
						defaultValue={searchParams.get('endDate')}
						bg="white"
						_dark={{ bg: '#182533' }}
						type="date"
					/>
				</InputGroup>
			</Grid>

			{selectedCustomer && (
				<Flex alignItems="center">
					<Stack>
						<Text fontWeight="600">Paciente</Text>
						<Flex
							bg="white"
							alignItems="center"
							gap="3"
							px="4"
							py="3"
							rounded="md"
							border="1px solid"
							borderColor="inherit"
							_dark={{ bg: '#182533' }}
							w="240px"
							maxW="100%"
						>
							<Box
								w="8"
								h="8"
								rounded="full"
								bg="gray.300"
								_dark={{ bg: 'gray.700' }}
							/>
							<Text
								fontWeight="500"
								fontSize="sm"
								// color="primary.800"
								// _dark={{ color: 'primary.200' }}
								whiteSpace="nowrap"
								overflow="hidden"
								textOverflow="ellipsis"
								_hover={{ textDecoration: 'underline' }}
								as={Link}
								to={`/registros/customers/${selectedCustomer.id}`}
							>
								{selectedCustomer.name ?? '-'}
							</Text>
						</Flex>
					</Stack>

					<Text
						ml="auto"
						textAlign="right"
						fontWeight="600"
						whiteSpace="nowrap"
						overflow="hidden"
						textOverflow="ellipsis"
						color="primary.800"
						display="block"
						_hover={{ textDecoration: 'underline' }}
						as={Link}
						to={`/historico/${selectedCustomer.id}`}
					>
						Ver histórico
					</Text>
				</Flex>
			)}

			<TableContainer
				bg="white"
				_dark={{ bg: '#182533' }}
				p="4"
				rounded="12"
				h="100%"
				mb="6"
				position="relative"
			>
				<Table variant="striped">
					<Thead>
						<tr>
							{!hasSelectedCustomer && <Th>Paciente</Th>}
							<Th>Profissional</Th>
							<Th>Descrição</Th>
							<Th>Data Início</Th>
							<Th textAlign="center">Situação</Th>
							{/* <Th textAlign="center">Data Conclusão</Th> */}
							<Th></Th>
						</tr>
					</Thead>
					<Tbody>
						{treatments &&
							!isEmpty &&
							treatments.map((it) => (
								<tr key={it.id}>
									{!hasSelectedCustomer && (
										<Td>
											<Link
												to={{
													search: `?customer=${it.customer?.id}`,
												}}
												replace={hasSelectedCustomer}
											>
												<Text
													colorScheme="primary"
													_hover={{
														textDecoration:
															'underline',
													}}
												>
													{it.customer?.name}
												</Text>
											</Link>
										</Td>
									)}
									<Td>{it.professional?.name}</Td>
									<Td>
										<div
											style={{ display: 'grid' }}
											title={it.description}
										>
											<Text
												whiteSpace="nowrap"
												textOverflow="ellipsis"
												overflow="hidden"
											>
												{it.description}
											</Text>
										</div>
									</Td>
									<Td>
										{it.startDate
											? dayjs(it.startDate)
													.tz('Etc/Greenwich')
													.format('DD/MM/YYYY')
											: '-'}
									</Td>
									<Td textAlign="center">
										{it.status === 'approved' ? (
											<Text
												color="#00CC66"
												textTransform="uppercase"
											>
												Aprovado
											</Text>
										) : (
											<Can
												I="update"
												this={it}
												passThrough
											>
												{(allowed) =>
													allowed ? (
														<TreatmentModal
															isApproval
															treatment={it}
															trigger={
																<Button
																	colorScheme="primary"
																	textTransform="uppercase"
																	fontWeight="400"
																>
																	Aprovar
																</Button>
															}
														/>
													) : (
														<Text
															color="red"
															textTransform="uppercase"
														>
															Não aprovado
														</Text>
													)
												}
											</Can>
										)}
									</Td>

									{/* <Td textAlign="center">
										{it.completionDate
											? dayjs(it.completionDate)
													.tz('Etc/Greenwich')
													.format('DD/MM/YYYY')
											: '-'}
									</Td> */}

									<Td>
										<Flex gap="3" justifyContent="end">
											<Pdf.PDFDownloadLink
												fileName="orcamento.pdf"
												document={
													<TreatmentPdf
														treatment={it}
													/>
												}
											>
												{(state) => (
													<IconButton
														bg="#f9f9f9"
														_dark={{
															bg: '#223344',
														}}
														aria-label="Baixar Orçamento"
														icon={
															state.loading ? (
																<Spinner size="sm" />
															) : (
																<DownloadIcon
																	fontSize={
																		20
																	}
																/>
															)
														}
													/>
												)}
											</Pdf.PDFDownloadLink>
											<IconButton
												bg="#f9f9f9"
												_dark={{ bg: '#223344' }}
												aria-label="Ver detalhes do Tratamento"
												icon={<EyeIcon size={20} />}
												as={Link}
												to={it.id}
											/>
											{it.status !== 'approved' && (
												<Can I="update" this={it}>
													<TreatmentModal
														treatment={it}
														trigger={
															<IconButton
																bg="#f9f9f9"
																_dark={{
																	bg: '#223344',
																}}
																aria-label="Editar Tratamento"
																icon={
																	<EditIcon
																		fontSize={
																			20
																		}
																	/>
																}
															/>
														}
													/>
												</Can>
											)}

											{it.status === 'approved' && (
												<>
													<Can I="read" a="Payment">
														<IconButton
															bg="#f9f9f9"
															_dark={{
																bg: '#223344',
															}}
															aria-label="Ver pagamentos do Tratamento"
															icon={
																<BiDollar
																	size={20}
																/>
															}
															as={Link}
															to={`${it.id}#pagamentos`}
														/>
													</Can>

													{/* {!it.completionDate && (
														<Can
															I="update"
															this={it}
														>
															<TreatmentCompletion
																treatment={it}
																trigger={
																	<IconButton
																		bg="#f9f9f9"
																		_dark={{
																			bg: '#223344',
																		}}
																		aria-label="Marcar como concluído"
																		icon={
																			<MdOutlineCheckBox
																				size={
																					20
																				}
																			/>
																		}
																	/>
																}
															/>
														</Can>
													)} */}
												</>
											)}

											<Can I="delete" this={it}>
												<DeleteTreatment
													treatment={it}
												/>
											</Can>
										</Flex>
									</Td>
								</tr>
							))}

						{!query.data && query.isLoading && (
							<tr style={{ display: 'block' }}>
								<td>
									<Center
										position="absolute"
										h="100%"
										top="50%"
										left="50%"
										transform="translate(-50%,-50%)"
									>
										<Spinner />
									</Center>
								</td>
							</tr>
						)}

						{isEmpty && (
							<tr style={{ display: 'block' }}>
								<td>
									<Center
										flexDirection="column"
										position="absolute"
										h="100%"
										top="50%"
										left="50%"
										transform="translate(-50%,-50%)"
									>
										<Text>
											Nenhum orçamento salvo ainda
										</Text>
										<Text>
											Clique no botão {'"+ Orçamento"'}{' '}
											para criar um novo
										</Text>
									</Center>
								</td>
							</tr>
						)}
						{query.isLoadingError && (
							<tr style={{ display: 'block' }}>
								<td>
									<Center
										flexDirection="column"
										position="absolute"
										h="100%"
										top="50%"
										left="50%"
										transform="translate(-50%,-50%)"
									>
										<Text>
											Algo deu errado ao carregar esses
											dados...
										</Text>
										<Text>{String(query.error)}</Text>
									</Center>
								</td>
							</tr>
						)}
					</Tbody>
				</Table>
			</TableContainer>

			{paginationInfo && paginationInfo.total > 0 && (
				<Flex alignItems="center" justify="end">
					<TablePagination
						total={paginationInfo.total}
						perPage={paginationInfo.perPage}
						page={page}
						onPageChange={onPageChange}
					/>

					<Text
						ml="5"
						fontSize="xs"
						fontWeight="medium"
						textTransform="uppercase"
					>
						{paginationInfo.total} registros
					</Text>
				</Flex>
			)}
		</Stack>
	)
}
