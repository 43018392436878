import { useQuery } from '@tanstack/react-query'
import { Payment } from 'data/Payment'
import api from 'services/api'

export function useTreatmentPaymentsQuery(treatmentId: string) {
	return useQuery({
		queryKey: ['payments', treatmentId],
		async queryFn() {
			const response = await api.get(
				`/treatments/${treatmentId}/payments`
			)
			const payments: Payment[] = response.data.payments?.map(
				Payment.fromJson
			)
			return payments
		},
	})
}
