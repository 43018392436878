import { infiniteQueryOptions, useInfiniteQuery } from '@tanstack/react-query'
import { CustomersRepository } from 'data/CustomersRepository'

interface CustomersQueryArgs {
	name?: string
}

export const infiniteCustomersQueryOptions = (args?: CustomersQueryArgs) =>
	infiniteQueryOptions({
		queryKey: ['customers', 'infinite', args],
		queryFn(context) {
			const page = context.pageParam
			return new CustomersRepository().getShortenedCustomers({
				page,
				...args,
				signal: context.signal,
			})
		},
		initialPageParam: 1,
		getNextPageParam(last) {
			return last.next
		},
		placeholderData(previousData) {
			if (!previousData) return
			return {
				...previousData,
				pages: previousData.pages.map((p) => ({
					...p,
					data: p.data.filter(
						(it) =>
							it.name?.localeCompare(args.name, undefined, {
								sensitivity: 'base',
							}) !== -1
					),
				})),
			}
		},
		select({ pages }) {
			return pages.flatMap((it) => it.data)
		},
	})

export function useInfiniteCustomersQuery(args?: CustomersQueryArgs) {
	return useInfiniteQuery(infiniteCustomersQueryOptions(args))
}
