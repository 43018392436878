import { useForm, FormProvider } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { editProcedureSchema } from './validation/editProcedureSchema'
import { ProceduresManagementContextProvider } from './contexts/ProceduresManagementContext'
import { defaultValues } from './contexts/ProceduresFormManagementContext/defaultValues'
import { ProcedureMain } from './components/ProcedureMain/ProcedureMain'

export default function History() {
	const editScheduleModalMethods = useForm({
		// defaultValues,
		resolver: yupResolver(editProcedureSchema),
		mode: 'all',
	})
	return (
		<FormProvider {...editScheduleModalMethods}>
			<ProceduresManagementContextProvider>
				<ProcedureMain />
			</ProceduresManagementContextProvider>
		</FormProvider>
	)
}
