import { AddIcon } from '@chakra-ui/icons'
import {
	Button,
	Flex,
	Heading,
	Stack,
	useMultiStyleConfig,
} from '@chakra-ui/react'
import {
	Link,
	LinkProps,
	Outlet,
	useLocation,
	useResolvedPath,
} from 'react-router-dom'

import { Can } from 'ability'

export function AdminRoute() {
	return (
		<Stack px="6" py="10" h="100%" gap="6">
			<Flex justify="space-between" alignItems="center">
				<Heading fontSize="2xl">Administração</Heading>
			</Flex>

			<Flex
				__css={{
					borderBottom: '2px solid',
					borderColor: 'inherit',
					flexDirection: 'row',
					justifyContent: 'flex-start',
				}}
			>
				<Can I="read" a="Product">
					<NavTab to="produtos">Controle de estoque</NavTab>
				</Can>
				<Can I="update" a="User">
					<NavTab to="permissoes">Permissões</NavTab>
				</Can>
				<Can I="read" a="Bill">
					<NavTab to="contas">Contas</NavTab>
				</Can>
				<Can I="read" a="Report">
					<NavTab to="relatorios">Relatórios</NavTab>
				</Can>
				<Can I="read" a="Customer">
					<NavTab to="retorno-de-clientes">
						Retorno de Clientes
					</NavTab>
				</Can>
			</Flex>

			<Outlet />
		</Stack>
	)
}

function NavTab({ to, children }: LinkProps) {
	const isSelected = useLocation().pathname === useResolvedPath(to).pathname

	const tabProps = {
		colorScheme: 'primary',
		'aria-selected': isSelected,
	}
	const styles = useMultiStyleConfig('Tabs', tabProps)

	return (
		<Button
			__css={styles.tab}
			{...tabProps}
			display="inline-block"
			fontWeight="600"
			as={Link}
			to={to}
			replace
		>
			{children}
		</Button>
	)
}
