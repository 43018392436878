import { useToast } from '@chakra-ui/react'
import useDebounce from 'app/hooks/useDebounce'
import { valueNoMask } from 'app/utils/funcs/valueNoMask'
import { phoneMask } from 'app/utils/masks'
import dayjs from 'dayjs'
import { recordsFormSchema } from 'modules/records/validations/recordsFormSchema'
import { useCreateRecord } from 'modules/records/queries/useCreateRecord'
import { useGetAllRecords } from 'modules/records/queries/useGetAllRecords'
import { useGetOneRecord } from 'modules/records/queries/useGetOneRecord'
import React, { createContext, useEffect, useMemo, useState } from 'react'
import { useFormContext, UseFormHandleSubmit } from 'react-hook-form'
import { useQueryClient } from '@tanstack/react-query'
import { useNavigate, useParams } from 'react-router-dom'
import Swal from 'sweetalert2'
import { getDefaultValues } from '../ManageRegistrationFormContext/defaultValues'
import { FormProps } from '../ManageRegistrationFormContext/types'
import api from 'services/api'

interface RecordsManagementContextProvider {
	children: React.ReactNode
}

interface RecordsManagementContextProps {
	allRecords: any
	totalOfAllRecords: number
	getAllRecordsIsLoading: boolean
	setFilterByTypeRecord: React.Dispatch<React.SetStateAction<string>>
	setFilterByTypeInfo: React.Dispatch<React.SetStateAction<string>>
	setOffsetPage: React.Dispatch<React.SetStateAction<number>>
	setInfoSearch: React.Dispatch<React.SetStateAction<string>>
	filterByTypeRecord: string
	filterByTypeInfo: string
	offsetPage: number
	infoSearch: string
	isOpenRecordFormModal: boolean
	setIsOpenRecordFormModal: React.Dispatch<React.SetStateAction<boolean>>
	closeRegistrationFormModal: () => void
	handleSubmit: UseFormHandleSubmit<FormProps>
	onSubmit: (data: FormProps) => void
	isCreateRecordLoading: boolean
	isOpenAccordion: boolean
	setIsOpenAccordion: React.Dispatch<React.SetStateAction<boolean>>
}

const RecordsManagementContext = createContext(
	{} as RecordsManagementContextProps
)

export const RecordsManagementContextProvider = ({
	children,
}: {
	children: React.ReactNode
}) => {
	const navigate = useNavigate()
	const toast = useToast()
	const queryClient = useQueryClient()
	const { reset, handleSubmit, watch } = useFormContext<FormProps>()
	const [allRecords, setAllRecords] = useState([])
	const [totalOfAllRecords, setTotalOfAllRecords] = useState(0)
	const [filterByTypeRecord, setFilterByTypeRecord] = useState('customers')
	const [filterByTypeInfo, setFilterByTypeInfo] = useState('name')
	const [offsetPage, setOffsetPage] = useState(0)
	const [infoSearch, setInfoSearch] = useState('')
	const [isOpenRecordFormModal, setIsOpenRecordFormModal] = useState(false)
	const [isOpenAccordion, setIsOpenAccordion] = useState(false)
	const debouncedInfoSearch = useDebounce(infoSearch, 2000)
	const hasResponsible =
		watch('legalRepresentative.config.hasResponsible') === 'Yes'

	const isLegalEntity = watch('personType') === 'PJ'

	const registrationType = watch('registrationType')

	const closeRegistrationFormModal = () => {
		reset(getDefaultValues())
		setIsOpenRecordFormModal(false)
		setIsOpenAccordion(false)
	}

	const filtersForRequestAllRecords = {
		typeInfo: filterByTypeInfo,
		recordType: filterByTypeRecord,
		offset: offsetPage,
		search: infoSearch,
	}

	useEffect(() => {
		setOffsetPage(0)
	}, [infoSearch, filterByTypeRecord, filterByTypeInfo])

	const { data, isLoading: getAllRecordsIsLoading } = useGetAllRecords({
		params: filtersForRequestAllRecords,
		onError: () => {
			toast({
				title: 'Ops...',
				description: 'Ocorreu um erro ao listar cadastros!',
				status: 'error',
				duration: 9000,
				isClosable: false,
				position: 'top-right',
			})
		},
	})

	React.useEffect(() => {
		if (data) {
			setAllRecords(data?.rows)
			setTotalOfAllRecords(data.count)
		}
	}, [data])

	const { mutate: handleCreateRecord, isPending: isCreateRecordLoading } =
		useCreateRecord({
			onSuccess: () => {
				closeRegistrationFormModal()
				queryClient.invalidateQueries({
					queryKey: ['useGetAllRecords'],
				})
				queryClient.invalidateQueries({
					queryKey: ['professionals'],
				})
				Swal.fire({
					title: 'Sucesso!',
					text: 'Cadastro criado com sucesso!',
					icon: 'success',
					confirmButtonText: 'Ok',
				})
			},
			onError: () => {
				toast({
					title: 'Ops...',
					description: 'Erro ao cadastrar registro!',
					status: 'error',
					duration: 9000,
					isClosable: false,
					position: 'top-right',
				})
			},
		})

	const onSubmit = async (data: FormProps) => {
		const schema = recordsFormSchema
		const schemaArray = Object.keys(schema.fields)
		for (let i = 0; i < schemaArray.length; i++) {
			if (!data[schemaArray[i]]) {
				if (
					!window.confirm(
						'Há campos não preenchidos. Gostaria de prosseguir mesmo assim?'
					)
				) {
					return
				}
				break
			}
		}

		const [nameExists, phoneExists] = await Promise.all([
			verifyNameExists(data.name),
			verifyPhoneExists(data.cellphoneNumber),
		])

		if (
			nameExists &&
			!window.confirm(
				'Já existe um cadastro com esse nome. Continuar mesmo assim?'
			)
		) {
			return
		}
		if (
			phoneExists &&
			!window.confirm(
				'Já existe um cadastro com esse telefone. Continuar mesmo assim?'
			)
		) {
			return
		}

		async function verifyNameExists(name: string) {
			const response = await api.get(`/customers?name=${name}`)
			return response.data.count > 0
		}

		async function verifyPhoneExists(phone: string) {
			const response = await api.get(`/customers?phone=${phone}`)
			return response.data.count > 0
		}

		data.cpf = valueNoMask(data.cpf)
		data.cnpj = valueNoMask(data.cnpj)
		data.legalRepresentative.cpf = valueNoMask(data.legalRepresentative.cpf)
		data.legalRepresentative.cnpj = valueNoMask(
			data.legalRepresentative.cnpj
		)
		data.isLegalEntity = isLegalEntity
		data.login = data.email
		data.user.password = data.cpf || data.cnpj
		data.cellphoneNumber = phoneMask.unmask(data.cellphoneNumber)
		if (data.secondaryCellphoneNumber) {
			data.secondaryCellphoneNumber = phoneMask.unmask(
				data.secondaryCellphoneNumber
			)
		}
		!hasResponsible && delete data?.legalRepresentative

		handleCreateRecord({
			values: {
				...data,
				cnpj: !data.cpf ? data.cnpj : undefined,
				registrationType: undefined,
				personType: undefined,
				isLegalEntity: undefined,
				preRegister: undefined,
			},
			typeRegister: data.registrationType,
		})
	}

	useGetOneRecord({
		recordType: undefined,
		recordId: undefined,
		onSuccess: (data) => {
			const form = {
				...data,
				birthday: dayjs(data.birthday).format('YYYY-MM-DD'),
				user: { ...data?.User },
				config: {
					registrationType: 'customers',
					personType: 'PJ',
				},
				legalRepresentative: {
					config: {
						hasResponsible: data?.LegalRepresentatives[0]
							? 'Yes'
							: 'No',
					},
					...data?.LegalRepresentatives[0],

					birthday: dayjs(
						data?.LegalRepresentatives[0]?.birthday
					).format('YYYY-MM-DD'),
				},
			}
			reset(form as unknown as FormProps)
		},
		onError: () => {
			toast({
				title: 'Ops...',
				description: 'Erro ao buscar informações do cadastro!',
				status: 'error',
				duration: 9000,
				isClosable: false,
				position: 'top-right',
			})
		},
	})

	const value: RecordsManagementContextProps = useMemo(() => {
		return {
			allRecords,
			totalOfAllRecords,
			getAllRecordsIsLoading,
			filterByTypeRecord,
			filterByTypeInfo,
			offsetPage,
			infoSearch,
			isOpenRecordFormModal,
			isCreateRecordLoading,
			isOpenAccordion,
			setFilterByTypeRecord,
			setFilterByTypeInfo,
			setOffsetPage,
			setInfoSearch,
			setIsOpenRecordFormModal,
			closeRegistrationFormModal,
			handleSubmit,
			onSubmit,
			setIsOpenAccordion,
		}
	}, [
		allRecords,
		totalOfAllRecords,
		getAllRecordsIsLoading,
		filterByTypeRecord,
		filterByTypeInfo,
		offsetPage,
		infoSearch,
		isOpenRecordFormModal,
		isCreateRecordLoading,
		isOpenAccordion,
		onSubmit,
	])

	return (
		<RecordsManagementContext.Provider value={value}>
			{children}
		</RecordsManagementContext.Provider>
	)
}

export default RecordsManagementContext
