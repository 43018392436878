/* eslint-disable react/jsx-props-no-spreading */
import { useId } from 'react'
import { InputProps } from '@chakra-ui/react'
import { Control, Controller } from 'react-hook-form'
import MaskedInput from '../MaskedInput'

interface MaskedInputProps extends InputProps {
	mask: string
	title: string
	margin?: string | object
	control: Control
	name: string
	hasError: boolean
	errorMessage?: string
	disabled?: boolean
}

export const MaskedInputController = (props: MaskedInputProps) => {
	const { control, name, ...rest } = props

	const id = useId()

	return (
		<Controller
			name={name}
			control={control}
			render={({ field }) => (
				<MaskedInput
					{...rest}
					{...field}
					value={field.value ?? ''}
					key={id}
				/>
			)}
		/>
	)
}

MaskedInputController.defaultProps = {
	margin: 0,
	errorMessage: 'Este campo é obrigatório',
	disabled: false,
}
