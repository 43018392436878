import { Button, Flex, useColorModeValue } from '@chakra-ui/react'
import { useProceduresManagement } from 'modules/history/hooks/useProceduresManagement'

export function FormFooter({ isDeleted }: { isDeleted: boolean }) {
	const { setIsOpenProcedureForm, isEditProcedureLoading } =
		useProceduresManagement()
	const btnBg = useColorModeValue('#F2F2F2', 'whiteAlpha.50')

	return (
		<Flex as="footer" marginTop="auto" columnGap="44px" alignSelf="center">
			<Button
				width="134px"
				display="flex"
				alignItems="center"
				background={btnBg}
				color="#55BBDD"
				fontSize="12px"
				textTransform="uppercase"
				paddingY="10px"
				onClick={() => setIsOpenProcedureForm(false)}
			>
				Cancelar
			</Button>
			{/* {isEdit ?  */}
			<Button
				width="134px"
				background="#55BBDD"
				color="white"
				fontSize="12px"
				textTransform="uppercase"
				paddingY="10px"
				type="submit"
				form="procedureForm"
				isLoading={isEditProcedureLoading}
				disabled={isDeleted}
			>
				Salvar
			</Button>
			{/* :
                <Button  width="134px" background="#55BBDD" color="white" fontSize="12px" textTransform="uppercase" paddingY="10px" type="button" onClick={() => setIsEdit(true)}>
                    Editar
                </Button>
            } */}
		</Flex>
	)
}
