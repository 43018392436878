/* eslint-disable no-use-before-define */
import { Tab, TabList, TabPanel, TabPanels, Tabs } from '@chakra-ui/react'
import React from 'react'
import { SettingsByProfessional } from './SettingsByProfessional'
import { SettingsDefault } from './SettingsDefault'

export interface Props {
	onCancel: () => void
}

export function ScheduleSettingsBody({ onCancel }: Props) {
	return (
		<div>
			<Tabs>
				<TabList>
					<Tab>Geral</Tab>
					<Tab>Por Profissional</Tab>
				</TabList>

				<TabPanels>
					<TabPanel>
						<SettingsDefault onCancel={onCancel} />
					</TabPanel>

					<TabPanel>
						<SettingsByProfessional onCancel={onCancel} />
					</TabPanel>
				</TabPanels>
			</Tabs>
		</div>
	)
}
