/* eslint-disable no-console */
/* eslint-disable no-underscore-dangle */
import { Box, Flex, Heading } from '@chakra-ui/react'
import { ImagesViewer } from 'app/components/ImagesViewer'
import { Modal } from 'app/components/Modal'
import React from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useNavigate, useParams } from 'react-router-dom'
import { useBreakpointsMediaQuery } from 'app/hooks/useMediaQuery'
import { useGetDocuments } from './queries/useGetDocuments'
import { useGetCustomer } from './queries/useGetCustomer'
import { ImageView } from './components/ProcedureForm/Image'

export function DocumentDetails() {
	document.title = 'ConsultData | Documento'

	const navigate = useNavigate()

	const { documentId } = useParams()
	const query = useGetDocuments()
	const doc = query.data?.data.find((it) => it._id === documentId)
	const form = useForm({ defaultValues: doc })
	const { isDesktop } = useBreakpointsMediaQuery()

	React.useEffect(() => {
		if (doc) {
			form.reset(doc)
		}
	}, [doc])
	const { customerId } = useParams()
	const customerQuery = useGetCustomer({ params: { customerId } })

	const viewer = ImagesViewer.useImagesViewer(doc?.files ?? [])
	const isViewerOpen = !!viewer.currentPhotoId

	const params = useParams()
	const renderTitle = () => {
		switch (params.type) {
			case 'anamnesis':
				return 'Anamnese'
			case 'radiograph':
				return 'Radiografia'
			default:
				return 'Documento'
		}
	}

	return (
		<FormProvider {...form}>
			<Modal
				isOpen
				onClose={() => {
					navigate('..', { replace: true })
				}}
				title={renderTitle()}
				handleForm={() => undefined}
				size="xl"
				body={
					<Box>
						{isViewerOpen && (
							<ImagesViewer
								photos={doc?.files ?? []}
								currentPhotoId={viewer.currentPhotoId}
								onBack={viewer.handleBackPhoto}
								onNext={viewer.handleNextPhoto}
								onClose={viewer.closePhoto}
							/>
						)}

						<Heading
							as="h3"
							fontSize="20px"
							fontWeight="700"
							color="#1188EE"
							mb={6}
						>
							{customerQuery?.data?.name}
						</Heading>

						{doc && (
							<Flex flexWrap="wrap" gap={4}>
								<Box
									marginTop="16px"
									display="grid"
									width={isDesktop ? '50%' : '100%'}
									marginX="auto"
									// gridAutoFlow="column"
									gridTemplateColumns="repeat(auto-fit, minmax(max(100px, 100px), 1fr))"
									rowGap="16px"
									columnGap="24px"
									justifyContent="center"
								>
									{doc.files.map((file: { url: string }) => (
										<ImageView
											onClick={() =>
												viewer.openPhoto(file.url)
											}
											key={file.url}
											url={file.url}
										/>
									))}
								</Box>
							</Flex>
						)}
					</Box>
				}
				footer={<></>}
				dividers={false}
			/>
		</FormProvider>
	)
}
