import { EditIcon } from '@chakra-ui/icons'
import {
	TableContainer,
	Table,
	Thead,
	Tbody,
	Th,
	Td,
	Text,
	IconButton,
	Flex,
	Center,
	Spinner,
} from '@chakra-ui/react'
import dayjs from 'dayjs'
import { useSearchParams } from 'react-router-dom'

import { TablePagination } from 'app/components/TablePagination'
import { useBillsQuery } from '../queries/useBillsQuery'
import { BillModal } from './BillModal'
import { DownloadIcon } from 'app/icons/DownloadIcon'
import { Can } from 'ability'

export function FinancialBillsRoute() {
	const [searchParams, setSearchParams] = useSearchParams()
	const page = Math.abs(Number(searchParams.get('page')) || 1)
	const onPageChange = (nextPage: number) => {
		setSearchParams(
			(init) => new URLSearchParams({ ...init, page: String(nextPage) })
		)
	}

	const query = useBillsQuery({ page })
	const bills = query.data?.data
	const paginationInfo = query.data?.meta

	const isEmpty = bills?.length <= 0

	return (
		<>
			<TableContainer
				bg="white"
				_dark={{ bg: '#182533' }}
				p="4"
				rounded="12"
				h="100%"
				mb="6"
				position="relative"
			>
				<Table variant="striped">
					<Thead>
						<tr>
							<Th fontSize="sm">Conta</Th>
							<Th fontSize="sm">Data de Pagamento</Th>
							<Th fontSize="sm">Data de Vencimento</Th>
							<Th fontSize="sm">Valor</Th>
							<Th fontSize="sm">Tributável?</Th>
							<Th fontSize="sm"></Th>
						</tr>
					</Thead>
					<Tbody>
						{bills &&
							!isEmpty &&
							bills.map((it) => {
								return (
									<tr key={it.id}>
										<Td>{it.name}</Td>
										<Td>
											{it.paymentDate
												? dayjs(it.paymentDate)
														.utc()
														.format('DD/MM/YYYY')
												: '-'}
										</Td>
										<Td>
											{it.dueDate
												? dayjs(it.dueDate)
														.utc()
														.format('DD/MM/YYYY')
												: '-'}
										</Td>
										<Td>
											{it.value?.toLocaleString([], {
												style: 'currency',
												currency: 'BRL',
											})}
										</Td>
										<Td>
											<Text
												textTransform="uppercase"
												color={
													it.isTaxable
														? '#00CC66'
														: undefined
												}
											>
												{it.isTaxable ? 'Sim' : 'Não'}
											</Text>
										</Td>

										<Td>
											<Flex gap="3" justifyContent="end">
												{it.file?.url && (
													<IconButton
														display="flex"
														variant="unstyled"
														color="#0C6"
														bg="#f9f9f9"
														_dark={{
															bg: '#223344',
														}}
														aria-label="Baixar recibo"
														icon={
															<DownloadIcon
																size={20}
															/>
														}
														as="a"
														download={it.file.url
															.split('/')
															.at(-1)
															.split('?')
															.at(0)}
														href={it.file.url}
														target="_blank"
													/>
												)}
												<Can I="update" this={it}>
													<BillModal
														bill={it}
														trigger={
															<IconButton
																colorScheme="primary"
																variant="unstyled"
																bg="#f9f9f9"
																_dark={{
																	bg: '#223344',
																}}
																aria-label="Editar pagamento"
																icon={
																	<EditIcon />
																}
															/>
														}
													/>
												</Can>
											</Flex>
										</Td>
									</tr>
								)
							})}

						{!query.data && query.isLoading && (
							<tr style={{ display: 'block' }}>
								<td>
									<Center
										position="absolute"
										h="100%"
										top="50%"
										left="50%"
										transform="translate(-50%,-50%)"
									>
										<Spinner />
									</Center>
								</td>
							</tr>
						)}

						{isEmpty && (
							<tr style={{ display: 'block' }}>
								<td>
									<Center
										flexDirection="column"
										position="absolute"
										h="100%"
										top="50%"
										left="50%"
										transform="translate(-50%,-50%)"
									>
										<Text>
											Nenhum pagamento salvo ainda
										</Text>
										<Text>
											Clique no botão {'"+ Pagamento"'}{' '}
											para criar um novo
										</Text>
									</Center>
								</td>
							</tr>
						)}
						{query.isLoadingError && (
							<tr style={{ display: 'block' }}>
								<td>
									<Center
										flexDirection="column"
										position="absolute"
										h="100%"
										top="50%"
										left="50%"
										transform="translate(-50%,-50%)"
									>
										<Text>
											Algo deu errado ao carregar esses
											dados...
										</Text>
										<Text>{String(query.error)}</Text>
									</Center>
								</td>
							</tr>
						)}
					</Tbody>
				</Table>
			</TableContainer>
			{paginationInfo && paginationInfo.total > 0 && (
				<Flex
					alignItems="center"
					justify="end"
					flexDirection={{ base: 'column', md: 'row' }}
					columnGap="5"
					rowGap="3"
				>
					<TablePagination
						total={paginationInfo.total}
						perPage={paginationInfo.perPage}
						page={page}
						onPageChange={onPageChange}
					/>

					<Text
						fontSize="xs"
						fontWeight="medium"
						textTransform="uppercase"
					>
						{paginationInfo.total} registros
					</Text>
				</Flex>
			)}
		</>
	)
}
