import { Thead, Th, useColorModeValue } from '@chakra-ui/react'

export function PacientTHeadMobile() {
	const bg = useColorModeValue('#F2F2F2', '#182533')
	const border = useColorModeValue('#C6C6C6', '#777777')

	return (
		<Thead display="grid" gridTemplateRows="1fr 1fr 1fr" bg={bg}>
			<Th paddingY="8px" paddingX="10px">
				Data
			</Th>
			<Th
				paddingY="8px"
				paddingX="10px"
				borderTop={`1px solid ${border}`}
			>
				Procedimentos
			</Th>
			<Th
				paddingY="8px"
				paddingX="10px"
				borderTop={`1px solid ${border}`}
			>
				Profissional
			</Th>
		</Thead>
	)
}
