import dayjs from 'dayjs'

import type { ScheduleSettings } from 'modules/schedule/queries/useSettingsByProfessional'
import { useBreakpointsMediaQuery } from 'app/hooks/useMediaQuery'
import { useProfessionalSchedule } from 'modules/schedule/queries/useProfessionalSchedule'
import { ScheduleTableRoot as Table } from './ScheduleTableRoot'
import { ScheduleCell } from './ScheduleCell'
import { ScheduleTime } from './ScheduleTime'
import { Annotation } from './Annotation'
import { isDisabledDay } from './isDisabledDay'

function isLunchTime(now: dayjs.Dayjs, startTime: string, endTime: string) {
	const [startHour, startMinute] = startTime.split(':').map(Number)
	const [endHour, endMinute] = endTime.split(':').map(Number)
	const start = now.hour(startHour).minute(startMinute)
	return (
		(now.isSame(start) || now.isAfter(start)) &&
		now.isBefore(now.hour(endHour).minute(endMinute))
	)
}

export function ScheduleRow({
	schedule,
	data,
	settings,
	view,
	onChangeView,
}: {
	schedule: dayjs.Dayjs
	data: ReturnType<typeof useProfessionalSchedule>['data']
	settings: ScheduleSettings
	view: 'schedule' | 'annotations'
	onChangeView: () => void
}) {
	const scheduleString = schedule.format('HH:mm')
	const tz = 'America/Sao_Paulo'
	const format = 'HH:mm dd/MM/yyyy'

	const events = data?.events.filter((ev: any) => {
		const eventStart = dayjs(ev.start).tz(tz)
		return eventStart.format(format) === schedule.format(format)
	})

	const annotation = data?.annotations.find((a: any) => {
		const annotationStart = dayjs(a.start).tz(tz)
		return annotationStart.format(format) === schedule.format(format)
	})

	const disabled =
		isDisabledDay(settings.agenda.availableDays, schedule.day()) ||
		isLunchTime(
			schedule,
			settings.agenda.lunchStartTime,
			settings.agenda.lunchEndTime
		)

	const startDate = schedule.toDate()
	const scheduleEnd = schedule.add(
		settings.agenda.sessionDurationMinutes,
		'minute'
	)
	const endDate = scheduleEnd.toDate()

	const { isMobile } = useBreakpointsMediaQuery()

	const isScheduleVisible = !isMobile || view === 'schedule'
	const isAnnotationVisible = !isMobile || view === 'annotations'

	return (
		<tr key={scheduleString}>
			<ScheduleTime scheduleString={scheduleString} />
			{isScheduleVisible && (
				<ScheduleCell
					disabled={disabled}
					events={events}
					settings={settings}
					hasAnnotation={!!annotation}
					onAnnotationIconPress={() => onChangeView()}
					allEvents={data.events}
					start={schedule.format('YYYY-MM-DDTHH:mm:ss')}
					end={scheduleEnd.format('YYYY-MM-DDTHH:mm:ss')}
				/>
			)}
			{!isMobile && (
				<Table.Cell
					bg={annotation ? 'red.500' : 'green.500'}
					p="12px"
				/>
			)}
			{isAnnotationVisible && (
				<Annotation
					annotation={annotation}
					startDate={startDate}
					endDate={endDate}
				/>
			)}
		</tr>
	)
}
