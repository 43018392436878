import { Box, Flex } from '@chakra-ui/react'

import { Heading } from 'app/components/Heading'
import { TablePagination } from 'app/components/TablePagination'
import { useRecordsManagement } from 'modules/records/hooks/useRecordsManagement'

export const Footer = () => {
	const { totalOfAllRecords, setOffsetPage, offsetPage } =
		useRecordsManagement()
	const LIMIT = 10

	return (
		<Flex
			alignItems="center"
			justifyContent="space-between"
			height={45}
			width="100%"
			mt={2}
		>
			<Box>
				<></>
			</Box>
			<Box>
				<TablePagination
					perPage={LIMIT}
					total={totalOfAllRecords}
					page={offsetPage / LIMIT + 1}
					onPageChange={(page) => setOffsetPage((page - 1) * LIMIT)}
				/>
			</Box>
			<Box>
				<Heading
					type="h3"
					fontSize={{ sm: '12px', md: '13px', lg: '15px' }}
				>
					{totalOfAllRecords} cadastros
				</Heading>
			</Box>
		</Flex>
	)
}
