import { DownloadIcon, SpinnerIcon } from '@chakra-ui/icons'
import { Box, Button, Spinner, useColorModeValue } from '@chakra-ui/react'
import React from 'react'
import api from 'services/api'

/**
 *
 * @example
 * <GenerateReport
		url="/api/endpoint"
		name="relatorio.xlsx"
	 />
 */
export function GenerateReport({ url, name }: { url: string; name: string }) {
	const [href, setHref] = React.useState('')
	const [loading, setLoading] = React.useState(false)
	const handleGenerateReport = async () => {
		try {
			setLoading(true)
			const { data } = await api.get(url, { responseType: 'blob' })
			const fileUrl = window.URL.createObjectURL(data)
			setHref(fileUrl)
		} finally {
			setLoading(false)
		}
	}
	const bg = useColorModeValue('#FFF', 'whiteAlpha.50')
	return (
		<Box display="flex" alignItems="center" columnGap="5px">
			<Button
				type="button"
				onClick={handleGenerateReport}
				boxSizing="border-box"
				width="24px"
				height="30px"
				p="0"
				borderRadius="5px"
				display="flex"
				alignItems="center"
				justifyContent="center"
				backgroundColor={bg}
			>
				{loading ? (
					<Spinner size="sm" color="#1188EE" />
				) : (
					<DownloadIcon color="#1188EE" />
				)}
			</Button>
			{href && (
				<a
					href={href}
					download={name}
					// ref={(el) => el.click()} // Para disparar o download automaticamente, mas pode provocar bloqueio de segurança do navegador
					style={{
						fontSize: '14px',
						fontWeight: '500',
						color: '#1188EE',
					}}
				>
					Baixar
				</a>
			)}
		</Box>
	)
}
