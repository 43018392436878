import { useQuery } from '@tanstack/react-query'
import api from 'services/api'

export function useCustomerQuery(customerId?: string) {
	return useQuery({
		queryKey: ['customers', customerId],
		async queryFn() {
			const response = await api.get(`/customers/${customerId}`)
			return response.data
		},
		enabled: !!customerId,
		staleTime: 10_000,
	})
}
