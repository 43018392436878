import { AddIcon, EditIcon, Search2Icon } from '@chakra-ui/icons'
import { Center, Spinner, Tr } from '@chakra-ui/react'
import {
	Stack,
	Heading,
	Flex,
	Button,
	Input,
	InputGroup,
	InputLeftElement,
	Table,
	Thead,
	Th,
	TableContainer,
	Tbody,
	Td,
	Text,
	IconButton,
} from '@chakra-ui/react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { FiDollarSign } from 'react-icons/fi'

import { ProcedureFormModal } from './ProcedureFormModal'
import { TablePagination } from '../../../app/components/TablePagination'
import { useProceduresQuery } from '../queries/useProceduresQuery'
import { Can } from 'ability'

export function ProceduresRoute() {
	const navigate = useNavigate()
	const [searchParams, setSearchParams] = useSearchParams()
	const page = Math.abs(Number(searchParams.get('page')) || 1)
	const onPageChange = (nextPage: number) => {
		setSearchParams(
			(init) => new URLSearchParams({ ...init, page: String(nextPage) })
		)
	}
	const search = searchParams.get('search') ?? ''
	const onSearchChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
		const value = e.target.value
		setSearchParams(
			(init) => new URLSearchParams({ ...init, search: value }),
			{ replace: !!search }
		)
	}

	const query = useProceduresQuery({ page, search })

	const procedures = query.data?.data
	const paginationInfo = query.data?.meta

	const isEmpty = procedures?.length === 0

	return (
		<Stack px="6" py="10" h="100%">
			<Flex justify="space-between" alignItems="center" mb="6">
				<Heading fontSize="2xl">Procedimentos</Heading>

				<Can I="create" a="ProcedureType">
					<ProcedureFormModal
						trigger={
							<Button
								size="sm"
								fontSize="xs"
								colorScheme="primary"
								textTransform="uppercase"
								leftIcon={<AddIcon />}
							>
								Procedimento
							</Button>
						}
					/>
				</Can>
			</Flex>

			<Flex gap="4" alignItems="center" mb="6">
				<InputGroup size="sm" maxW="543px">
					<InputLeftElement>
						<Search2Icon />
					</InputLeftElement>
					<Input
						bg="white"
						_dark={{ bg: '#182533' }}
						placeholder="Buscar por Nome"
						defaultValue={search}
						onChange={onSearchChange}
					/>
				</InputGroup>

				{query.isPlaceholderData && query.isFetching && (
					<Spinner size="sm" />
				)}
			</Flex>

			<TableContainer
				bg="white"
				_dark={{ bg: '#182533' }}
				p="4"
				rounded="12"
				h="100%"
				mb="6"
				position="relative"
			>
				<Table variant="striped">
					<Thead>
						<tr>
							<Th>Nome</Th>
							<Th textAlign="end">Valor unitário (R$)</Th>
							<Th></Th>
						</tr>
					</Thead>
					<Tbody>
						{!query.data && query.isLoading && (
							<Tr display="block">
								<td>
									<Center
										position="absolute"
										h="100%"
										top="50%"
										left="50%"
										transform="translate(-50%,-50%)"
									>
										<Spinner />
									</Center>
								</td>
							</Tr>
						)}

						{isEmpty && (
							<Tr display="block">
								<td>
									<Center
										flexDirection="column"
										position="absolute"
										h="100%"
										top="50%"
										left="50%"
										transform="translate(-50%,-50%)"
									>
										<Text>
											Nenhum procedimento salvo ainda
										</Text>
										<Text>
											Clique no botão {'"+ Procedimento"'}{' '}
											para criar um novo
										</Text>
									</Center>
								</td>
							</Tr>
						)}

						{query.data &&
							!isEmpty &&
							procedures?.map((it) => (
								<tr key={it.id}>
									<Td>{it.name}</Td>
									<Td textAlign="end">
										{formatPrice({ value: it.value })}
									</Td>
									<Td textAlign="end">
										<Flex
											gap="2"
											alignItems="center"
											justifyContent="flex-end"
										>
											<Can I="update" a="ProcedureType">
												<ProcedureFormModal
													procedure={it}
													trigger={
														<IconButton
															aria-label="Editar procedimento"
															icon={<EditIcon />}
														/>
													}
												/>
											</Can>
											<Can
												I="manage"
												a="ProcedureType"
												field="costs"
											>
												<IconButton
													onClick={() =>
														navigate(
															`/procedimentos/${it.id}/custos`
														)
													}
													aria-label="Custos"
													icon={<FiDollarSign />}
												/>
											</Can>
										</Flex>
									</Td>
								</tr>
							))}

						{query.isLoadingError && (
							<Tr display="block">
								<td>
									<Center
										flexDirection="column"
										position="absolute"
										h="100%"
										top="50%"
										left="50%"
										transform="translate(-50%,-50%)"
									>
										<Text>
											Algo deu errado ao carregar esses
											dados...
										</Text>
										<Text>{String(query.error)}</Text>
									</Center>
								</td>
							</Tr>
						)}
					</Tbody>
				</Table>
			</TableContainer>

			{paginationInfo && (
				<Flex alignItems="center" justify="end">
					<TablePagination
						total={paginationInfo.total}
						perPage={paginationInfo.perPage}
						page={page}
						onPageChange={onPageChange}
					/>

					<Text
						ml="5"
						fontSize="xs"
						fontWeight="medium"
						textTransform="uppercase"
					>
						{paginationInfo.total} registros
					</Text>
				</Flex>
			)}
		</Stack>
	)
}

function formatPrice({ value }: { value: number }) {
	return new Intl.NumberFormat(undefined, {
		style: 'currency',
		currency: 'BRL',
	}).format(value)
}
