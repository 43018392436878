/* eslint-disable @typescript-eslint/no-explicit-any */
import { useQuery } from '@tanstack/react-query'
import { AxiosResponse } from 'axios'
import api from 'services/api'
import { useSearchParams } from 'react-router-dom'

interface getAllProceduresProps {
	params: {
		offset: number
		typeInfo: string
		search: string
		typeSearch: string
		infoFilter: string

		dentist?: string
	}
}

interface useGetProceduresProps extends getAllProceduresProps {
	onSuccess?: (data: AxiosResponse) => void
	onError?: () => void
}

const getAllProcedures = async ({
	params: {
		offset,
		typeInfo,
		search,
		typeSearch,
		infoFilter,

		dentist,
	},
}: getAllProceduresProps) => {
	api.get('/history')

	if (search && (!infoFilter || infoFilter === ' ')) {
		const { data } = await api.get(
			`/procedures?limit=10&${typeSearch}=${search}&offset=${offset}`
		)
		/* eslint no-underscore-dangle: 0 */

		return {
			data: data.rows.map((item: any) => ({
				createdAt: item?.createdAt,
				customer: { id: item.customer?._id, name: item.customer?.name },

				dentist: { id: item.dentist?._id, name: item.dentist?.name },
				files: item.files?.map(
					(file: { _id: string; url: string }) => ({
						id: file._id,
						url: file.url,
					})
				),
				updatedAt: item?.updatedAt,
				id: item?._id,
				name: item?.name,
				observation: item?.observation,
				date: item?.date,
				deleted: item?.deleted,
			})),
			count: data.count,
		}
	}
	if (!search && infoFilter) {
		const { data } = await api.get(
			`/procedures?limit=10&${typeInfo}=${infoFilter}&offset=${offset}${
				dentist ? `&dentist=${dentist}` : ''
			}`
		)
		/* eslint no-underscore-dangle: 0 */
		return {
			data: data.rows.map((item: any) => ({
				createdAt: item?.createdAt,
				customer: { id: item.customer?._id, name: item.customer?.name },

				dentist: { id: item.dentist?._id, name: item.dentist?.name },
				files: item.files?.map(
					(file: { _id: string; url: string }) => ({
						id: file._id,
						url: file.url,
					})
				),
				updatedAt: item?.updatedAt,
				id: item?._id,
				name: item?.name,
				observation: item?.observation,
				date: item?.date,
				deleted: item?.deleted,
			})),
			count: data.count,
		}
	}
	if (search && infoFilter) {
		const { data } = await api.get(
			`/procedures?limit=10&${typeInfo}=${infoFilter}&${typeSearch}=${search}&offset=${offset}`
		)
		/* eslint no-underscore-dangle: 0 */

		return {
			data: data.rows.map((item: any) => ({
				createdAt: item?.createdAt,
				customer: { id: item.customer?._id, name: item.customer?.name },

				dentist: { id: item.dentist?._id, name: item.dentist?.name },
				files: item.files?.map(
					(file: { _id: string; url: string }) => ({
						id: file._id,
						url: file.url,
					})
				),
				updatedAt: item?.updatedAt,
				id: item?._id,
				name: item?.name,
				observation: item?.observation,
				date: item?.date,
				deleted: item?.deleted,
			})),
			count: data.count,
		}
	}
	if ((!search || search === '') && (!infoFilter || infoFilter === '')) {
		const { data } = await api.get(`/procedures?limit=10&offset=${offset}`)
		/* eslint no-underscore-dangle: 0 */
		return {
			data: data.rows.map((item: any) => ({
				createdAt: item?.createdAt,
				customer: { id: item.customer?._id, name: item.customer?.name },

				dentist: { id: item.dentist?._id, name: item.dentist?.name },
				files: item.files?.map(
					(file: { _id: string; url: string }) => ({
						id: file._id,
						url: file.url,
					})
				),
				updatedAt: item?.updatedAt,
				id: item?._id,
				name: item?.name,
				observation: item?.observation,
				date: item?.date,
				deleted: item?.deleted,
			})),
			count: data.count,
		}
	}
	return null
}

export const useGetAllProcedures = ({
	// onSuccess,
	// onError,
	...params
}: useGetProceduresProps) => {
	const [searchParams] = useSearchParams()

	const allParams = {
		...params.params,
		dentist: searchParams.get('dentist'),
	}

	return useQuery({
		queryKey: ['useGetAllProcedures', allParams],
		queryFn: () => getAllProcedures({ params: allParams }),
		// onSuccess,
		// onError,
		refetchOnWindowFocus: false,
	})
}

// TODO - ADD TIPAGEM
