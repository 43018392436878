import {
	AlertDialog,
	AlertDialogBody,
	AlertDialogCloseButton,
	AlertDialogContent,
	AlertDialogFooter,
	AlertDialogHeader,
	AlertDialogOverlay,
	Button,
	Stack,
	useDisclosure,
} from '@chakra-ui/react'
import { useIsMutating } from '@tanstack/react-query'
import React from 'react'

import { useUpdateSchedule } from 'modules/schedule/queries/useUpdateSchedule'
import { Schedule } from 'modules/schedule/queries/useProfessionalSchedule'
import { useProfessionals } from 'modules/schedule/queries/useProfessionals'
import { useParams } from 'react-router-dom'

interface EditScheduleFooterModalProps {
	onClose: () => void
	event: Schedule['events'][number]
}
const EditScheduleFooterModal: React.FC<EditScheduleFooterModalProps> = ({
	onClose,
	event,
}) => {
	const params = useParams()
	const isLoading = !!useIsMutating({
		mutationKey: ['update_schedule'],
	})

	const state = useDisclosure()
	const cancelRef = React.useRef()

	const updateSchedule = useUpdateSchedule()

	const customer = event?.customerInfo ?? event?.customer

	const dentist = useProfessionals().data.find(
		(it) => params.professionalId === it.id
	)

	return (
		<>
			<Stack flex="1" spacing={2}>
				<Button
					colorScheme="green"
					leftIcon={
						<svg
							width="24"
							height="24"
							viewBox="0 0 26 26"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								d="M13.376 2.58331C7.68848 2.58331 3.05306 7.21873 3.05306 12.9062C3.05306 14.7291 3.53223 16.5 4.42806 18.0625L2.96973 23.4166L8.43848 21.9791C9.94889 22.8021 11.6468 23.2396 13.376 23.2396C19.0635 23.2396 23.6989 18.6041 23.6989 12.9166C23.6989 10.1562 22.626 7.56248 20.6781 5.61456C19.7231 4.6499 18.5855 3.88505 17.3319 3.36462C16.0782 2.84418 14.7334 2.57859 13.376 2.58331ZM13.3864 4.3229C15.6781 4.3229 17.8239 5.21873 19.4489 6.84373C20.2464 7.64097 20.8787 8.58781 21.3095 9.62994C21.7403 10.6721 21.9611 11.789 21.9593 12.9166C21.9593 17.6458 18.1051 21.4896 13.376 21.4896C11.8343 21.4896 10.3239 21.0833 9.01139 20.2916L8.69889 20.1146L5.44889 20.9687L6.31348 17.8021L6.10514 17.4687C5.24466 16.1028 4.78953 14.5206 4.79264 12.9062C4.80306 8.17706 8.64681 4.3229 13.3864 4.3229ZM9.71973 8.1354C9.55306 8.1354 9.27181 8.1979 9.03223 8.45831C8.80306 8.71873 8.12598 9.35415 8.12598 10.6146C8.12598 11.8854 9.05306 13.1041 9.16764 13.2812C9.31348 13.4583 11.001 16.0625 13.5947 17.1666C14.2093 17.4479 14.6885 17.6041 15.0635 17.7187C15.6781 17.9166 16.2406 17.8854 16.6885 17.8229C17.1885 17.75 18.2093 17.1979 18.4281 16.5937C18.6468 15.9896 18.6468 15.4791 18.5843 15.3646C18.5114 15.2604 18.3447 15.1979 18.0843 15.0833C17.8239 14.9375 16.5531 14.3125 16.3239 14.2291C16.0843 14.1458 15.9385 14.1041 15.7406 14.3541C15.5739 14.6146 15.0739 15.1979 14.9281 15.3646C14.7718 15.5416 14.626 15.5625 14.376 15.4375C14.1051 15.3021 13.2718 15.0312 12.2926 14.1562C11.5218 13.4687 11.0114 12.625 10.8551 12.3646C10.7301 12.1146 10.8447 11.9583 10.9697 11.8437C11.0843 11.7291 11.251 11.5416 11.3551 11.3854C11.4906 11.2396 11.5322 11.125 11.6156 10.9583C11.6989 10.7812 11.6572 10.6354 11.5947 10.5104C11.5322 10.3958 11.0114 9.10415 10.7926 8.59373C10.5843 8.09373 10.376 8.15623 10.2093 8.14581C10.0635 8.14581 9.89681 8.1354 9.71973 8.1354Z"
								fill="currentColor"
							/>
						</svg>
					}
					as="a"
					href={getWhastappLink(
						normalizePhone(customer?.cellphoneNumber),
						generateMessage({
							dentist: dentist?.name,
							customer: customer?.name,
							start: event.start,
						})
					)}
					target="_blank"
					rel="noreferrer"
				>
					WhatsApp
				</Button>

				<Button
					colorScheme="blue"
					size="md"
					type="submit"
					isLoading={isLoading}
					form="edit-schedule-modal"
				>
					Salvar
				</Button>

				<Button
					colorScheme="red"
					variant="outline"
					size="md"
					onClick={state.onOpen}
				>
					Cancelar consulta
				</Button>
				<AlertDialog
					motionPreset="slideInBottom"
					onClose={state.onClose}
					isOpen={state.isOpen}
					isCentered
					leastDestructiveRef={undefined}
				>
					<AlertDialogOverlay />

					<AlertDialogContent>
						<AlertDialogHeader>
							Cancelar consulta?
						</AlertDialogHeader>
						<AlertDialogCloseButton />
						<AlertDialogBody>
							Quer mesmo cancelá-la?
						</AlertDialogBody>
						<AlertDialogFooter>
							<Button ref={cancelRef} onClick={state.onClose}>
								Não
							</Button>
							<Button
								colorScheme="red"
								ml={3}
								onClick={() => {
									updateSchedule.mutate({
										...event,
										status: 'cancelled',
									})
									onClose()
								}}
							>
								Sim
							</Button>
						</AlertDialogFooter>
					</AlertDialogContent>
				</AlertDialog>
			</Stack>
		</>
	)
}

function generateMessage({
	dentist,
	customer,
	start,
}: {
	dentist: string
	customer: string
	start: string
}) {
	const date = new Date(start)
	const getFormattedDate = () => {
		const dateOfMonth = date.getDate(),
			month = date.getMonth() + 1
		return `${String(dateOfMonth).padStart(2, '0')}/${String(
			month
		).padStart(2, '0')}`
	}
	const getFormattedTime = () => {
		const hours = date.getHours(),
			minutes = date.getMinutes()
		return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(
			2,
			'0'
		)}`
	}
	const message =
		`Oi, ${customer}.\n` +
		`Sua consulta foi marcada para ${getFormattedDate()}, às ${getFormattedTime()}.\n` +
		`Dentista: ${dentist}`

	return message
}

function normalizePhone(phone: string) {
	const number = phone.replace(/\D/g, '')
	if ([10, 11].includes(number.length)) {
		return '55' + number
	}
	return number
}

function getWhastappLink(number: string, message: string) {
	const url = `https://api.whatsapp.com/send?phone=${number}&text=${encodeURIComponent(
		message
	)}`

	return url
}

export default EditScheduleFooterModal
