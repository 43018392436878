export { FinancialRoute } from './components/FinancialRoute'
export { FinancialIndexRoute } from './components/FinancialIndexRoute'
export { FinancialBillsRoute } from './components/FinancialBillsRoute'
export { CustomerPaymentsRoute } from './components/CustomerPaymentsRoute'

export { PaymentModal } from './components/PaymentModal'

export const PAYMENT_METHODS = [
	{ name: 'Pix', value: 'pix' },
	{ name: 'Crédito', value: 'credit_card' },
	{ name: 'Débito', value: 'debit_card' },
	{ name: 'Dinheiro', value: 'cash' },
	{ name: 'Boleto', value: 'bank_slip' },
	{ name: 'Depósito', value: 'deposit' },
	{ name: 'Cheque', value: 'check' },
]
