import { useFormContext } from 'react-hook-form'
import { useProceduresManagement } from 'modules/history/hooks/useProceduresManagement'
import { Modal } from 'app/components/Modal'
import { ProcedureForm } from './index'
import { useHistoryQuery } from 'modules/history/queries/useHistoryQuery'

export function FormModal({ procedureId }: { procedureId: string }) {
	const {
		procedureId: stateProcedureId,
		setIsOpenProcedureForm,
		isOpenProcedureForm,
		onEditSubmit,
	} = useProceduresManagement()

	const query = useHistoryQuery()
	const history = query.data?.data

	const procedure = history?.find((it) => it.procedure?._id === procedureId)

	const { getValues } = useFormContext()

	if (stateProcedureId !== procedureId) return null

	return (
		//  <FormProvider {...methods} >
		//  </FormProvider>
		<Modal
			isOpen={isOpenProcedureForm}
			onClose={() => {
				setIsOpenProcedureForm(false)
				// reset(defaultValues)
			}}
			title="Procedimento"
			handleForm={() => undefined}
			body={
				<form
					method="put"
					style={{ height: '90%' }}
					onSubmit={(e) => {
						e.preventDefault()
						const values = {
							name: getValues('name'),
							date: getValues('date'),
							observation: getValues('observation'),
							files: getValues('files'),
							dentist: procedure.dentist?._id,
							procedure: procedureId,
						}
						onEditSubmit(values)
					}}
					autoComplete="off"
					id="procedureForm"
				>
					<ProcedureForm id={procedureId} />
				</form>
			}
			footer={<></>}
			dividers={false}
		/>
	)
}
