import React from 'react'
import { Input } from '@chakra-ui/react'
import { useRecordsManagement } from 'modules/records/hooks/useRecordsManagement'

export const InputToSearch = () => {
	const { setInfoSearch, infoSearch } = useRecordsManagement()
	return (
		<Input
			placeholder="Buscar por"
			size="lg"
			width={{ sm: '70%', md: '60%', lg: '60%' }}
			minWidth={{ md: '100px', lg: '100px' }}
			onChange={(e) => setInfoSearch(e.target.value)}
			value={infoSearch}
		/>
	)
}
