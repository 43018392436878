import { AddIcon } from '@chakra-ui/icons'
import {
	Button,
	Center,
	CloseButton,
	Flex,
	FormControl,
	FormLabel,
	Grid,
	Input,
	Select,
	Stack,
	Text,
} from '@chakra-ui/react'
import { useFieldArray, useFormContext } from 'react-hook-form'
import { formatAsCurrency } from './CurrencyInput'
import { useProceduresQuery } from '../../queries/useProceduresQuery'

export function ProceduresFields() {
	const form = useFormContext()
	const { errors } = form.formState

	const proceduresFieldArray = useFieldArray({
		name: 'items',
	})

	const proceduresQuery = useProceduresQuery()

	return (
		<>
			<Flex as="ol" flexDirection="column" gap="6" mb="6">
				{proceduresFieldArray.fields.map((it, i) => {
					const procedure = proceduresQuery.data?.find(
						(p) => p.id === form.watch(`items.${i}.procedure`)
					)

					const total =
						procedure?.value * form.watch(`items.${i}.quantity`)

					return (
						<Stack as="li" key={it.id}>
							<Flex gap="3" alignItems="center">
								<Center
									h="8"
									px="5"
									py="1"
									rounded="md"
									bg="gray.100"
									_dark={{ bg: '#4e596d' }}
								>
									<Text
										w="5"
										h="5"
										display="grid"
										placeContent="center"
										rounded="full"
										fontSize="xs"
										fontWeight="500"
										bg="primary.800"
										color="white"
										_dark={{
											bg: 'primary.200',
											color: 'gray.600',
										}}
									>
										{i + 1}
									</Text>
								</Center>

								<Select
									size="sm"
									placeholder="Selecione"
									{...form.register(`items.${i}.procedure`, {
										required: true,
									})}
									isInvalid={errors.items?.[i]?.procedure}
								>
									{proceduresQuery.data?.map((p) => (
										<option key={p.id} value={p.id}>
											{p.name}
										</option>
									))}
								</Select>

								{proceduresFieldArray.fields.length > 1 && (
									<CloseButton
										onClick={() =>
											proceduresFieldArray.remove(i)
										}
									/>
								)}
							</Flex>

							<Grid
								gap="4"
								templateColumns={{ md: '40px 1fr 112px' }}
							>
								<FormControl
									isInvalid={errors.items?.[i]?.quantity}
								>
									<FormLabel fontSize="sm">Qtd</FormLabel>
									<Input
										{...form.register(
											`items.${i}.quantity`,
											{
												valueAsNumber: true,
												required: true,
											}
										)}
										onInput={(e) => {
											const target = e.currentTarget
											if (!target.value) return
											target.value = target.value.replace(
												/[^\d]/g,
												''
											)
										}}
										size="sm"
									/>
								</FormControl>
								<FormControl
									isInvalid={errors.items?.[i]?.teeth}
								>
									<FormLabel fontSize="sm">
										Dente/Arcada/Face
									</FormLabel>
									<Input
										{...form.register(`items.${i}.teeth`)}
										size="sm"
									/>
								</FormControl>
								<FormControl>
									<FormLabel fontSize="sm">Total</FormLabel>
									<Input
										readOnly
										size="sm"
										value={
											total ? formatAsCurrency(total) : ''
										}
										textAlign="right"
									/>
								</FormControl>
							</Grid>
						</Stack>
					)
				})}
			</Flex>
			<Button
				colorScheme="primary"
				size="sm"
				onClick={() =>
					proceduresFieldArray.append({
						quantity: 1,
						teeth: '',
					})
				}
				leftIcon={<AddIcon />}
				textTransform="uppercase"
				fontSize="xs"
			>
				Adicionar
			</Button>
		</>
	)
}
