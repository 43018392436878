/* eslint-disable no-underscore-dangle */
import { HamburgerIcon, MoonIcon, SunIcon } from '@chakra-ui/icons'
import { Button, Flex, useColorMode, useColorModeValue } from '@chakra-ui/react'
import { Select } from 'app/components/Select'
import { useBreakpointsMediaQuery } from 'app/hooks/useMediaQuery'
import { LogoutIcon } from 'app/icons/LogoutIcon'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import * as yup from 'yup'
import { Link } from 'react-router-dom'

import api from 'services/api'
import {
	removeAuthTokenInStorage,
	setUserInfoInStorage,
} from 'services/api/auth'

export interface NavBarProps {
	onClick?: () => void
}
export const NavBar: React.FC<NavBarProps> = ({ onClick }) => {
	const { colorMode, toggleColorMode } = useColorMode()
	const bg = useColorModeValue('primary.500', '#182533')
	const border = useColorModeValue('#fff', 'transparent')
	const { isDesktop } = useBreakpointsMediaQuery()

	const user = useUserClinicsQuery()

	const queryClient = useQueryClient()

	return (
		<Flex
			width="100%"
			height="54px"
			as="nav"
			pos="fixed"
			bgColor={bg}
			alignItems="center"
			justifyContent={isDesktop ? 'flex-end' : 'space-between'}
			zIndex={20}
			pl="26px"
		>
			{isDesktop && (
				<Link to="/">
					<img
						src="/LightTypo.png"
						alt="ConsultData"
						height={277 / 9} // original dimensions divided by a constant
						width={774 / 9}
					/>
				</Link>
			)}
			{!isDesktop && (
				<Button
					onClick={onClick}
					colorScheme="primary"
					border={`1px solid ${border}`}
				>
					<HamburgerIcon />
				</Button>
			)}
			<Button
				onClick={toggleColorMode}
				bgColor={bg}
				color="#FFFF"
				_hover={{ backgroundColor: 'whiteAlpha.400' }}
				border={`1px solid ${border}`}
				mr="10px"
				ml="auto"
			>
				{colorMode === 'light' ? <MoonIcon /> : <SunIcon />}
			</Button>
			<Select
				value={user.data?.activeClinic}
				items={
					user.data?.clinics.map((it) => ({
						value: it._id,
						label: it.nomeFantasia,
					})) ?? []
				}
				onChange={async (e) => {
					const activeClinic = e.target.value
					const { data } = await api.post('/users/active-clinic', {
						activeClinic,
					})
					const updatedUser = data.user
					setUserInfoInStorage(updatedUser)
					queryClient.setQueryData(['user'], updatedUser)
					queryClient.setQueryData<UserClinics>(
						['user', 'clinics'],
						(p) => p && { ...p, activeClinic }
					)
					// reset all queries, except 'user'
					queryClient.resetQueries({
						predicate: (q) => q.queryKey.at(0) !== 'user',
					})
				}}
			/>
			<Button
				onClick={() => {
					removeAuthTokenInStorage()
					window.location.href = '/'
				}}
				bg="transparent"
				_hover={{ backgroundColor: 'whiteAlpha.400' }}
				display="flex"
				alignItems="center"
				columnGap="5px"
				color="white"
				mx="10px"
				fontWeight="400"
			>
				<LogoutIcon />
				{isDesktop && 'Sair'}
			</Button>
		</Flex>
	)
}

function useUserClinicsQuery() {
	return useQuery({
		queryKey: ['user', 'clinics'],
		async queryFn() {
			const response = await api.get('/users/clinics')
			const data: UserClinics = response.data
			return data
		},
	})
}

interface UserClinics {
	activeClinic: string
	clinics: { _id: string; nomeFantasia: string }[]
}
