/* eslint-disable react/prop-types */
import {
	Flex,
	Icon,
	Link,
	Menu,
	MenuButton,
	Text,
	useColorModeValue,
} from '@chakra-ui/react'
import { IconType } from 'react-icons'
import { LinkProps, NavLink } from 'react-router-dom'

interface NavItemProps {
	icon: IconType
	title: string
	// description?: string
	active?: boolean
	navSize: string

	to: LinkProps['to']
}

export default function NavItem({
	icon,
	title,
	active,
	navSize,
	to,
}: NavItemProps) {
	const flexBg = useColorModeValue('#FFF', 'transparent')
	const bgHover = useColorModeValue('#AEC8CA', 'whiteAlpha.100')
	return (
		<Flex
			mt={30}
			flexDir="column"
			w="100%"
			alignItems={navSize === 'small' ? 'center' : 'flex-start'}
		>
			<Menu placement="right">
				<Link
					backgroundColor="transparent"
					p={3}
					borderRadius={8}
					_hover={{ textDecor: 'none', backgroundColor: bgHover }}
					width="100%"
					as={NavLink}
					to={to}
				>
					<MenuButton w="100%">
						<Flex alignItems="center">
							<Flex
								alignItems="center"
								justifyContent="center"
								w="32px"
								h="32px"
								borderRadius="4px"
								backgroundColor={active ? '#55BBDD' : flexBg}
								color={active ? '#fff' : 'gray.500'}
								_hover={{ backgroundColor: '#AEC8CA' }}
							>
								<Icon
									as={icon}
									fontSize="xl"
									color={active ? '#fff' : 'gray.500'}
								/>
							</Flex>
							<Text
								ml={5}
								display={navSize === 'small' ? 'none' : 'flex'}
								color={active ? '#55BBDD' : 'gray.500'}
								fontWeight="600"
								fontSize="16px"
							>
								{title}
							</Text>
						</Flex>
					</MenuButton>
				</Link>
				{/* <MenuList py={0} border="none" w={200} h={200} ml={5}> */}
				{/* <NavHoverBox
						title={title}
						icon={icon}
						description={description}
					/> */}
				{/* </MenuList> */}
			</Menu>
		</Flex>
	)
}

NavItem.defaultProps = {
	active: false,
	// description: '',
}
