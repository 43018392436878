import React from 'react'
import { Button, Box } from '@chakra-ui/react'

export const FormActions: React.FC<{ form?: string; isLoading?: boolean }> = ({
	form,
	isLoading,
}) => {
	return (
		<Box>
			<Button
				colorScheme="blue"
				type="submit"
				isLoading={isLoading}
				form={form}
			>
				{form === 'edit-register-form' ? 'Atualizar' : 'Cadastrar'}
			</Button>
		</Box>
	)
}
