import { Grid, GridItem } from '@chakra-ui/react'
import { useBreakpointsMediaQuery } from 'app/hooks/useMediaQuery'
import React, { useState } from 'react'
import MobileMenu from '../MobileMenu'
import { NavBar } from '../NavBar'
import Sidebar from '../Sidebar'

const gridTemplateAreas = {
	sm: `
		'header header header'
		'content content content'
	`,
	md: `
	'header header header'
	'content content content'
`,
	lg: `
	'header header header'
	'sidebar content content'
	`,
}

const gridTemplateColumns = {
	sm: '1fr 1fr 1fr',
	md: '1fr 1fr 1fr',
	lg: '215px 1fr 1fr',
}
const gridTemplateRows = {
	sm: '54px 1fr',
	md: '54px 1fr',
	lg: '54px 1fr',
}

interface MainLayoutProps {
	children: React.ReactNode
}

export const MainLayout = ({ children }: MainLayoutProps) => {
	const { isDesktop } = useBreakpointsMediaQuery()
	const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false)
	const handleMobileMenu = () => {
		setIsMobileMenuOpen((prevState) => !prevState)
	}
	return (
		<>
			<Grid
				gridTemplateAreas={gridTemplateAreas}
				gridTemplateColumns={gridTemplateColumns}
				gridTemplateRows={gridTemplateRows}
				h="100vh"
			>
				<GridItem gridArea="header">
					<NavBar onClick={handleMobileMenu} />
				</GridItem>
				<GridItem
					gridArea="sidebar"
					display={{ sm: 'none', md: 'none', lg: 'block' }}
					position="fixed"
					mt="54px"
					w="215px"
				>
					<Sidebar />
				</GridItem>
				{!isDesktop && (
					<GridItem gridArea="mobileMenu">
						<MobileMenu
							isOpen={isMobileMenuOpen}
							onClose={() => setIsMobileMenuOpen(false)}
						/>
					</GridItem>
				)}

				<GridItem gridArea="content">{children}</GridItem>
			</Grid>
		</>
	)
}
