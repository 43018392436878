import { useSearchParams } from 'react-router-dom'
import {
	Button,
	Center,
	Flex,
	Heading,
	Popover,
	PopoverArrow,
	PopoverBody,
	PopoverCloseButton,
	PopoverContent,
	PopoverHeader,
	PopoverTrigger,
	Spinner,
	Stack,
	Table,
	TableContainer,
	Tbody,
	Td,
	Text,
	Textarea,
	Th,
	Thead,
} from '@chakra-ui/react'
import { useQuery } from '@tanstack/react-query'
import dayjs from 'dayjs'

import { TablePagination } from 'app/components/TablePagination'
import {
	CustomersRepository,
	ReturnReportCustomerDto,
} from 'data/CustomersRepository'
import { useForm } from 'react-hook-form'

export function ReturnReportRoute() {
	const [searchParams, setSearchParams] = useSearchParams()
	const page = Math.abs(Number(searchParams.get('page')) || 1)
	const onPageChange = (nextPage: number) => {
		setSearchParams(
			(init) => new URLSearchParams({ ...init, page: String(nextPage) })
		)
	}

	const query = useQuery({
		queryKey: ['customers_return', { page }],
		queryFn: ({ signal }) =>
			new CustomersRepository().getCustomersReturnReport({
				page,
				signal,
			}),
	})
	const customers = query.data?.data
	const paginationInfo = query.data?.meta

	const isEmpty = customers?.length <= 0

	return (
		<>
			<Flex justifyContent="space-between">
				<Heading fontSize="2xl">Retorno de Clientes</Heading>
			</Flex>

			<TableContainer
				bg="white"
				_dark={{ bg: '#182533' }}
				p="4"
				rounded="12"
				h="100%"
				mb="6"
				position="relative"
			>
				<Table variant="striped">
					<Thead>
						<tr>
							<Th fontSize="sm">Nome</Th>
							<Th fontSize="sm">Data Última Consulta</Th>
							<Th fontSize="sm">Contato</Th>
							<Th></Th>
						</tr>
					</Thead>
					<Tbody>
						{customers &&
							!isEmpty &&
							customers.map((customer) => {
								const monthsDiff =
									customer.lastAppointmentDate &&
									dayjs(
										dayjs().diff(
											customer.lastAppointmentDate
										)
									).format('M')
								const hasMoreThan6Months =
									monthsDiff && Number(monthsDiff) >= 6

								return (
									<tr key={customer._id}>
										<Td>{customer.name}</Td>
										<Td
											style={{
												color: hasMoreThan6Months
													? 'red'
													: undefined,
											}}
										>
											{customer.lastAppointmentDate
												? dayjs(
														customer.lastAppointmentDate
												  )
														.utc()
														.format('DD/MM/YYYY')
												: '-'}
										</Td>
										<Td>
											{customer.cellphoneNumber?.replace(
												/^(\d{2})(\d{1})(\d{4})(\d{4})$/,
												'($1) $2$3-$4'
											)}
										</Td>
										<Td>
											<SendReturnMessage
												customer={customer}
											/>
										</Td>
									</tr>
								)
							})}

						{!query.data && query.isLoading && (
							<tr style={{ display: 'block' }}>
								<td>
									<Center
										position="absolute"
										h="100%"
										top="50%"
										left="50%"
										transform="translate(-50%,-50%)"
									>
										<Spinner />
									</Center>
								</td>
							</tr>
						)}

						{isEmpty && (
							<tr style={{ display: 'block' }}>
								<td>
									<Center
										flexDirection="column"
										position="absolute"
										h="100%"
										top="50%"
										left="50%"
										transform="translate(-50%,-50%)"
									>
										<Text>Nada aqui ainda</Text>
									</Center>
								</td>
							</tr>
						)}
						{query.isLoadingError && (
							<tr style={{ display: 'block' }}>
								<td>
									<Center
										flexDirection="column"
										position="absolute"
										h="100%"
										top="50%"
										left="50%"
										transform="translate(-50%,-50%)"
									>
										<Text>
											Algo deu errado ao carregar esses
											dados...
										</Text>
										<Text>{String(query.error)}</Text>
									</Center>
								</td>
							</tr>
						)}
					</Tbody>
				</Table>
			</TableContainer>
			{paginationInfo && paginationInfo.total > 0 && (
				<Flex
					alignItems="center"
					justify="end"
					flexDirection={{ base: 'column', md: 'row' }}
					columnGap="5"
					rowGap="3"
				>
					<TablePagination
						total={paginationInfo.total}
						perPage={paginationInfo.perPage}
						page={page}
						onPageChange={onPageChange}
					/>

					<Text
						fontSize="xs"
						fontWeight="medium"
						textTransform="uppercase"
					>
						{paginationInfo.total} registros
					</Text>
				</Flex>
			)}
		</>
	)
}

function SendReturnMessage({
	customer,
}: {
	customer: ReturnReportCustomerDto
}) {
	const form = useForm({ defaultValues: { text: '' } })

	return (
		<Popover>
			<PopoverTrigger>
				<Button
					size="sm"
					type="button"
					colorScheme="primary"
					textTransform="uppercase"
				>
					Mensagem
				</Button>
			</PopoverTrigger>
			<PopoverContent
				as="form"
				onSubmit={form.handleSubmit((data) => {
					const sp = new URLSearchParams()
					sp.append('phone', normalizePhone(customer.cellphoneNumber))
					sp.append('text', data.text) // URLSearchParams automatically encodes uri

					if (isMobileBrowser()) {
						const whatsappLink = `https://api.whatsapp.com/send?${sp}`
						window.open(whatsappLink, '_blank')
					} else {
						const whatsappWebLink = `https://web.whatsapp.com/send?${sp}`
						window.open(whatsappWebLink, '_blank')
					}

					function normalizePhone(phone: string) {
						const number = phone.replace(/\D/g, '')
						if ([10, 11].includes(number.length)) {
							return '55' + number
						}
						return number
					}
					function isMobileBrowser() {
						return [
							/Android/i,
							/webOS/i,
							/iPhone/i,
							/iPad/i,
							/iPod/i,
							/BlackBerry/i,
							/Windows Phone/i,
						].some((it) => navigator.userAgent.match(it))
					}
				})}
			>
				<PopoverArrow />
				<PopoverCloseButton />
				<PopoverHeader>Enviar mensagem de retorno</PopoverHeader>
				<PopoverBody>
					<Stack>
						<Textarea {...form.register('text')} rows={6} />
						<Button colorScheme="primary" type="submit">
							Enviar
						</Button>
					</Stack>
				</PopoverBody>
			</PopoverContent>
		</Popover>
	)
}
