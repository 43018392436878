import { Center, Button, Text } from '@chakra-ui/react'
import { useController } from 'react-hook-form'

export function UploadInput({ name }: { name: string }) {
	const { field } = useController({ name })

	const fileListOrServerFile = field.value

	let fileData: { name: string; path: string } | undefined
	if (fileListOrServerFile instanceof FileList) {
		const file = fileListOrServerFile.item(0)
		fileData = { name: file.name, path: URL.createObjectURL(file) }
	} else if (fileListOrServerFile?.url) {
		const url: string = fileListOrServerFile.url
		fileData = {
			name: url.split('/').at(-1).split('?').at(0),
			path: url,
		}
	}

	return (
		<Center
			flexDirection="column"
			gap="2"
			border="2px dashed #E9E9E9"
			rounded="md"
			py="5"
		>
			<Button
				as="label"
				size="sm"
				fontSize="xs"
				colorScheme="primary"
				textTransform="uppercase"
			>
				Escolher arquivo
				<input
					type="file"
					style={{ display: 'none' }}
					ref={field.ref}
					onChange={(e) => {
						const files = e.target.files
						field.onChange(files)
					}}
				/>
			</Button>
			<Text fontSize="sm">PNG, JPG, GIF até 5MB</Text>
			{fileData && (
				<Text
					as="a"
					target="_blank"
					href={fileData.path}
					_hover={{ textDecoration: 'underline' }}
					fontSize="sm"
					maxW="90%"
					whiteSpace="nowrap"
					overflow="hidden"
					textOverflow="ellipsis"
				>
					{fileData.name}
				</Text>
			)}
		</Center>
	)
}
