import React, { ReactNode } from 'react'
import { ChakraProvider } from '@chakra-ui/react'
import theme from 'app/styles/chakraTheme/theme'
import { Toaster } from 'react-hot-toast'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import * as dayjs from 'dayjs'
import 'dayjs/locale/pt-br'
import { AbilityContext, createAbility } from 'ability'
import axios from 'axios'
import { useUserQuery } from 'useUserQuery'

dayjs.locale('pt-br')

interface ProvidersTypes {
	children?: ReactNode
}
const client = new QueryClient({
	defaultOptions: {
		queries: {
			refetchOnWindowFocus: process.env.NODE_ENV === 'production',
			staleTime: 1 * 30 * 1000,
			retry(failureCount, error) {
				if (axios.isAxiosError(error)) {
					return !error.response
				}
				return failureCount < 3
			},
		},
	},
})

export function Providers({ children }: ProvidersTypes) {
	return (
		<QueryClientProvider client={client}>
			<AbilityProvider>
				<Toaster
					position="top-right"
					toastOptions={{
						style: {
							padding: '16px',
						},
					}}
				/>
				<ChakraProvider
					theme={theme}
					toastOptions={{ defaultOptions: { position: 'top-right' } }}
				>
					{children}
				</ChakraProvider>
			</AbilityProvider>
		</QueryClientProvider>
	)
}

function AbilityProvider({
	children,
}: React.PropsWithChildren<Record<string, unknown>>) {
	const query = useUserQuery()
	const user = query.data

	return (
		<AbilityContext.Provider value={createAbility(user)}>
			{children}
		</AbilityContext.Provider>
	)
}

Providers.defaultProps = { children: null }
