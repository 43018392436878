import {
	AlertDialog,
	Button,
	AlertDialogBody,
	AlertDialogOverlay,
	AlertDialogCloseButton,
	AlertDialogContent,
	AlertDialogHeader,
	useDisclosure,
	AlertDialogFooter,
} from '@chakra-ui/react'
import { Slot } from '@radix-ui/react-slot'

interface Props {
	title: string
	body: string
	trigger: JSX.Element
	onConfirm: () => void
}

export function ConfirmAlert({ trigger, title, body, onConfirm }: Props) {
	const state = useDisclosure()

	return (
		<>
			<Slot onClick={state.onOpen}>{trigger}</Slot>

			<AlertDialog
				motionPreset="slideInBottom"
				onClose={state.onClose}
				isOpen={state.isOpen}
				isCentered
				leastDestructiveRef={undefined}
			>
				<AlertDialogOverlay />

				<AlertDialogContent>
					<AlertDialogHeader>{title}</AlertDialogHeader>
					<AlertDialogCloseButton />
					<AlertDialogBody>{body}</AlertDialogBody>
					<AlertDialogFooter>
						<Button onClick={state.onClose}>Não</Button>
						<Button
							ml={3}
							colorScheme="primary"
							onClick={() => {
								onConfirm()
								state.onClose()
							}}
						>
							Sim
						</Button>
					</AlertDialogFooter>
				</AlertDialogContent>
			</AlertDialog>
		</>
	)
}
