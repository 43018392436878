import { useState, useEffect } from 'react'

export default function useDebounce(value: string, delay = 500) {
	const [debouncedValue, setDebouncedValue] = useState(value)
	useEffect(() => {
		const handler = setTimeout(() => {
			setDebouncedValue(value)
		}, delay)
		return () => {
			clearTimeout(handler)
		}
	}, [value])
	return debouncedValue
}
