import api from 'services/api'
import { Customer } from './Customer'

export class CustomersRepository {
	async getCustomers({
		name,
		page,
		signal,
	}: {
		name?: string
		page: number
		signal?: AbortSignal
	}) {
		const limit = 10
		const searchParams = new URLSearchParams(
			Object.entries({
				name,
				offset: String((page - 1) * limit),
				limit: String(limit),
			}).filter(([, v]) => Boolean(v))
		)
		const response = await api.get(`/customers?${searchParams}`, {
			signal,
		})
		const hasNext = response.data.count > page * limit
		return {
			data: response.data.rows.map(Customer.fromJson) as Customer[],
			next: hasNext ? page + 1 : null,
		}
	}

	async getShortenedCustomers({
		name,
		page,
		signal,
	}: {
		name?: string
		page: number
		signal?: AbortSignal
	}) {
		const limit = 10
		const searchParams = new URLSearchParams(
			Object.entries({
				name,
				page: String(page),
				limit: String(limit),
				extra: 'cpf',
			}).filter(([, v]) => Boolean(v))
		)
		const response = await api.get(`/customers/shortened?${searchParams}`, {
			signal,
		})
		const hasNext = response.data.meta?.total > page * limit
		return {
			data: response.data.data.map(Customer.fromJson) as Customer[],
			next: hasNext ? page + 1 : null,
		}
	}

	async getCustomersReturnReport({
		name,
		page,
		signal,
	}: {
		name?: string
		page: number
		signal?: AbortSignal
	}) {
		const limit = 10
		const searchParams = new URLSearchParams(
			Object.entries({
				name,
				page: String(page),
				limit: String(limit),
			}).filter(([, v]) => Boolean(v))
		)
		const response = await api.get<CustomersReturnResponse>(
			`/customers/return-report?${searchParams}`,
			{ signal }
		)
		return response.data

		interface CustomersReturnResponse {
			data: Array<ReturnReportCustomerDto>
			meta: { total: number; perPage: number }
		}
	}
}

export interface ReturnReportCustomerDto {
	_id: string
	name: string
	cellphoneNumber: string
	lastAppointmentDate?: string
}
