import { Flex, Image, ImageProps, Link, Skeleton } from '@chakra-ui/react'
import { FileIcon } from 'app/icons/FileIcon'
import { useEffect, useState } from 'react'
import api from 'services/api'

interface Props extends Partial<ImageProps> {
	url: string
}

export function ImageView({ url, ...props }: Props) {
	const isComputerImage = url.startsWith('blob')
	const isPDF = url.includes('pdf')
	const isDocument = url.split('.').at(-1).includes('document')
	const isVideo = url.includes('mp4')
	const [src, setSrc] = useState(isComputerImage ? url : '')

	useEffect(() => {
		const getImage = async () => {
			try {
				if (isDocument) {
					const response = await api.get(url, {
						responseType: 'document',
					})
					const blob = new Blob([response.data], {
						type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
					})
					setSrc(URL.createObjectURL(blob))
					return
				}
				const { data } = await api.get(url, {
					responseType: 'blob',
				})
				setSrc(URL.createObjectURL(data))
			} catch (error) {
				console.log(error)
			}
		}
		if (!isComputerImage) {
			getImage()
		}
	}, [])
	if (!src) {
		return <Skeleton height="60px" width="60px" />
		// <Box bg="lightgray" width="96px" height="96px" />
	}
	return (
		<>
			{(isPDF || isDocument) && (
				<a href={src} target="_blank" rel="noreferrer">
					<FileIcon size={64} />
				</a>
			)}
			{!isPDF && !isDocument && !isVideo && (
				<Image
					width="100%"
					// m="12px 0px"
					// boxSize="100px"
					// maxHeight="21.875rem"
					// maxWidth="15.625rem"
					src={src}
					alt="Formato não compatível para ser mostrado"
					// fallbackSrc="https://static.vecteezy.com/system/resources/previews/002/318/271/original/user-profile-icon-free-vector.jpg"
					{...props}
				/>
			)}
			{isVideo && (
				<Flex flexDirection="column" rowGap="8px">
					<video width="200" height="150" controls src={src}>
						<source src={src} type="video/mp4" />
					</video>
					<Link
						href={src}
						download
						textColor="#55BBDD"
						fontWeight="500"
					>
						Baixar
					</Link>
				</Flex>
			)}
		</>
	)
}
