import { Accordion } from 'app/components/Accordion'
import { useFormContext } from 'react-hook-form'
import { RadiosController } from 'app/components/RadiosController'
import { useEffect } from 'react'
import { useRecordsManagement } from 'modules/records/hooks/useRecordsManagement'
import { itemsTypePerson, itemsHasResponsible } from './itemsToSelect'
import { useAbility } from 'ability'

export const GeneralData = () => {
	const { control, watch, clearErrors } = useFormContext()
	const { isOpenAccordion } = useRecordsManagement()

	const registrationTypeHadChangedValue = watch('registrationType')
	const personTypeHadChangedValue = watch('personType')

	const hasResponsible =
		watch('legalRepresentative.config.hasResponsible') === 'Yes'

	const responsibleTypeOfPersonHadChangedValue = watch(
		'legalRepresentative.config.responsibleTypeOfPerson'
	)

	useEffect(() => {
		clearErrors()
	}, [
		registrationTypeHadChangedValue,
		personTypeHadChangedValue,
		responsibleTypeOfPersonHadChangedValue,
		hasResponsible,
	])

	const ability = useAbility()

	return (
		<Accordion title="Dados Gerais" isOpenAccordion>
			<RadiosController
				items={[
					{ value: 'customers', label: 'Paciente' },
					ability.can('create', 'Worker') && {
						value: 'workers',
						label: 'Colaborador',
					},
				].filter(Boolean)}
				title="Tipo de Cadastro"
				name="registrationType"
				control={control}
			/>

			<RadiosController
				items={itemsTypePerson}
				title="Tipo de Pessoa"
				name="personType"
				control={control}
			/>
			<RadiosController
				items={itemsHasResponsible}
				title="Possui responsável ?"
				name="legalRepresentative.config.hasResponsible"
				control={control}
			/>

			{hasResponsible && (
				<RadiosController
					items={itemsTypePerson}
					title="Tipo de Pessoa - Responsável"
					name="legalRepresentative.config.responsibleTypeOfPerson"
					control={control}
				/>
			)}
		</Accordion>
	)
}
