/* eslint-disable no-underscore-dangle */
/* eslint-disable no-else-return */
/* eslint-disable no-use-before-define */
import {
	SmallCloseIcon as CloseCircleOutlined,
	ChevronLeftIcon as LeftOutlined,
	ChevronRightIcon as RightOutlined,
	AddIcon as ZoomInOutlined,
	MinusIcon as ZoomOutOutlined,
} from '@chakra-ui/icons'
import { Box, Flex, IconButton, Link, Portal, Text } from '@chakra-ui/react'
import { FileIcon } from 'app/icons/FileIcon'
import React, { useState } from 'react'
import api from 'services/api'

interface Photo {
	url: string
	file?: File
}

interface Props {
	photos: Photo[]
	currentPhotoId: string
	onBack: () => void
	onNext: () => void
	onClose: () => void
}

const MAX_SCALE = 3

ImagesViewer.useImagesViewer = function useImagesViewer(photos: Photo[]) {
	const [currentPhotoId, setCurrentPhotoId] = useState('')

	const handleBackPhoto = () => {
		const _photos = photos
		const currentPhotoIndex = _photos.findIndex(
			(photo) => photo.url.toString() === currentPhotoId
		)
		if (currentPhotoIndex > 0) {
			setCurrentPhotoId(_photos[currentPhotoIndex - 1].url.toString())
		}
	}

	const handleNextPhoto = () => {
		const _photos = photos
		const currentPhotoIndex = _photos.findIndex(
			(photo) => photo.url.toString() === currentPhotoId
		)
		if (currentPhotoIndex < _photos.length - 1) {
			setCurrentPhotoId(_photos[currentPhotoIndex + 1].url.toString())
		}
	}

	const openPhoto = (photoId: string) => setCurrentPhotoId(photoId)
	const closePhoto = () => setCurrentPhotoId('')

	return {
		handleBackPhoto,
		handleNextPhoto,
		currentPhotoId,
		openPhoto,
		closePhoto,
	}
}

export function ImagesViewer({
	photos,
	onBack,
	onNext,
	onClose,
	...props
}: Props) {
	React.useEffect(() => {
		document.body.style.height = '100%'
		document.body.style.overflow = 'hidden'
		return () => {
			document.body.style.height = 'auto'
			document.body.style.overflow = 'auto'
		}
	}, [])

	React.useEffect(() => {
		const handleKey = (e: KeyboardEvent) => {
			e.preventDefault()
			if (['ArrowUp', 'ArrowLeft'].includes(e.key)) {
				onBack()
			} else if (['ArrowDown', 'ArrowRight'].includes(e.key)) {
				onNext()
			} else if (e.key === 'Escape') {
				onClose()
			}
		}
		document.addEventListener('keyup', handleKey)
		return () => document.removeEventListener('keyup', handleKey)
	})

	return (
		<Portal>
			<div
				onClick={onClose}
				style={{
					backgroundColor: '#00000060',
					position: 'fixed',
					inset: 0,
					zIndex: 9998,
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center',
				}}
			>
				<ol onClick={(e) => e.stopPropagation()}>
					{photos.map((photo, i, self) => (
						<ImagesViewerItem
							key={photo.url}
							photo={photo}
							onNext={onNext}
							onBack={onBack}
							onClose={onClose}
							hasNext={i < self.length - 1}
							hasPrevious={Boolean(i)}
							{...props}
						/>
					))}
				</ol>
			</div>
		</Portal>
	)
}

function ImagesViewerItem({
	currentPhotoId,
	photo,
	onBack,
	onNext,
	onClose,
	hasNext,
	hasPrevious,
}: Pick<Props, 'currentPhotoId' | 'onBack' | 'onNext' | 'onClose'> & {
	photo: Photo
	hasNext: boolean
	hasPrevious: boolean
}) {
	const { imageScale, x, y, handleZoomIn, handleZoomOut } = useImageScale()

	const isBlobUrl = photo.url.startsWith('blob')
	const [src, setSrc] = React.useState(isBlobUrl ? photo.url : '')
	React.useEffect(() => {
		if (!isBlobUrl) {
			// if (photo.url.includes('document')) {
			// 	api.get(photo.url, { responseType: 'document' }).then((res) => {
			// 		const blob = new Blob([res.data], {
			// 			type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
			// 		})
			// 		setSrc(URL.createObjectURL(blob))
			// 	})
			// 	return
			// }
			api.get(photo.url, { responseType: 'blob' }).then((res) =>
				setSrc(URL.createObjectURL(res.data))
			)
		}
	}, [isBlobUrl])

	const dblClickScaleDirection = React.useRef('in')
	if (imageScale >= MAX_SCALE) {
		dblClickScaleDirection.current = 'out'
	}
	if (imageScale <= 1) {
		dblClickScaleDirection.current = 'in'
	}

	const extension = photo.url?.split('.')?.[0]

	return (
		<li
			style={{
				display:
					currentPhotoId === photo.url.toString() ? 'flex' : 'none',
				gap: 16,
				alignItems: 'center',
			}}
		>
			<NavButton
				disabled={!hasPrevious}
				onClick={onBack}
				icon={<LeftOutlined />}
				style={{ left: 24 }}
			/>

			<Flex
				alignItems="center"
				justify="end"
				style={{
					height: 56,
					background: '#00000065',
					top: 0,
					left: 0,
					width: '100%',
					zIndex: 9999,
					gap: 16,
					paddingRight: 16,
					position: 'absolute',
				}}
			>
				<ActionButton
					onClick={handleZoomOut}
					icon={<ZoomOutOutlined />}
				/>
				<ActionButton
					onClick={handleZoomIn}
					icon={<ZoomInOutlined />}
					style={{ marginRight: 12 }}
				/>

				<ActionButton
					onClick={onClose}
					icon={<CloseCircleOutlined />}
				/>
			</Flex>
			{isBlobUrl && !photo.file && (
				<img
					src={src}
					// alt={'Foto '.concat(photo.name)}
					alt=""
					style={{
						transform: `scale(${imageScale}) translate(${x}px, ${y}px)`,
						maxHeight: '70vh',
					}}
					onDoubleClick={
						dblClickScaleDirection.current === 'out'
							? handleZoomOut
							: handleZoomIn
					}
				/>
			)}
			{isBlobUrl && photo.file && (
				<>
					{photo.file.type?.includes('image') && (
						<img
							src={src}
							// alt={'Foto '.concat(photo.name)}
							alt=""
							style={{
								transform: `scale(${imageScale}) translate(${x}px, ${y}px)`,
								maxHeight: '70vh',
							}}
							onDoubleClick={
								dblClickScaleDirection.current === 'out'
									? handleZoomOut
									: handleZoomIn
							}
						/>
					)}
					{photo.file.type?.includes('video') && (
						<video
							src={src}
							width="400"
							height="100"
							controls
							style={{
								maxHeight: '70vh',
								transform: `scale(${imageScale}) translate(${x}px, ${y}px)`,
							}}
						/>
					)}
					{(photo.file.type?.includes('document') ||
						photo.file.type.includes('application')) && (
						<Box
							display="flex"
							flexDirection="column"
							alignItems="center"
							padding="10px"
							borderRadius="10px"
							bg="white"
						>
							<FileIcon size={64} />
							<Text
								overflow="hidden"
								textOverflow="ellipsis"
								whiteSpace="nowrap"
								fontSize="12px"
							>
								{photo.file.name}
							</Text>
						</Box>
					)}
				</>
			)}
			{!isBlobUrl && (
				<>
					{!photo.url.endsWith('mp4') && !photo.url.endsWith('pdf') && (
						<img
							src={src}
							// alt={'Foto '.concat(photo.name)}
							alt=""
							style={{
								transform: `scale(${imageScale}) translate(${x}px, ${y}px)`,
								maxHeight: '70vh',
							}}
							onDoubleClick={
								dblClickScaleDirection.current === 'out'
									? handleZoomOut
									: handleZoomIn
							}
						/>
					)}
					{photo.url.endsWith('mp4') && (
						<video
							src={src}
							width="400"
							height="100"
							controls
							style={{
								maxHeight: '70vh',
								transform: `scale(${imageScale}) translate(${x}px, ${y}px)`,
							}}
						/>
					)}
					{['doc', 'pdf'].includes(extension) && (
						<Box
							display="flex"
							flexDirection="column"
							alignItems="center"
							padding="10px"
							borderRadius="10px"
							bg="white"
						>
							<FileIcon size={64} />
						</Box>
					)}
				</>
			)}

			<NavButton
				onClick={onNext}
				icon={<RightOutlined />}
				disabled={!hasNext}
				style={{ right: 24 }}
			/>
		</li>
	)
}

function ActionButton({ icon, disabled, ...props }: any) {
	return (
		<button
			type="button"
			{...props}
			disabled={disabled}
			style={{
				cursor: disabled ? 'default' : 'pointer',
				color: disabled ? 'lightgray' : 'white',
				background: 'transparent',
				border: 'none',
				...props.style,
			}}
		>
			{icon}
		</button>
	)
}

function NavButton({ onClick, icon, ...props }: any) {
	return (
		<IconButton
			position="absolute"
			rounded="full"
			zIndex={10}
			bg="#55BBDD"
			size="md"
			icon={icon}
			onClick={onClick}
			{...props}
		/>
	)
}

function useImageScale() {
	const [imageScale, setImageScale] = useState(1)

	const getNextScale = React.useCallback(
		(sign: number) => {
			const isMinus = sign < 0 && imageScale > 1
			const isPlus = sign > 0 && imageScale < MAX_SCALE
			if (isMinus) {
				return imageScale - 0.5
			} else if (isPlus) {
				return imageScale + 0.5
			}
			return imageScale
		},
		[imageScale]
	)

	const handleZoom = React.useCallback((nextImageScale: number) => {
		setImageScale(nextImageScale)
	}, [])
	const handleZoomOut = React.useCallback(() => {
		handleZoom(getNextScale(-1))
	}, [getNextScale, handleZoom])
	const handleZoomIn = React.useCallback(() => {
		handleZoom(getNextScale(1))
	}, [getNextScale, handleZoom])

	const [{ x, y }, setPointsState] = React.useState({
		x: 0,
		y: 0,
	})
	const pointsRef = React.useRef({ x: 0, y: 0 })
	const isPanning = React.useRef(false)

	React.useEffect(() => {
		const onMouseDown = (e: MouseEvent) => {
			e.preventDefault()
			pointsRef.current = { x: e.clientX - x, y: e.clientY - y }
			isPanning.current = true
		}

		const onMouseUp = () => {
			isPanning.current = false
		}

		const onMouseMove = (e: MouseEvent) => {
			e.preventDefault()
			if (!isPanning.current) return
			setPointsState({
				x: e.clientX - pointsRef.current.x,
				y: e.clientY - pointsRef.current.y,
			})
		}

		const onWheelMove = (e: WheelEvent) => {
			const nextImageScale = getNextScale(e.deltaY < 0 ? 1 : -1)

			if (nextImageScale === 1) {
				setPointsState({
					x: 0,
					y: 0,
				})
			}

			handleZoom(nextImageScale)
		}
		document.addEventListener('wheel', onWheelMove)
		document.addEventListener('mousedown', onMouseDown)
		document.addEventListener('mouseup', onMouseUp)
		document.addEventListener('mousemove', onMouseMove)

		return () => {
			document.removeEventListener('wheel', onWheelMove)
			document.removeEventListener('mousedown', onMouseDown)
			document.removeEventListener('mouseup', onMouseUp)
			document.removeEventListener('mousemove', onMouseMove)
		}
	}, [handleZoom, x, y, imageScale, getNextScale])

	return { imageScale, x, y, handleZoomIn, handleZoomOut }
}
