/* eslint-disable no-underscore-dangle */
import { useToast } from '@chakra-ui/react'
import dayjs from 'dayjs'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useParams } from 'react-router-dom'

import api from 'services/api'
import { ScheduleForm } from '../validations/scheduleFormSchema'

export function useCreateSchedule() {
	const { professionalId } = useParams()

	const client = useQueryClient()

	const toast = useToast()

	return useMutation({
		mutationKey: ['create_schedule'],

		mutationFn({
			title,
			customerId,
			start,
			end,
			cellphoneNumber,
			notes,
			id,
			isPreRegister,
		}: ScheduleForm) {
			return api.post(`/dentists/${professionalId}/events`, {
				event: [
					{
						// _id: id,
						title: isPreRegister ? customerId : title,
						start: dayjs(start).tz('Etc/Greenwich').toISOString(),
						end: dayjs(end).tz('Etc/Greenwich').toISOString(),
						customer: isPreRegister ? undefined : customerId,
						name: isPreRegister ? customerId : undefined,
						notes,
						phone: cellphoneNumber,
						isPreRegister,
					},
				],
			})
		},

		onSuccess() {
			toast({
				title: 'Agendado!',
				status: 'success',
				duration: 3000,
				isClosable: true,
				position: 'top-right',
			})

			client.invalidateQueries({
				queryKey: ['professional_schedule'],
			})
			client.invalidateQueries({
				queryKey: ['customers'],
			})
		},
	})
}
