import { objectToFormData } from 'app/utils/objectToFormData'
import { AxiosError, AxiosResponse } from 'axios'
import { RegistrationTypeProps } from 'modules/records/contexts/ManageRegistrationFormContext/types'
import { useMutation } from '@tanstack/react-query'
import api from 'services/api'

interface useCreateRecordsProps {
	onSuccess: (data: AxiosResponse) => void
	onError: (error: AxiosError) => void
}

interface createRecordProps {
	values: any
	typeRegister: RegistrationTypeProps
}

export const useCreateRecord = ({
	onSuccess,
	onError,
}: useCreateRecordsProps) => {
	return useMutation({
		async mutationFn({ values, typeRegister }: createRecordProps) {
			if (values.legalRepresentative && values.legalRepresentative.name) {
				values.legalRepresentatives = [values.legalRepresentative]
			}
			const formData = objectToFormData(values)
			const data = await api.post(`/${typeRegister}`, formData)
			return data
		},
		onSuccess: (data) => onSuccess(data),
		onError: (error) => onError(error as AxiosError),
	})
}
