import { GridItem, Flex } from '@chakra-ui/react'
import React from 'react'
import { ButtonToOpenRecordFormModal } from '../../Table/ButtonToOpenRecordFormModal'
import { InputToSearch } from '../InputToSearch'
import { SelectFilterByTypeInfo } from '../SelectFilterByTypeInfo'
import { SelectFilterByTypeRecord } from '../SelectFilterByTypeRecord'

export const MobileTableFilters = () => {
	return (
		<>
			<GridItem
				colSpan={1}
				rowSpan={1}
				height="48px"
				width="90%"
				margin="auto"
			>
				<Flex justifyContent="space-between">
					<ButtonToOpenRecordFormModal />
					<SelectFilterByTypeRecord />
				</Flex>
			</GridItem>
			<GridItem
				colSpan={1}
				rowSpan={1}
				height="auto"
				width="90%"
				margin="auto"
			>
				<Flex width="100%" mt="24px">
					<InputToSearch />
					<SelectFilterByTypeInfo />
				</Flex>
			</GridItem>
		</>
	)
}
