/* eslint-disable no-underscore-dangle */
import { useToast } from '@chakra-ui/react'
import dayjs from 'dayjs'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useParams } from 'react-router-dom'
import api from 'services/api'
import { ScheduleForm } from '../validations/scheduleFormSchema'
import { useCustomer } from './useCustomer'

export function useUpdateSchedule() {
	const { professionalId } = useParams()

	const client = useQueryClient()

	const toast = useToast()

	return useMutation({
		mutationKey: ['update_schedule'],

		mutationFn({
			title,
			customerId,
			start,
			end,
			cellphoneNumber,
			status,
			notes,
			id,
		}: ScheduleForm) {
			return api.put(`/dentists/${professionalId}/events?eventId=${id}`, {
				// _id: id,
				title: title ?? 'Consulta',
				start: dayjs(start).tz('Etc/Greenwich').toISOString(),
				end: dayjs(end).tz('Etc/Greenwich').toISOString(),
				customer: customerId,
				notes,
				phone: cellphoneNumber,
				status,
			})
		},

		onSuccess() {
			toast({
				title: 'Atualizado!',
				status: 'success',
				duration: 3000,
				isClosable: true,
				position: 'top-right',
			})
			client.invalidateQueries({
				queryKey: ['professional_schedule'],
			})
			client.invalidateQueries({
				queryKey: ['schedule_search'],
			})
		},
	})
}
