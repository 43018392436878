import React from 'react'
import { createRoot } from 'react-dom/client'
import { ColorModeScript } from '@chakra-ui/react'
import { Font } from '@react-pdf/renderer'
import dayjs from 'dayjs'
import weekYear from 'dayjs/plugin/weekYear'
import weekOfYear from 'dayjs/plugin/weekOfYear'

import theme from 'app/styles/chakraTheme/theme'
import { RoutesConfig } from 'app/routes'
import { Providers } from './Providers'

dayjs.extend(weekOfYear)
dayjs.extend(weekYear)

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const container = document.getElementById('root')!
const root = createRoot(container)

root.render(
	<React.StrictMode>
		<ColorModeScript initialColorMode={theme.config.initialColorMode} />
		<Providers>
			<RoutesConfig />
		</Providers>
	</React.StrictMode>
)

Font.register({
	family: 'Open Sans',
	fonts: [
		{
			src: 'https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-regular.ttf',
		},
		{
			src: 'https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-600.ttf',
			fontWeight: 600,
		},
	],
})
