import { Box, Button, Flex } from '@chakra-ui/react'
import { useModalState } from 'app/utils/useModalState'
import React from 'react'
import { Modal } from './Modal'

interface Props {
	onCapture: (blob: Blob) => void
}

export function WebCam({ onCapture }: Props) {
	const streamRef = React.useRef<MediaStream>(null)
	const videoRef = React.useRef<HTMLVideoElement>(null)
	const canvasRef = React.useRef<HTMLCanvasElement>(null)

	const [src, setSrc] = React.useState('')
	const modal = useModalState()

	const handleStop = () => {
		streamRef.current?.getTracks().forEach((t) => t.stop())
		if (videoRef.current) videoRef.current.srcObject = null
	}

	const handleStart = () =>
		requestAnimationFrame(async () => {
			const stream = await navigator.mediaDevices.getUserMedia({
				video: true,
			})

			if (streamRef) streamRef.current = stream
			if (videoRef) {
				videoRef.current.srcObject = stream
				videoRef.current.play()
			}
		})

	const handleCapture = () => {
		const video = videoRef.current
		const canvas = canvasRef.current
		const context = canvas.getContext('2d')

		canvas.width = video.videoWidth
		canvas.height = video.videoHeight
		context.drawImage(video, 0, 0, video.videoWidth, video.videoHeight)
		canvas.toBlob((blob) => {
			onCapture(blob)
			// setSrc(URL.createObjectURL(blob))
			handleStop()
			modal.close()
		})
	}

	const handleCancel = () => {
		handleStop()
		modal.close()
	}

	// eslint-disable-next-line consistent-return
	React.useEffect(() => {
		if (modal.isOpen) {
			handleStart()
			return () => handleStop()
		}
	}, [modal.isOpen])

	return (
		<>
			<Button variant="outline" flex={1} onClick={modal.open}>
				WebCam
			</Button>
			<Modal
				size="md"
				onClose={handleCancel}
				isOpen={modal.isOpen}
				title="WebCam"
				body={
					<div>
						<Box mb={3}>
							{src && (
								<img
									src={src}
									alt=""
									width={400}
									height={400}
								/>
							)}

							<video
								ref={videoRef}
								height={400}
								width={400}
								style={{ display: src ? 'none' : 'block' }}
							/>

							<canvas
								ref={canvasRef}
								style={{ display: 'none' }}
							/>
						</Box>

						<Flex>
							<Button
								colorScheme="blue"
								ml="auto"
								type="button"
								onClick={handleCapture}
							>
								Capturar
							</Button>
						</Flex>
					</div>
				}
				footer={null}
				dividers={false}
			/>
		</>
	)
}
