/* eslint-disable @typescript-eslint/no-explicit-any */
import { keepPreviousData, useQuery } from '@tanstack/react-query'
import api from 'services/api'
import React from 'react'
import { subject } from '@casl/ability'

interface getAllRecordsProps {
	params: {
		typeInfo: string
		recordType: string
		offset: number
		search: string
	}
}

interface useGetRecordsProps extends getAllRecordsProps {
	onSuccess?: (data: any) => void
	onError?: () => void
}

export const useGetAllRecords = ({
	onSuccess,
	onError,
	...params
}: useGetRecordsProps) => {
	const { recordType, typeInfo, search, offset } = params.params
	const url = `/${recordType}/${typeInfo}/?${typeInfo}=${search}&offset=${offset}&limit=10&preRegister=0`

	const hasFetchedOne = React.useRef(false)
	const query = useQuery({
		queryKey: ['useGetAllRecords', url],

		queryFn: async ({ signal }) => {
			const response = await api(url, { signal })
			const data: RecordsResponse = response.data
			interface RecordsResponse {
				rows: any[]
				count: number
			}

			if (recordType === 'customers') {
				data.rows = data.rows.map((it) => subject('Customer', it))
			}
			if (recordType === 'workers') {
				data.rows = data.rows.map((it) => subject('Worker', it))
			}

			return data
		},

		// onSuccess,
		// onError,
		refetchOnWindowFocus: false,
		placeholderData: keepPreviousData,
	})

	return query
}
// TODO - ADD TIPAGEM
