import React from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import {
	Menu,
	MenuButton,
	MenuList,
	MenuItem,
	Button,
	Text,
	Image,
	Box,
	useColorModeValue,
} from '@chakra-ui/react'
import { ChevronDownIcon } from '@chakra-ui/icons'

import api from 'services/api'
import { ProfessionalsQuery } from 'modules/schedule/queries/useProfessionals'
import PictureProfileNotFound from '../../../../assets/user_image_not_found.jpeg'

interface Props {
	data?: ProfessionalsQuery['data']
}

export const ProfessionalSelect: React.FC<Props> = ({ data }) => {
	const navigate = useNavigate()
	const { professionalId, type } = useParams()

	const professional = data?.find((item) => item.id === professionalId)
	const menuBg = useColorModeValue('#fff', 'transparent')
	const border = useColorModeValue('#e9e9e9', '#777')

	const [src, setSrc] = React.useState('')
	React.useEffect(() => {
		if (professional?.imageSrc) {
			api.get(professional.imageSrc, { responseType: 'blob' }).then(
				(res) => setSrc(URL.createObjectURL(res.data))
			)
		} else {
			setSrc('')
		}
	}, [professional])

	return (
		<Menu>
			<Text fontSize="14px" fontWeight="semibold" mb={2}>
				Profissional
			</Text>
			<MenuButton
				as={Button}
				rightIcon={<ChevronDownIcon />}
				borderWidth={1}
				borderColor={border}
				bg={menuBg}
				width={{ sm: '100%', md: 240 }}
				height="auto"
				title={professional?.name ?? 'Selecione'}
			>
				<Box
					display="grid"
					alignItems="center"
					gap={2}
					style={{ gridTemplateColumns: 'auto 1fr' }}
					py={2}
					px={0}
				>
					<Image
						src={src || PictureProfileNotFound}
						alt=""
						width="32px"
						height="32px"
						rounded="full"
					/>
					{professional ? (
						<Text
							textAlign="left"
							whiteSpace="nowrap"
							overflow="hidden"
							textOverflow="ellipsis"
						>
							{professional.name}
						</Text>
					) : (
						<Text
							textAlign="left"
							fontWeight="regular"
							color="gray"
						>
							Selecione
						</Text>
					)}
				</Box>
			</MenuButton>
			{data && (
				<MenuList>
					{data.map((item) => {
						return (
							<MenuItem
								onClick={() => {
									sessionStorage.setItem(
										'agenda:professionalId',
										item.id
									)
									navigate(
										`/agendamentos/${item.id}/${type ?? ''}`
									)
								}}
								key={item.id}
							>
								{item.name}
							</MenuItem>
						)
					})}
				</MenuList>
			)}
		</Menu>
	)
}
