import { Control, Controller } from 'react-hook-form'
import { Select } from '../Select'

interface ItemsProps {
	[key: string]: string | number | readonly string[]
}

interface SelectControllerProps {
	items: ItemsProps[]
	title: string
	name: string
	width?: string | object | number
	flex?: any;
	margin?: string | object
	control: Control<any>
	hasError?: boolean
	errorMessage?: string
	placeholder?: string
	isRequired?: boolean
}

export const SelectController = (props: SelectControllerProps) => {
	const { control, name, ...rest } = props
	return (
		<Controller
			control={control}
			name={name}
			render={({ field }) => <Select {...rest} {...field} />}
		/>
	)
}

SelectController.defaultProps = {
	margin: 0,
	hasError: false,
	errorMessage: 'Este campo é obrigatório',
}
