import api from 'services/api'
import { Payment } from './Payment'

export class PaymentsRepository {
	async getPayments({
		page,
		search,
		startDate,
		endDate,
		customer,
		dentist,
		status,
		withNote,
		signal,
	}: GetPaymentsProps): Promise<GetPaymentsResponse> {
		const searchParams = new URLSearchParams(
			[
				['page', String(page ?? '')],
				['limit', '10'],
				['search', search ?? ''],
				['startDate', startDate ?? ''],
				['endDate', endDate ?? ''],
				customer && ['customer', customer],
				dentist && ['dentist', dentist],
				status && ['status', status],
				withNote !== undefined && ['withNote', String(withNote)],
			].filter(Boolean)
		)
		const response = await api.get(`/payments?${searchParams}`, { signal })
		const paginatedPayments = response.data
		return {
			...response.data,
			data: paginatedPayments.data?.map(Payment.fromJson),
		}
	}

	async createPayment(data: any) {
		const body = this.generateFormData(data)
		return await api.post('/payments', body)
	}

	async updatePayment(data: any) {
		const body = this.generateFormData(data)
		return await api.put(`/payments/${data.id}`, body)
	}

	private generateFormData(data: Record<string, any>) {
		const body = new FormData()
		for (const key in data) {
			const value = data[key]
			if (value instanceof FileList) {
				Array.from(value, (file) => body.append(key, file))
			} else if (value !== undefined) {
				body.set(key, value ?? '')
			}
		}
		return body
	}
}

interface GetPaymentsProps {
	page?: number
	search?: string
	startDate?: string
	endDate?: string
	customer?: string
	dentist?: string
	status?: string
	withNote?: boolean
	signal?: AbortSignal
}

interface GetPaymentsResponse {
	data: Payment[]
	meta: { total: number; perPage: number }
	totalLatePayments: number
	totalLatePaymentsValue: number
	totalPaidValue: number
	totalPendingInstallments: number
	totalPendingValue: number
	totalBy?: Record<string, number>
}
