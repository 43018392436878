export function UserIcon() {
	return (
		<svg
			width="16"
			height="16"
			viewBox="0 0 16 16"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M8.00008 2.66683C6.89551 2.66683 6.00008 3.56226 6.00008 4.66683C6.00008 5.7714 6.89551 6.66683 8.00008 6.66683C9.10465 6.66683 10.0001 5.7714 10.0001 4.66683C10.0001 3.56226 9.10465 2.66683 8.00008 2.66683ZM4.66675 4.66683C4.66675 2.82588 6.15913 1.3335 8.00008 1.3335C9.84103 1.3335 11.3334 2.82588 11.3334 4.66683C11.3334 6.50778 9.84103 8.00016 8.00008 8.00016C6.15913 8.00016 4.66675 6.50778 4.66675 4.66683ZM3.64306 10.3098C4.26818 9.68469 5.11603 9.3335 6.00008 9.3335H10.0001C10.8841 9.3335 11.732 9.68469 12.3571 10.3098C12.9822 10.9349 13.3334 11.7828 13.3334 12.6668V14.0002C13.3334 14.3684 13.0349 14.6668 12.6667 14.6668C12.2986 14.6668 12.0001 14.3684 12.0001 14.0002V12.6668C12.0001 12.1364 11.7894 11.6277 11.4143 11.2526C11.0392 10.8775 10.5305 10.6668 10.0001 10.6668H6.00008C5.46965 10.6668 4.96094 10.8775 4.58587 11.2526C4.21079 11.6277 4.00008 12.1364 4.00008 12.6668V14.0002C4.00008 14.3684 3.7016 14.6668 3.33341 14.6668C2.96522 14.6668 2.66675 14.3684 2.66675 14.0002V12.6668C2.66675 11.7828 3.01794 10.9349 3.64306 10.3098Z"
				fill="currentColor"
			/>
		</svg>
	)
}
