/* eslint-disable no-underscore-dangle */
import dayjs from 'dayjs'
import React, { useId } from 'react'
import {
	Button,
	Popover,
	PopoverBody,
	PopoverContent,
	PopoverFooter,
	PopoverHeader,
	PopoverTrigger,
	Text,
	Textarea,
	useColorModeValue,
} from '@chakra-ui/react'
import toast from 'react-hot-toast'

import { useUpdateAnnotation } from 'modules/schedule/queries/useUpdateAnnotation'
import { useCreateAnnotation } from 'modules/schedule/queries/useCreateAnnotation'
import { ScheduleTableRoot as Table } from './ScheduleTableRoot'

export function Annotation({
	annotation,
	startDate,
	endDate,
	disabled,
}: {
	annotation: any
	startDate: Date
	endDate: Date
	disabled?: boolean
}) {
	const start = dayjs(startDate).format('YYYY-MM-DD HH:mm')
	const end = dayjs(endDate).format('YYYY-MM-DD HH:mm')
	const updateAnnotation = useUpdateAnnotation()
	const createAnnotation = useCreateAnnotation()

	const id = useId()
	const key = React.useMemo(() => {
		return startDate.toString().concat(id)
	}, [startDate])

	const isLoading = updateAnnotation.isPending || createAnnotation.isPending

	const initialFocusRef = React.useRef(null)
	const unavailable = useColorModeValue('#f0f0f0', '#2a3f54')

	return (
		<Table.Cell
			py={0}
			px={0}
			fontSize={{ base: 'xs', md: 'md' }}
			bg={disabled ? unavailable : undefined}
		>
			<Popover placement="bottom" initialFocusRef={initialFocusRef}>
				{({ onClose }) => (
					<>
						<PopoverTrigger>
							<button
								aria-label={
									annotation?.body
										? undefined
										: 'Adicionar anotação'
								}
								type="button"
								style={{
									display: 'block',
									textAlign: 'left',
									width: '100%',
									height: 39,
									paddingLeft: 12,
									cursor: 'default',
								}}
								disabled={disabled}
							>
								<Text
									overflow="hidden"
									textOverflow="ellipsis"
									whiteSpace="nowrap"
								>
									{annotation?.body ?? ''}
								</Text>
							</button>
						</PopoverTrigger>
						<PopoverContent>
							<form
								onSubmit={(e) => {
									e.preventDefault()
									const form =
										e.currentTarget as HTMLFormElement
									const formData = new FormData(form)
									const body = formData
										.get('body')
										?.toString()

									const options = {
										onSuccess: () => onClose(),
										onError: () => {
											toast.error(
												'Erro ao salvar anotação'
											)
										},
									}

									if (annotation) {
										updateAnnotation.mutate(
											{
												annotationId: annotation._id,
												body,
												start,
												end,
											},
											options
										)
									} else if (body) {
										createAnnotation.mutate(
											{
												body,
												start,
												end,
											},
											options
										)
									}
								}}
							>
								<PopoverHeader fontWeight="semibold">
									Anotação
								</PopoverHeader>
								<PopoverBody>
									<Textarea
										ref={initialFocusRef}
										key={key}
										name="body"
										defaultValue={annotation?.body}
									/>
								</PopoverBody>
								<PopoverFooter>
									<Button
										display="block"
										ml="auto"
										type="submit"
										colorScheme="blue"
										isLoading={isLoading}
									>
										Salvar
									</Button>
								</PopoverFooter>
							</form>
						</PopoverContent>
					</>
				)}
			</Popover>
		</Table.Cell>
	)
}
