import { Select } from '@chakra-ui/react'
import { useRecordsManagement } from 'modules/records/hooks/useRecordsManagement'
import { Can } from 'ability'

export const SelectFilterByTypeRecord = () => {
	const { filterByTypeRecord, setFilterByTypeRecord } = useRecordsManagement()

	return (
		<Select
			placeholder="Pesquisar por"
			size="lg"
			width={{ sm: '60%', md: 'auto', lg: 'auto' }}
			minWidth={{ md: '190px', lg: '190px' }}
			onChange={(e) => setFilterByTypeRecord(e.target.value)}
			value={filterByTypeRecord}
		>
			<option value="customers">Pacientes</option>
			<Can I="read" a="Worker">
				<option value="workers">Colaboradores</option>
			</Can>
		</Select>
	)
}
