import { Tbody, Tr, Td, useColorModeValue } from '@chakra-ui/react'
import dayjs from 'dayjs'

interface TableProps {
	date: string
	procedure: string
	professional: string
}

export function PacientTBodyMobile({
	date,
	procedure,
	professional,
}: TableProps) {
	const border = useColorModeValue('#C6C6C6', '#777777')

	return (
		<Tbody width="100%">
			<Tr display="flex" flexDirection="column" fontSize="14px">
				<Td paddingY="8px" paddingX="10px">
					{dayjs(date).format('DD/MM/YYYY') || '-'}
				</Td>
				<Td
					paddingY="8px"
					paddingX="10px"
					borderTop={`1px solid ${border}`}
				>
					{procedure || '-'}
				</Td>
				<Td
					paddingY="8px"
					paddingX="10px"
					borderTop={`1px solid ${border}`}
				>
					{professional || '-'}
				</Td>
			</Tr>
		</Tbody>
	)
}
