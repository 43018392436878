import React from 'react'
import { InputProps } from '@chakra-ui/react'
import { Controller, Control } from 'react-hook-form'
import { InputText } from '../Input'

interface InputTextProps extends InputProps {
	title: string
	isDisabled?: boolean
	margin?: string | object
	control: Control<any>
	name: string
	hasError?: boolean
	errorMessage?: string
	disabled?: boolean
}

export const InputTextController = (props: InputTextProps) => {
	const { control, name, onChange, ...rest } = props
	return (
		<Controller
			control={control}
			name={name}
			render={({
				field: { onChange: controllerOnChange, ...field },
				fieldState: { error },
			}) => (
				<InputText
					{...rest}
					{...field}
					value={field.value ?? ''}
					errorMessage={error?.message}
					hasError={!!error?.message}
					onChange={(e) => {
						onChange?.(e)
						return controllerOnChange(e)
					}}
				/>
			)}
		/>
	)
}

InputTextController.defaultProps = {
	isDisabled: false,
	margin: 0,
	hasError: false,
	errorMessage: 'Este campo é obrigatório',
	disabled: false,
}
