interface ICustomer {
	id: string
	name: string
	cellphoneNumber?: string
	cpf?: string
	legalRepresentatives?: [{ cpf: string }]
}

export class Customer implements ICustomer {
	id: string
	name: string
	cellphoneNumber?: string
	cpf?: string
	legalRepresentatives?: [{ cpf: string }]

	constructor(object: ICustomer) {
		Object.assign(this, object)
	}

	static fromJson(json: Record<string, any>) {
		return new Customer({
			id: json._id,
			name: json.name,
			cellphoneNumber: json.cellphoneNumber,
			cpf: json.cpf,
			legalRepresentatives: json.legalRepresentatives,
		})
	}
}
