import { Flex, useColorModeValue } from '@chakra-ui/react'
import { useState } from 'react'
import { useLocation } from 'react-router-dom'
import { CgNotes } from 'react-icons/cg'
import { FiCalendar } from 'react-icons/fi'
import { MdAdminPanelSettings, MdOutlineAppRegistration } from 'react-icons/md'

import NavItem from './NavItem'
import { NewsPaperIcon } from 'app/icons/NewsPaperIcon'
import { CreditCardIcon } from 'app/icons/CreditCardIcon'
import { Can, useAbility } from 'ability'

export default function Sidebar() {
	const [navSize] = useState('large')
	const location = useLocation()
	const flexBg = useColorModeValue('#FFF', '#263849')

	const ability = useAbility()
	const canAccessAdmin = [
		ability.can('read', 'Product'),
		ability.can('update', 'User'),
		ability.can('read', 'Bill'),
		ability.can('read', 'Report'),
	].some(Boolean)

	return (
		<Flex
			h="calc(100vh - 54px)"
			w="auto"
			flexDir="column"
			columnGap="8px"
			boxShadow="0 4px 12px 0 rgba(0, 0, 0, 0.05)"
			bg={flexBg}
		>
			<Flex
				as="nav"
				overflowY="auto"
				p="5%"
				flexDir="column"
				w="100%"
				alignItems="flex-start"
				bg={flexBg}
			>
				<Can I="read" a="Customer">
					<NavItem
						navSize={navSize}
						icon={MdOutlineAppRegistration}
						title="Cadastro"
						active={location.pathname.includes('registros')}
						to="/registros"
						// description="This is the description for the dashboard."
					/>
				</Can>
				<NavItem
					navSize={navSize}
					icon={FiCalendar}
					title="Agenda"
					active={location.pathname.includes('agendamentos')}
					to="/agendamentos"
				/>
				<NavItem
					to="/historico"
					navSize={navSize}
					icon={CgNotes}
					active={location.pathname.includes('historico')}
					title="Histórico"
				/>
				<Can I="read" a="ProcedureType">
					<NavItem
						to="/procedimentos"
						navSize={navSize}
						icon={NewsPaperIcon}
						active={location.pathname.includes('procedimentos')}
						title="Procedimentos"
					/>
				</Can>
				<Can I="read" a="Treatment">
					<NavItem
						to="/tratamentos"
						navSize={navSize}
						icon={NewsPaperIcon}
						active={location.pathname.includes('tratamentos')}
						title="Tratamentos"
					/>
				</Can>
				<Can I="read" a="Payment">
					<NavItem
						to="/financeiro"
						navSize={navSize}
						icon={() => <CreditCardIcon size={20} />}
						active={location.pathname.includes('financeiro')}
						title="Financeiro"
					/>
				</Can>
				{canAccessAdmin && (
					<NavItem
						to="/admin"
						navSize={navSize}
						icon={() => <MdAdminPanelSettings size={20} />}
						active={location.pathname.includes('admin')}
						title="Administração"
					/>
				)}
			</Flex>
		</Flex>
	)
}
