import {
	Button,
	Center,
	CloseButton,
	Flex,
	FormControl,
	FormErrorMessage,
	FormLabel,
	Heading,
	Input,
	Spinner,
	Stack,
	Text,
	useToast,
} from '@chakra-ui/react'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import {
	FormProvider,
	useFieldArray,
	useForm,
	useFormContext,
} from 'react-hook-form'
import { useNavigate, useParams } from 'react-router-dom'
import { AddIcon } from '@chakra-ui/icons'

import { CurrencyInput } from 'app/components/CurrencyInput'
import api from 'services/api'

interface ProcedureResponse {
	name: string
	costs: ProcedureCosts
}

interface ProcedureCosts {
	clinicalHour: number
	materials: Array<{ name: string; value: number }>
	thirdParty: number
}

export function ProcedureCosts() {
	const params = useParams()
	const query = useQuery({
		queryKey: ['procedure_costs', params.id],
		queryFn: () =>
			api
				.get<ProcedureResponse>(`/procedure-types/${params.id}`)
				.then((res) => res.data),
	})

	if (query.isLoading) {
		return (
			<Stack px="6" py="8" h="100%">
				<Center h="100%">
					<Spinner color="primary.700" size="xl" />
				</Center>
			</Stack>
		)
	}

	const data = query.data

	return (
		<Stack px="6" py="8" h="100%">
			<Flex justify="space-between" alignItems="center" mb="6">
				<Heading fontSize="2xl">
					Custos do procedimento: {data.name}
				</Heading>
			</Flex>

			<CostsForm costs={data.costs} key={`form:${query.dataUpdatedAt}`} />
		</Stack>
	)
}

interface Props {
	costs?: ProcedureCosts
}

function CostsForm({ costs }: Props) {
	const params = useParams()
	const client = useQueryClient()
	const mutation = useMutation({
		mutationFn: (data: typeof costs) =>
			api.put(`/procedure-types/${params.id}`, { costs: data }),
		onSuccess: () =>
			client.invalidateQueries({ queryKey: ['procedure_costs'] }),
	})
	const form = useForm({ defaultValues: costs })
	const toast = useToast()
	const navigate = useNavigate()

	const onSave = form.handleSubmit((data) =>
		mutation.mutate(data, {
			onSuccess() {
				toast({ status: 'success', title: 'Salvo!' })
				navigate(-1)
			},
			onError: (error) =>
				toast({
					status: 'error',
					title: 'Erro',
					description: error.message,
				}),
		})
	)

	const total = [
		form.watch('clinicalHour'),
		...(form.watch('materials') ?? []).map((it) => it.value),
		form.watch('thirdParty'),
	].reduce((sum, it) => sum + (it ? Number(it) : 0), 0)
	const totalFormatted = total.toLocaleString([], {
		currency: 'BRL',
		style: 'currency',
	})

	return (
		<FormProvider {...form}>
			<form onSubmit={onSave} noValidate style={{ maxWidth: 512 }}>
				<FormControl
					isInvalid={!!form.formState.errors.clinicalHour}
					mb="5"
				>
					<FormLabel fontSize="sm">Hora clínica</FormLabel>
					<CurrencyInput
						fontSize="sm"
						placeholder="R$"
						name="clinicalHour"
					/>
					<FormErrorMessage position="absolute" right="0" mt="1">
						{form.formState.errors.clinicalHour?.message}
					</FormErrorMessage>
				</FormControl>

				<FormControl mb="5">
					<MaterialsFields />
				</FormControl>

				<FormControl
					isInvalid={!!form.formState.errors.thirdParty}
					mb="5"
				>
					<FormLabel fontSize="sm">Terceiros</FormLabel>
					<CurrencyInput
						fontSize="sm"
						placeholder="R$"
						name="thirdParty"
					/>
					<FormErrorMessage position="absolute" right="0" mt="1">
						{form.formState.errors.thirdParty?.message}
					</FormErrorMessage>
				</FormControl>

				<FormControl mb="10">
					<FormLabel fontSize="sm">Total</FormLabel>
					<Input
						fontSize="sm"
						readOnly
						isReadOnly
						value={totalFormatted}
					/>
				</FormControl>

				<Flex justify="space-evenly" flexDir="row-reverse">
					<Button
						type="submit"
						size="sm"
						w="130px"
						h="32px"
						colorScheme="primary"
						textTransform="uppercase"
						isLoading={mutation.isPending}
					>
						Salvar
					</Button>
					<Button
						size="sm"
						w="130px"
						h="32px"
						colorScheme="primary"
						variant="outline"
						textTransform="uppercase"
						type="button"
						onClick={() => navigate('/procedimentos')}
					>
						Voltar
					</Button>
				</Flex>
			</form>
		</FormProvider>
	)
}

function MaterialsFields() {
	const form = useFormContext<ProcedureCosts>()
	const { errors } = form.formState

	const materialsFieldArray = useFieldArray<ProcedureCosts>({
		name: 'materials',
	})
	const { fields } = materialsFieldArray

	return (
		<>
			<Flex alignItems="center" justify="space-between" mb="4">
				<FormLabel fontSize="sm" mb="0">Materiais</FormLabel>
				<Button
					colorScheme="primary"
					size="sm"
					onClick={() =>
						materialsFieldArray.append({
							name: '',
							value: 0,
						})
					}
					leftIcon={<AddIcon />}
					textTransform="uppercase"
					fontSize="xs"
					display="block"
					ml="auto"
				>
					Adicionar
				</Button>
			</Flex>

			<Flex as="ol" flexDirection="column" gap="6">
				{fields.map((it, i) => {
					return (
						<Stack as="li" key={it.id}>
							<Flex gap="3" alignItems="center">
								<Input
									size="sm"
									placeholder="Nome"
									{...form.register(`materials.${i}.name`, {
										required: true,
									})}
									isInvalid={!!errors.materials?.[i]?.name}
								/>
								<CurrencyInput
									size="sm"
									placeholder="Valor"
									name={`materials.${i}.value`}
									rules={{ required: true }}
									isInvalid={!!errors.materials?.[i]?.value}
								/>

								<CloseButton
									onClick={() =>
										materialsFieldArray.remove(i)
									}
								/>
							</Flex>
						</Stack>
					)
				})}
			</Flex>
		</>
	)
}
