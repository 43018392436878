import { Skeleton, Table, TableContainer, Text } from '@chakra-ui/react'
import { THeadDesktop } from './THeadDesktop'
import { TBodyDesktop } from './TBodyDesktop'
import { Footer } from '../PacientTable/Footer'
import { useHistoryQuery } from 'modules/history/queries/useHistoryQuery'

export function DesktopTable() {
	const query = useHistoryQuery()
	const history = query.data?.data

	return (
		<>
			{query.isLoading && (
				<TableContainer
					width="100%"
					h="100%"
					display="flex"
					alignItems="center"
					justifyContent="center"
				>
					<Skeleton
						height="60vh"
						width="100%"
						mt={{ sm: '0px', md: '32px', lg: '32px' }}
					/>
				</TableContainer>
			)}
			{query.data && (
				<TableContainer
					width="100%"
					h="100%"
					display="flex"
					alignItems="flex-start"
					justifyContent="center"
				>
					{history?.length > 0 ? (
						<Table variant="striped">
							<THeadDesktop />
							<TBodyDesktop />
						</Table>
					) : (
						<Text
							as="p"
							width="75%"
							color="#3388CC"
							fontSize="20px"
							fontWeight="700"
							textAlign="center"
							marginX="auto"
						>
							Não há procedimentos para serem mostrados
						</Text>
					)}
				</TableContainer>
			)}
			{history?.length > 0 && <Footer />}
		</>
	)
}
