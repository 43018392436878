import {
	Box,
	Select as SelectChakraUI,
	Text,
	useColorModeValue,
} from '@chakra-ui/react'
import { Heading } from '../Heading'

interface ItemsProps {
	[key: string]: string | number | readonly string[]
}

interface SelectProps {
	items: ItemsProps[]
	title?: string
	onChange: React.ChangeEventHandler<HTMLSelectElement>
	value: any
	name?: string
	width?: string | object | number
	flex?: string
	margin?: string | object
	hasError?: boolean
	errorMessage?: string
	placeholder?: string
}

export const Select = ({
	items,
	title,
	value,
	onChange,
	name,
	width,
	margin,
	hasError,
	errorMessage,
	placeholder,
	flex,
}: SelectProps) => {
	return (
		<Box margin={margin} flex={flex}>
			{title && (
				<Heading type="h4" mb="4px">
					{title}
				</Heading>
			)}
			<SelectChakraUI
				placeholder={placeholder}
				width={width}
				value={value}
				onChange={onChange}
				name={name}
				errorBorderColor="red.400"
				isInvalid={hasError}
				bg={useColorModeValue('gray.100', 'gray.700')}
			>
				{items.map((item: ItemsProps) => (
					<option key={String(item.value)} value={item.value}>
						{item.label}
					</option>
				))}
			</SelectChakraUI>
			{hasError && (
				<Text fontSize="xs" padding={1} color="red.400">
					{errorMessage}
				</Text>
			)}
		</Box>
	)
}

Select.defaultProps = {
	margin: 0,
	hasError: false,
	errorMessage: 'Este campo é obrigatório',
	placeholder: '',
}
