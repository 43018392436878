/* eslint-disable no-underscore-dangle */
import React from 'react'
import dayjs from 'dayjs'
import { useQuery } from '@tanstack/react-query'
import { useParams, useSearchParams } from 'react-router-dom'
import api from 'services/api'

export type ScheduleEvent = {
	id: string
	status: string
	customerInfo: {
		_id: string
		name: string
		cellphoneNumber: string
	}
	start: string
	end: string
	phone: string
	notes?: string
}

type TData = {
	professional: {
		id: string
		name: string
		imageSrc: string
	}
	events: Array<ScheduleEvent>
	annotations: { body: string; start: string; end: string }[]
}

// function mockDate(hour: number, minute: number) {
// 	const date = new Date()
// 	date.setHours(hour)
// 	date.setMinutes(minute)
// 	return date.toISOString()
// }

// const MOCKED: TData = {
// 	professional: { id: '1', name: 'Ernes Campbel', imageSrc: '' },
// 	events: [
// 		{
// 			status: 'finalizado',
// 			customer: {
// 				name: 'Maria Luiza Romanha',
// 			},
// 			annotation: 'Tem medo de agulhas de precisa do rsp',
// 			start: mockDate(6, 0),
// 			end: mockDate(7, 0),
// 		},
// 		{
// 			status: 'finalizado',
// 			customer: {
// 				name: 'Maria Luiza Romanha',
// 			},
// 			annotation: '',
// 			start: mockDate(7, 0),
// 			end: mockDate(8, 0),
// 		},
// 	],
// }

const API_DATE_FORMAT = 'YYYY-MM-DD'

const dateHandlers: Record<
	string,
	(date: string) => Record<string, dayjs.Dayjs>
> = {
	day(currentDateParam: string) {
		const start = dayjs(currentDateParam)
		const end = start.add(1, 'day')
		return { start, end }
	},
	week(endDateParam: string) {
		const end = dayjs(endDateParam).set('day', 1).add(7, 'day')
		const start = dayjs(end.add(-7, 'day'))
		return { start, end }
	},
	month(date: string) {
		const dateHandle = dayjs(date)
		const firstDate = dateHandle.startOf('month')
		const lastDate = dateHandle.endOf('month')
		const numberOfWeeks = lastDate.week() + 1 - firstDate.week()
		const initialWeek = dateHandle.startOf('month').week()
		const lastWeek = initialWeek + numberOfWeeks - 1
		const start = dateHandle.startOf('weeks').week(initialWeek)
		const end = dateHandle.startOf('weeks').week(lastWeek).add(6, 'day')
		return { start, end }
	},
}

export function useProfessionalSchedule(
	type: 'day' | 'week' | 'month' = 'day'
) {
	const { professionalId } = useParams()
	const [searchParams] = useSearchParams()

	const now = React.useRef(new Date()).current
	const currentDateParam = dayjs(searchParams.get('date') || now)
		.hour(0)
		.minute(0)
		.second(0)
		.millisecond(0)
		.toISOString()

	return useQuery({
		queryKey: [
			'professional_schedule',
			professionalId,
			currentDateParam,
			type,
		],

		queryFn: async (ctx) => {
			const apiSearchParams = new URLSearchParams()

			const calendarTypeParam = type

			const dates = dateHandlers[calendarTypeParam]?.(currentDateParam)

			const targetZone = 'Europe/London'
			const start = dates.start.tz(targetZone).toString()
			const end = dates.end.tz(targetZone).toString()
			apiSearchParams.set('start', start)
			apiSearchParams.set('end', end)

			const { data } = await api.get(
				`/dentists/${professionalId}/events?${apiSearchParams}`,
				{ signal: ctx.signal }
			)

			return {
				professional: {
					id: professionalId,
					name: data?.name,
					imageSrc: '',
				},
				events: (data?.agenda.events || []).map((event: any) => ({
					id: event._id,
					status: event.status,
					customerInfo: event.customerInfo,
					start: event.start,
					end: event.end,
					phone: event.phone,
					notes: event.notes,
				})) as any[],
				annotations: data?.agenda.annotations ?? [],
			}
		},
	})
}

export type Schedule = ReturnType<typeof useProfessionalSchedule>['data']
