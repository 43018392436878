import * as yup from 'yup'

export interface ScheduleForm {
	isPreRegister?: boolean
	start: string
	end: string
	title?: string
	customerId: string
	cellphoneNumber: string
	notes?: string
	id?: string
	status?: string
}

export const scheduleFormSchema = yup.object({
	isPreRegister: yup.boolean().default(false),
	start: yup.string().required(),
	end: yup.string().required(),
	title: yup.string(),
	customerId: yup.string().required('Nome é obrigatório'),
	cellphoneNumber: yup.string().required('Telefone é obrigatório'),
	// .phone('BR', true, 'Telefone inválido')
	notes: yup.string(),
	id: yup.string(),
})
