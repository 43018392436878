import {
	Button,
	Flex,
	Heading,
	Menu,
	MenuButton,
	MenuItem,
	MenuList,
	Text,
	useColorModeValue,
} from '@chakra-ui/react'
import { Modal } from 'app/components/Modal'
import { Link, useParams, useSearchParams } from 'react-router-dom'
import { EditIcon } from '@chakra-ui/icons'
import { FormProvider, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import React from 'react'

import { getUserInfoInStorage } from 'services/api/auth'
import { editProcedureSchema } from 'modules/history/validation/editProcedureSchema'
import { useProfessionals } from 'modules/schedule/queries/useProfessionals'
import { Select } from 'app/components/Select'
import { useProceduresManagement } from '../hooks/useProceduresManagement'
import { NewProcedureForm } from './NewProcedureForm'
import { HistoryPdfLink } from './HistoryPdfLink'
import { ProcedureFormModal } from './ProcedureFormModal'
import { useCustomerQuery } from '../queries/useCustomerQuery'

export function PatientHeader() {
	const btnBg = useColorModeValue('#F2F2F2', 'whiteAlpha.50')

	const {
		setCustomerId,
		isOpenNewProcedureForm,
		setIsOpenNewProcedureForm,
		allProcedures,
	} = useProceduresManagement()
	const { customerId } = useParams()
	const now = new Date().toISOString().slice(0, 10)

	const methods = useForm({
		defaultValues: {
			name: '',
			date: now.split('T')[0],
			dentist: '',
			observation: '',
			files: undefined,
			customer: customerId,
		},
		resolver: yupResolver(editProcedureSchema),
		mode: 'all',
	})
	setCustomerId(customerId)

	const professionals = useProfessionals()

	const [searchParams, setSearchParams] = useSearchParams()

	const navigatedDefaultProfessional = React.useRef(false)
	const defaultProfessional =
		!searchParams.get('dentist') &&
		!navigatedDefaultProfessional.current &&
		professionals.data?.find(
			(it) => it.userId === getUserInfoInStorage()?.id
		)
	if (defaultProfessional) {
		setSearchParams(
			(next) => {
				next.set('dentist', defaultProfessional.id)
				return next
			},
			{
				replace: true,
				preventScrollReset: true,
			}
		)
		navigatedDefaultProfessional.current = true
	}

	const customerQuery = useCustomerQuery(customerId)
	const customer = customerQuery.data

	return (
		<>
			<Flex
				as="header"
				justifyContent="space-between"
				alignItems="center"
				flexWrap="wrap"
				gap="24px"
				marginX={{ base: '24px', md: 0 }}
			>
				<Flex columnGap="10px" alignItems="center">
					<Heading as="h3" fontSize="20px" color="#1188EE">
						{customer?.name}
					</Heading>
					<Link
						to={`/registros/customers/${customerId}`}
						style={{
							padding: '10px 0',
							fontWeight: '600',
							color: '#1188EE',
							borderRadius: '6px',
						}}
					>
						<EditIcon color="#55BBDD" boxSize={5} />
					</Link>
					<HistoryPdfLink data={allProcedures?.data} />

					<Text
						ml="4"
						textAlign="right"
						fontWeight="600"
						whiteSpace="nowrap"
						overflow="hidden"
						textOverflow="ellipsis"
						color="primary.800"
						display="block"
						_hover={{ textDecoration: 'underline' }}
						as={Link}
						to={`/tratamentos?customer=${customerId}`}
					>
						Ver tratamento
					</Text>
				</Flex>
				<Flex gap="24px" flexWrap="wrap">
					<Select
						placeholder="Selecione o profissional"
						value={searchParams.get('dentist') ?? ''}
						onChange={(e) =>
							setSearchParams(
								(next) => {
									next.set('dentist', e.target.value)
									return next
								},
								{
									replace: searchParams.has('dentist'),
									preventScrollReset: true,
								}
							)
						}
						items={
							professionals.data?.map((it) => ({
								value: it.id,
								label: it.name,
							})) ?? []
						}
					/>

					<ProcedureFormModal
						trigger={
							<Button bg="#55BBDD" color="white">
								+ Procedimento
							</Button>
						}
					/>

					<Menu>
						<MenuButton as={Button} bg={btnBg} color="#55BBDD">
							Documentos
						</MenuButton>
						<MenuList>
							<MenuItem
								as={Link}
								to={`/historico/${customerId}/documentos/anamnesis`}
								replace
							>
								Anamneses
							</MenuItem>
							<MenuItem
								as={Link}
								to={`/historico/${customerId}/documentos/radiograph`}
								replace
							>
								Radiografia
							</MenuItem>
							<MenuItem
								as={Link}
								to={`/historico/${customerId}/documentos/document`}
								replace
							>
								Documentos
							</MenuItem>
						</MenuList>
					</Menu>
				</Flex>
			</Flex>
			<Flex>
				<FormProvider {...methods}>
					<Modal
						isOpen={isOpenNewProcedureForm}
						onClose={() => {
							if (window.confirm('Gostaria mesmo de sair?')) {
								setIsOpenNewProcedureForm(false)
								methods.reset({
									name: '',
									date: now.split('T')[0],
									dentist: '',
									observation: '',
									files: undefined,
								})
							}
						}}
						title="Procedimento"
						handleForm={() => undefined}
						body={<NewProcedureForm />}
						footer={<></>}
						dividers={false}
					/>
				</FormProvider>
			</Flex>
		</>
	)
}
