import {
	Accordion as AccordionChakraUI,
	AccordionItem,
	AccordionButton,
	Box,
	AccordionIcon,
	AccordionPanel,
	useColorModeValue,
} from '@chakra-ui/react'

import { Heading } from 'app/components/Heading'

interface AccordionProps {
	title: string
	children: React.ReactNode
	isOpenAccordion?: boolean
}

export const Accordion = ({
	title,
	children,
	isOpenAccordion,
}: AccordionProps) => {
	const border = useColorModeValue('#E2E8F0', '#777')

	return (
		<AccordionChakraUI
			allowMultiple
			defaultIndex={isOpenAccordion ? [0] : []}
			allowToggle
			border={`1px solid ${border}`}
			borderRadius="6px"
		>
			<AccordionItem>
				<h2>
					<AccordionButton _expanded={{ color: 'primary' }}>
						<Box flex="1" textAlign="left">
							<Heading type="h3">
								{title}
								<AccordionIcon />
							</Heading>
						</Box>
					</AccordionButton>
				</h2>
				<AccordionPanel pb={4}>{children}</AccordionPanel>
			</AccordionItem>
		</AccordionChakraUI>
	)
}

Accordion.defaultProps = {
	isOpenAccordion: false,
}
