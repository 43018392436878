import {
	Accordion,
	AccordionButton,
	AccordionIcon,
	AccordionItem,
	AccordionPanel,
	Box,
	Flex,
	FormLabel,
	Heading,
	useColorModeValue,
} from '@chakra-ui/react'
import { useProceduresManagement } from 'modules/history/hooks/useProceduresManagement'
import { useFormContext } from 'react-hook-form'
import { InputTextController } from 'app/components/InputTextController'
import { ImageUpload } from './ImagesUpload'
import { FormFooter } from './FormFooter'
import { TextAreaController } from '../TextareaController'
import { useHistoryQuery } from 'modules/history/queries/useHistoryQuery'

export function ProcedureForm({ id }: { id: string }) {
	const { customer } = useProceduresManagement()

	const query = useHistoryQuery()
	const history = query.data?.data

	const procedure = history?.find((it) => it.procedure?._id === id)?.procedure

	const {
		control,
		formState: { errors },
	} = useFormContext()
	const color = useColorModeValue('#223344', '#fff')
	const btnBg = useColorModeValue('#F9F9F9', 'whiteAlpha.50')
	const border = useColorModeValue('#F4F4F4', '#777')

	const isRead = !!id

	return (
		<Box height="100%">
			<Heading as="h3" fontSize="20px" fontWeight="700" color="#1188EE">
				{customer?.name}
			</Heading>
			<Flex
				flexDirection="column"
				height="95%"
				marginTop="16px"
				justifyContent="space-between"
			>
				<Accordion
					width="100%"
					display="flex"
					flexDirection="column"
					rowGap="24px"
					defaultIndex={[0, 1]}
					allowMultiple
				>
					<AccordionItem
						width="100%"
						border={`1px solid ${border}`}
						borderRadius="4px"
					>
						{({ isExpanded }) => (
							<>
								<AccordionButton
									_expanded={{ color: '#3388CC' }}
									color={color}
								>
									<Flex
										alignItems="center"
										width="100%"
										justifyContent="space-between"
									>
										<Heading
											as="h4"
											fontSize="16px"
											fontWeight="600"
										>
											Dados Gerais
										</Heading>
										{/* <Box _expanded={{background: 'black'}} width="24px" height="24px" display="flex" alignItems="center" justifyContent="center" borderRadius="4px" background="#55BBDD"> */}
										<AccordionIcon
											width="24px"
											height="24px"
											borderRadius="4px"
											color="#223344"
											background={
												isExpanded ? '#55BBDD' : btnBg
											}
										/>
										{/* </Box>                             */}
									</Flex>
								</AccordionButton>
								<AccordionPanel
									pb={4}
									display="flex"
									flexDirection="column"
									rowGap="16px"
								>
									<InputTextController
										title="Nome do procedimento*"
										type="string"
										width="100%"
										name="name"
										control={control}
										hasError={errors?.name}
										errorMessage={errors?.name?.message}
										isReadOnly={false}
										isRequired
										disabled={procedure?.deleted}
										// defaultValue={procedure?.name}
									/>
									<InputTextController
										title="Data*"
										type="date"
										width="100%"
										name="date"
										control={control}
										hasError={errors?.date?.message}
										errorMessage={errors?.date}
										isReadOnly={false}
										isRequired
										disabled={procedure?.deleted}
										// defaultValue={procedure?.date.split('T')[0]}
									/>
									<FormLabel
										fontSize="14px"
										fontWeight="600"
										// color="#223344"
									>
										Observações
									</FormLabel>
									<TextAreaController
										title="Observações"
										// type="string"
										width="100%"
										height="200px"
										name="observation"
										control={control}
										hasError={errors?.observation}
										errorMessage={
											errors?.observation?.message
										}
										isReadOnly={false}
										disabled={procedure?.deleted}
										// defaultValue={procedure?.observation}
									/>
								</AccordionPanel>
							</>
						)}
					</AccordionItem>
					<AccordionItem
						width="100%"
						border={`1px solid ${border}`}
						borderRadius="4px"
						mb="20px"
					>
						{({ isExpanded }) => (
							<>
								<AccordionButton
									_expanded={{ color: '#3388CC' }}
									color={color}
								>
									<Flex
										alignItems="center"
										width="100%"
										justifyContent="space-between"
									>
										<Heading
											as="h4"
											fontSize="16px"
											fontWeight="600"
										>
											Arquivo imagem
										</Heading>
										<AccordionIcon
											width="24px"
											height="24px"
											borderRadius="4px"
											color="#223344"
											background={
												isExpanded ? '#55BBDD' : btnBg
											}
										/>
									</Flex>
								</AccordionButton>
								<AccordionPanel pb={4}>
									<ImageUpload
										imagesEdit={procedure?.files.map(
											(file: {
												id: string
												url: string
											}) => file.url
										)}
									/>
								</AccordionPanel>
							</>
						)}
					</AccordionItem>
				</Accordion>
				{!isRead && <FormFooter isDeleted={procedure?.deleted} />}
			</Flex>
			{/* </form> */}
		</Box>
	)
}
