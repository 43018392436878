import { Thead, Th } from '@chakra-ui/react'

export function THeadDesktop() {
	return (
		<Thead>
			<Th>Data</Th>
			<Th>Procedimentos</Th>
			<Th>Paciente</Th>
			<Th>Profissional</Th>
		</Thead>
	)
}
