import {
	Flex,
	Skeleton,
	Table as TableChakraUI,
	TableContainer,
	Text,
	useColorModeValue,
} from '@chakra-ui/react'
import { useRecordsManagement } from 'modules/records/hooks/useRecordsManagement'
import { Body } from './Body'
import { Footer } from './Footer'
import { Head } from './Head'

export const Table = () => {
	const { getAllRecordsIsLoading, totalOfAllRecords } = useRecordsManagement()
	const border = useColorModeValue(
		'var(--chakra-colors-gray-100)',
		'transparent'
	)
	if (getAllRecordsIsLoading) {
		return (
			<Skeleton
				height="60vh"
				width="100%"
				mt={{ sm: '0px', md: '32px', lg: '32px' }}
			/>
		)
	}

	return (
		<>
			{totalOfAllRecords > 0 && (
				<>
					<TableContainer
						width="100%"
						border={`1px solid ${border}`}
						borderRadius={5}
					>
						<TableChakraUI variant="striped">
							<Head />
							<Body />
						</TableChakraUI>
					</TableContainer>
					<Footer />
				</>
			)}
			{totalOfAllRecords === 0 && (
				<Flex
					width="100%"
					mt={2}
					justifyContent="center"
					alignItems="center"
					textAlign="center"
				>
					<Text fontSize={{ sm: '14px', md: '16px', lg: '17px' }}>
						Nenhum informação correspondente encontrada.
					</Text>
				</Flex>
			)}
		</>
	)
}
