import React from 'react'
import { Button } from '@chakra-ui/react'
import { AddIcon } from '@chakra-ui/icons'
import { useRecordsManagement } from 'modules/records/hooks/useRecordsManagement'
import { Can } from 'ability'

export const ButtonToOpenRecordFormModal = () => {
	const { setIsOpenRecordFormModal } = useRecordsManagement()

	return (
		<Can I="create" a="Customer">
			<Button
				onClick={() => setIsOpenRecordFormModal(true)}
				width={{
					sm: '120px',
					md: '130px',
					lg: '153px',
				}}
				colorScheme="primary"
				height="48px"
				leftIcon={<AddIcon />}
				mr={{ md: '4px', lg: '4px' }}
			>
				Cadastrar
			</Button>
		</Can>
	)
}
