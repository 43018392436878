/* eslint-disable @typescript-eslint/no-explicit-any */
import { DownloadIcon } from '@chakra-ui/icons'
import { Box, Button, Spinner, useColorModeValue } from '@chakra-ui/react'
import { Document, PDFDownloadLink } from '@react-pdf/renderer'
import React from 'react'

/**
 *
 * @example
 * <PdfLink
		name="relatorio.pdf"
		document={
			<Pdf.Document>
				<Pdf.Page>Your document</Pdf.Page>
			</Pdf.Document>
		}
	 />
 */
export function PdfLink({
	name,
	document,
}: {
	name: string
	document: React.ReactElement<
		React.ComponentProps<typeof Document>,
		string | React.JSXElementConstructor<any>
	>
}) {
	const bg = useColorModeValue('#FFF', 'whiteAlpha.50')
	return (
		<Box display="flex" alignItems="center" columnGap="5px">
			<PDFDownloadLink document={document} fileName={name}>
				{({ loading }) => {
					return (
						<Button
							type="button"
							boxSizing="border-box"
							width="24px"
							height="30px"
							p="0"
							borderRadius="5px"
							display="flex"
							alignItems="center"
							justifyContent="center"
							backgroundColor={bg}
						>
							{loading ? (
								<Spinner size="sm" color="#1188EE" />
							) : (
								<DownloadIcon color="#1188EE" />
							)}
						</Button>
					)
				}}
			</PDFDownloadLink>
		</Box>
	)
}
