import { AxiosError, AxiosResponse } from 'axios'
import { RegistrationTypeProps } from 'modules/records/contexts/ManageRegistrationFormContext/types'
import { useMutation } from '@tanstack/react-query'
import api from 'services/api'

interface useCreateRecordsProps {
	onSuccess: (data: AxiosResponse) => void
	onError: (error: AxiosError) => void
}

interface createRecordProps {
	formData: FormData
}
export const useCreateProcedure = ({
	onSuccess,
	onError,
}: useCreateRecordsProps) => {
	return useMutation({
		async mutationFn({ formData }: createRecordProps) {
			const data = await api.post(`/procedures`, formData, {
				headers: { 'Content-Type': 'multipart/form-data' },
			})
			return data
		},
		onSuccess: (data) => onSuccess(data),
		onError: (error) => onError(error as AxiosError),
	})
}
