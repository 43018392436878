import { keepPreviousData, useQuery } from '@tanstack/react-query'
import { PaymentsRepository } from 'data/PaymentsRepository'

interface Props {
	page?: number
	search?: string
	startDate?: string
	endDate?: string
	customer?: string
	dentist?: string
	status?: string
	withNote?: boolean
}

export function usePaymentsQuery(
	{
		page,
		search,
		startDate,
		endDate,
		customer,
		dentist,
		status,
		withNote,
	}: Props,
	options?: { enabled?: boolean }
) {
	return useQuery({
		queryKey: [
			'payments',
			{
				page,
				search,
				startDate,
				endDate,
				customer,
				dentist,
				status,
				withNote,
			},
		],
		async queryFn(context) {
			const repository = new PaymentsRepository()
			return await repository.getPayments({
				page,
				search,
				startDate,
				endDate,
				customer,
				dentist,
				status,
				withNote,
				signal: context.signal,
			})
		},
		enabled: options?.enabled,
		placeholderData: keepPreviousData,
		staleTime: 10_000,
	})
}
