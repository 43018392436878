import {
	BrowserRouter,
	Navigate,
	Outlet,
	Route,
	Routes,
} from 'react-router-dom'

import { MainLayout } from 'app/layout/MainLayout'
import { DocumentDetails } from 'modules/history/DocumentDetails'
import Documents from 'modules/history/DocumentsList'
import { DocumentNew } from 'modules/history/DocumentNew'
import { EditRecord } from 'modules/records/components/EditRecord'
import { AuthPage } from 'pages/AuthPage'
import HistoryPage from 'pages/HistoryPage'
import PlaygroundPage from 'pages/PlaygroundPage'
import { RecordsPage } from 'pages/RecordsPage'
import { SchedulePage } from 'pages/SchedulePage'
import { isAuthenticated } from 'services/api/auth'
import { ProcedureCosts, ProceduresRoute } from 'modules/procedures'
import { ScheduleSearchRoute } from 'modules/schedule/components/ScheduleSearchRoute'
import {
	FinancialRoute,
	CustomerPaymentsRoute,
	FinancialIndexRoute,
	FinancialBillsRoute,
} from 'modules/financial'
import { TreatmentsRoute, TreatmentRoute } from 'modules/treatments'
import {
	AdminIndexRoute,
	AdminRoute,
	BillsRoute,
	PermissionsRoute,
	ProductsRoute,
	ReportsRoute,
	ReturnReportRoute,
} from 'modules/admin'

interface PrivateRouteProps {
	children: JSX.Element
}

function PrivateRoute({ children }: PrivateRouteProps) {
	return isAuthenticated() ? (
		<MainLayout>{children}</MainLayout>
	) : (
		<Navigate to="/" />
	)
}

export function RoutesConfig() {
	return (
		<BrowserRouter>
			<Routes>
				<Route
					element={
						<PrivateRoute>
							<Outlet />
						</PrivateRoute>
					}
				>
					<Route path="/registros" element={<RecordsPage />}>
						<Route
							path=":typeRegistration/:registrationTypeId"
							element={<EditRecord />}
						/>
					</Route>

					<Route
						path="/agendamentos/:professionalId/buscar"
						element={<ScheduleSearchRoute />}
					/>
					<Route
						path="/agendamentos/:professionalId?/:type?"
						element={<SchedulePage />}
					/>
					<Route
						path="/historico/:customerId?"
						element={<HistoryPage />}
					/>
					<Route
						path="/historico/:customerId/documentos/:type"
						element={<Documents />}
					>
						<Route path="novo" element={<DocumentNew />} />
						<Route
							path=":documentId"
							element={<DocumentDetails />}
						/>
					</Route>

					<Route
						path="/procedimentos"
						element={<ProceduresRoute />}
					/>
					<Route
						path="/procedimentos/:id/custos"
						element={<ProcedureCosts />}
					/>

					<Route path="/tratamentos" element={<TreatmentsRoute />} />
					<Route
						path="/tratamentos/:id"
						element={<TreatmentRoute />}
					/>

					<Route path="/financeiro" element={<FinancialRoute />}>
						<Route index element={<FinancialIndexRoute />} />
						<Route
							path="contas"
							element={<FinancialBillsRoute />}
						/>
					</Route>
					<Route
						path="/financeiro/pacientes/:id"
						element={<CustomerPaymentsRoute />}
					/>

					<Route path="/admin" element={<AdminRoute />}>
						<Route index element={<AdminIndexRoute />} />
						<Route path="produtos" element={<ProductsRoute />} />
						<Route
							path="permissoes"
							element={<PermissionsRoute />}
						/>
						<Route path="contas" element={<BillsRoute />} />
						<Route path="relatorios" element={<ReportsRoute />} />
						<Route
							path="retorno-de-clientes"
							element={<ReturnReportRoute />}
						/>
					</Route>

					<Route path="/playground" element={<PlaygroundPage />} />
				</Route>
				<Route index element={<AuthPage />} />
			</Routes>
		</BrowserRouter>
	)
}
