import { AxiosError, AxiosResponse } from 'axios'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import api from 'services/api'

interface useCreateRecordsProps {
	onSuccess: (data: AxiosResponse) => void
	onError: (error: AxiosError) => void
}

interface editProcedureProps {
	formData: FormData
	procedure: string
}
export const useEditProcedure = ({
	onSuccess,
	onError,
}: useCreateRecordsProps) => {
	const client = useQueryClient()
	return useMutation({
		async mutationFn({ formData, procedure }: editProcedureProps) {
			const data = await api.put(`/procedures/${procedure}`, formData, {
				headers: { 'Content-Type': 'multipart/form-data' },
			})
			return data
		},
		onSuccess: (data) => {
			onSuccess(data)
			return client.invalidateQueries({
				queryKey: ['useGetAllProcedures'],
			})
		},
		onError: (error) => onError(error as AxiosError),
	})
}
