import {
	Grid,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
} from '@chakra-ui/react'
import { Can, useAbility } from 'ability'
import { CreditCardIcon } from 'app/icons/CreditCardIcon'
import { NewsPaperIcon } from 'app/icons/NewsPaperIcon'
import React from 'react'

import { CgNotes } from 'react-icons/cg'
import { FiCalendar } from 'react-icons/fi'
import { MdAdminPanelSettings, MdOutlineAppRegistration } from 'react-icons/md'
import { useLocation } from 'react-router-dom'
import { NavItem } from './NavItem'

export interface MobileMenuProps {
	isOpen: boolean
	onClose: () => void
}
const MobileMenu: React.FC<MobileMenuProps> = ({ isOpen, onClose }) => {
	const [navSize] = React.useState('large')

	const location = useLocation()

	React.useEffect(() => {
		onClose()
	}, [location])

	const ability = useAbility()
	const canAccessAdmin = [
		ability.can('read', 'Product'),
		ability.can('update', 'User'),
		ability.can('read', 'Bill'),
		ability.can('read', 'Report'),
	].some(Boolean)

	return (
		<Modal isOpen={isOpen} onClose={onClose} size="full">
			<ModalContent>
				<ModalBody>
					<ModalCloseButton />
					<Grid height="100vh" width="full" placeContent="center">
						<Can I="read" a="Customer">
							<NavItem
								navSize={navSize}
								icon={MdOutlineAppRegistration}
								title="Cadastro"
								active={location.pathname.includes('registros')}
								to="/registros"
							/>
						</Can>
						<NavItem
							navSize={navSize}
							icon={FiCalendar}
							title="Agenda"
							active={location.pathname.includes('agendamentos')}
							to="/agendamentos"
						/>
						<NavItem
							navSize={navSize}
							icon={CgNotes}
							title="Histórico"
							active={location.pathname.includes('historico')}
							to="/historico"
						/>
						<Can I="read" a="ProcedureType">
							<NavItem
								to="/procedimentos"
								navSize={navSize}
								icon={NewsPaperIcon}
								active={location.pathname.includes(
									'procedimentos'
								)}
								title="Procedimentos"
							/>
						</Can>
						<Can I="read" a="Treatment">
							<NavItem
								to="/tratamentos"
								navSize={navSize}
								icon={NewsPaperIcon}
								active={location.pathname.includes(
									'tratamentos'
								)}
								title="Tratamentos"
							/>
						</Can>
						<Can I="read" a="Payment">
							<NavItem
								to="/financeiro"
								navSize={navSize}
								icon={() => <CreditCardIcon size={20} />}
								active={location.pathname.includes(
									'financeiro'
								)}
								title="Financeiro"
							/>
						</Can>
						{canAccessAdmin && (
							<NavItem
								to="/admin"
								navSize={navSize}
								icon={() => <MdAdminPanelSettings size={20} />}
								active={location.pathname.includes('admin')}
								title="Administração"
							/>
						)}
					</Grid>
				</ModalBody>
			</ModalContent>
		</Modal>
	)
}

export default MobileMenu
