import { FormProvider, useForm } from 'react-hook-form'
import { useSettingsDefault } from 'modules/schedule/queries/useSettingsDefault'
import {
	Settings,
	settingsSchema,
} from 'modules/schedule/validations/settingsSchema'
import React from 'react'
import { useSaveSettingsDefault } from 'modules/schedule/queries/useSaveSettingsDefault'
import { yupResolver } from '@hookform/resolvers/yup'
import { SettingsForm } from './SettingsForm'
import { Props } from './ScheduleSettingsBody'

export function SettingsDefault({ onCancel }: Props) {
	const settingsDefault = useSettingsDefault()

	const form = useForm<Settings>({
		resolver: yupResolver(settingsSchema),
	})

	const { reset, control, handleSubmit } = form

	React.useEffect(() => {
		reset(settingsDefault.data ?? {})
	}, [settingsDefault.data])

	const saveSettingsDefault = useSaveSettingsDefault()

	return (
		<FormProvider
			{...form}
			key={settingsDefault.data ? 'form-with-data' : 'form-no-data'}
		>
			<SettingsForm
				onSubmit={handleSubmit((values) => {
					saveSettingsDefault.mutate(values)
				})}
				control={control}
				onCancel={onCancel}
			/>
		</FormProvider>
	)
}
