/* eslint-disable react/jsx-props-no-spreading */
import React from 'react'
import InputMask from 'react-input-mask'
import { InputProps, Box } from '@chakra-ui/react'
import { InputText } from '../Input'

interface MaskedInputProps extends InputProps {
	mask: string
	title: string
	margin?: string | object
	hasError?: boolean
}

const MaskedInput = (props: MaskedInputProps) => {
	const { margin, ...rest } = props

	return (
		<Box margin={margin}>
			<InputText as={InputMask} {...rest} />
		</Box>
	)
}

MaskedInput.defaultProps = {
	margin: 0,
	hasError: false,
}
export default MaskedInput
