import { Flex, Select, Input, Button } from '@chakra-ui/react'
import { Modal } from 'app/components/Modal'
import { useProceduresManagement } from 'modules/history/hooks/useProceduresManagement'
import { FormProvider, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { editProcedureSchema } from 'modules/history/validation/editProcedureSchema'
import { NewProcedureForm } from '../NewProcedureForm'
import { useSearchParams } from 'react-router-dom'
import { useProfessionalsQuery } from 'modules/history/queries/useProfessionalsQuery'

export function FiltersContainerDesktop() {
	const {
		setInfoSearch,
		infoSearch,
		setFilterByTypeInfo,
		filterByTypeInfo,
		setInfoFilter,
		setSearch,
		isOpenNewProcedureForm,
		setIsOpenNewProcedureForm,
		setCustomerId,
	} = useProceduresManagement()

	if (filterByTypeInfo === 'customer') {
		setFilterByTypeInfo('')
	}
	const now = new Date().toISOString().slice(0, 10)
	const methods = useForm({
		defaultValues: {
			name: '',
			date: now.split('T')[0],
			dentist: '',
			observation: '',
			files: undefined,
			customer: '',
		},
		resolver: yupResolver(editProcedureSchema),
		mode: 'all',
	})

	const { data: professionals } = useProfessionalsQuery()

	const [searchParams, setSearchParams] = useSearchParams()
	const search = searchParams.get('search') ?? ''
	const dentist = searchParams.get('dentist') ?? ''

	return (
		<>
			<Flex
				as="header"
				flexDirection={{ base: 'column', md: 'row' }}
				justifyContent={{ md: 'space-between' }}
				alignItems="center"
				rowGap="6"
				px="6"
			>
				<Flex
					flexDirection={{ base: 'column', md: 'row' }}
					width={{ base: '100%', md: '50%' }}
				>
					<Input
						placeholder="Buscar por nome do paciente ou do profissional"
						minW={{ lg: '387px' }}
						fontSize="14px"
						defaultValue={search}
						onChange={(e) => {
							setInfoSearch(e.target.value)
							setSearch('name')

							searchParams.set('search', e.target.value)
							setSearchParams(searchParams, { replace: !!search })
						}}
						// value={infoSearch}
					/>
				</Flex>
				<Select
					width="100%"
					maxW={{ md: '240px' }}
					defaultValue={dentist}
					onChange={(e) => {
						setInfoFilter(e.target.value)
						setFilterByTypeInfo('dentist')

						searchParams.set('dentist', e.target.value)
						setSearchParams(searchParams, { replace: !!dentist })
					}}
				>
					<option value="">Selecione o profissional</option>
					{professionals?.map((professional) => (
						<option
							key={'p-' + professional.id}
							value={professional.id}
						>
							{professional.name}
						</option>
					))}
				</Select>
				<Button
					w={{ base: '100%', md: 'auto' }}
					bg="#55BBDD"
					color="white"
					onClick={() => {
						setIsOpenNewProcedureForm(true)
						methods.reset({
							name: '',
							date: now.split('T')[0],
							dentist: '',
							observation: '',
							files: undefined,
							customer: '',
						})
						setCustomerId('')
					}}
				>
					+ Procedimento
				</Button>
				<FormProvider {...methods}>
					<Modal
						isOpen={isOpenNewProcedureForm}
						onClose={() => {
							setIsOpenNewProcedureForm(false)
							methods.reset({
								name: '',
								date: now.split('T')[0],
								dentist: '',
								observation: '',
								files: undefined,
								customer: '',
							})
						}}
						title="Procedimento"
						handleForm={() => undefined}
						body={<NewProcedureForm />}
						footer={<></>}
						dividers={false}
					/>
				</FormProvider>
			</Flex>
		</>
	)
}
