import React from 'react'
import { Accordion } from 'app/components/Accordion'
import { Box, Button, ButtonGroup, Flex, Grid, Image } from '@chakra-ui/react'
import { ImageUpload } from 'app/components/ImageUpload'
import { useFormContext } from 'react-hook-form'
import api from 'services/api'
import { WebCam } from 'app/components/WebCam'
import { Heading } from 'app/components/Heading'
import { ImagesViewer } from 'app/components/ImagesViewer'

export const RecordProfilePicture = () => {
	const { setValue, watch } = useFormContext()

	const userPicture = watch('user.picture')

	const handleChangeProfilePicture = (picture: File | Blob) => {
		setValue('user.picture', picture)
	}

	const [src, setSrc] = React.useState(
		userPicture instanceof File ? URL.createObjectURL(userPicture) : ''
	)

	React.useEffect(() => {
		if (typeof userPicture === 'string') {
			api.get(userPicture, { responseType: 'blob' }).then((res) =>
				setSrc(URL.createObjectURL(res.data))
			)
		} else if (userPicture instanceof File || userPicture instanceof Blob) {
			setSrc(URL.createObjectURL(userPicture))
		} else {
			setSrc('')
		}
	}, [userPicture])
	const viewer = ImagesViewer.useImagesViewer([{url: src}])
	const isViewerOpen = !!viewer.currentPhotoId

	return (
		<Box mt="24px">
			<Accordion title="Adicionar Imagem" isOpenAccordion>
				<Flex
					alignItems="center"
					justifyContent="center"
					flexDir="column"
				>
					{isViewerOpen && (
						<ImagesViewer
							photos={[{url: src}]}
							currentPhotoId={viewer.currentPhotoId}
							onBack={viewer.handleBackPhoto}
							onNext={viewer.handleNextPhoto}
							onClose={viewer.closePhoto}
						/>
					)}
					<Grid
						gap={5}
						gridTemplateColumns={{
							base: 'repeat(2,1fr)',
							md: 'repeat(2,180px)',
						}}
					>
						<ImageUpload onChange={handleChangeProfilePicture} />
						<WebCam onCapture={handleChangeProfilePicture} />
					</Grid>
					<Flex direction="column">
						<Image
							m="12px 0px"
							boxSize="auto"
							maxHeight="21.875rem"
							maxWidth="15.625rem"
							src={String(src)}
							alt="Imagem de perfil"
							fallbackSrc="https://static.vecteezy.com/system/resources/previews/002/318/271/original/user-profile-icon-free-vector.jpg"
							onClick={() => viewer.openPhoto(String(src))}
						/> 
						{src && (
							<ButtonGroup
								variant="outline"
								display="flex"
								alignItems="center"
								justifyContent="center"
								mt="5px"
							>
								<Button
									height="32px"
									width={{ sm: '90%', md: '90%', lg: '70%' }}
									onClick={() =>
										handleChangeProfilePicture(null)
									}
								>
									<Heading type="h4">Remover Imagem</Heading>
								</Button>
							</ButtonGroup>
						)}
					</Flex>
				</Flex>
			</Accordion>
		</Box>
	)
}
