import { useQuery } from '@tanstack/react-query'
import api from 'services/api'

interface GetOneRecordProps {
	recordId: string
	recordType: string
}

interface UseGetRecordsProps extends GetOneRecordProps {
	onSuccess?: (data: any) => void
	onError?: () => void
}

const getOneRecord = async ({ recordType, recordId }: GetOneRecordProps) => {
	const { data } = await api(`/${recordType}/${recordId}`)
	return data
}

export const useGetOneRecord = ({
	// onSuccess,
	// onError,
	...params
}: UseGetRecordsProps) =>
	useQuery({
		queryKey: ['useGetOneRecord', params.recordType, params.recordId],
		queryFn: () => getOneRecord(params),
		// onSuccess,
		// onError,
		enabled: !!params.recordId,
		refetchOnWindowFocus: false,
	})

export type RecordQuery = ReturnType<typeof useGetOneRecord>
