/* eslint-disable no-underscore-dangle */
import { useQuery } from '@tanstack/react-query'
import * as yup from 'yup'

import api from 'services/api'

export function useProfessionals() {
	return useQuery({
		queryKey: ['professionals'],
		queryFn: async () => {
			const response = await api.get('/dentists/?offset=0&limit=100')

			const schema = yup.object({
				rows: yup.array(
					yup.object({
						_id: yup.string(),
						name: yup.string(),
						user: yup.object({
							_id: yup.string(),
							picture: yup.string().nullable(),
						}),
					})
				),
			})

			const data = await schema.validate(response.data, {
				stripUnknown: true,
			})

			const promises = data.rows.map(async (p) => ({
				id: p._id,
				name: p.name,
				imageSrc: p.user.picture ?? '',
				userId: p.user._id,
			}))
			const result = await Promise.all(promises)
			return result
		},
		staleTime: 60 * 5 * 1000,
		refetchOnWindowFocus: false,
		placeholderData: undefined,
	})
}

export type ProfessionalsQuery = ReturnType<typeof useProfessionals>
