import React from 'react'
import { Box, Select, Spinner } from '@chakra-ui/react'
import { useRecordsManagement } from 'modules/records/hooks/useRecordsManagement'
import { useIsFetching } from '@tanstack/react-query'

export const SelectFilterByTypeInfo = () => {
	const { filterByTypeInfo, setFilterByTypeInfo } = useRecordsManagement()

	const isFetching = useIsFetching({ queryKey: ['useGetAllRecords'] })

	return (
		<>
			<Select
				placeholder="Alterar buscar"
				size="lg"
				width={{ sm: '208px', md: '190px', lg: '190px' }}
				onChange={(e) => setFilterByTypeInfo(e.target.value)}
				value={filterByTypeInfo}
			>
				<option value="name">Nome</option>
				<option value="doc">CPF/CNPJ</option>
				<option value="phone">Telefone</option>
			</Select>
			<Box
				px={3}
				alignSelf="center"
				height={3}
				width={{ base: 4, md: 5 }}
			>
				{!!isFetching && <Spinner size={{ base: 'xs', md: 'sm' }} />}
			</Box>
		</>
	)
}
