import { Modal } from 'app/components/Modal'
import { FormProvider, useForm } from 'react-hook-form'
import { useNavigate, useParams } from 'react-router-dom'
import { DocumentForm } from './components/DocumentForm'

export function DocumentNew() {
	document.title = 'ConsultData | Criar Documento'

	const form = useForm()

	const navigate = useNavigate()
	const params = useParams()

	const renderTitle = () => {
		switch (params.type) {
			case 'anamnesis':
				return 'Anamnese'
			case 'radiograph':
				return 'Radiografia'
			default:
				return 'Documento'
		}
	}

	return (
		<FormProvider {...form}>
			<Modal
				size="lg"
				isOpen
				onClose={() => {
					if (window.confirm('Gostaria mesmo de sair?')) {
						navigate('..', { replace: true })
					}
				}}
				title={renderTitle()}
				handleForm={() => undefined}
				body={<DocumentForm />}
				footer={<></>}
				dividers={false}
			/>
		</FormProvider>
	)
}
