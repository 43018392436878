import { Tbody, Tr, Td, Text, useColorModeValue } from '@chakra-ui/react'
import dayjs from 'dayjs'
import { useNavigate } from 'react-router-dom'

interface TableProps {
	// procedure: {
	customer?: {
		name: string
		_id: string
	}
	dentist?: {
		name: string
	}
	date: string
	name: string
	// }
}

export function TBodyMobile({ customer, dentist, date, name }: TableProps) {
	const navigate = useNavigate()
	const border = useColorModeValue('#C6C6C6', '#777777')

	return (
		<Tbody width="100%" maxHeight="136px">
			<Tr
				display="grid"
				gridTemplateRows="repeat(4, 34px)"
				fontSize="14px"
				maxHeight="136px"
			>
				<Td
					paddingBottom="10px"
					paddingX="10px"
					paddingY="0"
					display="flex"
					flexDirection="column"
					justifyContent="center"
				>
					{dayjs(date).format('DD/MM/YYYY')}
				</Td>
				<Td
					paddingX="10px"
					borderTop={`1px solid ${border}`}
					paddingY="0"
					display="flex"
					flexDirection="column"
					justifyContent="center"
				>
					{name}
				</Td>
				<Td
					paddingX="10px"
					borderTop={`1px solid ${border}`}
					paddingY="0"
					display="flex"
					flexDirection="column"
					justifyContent="center"
				>
					<Text
						color="#1188EE"
						textDecoration="underline"
						fontSize="14px"
						fontWeight="500"
						onClick={() => navigate(`/historico/${customer?._id}`)}
					>
						{customer.name}
					</Text>
				</Td>
				<Td
					paddingX="10px"
					borderTop={`1px solid ${border}`}
					paddingY="0"
					display="flex"
					flexDirection="column"
					justifyContent="center"
				>
					{dentist?.name}
				</Td>
			</Tr>
		</Tbody>
	)
}
