import { useMutation, useQueryClient } from '@tanstack/react-query'
import api from 'services/api'

export const useCreateDocument = () => {
	const client = useQueryClient()
	return useMutation({
		async mutationFn(payload: FormData) {
			const response = await api.post(`/documents`, payload, {
				headers: { 'Content-Type': 'multipart/form-data' },
			})
			return response.data
		},
		onSuccess: (_, payload) => {
			client.invalidateQueries({
				queryKey: [
					'documents',
					{
						type: payload.get('type'),
						customer: payload.get('customer'),
					},
				],
			})
		},
	})
}
