import { useEffect } from 'react'
import { Box, Flex, Stack } from '@chakra-ui/react'
import { Accordion } from 'app/components/Accordion'
import { useFormContext } from 'react-hook-form'
import { InputTextController } from 'app/components/InputTextController'
import { MaskedInputController } from 'app/components/MaskedInputController'
import { SelectController } from 'app/components/SelectController'
import { phoneMask } from 'app/utils/masks'
import { itemsGenre, itemsMaritalStatus } from './itemsToSelect'

export const RegistrationInformationOfResponsible = () => {
	const {
		control,
		formState: { errors },
		watch,
		setValue,
	} = useFormContext()

	const isresponsibleTypeOfPerson = watch(
		'legalRepresentative.config.responsibleTypeOfPerson'
	)

	const title = `Informações do Responsável`
	const titleDocumentLabel =
		isresponsibleTypeOfPerson === 'PF' ? 'CPF' : 'CNPJ'

	const nameDocumentInputField =
		isresponsibleTypeOfPerson === 'PF'
			? 'legalRepresentative.cpf'
			: 'legalRepresentative.cnpj'

	const documentMask =
		isresponsibleTypeOfPerson === 'PF'
			? '999.999.999-99'
			: '99.999.999/9999-99'

	useEffect(() => {
		if (isresponsibleTypeOfPerson === 'PJ') {
			setValue('legalRepresentative.cpf', '')
		}
		if (isresponsibleTypeOfPerson === 'PF') {
			setValue('legalRepresentative.cnpj', '')
		}
	}, [isresponsibleTypeOfPerson])

	return (
		<Box mt="24px">
			<Accordion title={title} isOpenAccordion>
				<Flex direction={{ sm: 'column', md: 'row', lg: 'row' }}>
					<Stack
						spacing={5}
						direction={{ sm: 'column', md: 'row', lg: 'row' }}
					>
						<InputTextController
							title="Nome"
							name="legalRepresentative.name"
							control={control}
							hasError={errors?.legalRepresentative?.name}
							errorMessage={
								errors?.legalRepresentative?.name?.message
							}
						/>

						<MaskedInputController
							name={nameDocumentInputField}
							title={titleDocumentLabel}
							mask={documentMask}
							width="100%"
							control={control}
							hasError={
								errors?.legalRepresentative?.[
									titleDocumentLabel.toLowerCase()
								]
							}
							errorMessage={
								errors?.legalRepresentative?.[
									titleDocumentLabel.toLowerCase()
								]?.message
							}
							inputMode="numeric"
						/>

						<InputTextController
							title="Data de Nascimento"
							placeholder=""
							size="md"
							type="date"
							width="100%"
							name="legalRepresentative.birthday"
							control={control}
							hasError={errors?.legalRepresentative?.birthday}
						/>

						<SelectController
							items={itemsMaritalStatus}
							title="Estado Civil"
							width="100%"
							name="legalRepresentative.maritalStatus"
							control={control}
							hasError={
								errors?.legalRepresentative?.maritalStatus
							}
							placeholder="Selecione"
						/>

						<SelectController
							items={itemsGenre}
							title="Gênero"
							width="100%"
							name="legalRepresentative.gender"
							control={control}
							hasError={errors?.legalRepresentative?.gender}
							placeholder="Selecione"
						/>
					</Stack>
				</Flex>
				<Flex
					mt="16px"
					direction={{ sm: 'column', md: 'row', lg: 'row' }}
				>
					<Stack
						spacing={5}
						direction={{ sm: 'column', md: 'row', lg: 'row' }}
					>
						<InputTextController
							title="RG"
							name="legalRepresentative.rg"
							size="md"
							control={control}
							hasError={errors?.legalRepresentative?.rg}
							inputMode="numeric"
						/>
						<InputTextController
							title="Telefone"
							name="legalRepresentative.cellphoneNumber"
							size="md"
							onChange={phoneMask.onChange}
							control={control}
							hasError={
								errors?.legalRepresentative?.cellphoneNumber
							}
							errorMessage={
								errors?.legalRepresentative?.cellphoneNumber
									?.message
							}
							type="tel"
						/>

						<InputTextController
							title="Telefone 2"
							name="legalRepresentative.secondaryCellphoneNumber"
							size="md"
							onChange={phoneMask.onChange}
							control={control}
							hasError={
								errors?.legalRepresentative
									?.secondaryCellphoneNumber
							}
							errorMessage={
								errors?.legalRepresentative
									?.secondaryCellphoneNumber?.message
							}
							type="tel"
						/>

						<InputTextController
							title="E-mail"
							name="legalRepresentative.email"
							size="md"
							control={control}
							hasError={errors?.legalRepresentative?.email}
							errorMessage={
								errors?.legalRepresentative?.email?.message
							}
						/>

						<InputTextController
							title="Profissão"
							name="legalRepresentative.occupation"
							size="md"
							width="100%"
							control={control}
							hasError={errors?.legalRepresentative?.occupation}
						/>
					</Stack>
				</Flex>
			</Accordion>
		</Box>
	)
}
