interface IProfessional {
	id: string
	name: string
	userId: string
}

export class Professional implements IProfessional {
	id: string
	name: string
	userId: string

	constructor(object: IProfessional) {
		Object.assign(this, object)
	}

	static fromJson(json: Record<string, any>) {
		return new Professional({
			id: json._id,
			name: json.name,
			userId: json.user?._id,
		})
	}
}
