/* eslint-disable react/jsx-props-no-spreading */
import React from 'react'
import {
	Text as TextHeading,
	TextProps as ChakraTextProps,
} from '@chakra-ui/react'

export interface HeadingProps extends ChakraTextProps {
	type: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6'
}

export const Heading = ({ type, children, ...props }: HeadingProps) => {
	return (
		<TextHeading as={type} textStyle={type} {...props}>
			{children}
		</TextHeading>
	)
}
