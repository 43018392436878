import * as yup from 'yup'

const fieldRequiredMessage = 'Este campo é obrigátorio!'

export const editProcedureSchema = yup
	.object({
		name: yup.string().required(fieldRequiredMessage),
		date: yup.string().required(fieldRequiredMessage),
		dentist: yup.string().required(fieldRequiredMessage),
		observation: yup.string().nullable(),
	})
	.required()
