const weekDaysMap = [
	'sunday',
	'monday',
	'tuesday',
	'wednesday',
	'thursday',
	'friday',
	'saturday',
]

export function isDisabledDay(availableDays: string[], day: number) {
	return !availableDays.includes(weekDaysMap[day])
}
