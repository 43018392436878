/* eslint-disable no-underscore-dangle */
import dayjs from 'dayjs'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useParams, useSearchParams } from 'react-router-dom'
import api from 'services/api'

interface AnnotationForm {
	annotationId: string
	body: string
	start: string
	end: string
}

export function useUpdateAnnotation() {
	const { professionalId } = useParams()

	const client = useQueryClient()

	return useMutation({
        mutationKey: ['save_annotation'],

        mutationFn({ annotationId, body, start, end }: AnnotationForm) {
			return api.put(
				`/dentists/${professionalId}/annotations?annotationId=${annotationId}`,
				{
					body,
					start: dayjs(start).tz('Etc/Greenwich').toISOString(),
					end: dayjs(end).tz('Etc/Greenwich').toISOString(),
				}
			)
		},

        onSuccess() {
			client.invalidateQueries({
				queryKey: ['professional_schedule'],
			})
		}
    });
}
