import React from 'react'
import {
	Flex,
	Grid,
	GridItem,
	Input,
	InputGroup,
	InputLeftElement,
	Icon,
	Stack,
	Button,
	useToast,
	useColorModeValue,
	Box,
} from '@chakra-ui/react'
import { FiUser, FiLock } from 'react-icons/fi'
import {
	isAuthenticated,
	setAuthTokenInStorage,
	setUserInfoInStorage,
} from 'services/api/auth'
import { Navigate, useNavigate } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import {
	useUserAuthenticationQuery,
	credentialsType,
} from './queries/useUserAuthenticationQuery'
import { useQueryClient } from '@tanstack/react-query'

const schema = yup
	.object({
		access: yup.string().required(),
		password: yup.string().required(),
	})
	.required()

export default function Auth() {
	const {
		register,
		handleSubmit,
		formState: { isValid },
	} = useForm<credentialsType>({
		mode: 'onChange',
		defaultValues: {
			access: '',
			password: '',
		},
		resolver: yupResolver(schema),
	})

	const isDisableAccessButton = !isValid

	const navigate = useNavigate()
	const toast = useToast()

	const client = useQueryClient()
	const { mutate, isPending: isLoadingUserAuthentication } =
		useUserAuthenticationQuery({
			onSuccess: ({ data }) => {
				setAuthTokenInStorage(data.token)
				setUserInfoInStorage({
					id: data.id,
					type: data.type,
					roles: data.roles,
					permissions: data.permissions,
					activeClinic: data.activeClinic,
				})

				client.invalidateQueries({ queryKey: ['user'] })

				toast({
					title: 'Sucesso!',
					description: 'Login realizado com sucesso!',
					status: 'success',
					duration: 3000,
					isClosable: true,
					position: 'top-right',
				})

				navigate('/agendamentos')
			},
			onError: () => {
				toast({
					title: 'Ops...',
					description: 'Aconteceu um erro inesperado!',
					status: 'error',
					duration: 9000,
					isClosable: false,
					position: 'top-right',
				})
			},
		})

	const onSubmit = (data: credentialsType) => mutate(data)

	if (isAuthenticated()) {
		return <Navigate to="/registros" />
	}
	const bg = useColorModeValue('primary.500', '#182533')
	const bg2 = useColorModeValue('#F6F6F6', '#223344')

	return (
		<Grid
			templateColumns={{
				sm: '1fr',
				md: 'repeat(2,1fr)',
				lg: 'repeat(2,1fr)',
			}}
		>
			<GridItem
				h="100vh"
				bg={bg}
				px={2}
				placeItems="center"
				display={{ sm: 'none', md: 'grid', lg: 'grid' }}
			>
				<img
					src="/ConsultLightTypo.png"
					alt="ConsultData"
					width={192}
					height={192}
					style={{ maxWidth: '100%' }}
				/>
			</GridItem>
			<GridItem h="100vh" bg={bg2}>
				<Flex
					height="100%"
					flexDir="column"
					gap={8}
					justifyContent="center"
					alignItems="center"
				>
					<Box
						display={{ sm: 'grid', md: 'none' }}
						placeContent="center"
					>
						<img
							src="ConsultTypo.png"
							alt="ConsultData"
							width={144}
							height={144}
							style={{ maxWidth: '100%' }}
						/>
					</Box>

					<form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
						<Stack spacing={6}>
							<InputGroup size="lg">
								<InputLeftElement
									pointerEvents="none"
									children={<Icon as={FiUser} />}
								/>
								<Input
									type="text"
									placeholder="Usuário"
									autoFocus
									{...register('access')}
								/>
							</InputGroup>

							<InputGroup size="lg">
								<InputLeftElement
									pointerEvents="none"
									children={<Icon as={FiLock} />}
								/>
								<Input
									type="password"
									placeholder="Senha"
									{...register('password')}
								/>
							</InputGroup>

							<Button
								isLoading={isLoadingUserAuthentication}
								disabled={isDisableAccessButton}
								colorScheme="primary"
								opacity={isDisableAccessButton ? '0.3' : 1}
								type="submit"
								_hover={{}}
							>
								Acessar
							</Button>
						</Stack>
					</form>
				</Flex>
			</GridItem>
		</Grid>
	)
}

// TODO - REMOVER ONCLICK DO BOTÃO SUBMIT ao estar desabilitado.
