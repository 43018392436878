/* eslint-disable no-param-reassign */
import axios from 'axios'
import {
	getAuthTokenInStorage,
	removeAuthTokenInStorage,
	removeUserInfoInStorage,
} from './auth'

// eslint-disable-next-line no-undef
const URL = process.env.REACT_APP_BASE_URL

const api = axios.create({
	headers: { 'ngrok-skip-browser-warning': true },
	baseURL: URL,
})

api.interceptors.request.use(async (config) => {
	const token = getAuthTokenInStorage()
	if (token && config.headers) {
		config.headers.Authorization = `Bearer ${token}`
	}
	return config
})

api.interceptors.response.use(undefined, async (error) => {
	if (error.response?.status === 401) {
		removeAuthTokenInStorage()
		removeUserInfoInStorage()
		window.location.href = '/'
	}
	if (axios.isAxiosError(error) && error.response?.data) {
		throw error.response.data
	}
	throw error
})

export default api
