import React, { useRef, useId } from 'react'
import { ButtonGroup, Button } from '@chakra-ui/react'
import { useBreakpointsMediaQuery } from 'app/hooks/useMediaQuery'
import { Heading } from '../Heading'

interface ImageUploadProps {
	onChange: (value: File) => void
}

const ALLOWED_TYPES = ['image/png', 'image/jpeg', 'image/jpg']

export const ImageUpload = ({ onChange }: ImageUploadProps) => {
	const { isDesktop } = useBreakpointsMediaQuery()

	const key = useId()

	const inputFile = useRef<HTMLInputElement | null>(null)

	const handleImageUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
		if (e?.target?.files) {
			const selected = e.target.files[0]

			if (selected && ALLOWED_TYPES.includes(selected.type)) {
				onChange(selected)
			}
		}
	}

	const openImageSelection = () => {
		if (inputFile?.current) {
			inputFile?.current.click()
		}
	}

	return (
		<>
			<ButtonGroup
				variant="outline"
				display="flex"
				alignItems="center"
				justifyContent="center"
				flex={1}
			>
				<Button onClick={openImageSelection} w="full">
					<Heading type="h4">
						{isDesktop ? 'Escolher arquivo' : 'Arquivo'}
					</Heading>
				</Button>
			</ButtonGroup>
			<input
				type="file"
				multiple
				ref={inputFile}
				style={{ display: 'none' }}
				onChange={handleImageUpload}
				key={`file-${key}`}
				accept={ALLOWED_TYPES.join(',')}
			/>
		</>
	)
}
