export function objectToFormData(
	obj: Record<string, any>,
	formData = new FormData(),
	parentKey = ''
) {
	Object.keys(obj).forEach((key) => {
		if (Object.prototype.hasOwnProperty.call(obj, key)) {
			const value = obj[key]

			const formattedKey = parentKey ? `${parentKey}[${key}]` : key

			if (typeof value === 'undefined') {
				formData.delete(formattedKey)
			} else if (typeof value === 'boolean') {
				formData.set(formattedKey, value ? '1' : '0')
			} else if (value === null) {
				formData.set(formattedKey, '')
			} else if (value instanceof File || value instanceof Blob) {
				formData.append(formattedKey, value)
			} else if (Array.isArray(value)) {
				value.forEach((item, index) => {
					const nestedKey = `${formattedKey}[${index}]`
					objectToFormData(item, formData, nestedKey)
				})
			} else if (typeof value === 'object' && value !== null) {
				objectToFormData(value, formData, formattedKey)
			} else {
				formData.append(formattedKey, value)
			}
		}
	})

	return formData
}
