import { Procedure } from 'data/Procedure'
import { Customer } from './Customer'
import { Professional } from './Professional'

interface ITreatmentProcedure {
	procedure: Procedure
	quantity: number
	teeth: string
	total: number
}

interface ITreatment {
	id: string
	customer?: Customer
	professional?: Professional
	startDate?: string
	description?: string
	items?: ITreatmentProcedure[]
	discount?: number
	status: string
	completionDate?: string
	clinic?: string
}

export class Treatment implements ITreatment {
  static modelName = 'Treatment'

	id: string
	customer?: Customer
	professional?: Professional
	startDate?: string
	completionDate?: string
	description?: string
	items?: ITreatmentProcedure[]
	discount?: number
	status: string
	clinic?: string

	constructor(object: ITreatment) {
		Object.assign(this, object)
	}

	static fromJson(json: Record<string, any>) {
		return new Treatment({
			id: json._id,
			customer: json.customer && Customer.fromJson(json.customer),
			professional: json.dentist && Professional.fromJson(json.dentist),
			startDate: json.startDate,
			completionDate: json.completionDate,
			description: json.description,
			items: json.items?.map((it: any) => ({
				...it,
				procedure: Procedure.fromJson(it.procedure),
			})),
			discount: json.discount,
			status: json.status,
			clinic: json.clinic,
		})
	}
}
