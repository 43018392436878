import { DeleteIcon } from '@chakra-ui/icons'
import { IconButton, useToast } from '@chakra-ui/react'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { ConfirmAlert } from 'app/components/ConfirmAlert'
import { Payment } from 'data/Payment'

import api from 'services/api'

export function DeletePayment({ payment }: { payment: Payment }) {
	const queryClient = useQueryClient()
	const mutation = useMutation({
		mutationFn: () => api.delete(`/payments/${payment.id}`),
		onSuccess: () =>
			queryClient.invalidateQueries({ queryKey: ['payments'] }),
	})
	const toast = useToast()
	const onDelete = () => {
		const deletePromise = mutation.mutateAsync()
		toast.promise(deletePromise, {
			success: { title: 'Deletado!', duration: 2000, isClosable: true },
			error: { title: 'Erro ao deletar', duration: 5000 },
			loading: { title: 'Deletando...' },
		})
	}

	return (
		<ConfirmAlert
			title="Quer mesmo deletar esse pagamento?"
			body="Essa ação é irreversível"
			trigger={
				<IconButton
					bg="#f9f9f9"
					_dark={{
						bg: '#223344',
					}}
					aria-label="Deletar Pagamento"
					icon={<DeleteIcon />}
				/>
			}
			onConfirm={onDelete}
		/>
	)
}
