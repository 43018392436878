import { extendTheme, ThemeConfig } from '@chakra-ui/react'
import { mode } from '@chakra-ui/theme-tools'
import { textStyles } from './texts/textStyles'
import { Modal } from './components/Modal'

const config: ThemeConfig = {}
// // min-width = a partir
// a partir de 320px => celular
// a partir de 768px => tablet
// a partir de 960px => desktop
// a partir de 1200px => extraLargeDesktop

const breakpoints = {
	sm: '0px',
	md: '768px',
	lg: '960px',
}

const colors = {
	primary: {
		50: '#EBF7FB',
		100: '#CEEAF5',
		200: '#AEDCEF',
		300: '#8DCEE9',
		400: '#74C4E4',
		500: '#5CB9DF',
		600: '#54B2DB',
		700: '#4AAAD7',
		800: '#41A2D2',
		900: '#3093CA',
	},
}

const theme = extendTheme({
	styles: {
		global: (props: any) => ({
			'html body, input': {
				fontFamily: 'Inter, sans-serif',
				color: mode('#223344', '#f9f9f9')(props),
				backgroundColor: mode('#f9f9f9', '#223344')(props),
				fontSize: '16px',
			},
			'table[data-border] th, table[data-border] td': {
				border: '1px solid #e0e0e0 !important',
			},
		}),
	},
	textStyles,
	config,
	breakpoints,
	colors,
	components: {
		Modal,
	},
})

export default theme
