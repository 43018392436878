import { Modal } from 'app/components/Modal'
import { useBreakpointsMediaQuery } from 'app/hooks/useMediaQuery'
import React, { useEffect } from 'react'
import { useFormContext } from 'react-hook-form'
import type { Schedule } from 'modules/schedule/queries/useProfessionalSchedule'
import EditScheduleBodyModal from './EditScheduleBodyModal'
import EditScheduleFooterModal from './EditScheduleFooterModal'

export interface EditScheduleModalProps {
	isOpen: boolean
	onClose: () => void
	onSubmit: (data: any) => void
	event: Schedule['events'][number]
}

const EditScheduleModal: React.FC<EditScheduleModalProps> = ({
	isOpen,
	onClose,
	onSubmit,
	event,
}) => {
	const { isDesktop } = useBreakpointsMediaQuery()
	const SIZE_MODAL = isDesktop ? 'md' : 'full'

	const { handleSubmit, reset } = useFormContext()
	useEffect(() => {
		// reset form values on close modal
		if (!isOpen) {
			reset()
		}
	}, [isOpen, reset])

	return (
		<Modal
			isOpen={isOpen}
			onClose={onClose}
			title="Consulta"
			body={
				<form
					id="edit-schedule-modal"
					onSubmit={handleSubmit(onSubmit)}
					autoComplete="off"
				>
					<EditScheduleBodyModal event={event} />
				</form>
			}
			footer={<EditScheduleFooterModal event={event} onClose={onClose} />}
			handleForm={() => undefined}
			dividers
			size={SIZE_MODAL}
		/>
	)
}

export default EditScheduleModal
