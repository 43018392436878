interface IProcedure {
	id: string
	name: string
	value: number
	costs: Record<string, number>
}

export class Procedure implements IProcedure {
	static modelName = 'ProcedureType'

	id: string
	name: string
	value: number
	costs: Record<string, number>

	constructor(object: IProcedure) {
		Object.assign(this, object)
	}

	static fromJson(json: Record<string, any>) {
		return new Procedure({
			id: json._id,
			name: json.name,
			value: json.value,
			costs: json.costs,
		})
	}
}
