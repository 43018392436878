/* eslint-disable react/jsx-props-no-spreading */
import { useId } from 'react'
import { Textarea, TextareaProps } from '@chakra-ui/react'
import { Control, Controller } from 'react-hook-form'

interface TextAreaProps extends TextareaProps {
	title: string
	margin?: string | object
	control: Control
	name: string
	hasError: boolean
	errorMessage?: string
	disabled?: boolean
}

export const TextAreaController = (props: TextAreaProps) => {
	const { control, name, ...rest } = props

	const id = useId()

	return (
		<Controller
			name={name}
			control={control}
			render={({ field }) => <Textarea {...rest} {...field} key={id} />}
		/>
	)
}

TextAreaController.defaultProps = {
	margin: 0,
	errorMessage: 'Este campo é obrigatório',
	disabled: false,
}
