/* eslint-disable @typescript-eslint/no-explicit-any */
import { useQuery } from '@tanstack/react-query'
import { AxiosResponse } from 'axios'
import api from 'services/api'

interface getCustomerProps {
	params: {
		customerId: string
	}
}

interface useGetCustomerProps extends getCustomerProps {
	onSuccess?: (data: AxiosResponse) => void
	onError?: () => void
}

const getCustomer = async ({ params: { customerId } }: getCustomerProps) => {
	const { data } = await api.get(`/customers/${customerId}`)
	return data
}

export const useGetCustomer = ({
	// onSuccess,
	// onError,
	...params
}: useGetCustomerProps) =>
	useQuery({
		queryKey: ['useGetCustomer', params],
		queryFn: () => getCustomer(params),
		// onSuccess,
		// onError,
		refetchOnWindowFocus: false,
	})

// TODO - ADD TIPAGEM
