import React, { useDeferredValue, useEffect, useState } from 'react'
import { EditIcon, Search2Icon } from '@chakra-ui/icons'
import {
	Center,
	Flex,
	Grid,
	IconButton,
	Input,
	InputGroup,
	InputLeftElement,
	Spinner,
	Stack,
	Table,
	TableContainer,
	Tbody,
	Td,
	Text,
	Th,
	Thead,
} from '@chakra-ui/react'
import { Link, useSearchParams } from 'react-router-dom'
import dayjs from 'dayjs'

import { TablePagination } from 'app/components/TablePagination'
import { PaymentModal } from './PaymentModal'
import { usePaymentsQuery } from '../queries/usePaymentsQuery'
import { InfoCard } from './InfoCard'
import { CreditCardIcon } from 'app/icons/CreditCardIcon'
import { DownloadIcon } from 'app/icons/DownloadIcon'
import { Payment } from 'data/Payment'
import { Can } from 'ability'
import { PAYMENT_METHODS } from '..'
import { DeletePayment } from './DeletePayment'

export function FinancialIndexRoute() {
	const [localSearchState, setLocalSearchState] = useState(
		() => new URLSearchParams(window.location.search)
	)
	const searchParams = useDeferredValue(localSearchState)
	useEffect(() => {
		history.replaceState(
			null,
			'',
			[window.location.pathname, searchParams.toString()]
				.filter(Boolean)
				.join('?')
		)
	}, [searchParams])
	const search = searchParams.get('search') ?? ''
	const startDate = searchParams.get('startDate') ?? ''
	const endDate = searchParams.get('endDate') ?? ''

	const [globalSearchParams, setGlobalSearchParams] = useSearchParams()
	const status = globalSearchParams.has('paid') ? 'paid' : 'pending'
	const page = Math.abs(Number(globalSearchParams.get('page')) || 1)
	const onPageChange = (nextPage: number) => {
		setGlobalSearchParams((init) => {
			init.set('page', String(nextPage))
			return init
		})
	}

	const hasSearch = Boolean(search || startDate || endDate)

	const query = usePaymentsQuery(
		{ page, search, startDate, endDate, status },
		{ enabled: hasSearch }
	)

	const payments = query.data?.data
	const paginationInfo = query.data?.meta
	const totalBy = query.data?.totalBy

	const isEmpty = payments?.length <= 0
	const isLastPage =
		paginationInfo && page * paginationInfo.perPage >= paginationInfo.total

	return (
		<>
			<Grid
				gap="6"
				templateColumns={{ base: 'auto', md: '572fr 274fr 274fr' }}
				templateRows={{ base: 'repeat(3,1fr)', md: 'auto' }}
				as="form"
				onChange={(e) => {
					const form: unknown = e.currentTarget
					const formData = new FormData(form as HTMLFormElement)

					const entries = Array.from(formData)
					entries.forEach(
						([k, v]) =>
							typeof v === 'string' && localSearchState.set(k, v)
					)

					setLocalSearchState(new URLSearchParams(localSearchState))
				}}
				onSubmit={(e) => e.preventDefault()}
			>
				<InputGroup flex={1} size="sm" maxW="543px">
					<InputLeftElement>
						<Search2Icon />
					</InputLeftElement>
					<Input
						name="search"
						defaultValue={localSearchState.get('search')}
						bg="white"
						_dark={{ bg: '#182533' }}
						placeholder="Busque por paciente"
					/>
				</InputGroup>

				<InputGroup
					size="sm"
					display="flex"
					alignItems="center"
					gap="3"
				>
					<Text
						fontSize="sm"
						fontWeight="600"
						whiteSpace="nowrap"
						textTransform="uppercase"
					>
						Data Início
					</Text>
					<Input
						size="sm"
						name="startDate"
						defaultValue={searchParams.get('startDate')}
						bg="white"
						_dark={{ bg: '#182533' }}
						type="date"
					/>
				</InputGroup>

				<InputGroup
					size="sm"
					display="flex"
					alignItems="center"
					gap="3"
				>
					<Text
						fontSize="sm"
						fontWeight="600"
						whiteSpace="nowrap"
						textTransform="uppercase"
					>
						Data Fim
					</Text>
					<Input
						size="sm"
						name="endDate"
						defaultValue={searchParams.get('endDate')}
						bg="white"
						_dark={{ bg: '#182533' }}
						type="date"
					/>
				</InputGroup>
			</Grid>

			<Flex
				gap="6"
				justify="space-between"
				px="4"
				display={{ base: 'none', xl: 'flex' }}
			>
				<InfoCard
					title="Valor Recebido"
					icon={<CreditCardIcon size={20} />}
					content={
						query.data?.totalPaidValue.toLocaleString([], {
							style: 'currency',
							currency: 'BRL',
						}) ?? ''
					}
					color="#0C6"
				/>
				<InfoCard
					title="Pendentes"
					icon={<PendingIcon />}
					content={
						query.data
							? `${query.data.totalPendingInstallments} parcelas`
							: ''
					}
					color="#38C"
				/>
				<InfoCard
					title="Valor a Receber"
					icon={<CalendarDaysIcon />}
					content={
						query.data?.totalPendingValue.toLocaleString([], {
							style: 'currency',
							currency: 'BRL',
						}) ?? ''
					}
					color="#5BD"
				/>
				<InfoCard
					title="Atrasados"
					icon={<ClockIcon />}
					content={
						query.data
							? `${query.data.totalLatePayments} pagamentos`
							: ''
					}
					color="#F93"
				/>
				<InfoCard
					title="Valores Atrasados"
					icon={<AlertIcon />}
					content={
						query.data?.totalLatePaymentsValue.toLocaleString([], {
							style: 'currency',
							currency: 'BRL',
						}) ?? ''
					}
					color="#E55"
				/>
			</Flex>

			{hasSearch ? (
				<>
					<TableContainer
						bg="white"
						_dark={{ bg: '#182533' }}
						p="4"
						rounded="12"
						h="100%"
						mb="6"
						position="relative"
					>
						<Table variant="striped">
							<Thead>
								<tr>
									<Th fontSize="sm">Paciente</Th>
									<Th fontSize="sm">Consulta/Tratamento</Th>
									<Th fontSize="sm">Valor Final</Th>
									<Th fontSize="sm">Tipo</Th>
									<Th fontSize="sm">Vencimento</Th>
									<Th fontSize="sm">Multa</Th>
									<Th fontSize="sm">Status</Th>
									<Th fontSize="sm">Comprovante</Th>
								</tr>
							</Thead>
							<Tbody>
								{payments &&
									!isEmpty &&
									payments.map((it) => {
										return (
											<PaymentsTableRow
												key={it.id}
												payment={it}
											/>
										)
									})}

								{(query.isPlaceholderData ||
									query.isLoading) && (
									<tr style={{ display: 'block' }}>
										<td>
											<Center
												position="absolute"
												h="100%"
												top="50%"
												left="50%"
												transform="translate(-50%,-50%)"
											>
												<Spinner />
											</Center>
										</td>
									</tr>
								)}

								{isEmpty && (
									<tr style={{ display: 'block' }}>
										<td>
											<Center
												flexDirection="column"
												position="absolute"
												h="100%"
												top="50%"
												left="50%"
												transform="translate(-50%,-50%)"
											>
												<Text>
													Nenhum pagamento salvo ainda
												</Text>
												<Text>
													Clique no botão{' '}
													{'"+ Pagamento"'} para criar
													um novo
												</Text>
											</Center>
										</td>
									</tr>
								)}
								{query.isLoadingError && (
									<tr style={{ display: 'block' }}>
										<td>
											<Center
												flexDirection="column"
												position="absolute"
												h="100%"
												top="50%"
												left="50%"
												transform="translate(-50%,-50%)"
											>
												<Text>
													Algo deu errado ao carregar
													esses dados...
												</Text>
												<Text>
													{String(query.error)}
												</Text>
											</Center>
										</td>
									</tr>
								)}
							</Tbody>
						</Table>
					</TableContainer>

					{isLastPage && totalBy && (
						<Center>
							<Stack gap="1">
								{Object.entries(totalBy).map(([key, value]) => {
									function parseKey(_key: string) {
										if (_key === 'all') return 'Total'
										return (
											PAYMENT_METHODS.find(
												(it) => it.value === _key
											)?.name ?? _key
										)
									}

									return (
										<Text key={key} fontWeight="500">
											{parseKey(key)}:{' '}
											{value.toLocaleString([], {
												style: 'currency',
												currency: 'BRL',
											})}
										</Text>
									)
								})}
							</Stack>
						</Center>
					)}

					{paginationInfo && paginationInfo.total > 0 && (
						<Flex
							alignItems="center"
							justify="end"
							flexDirection={{ base: 'column', md: 'row' }}
							columnGap="5"
							rowGap="3"
						>
							<TablePagination
								total={paginationInfo.total}
								perPage={paginationInfo.perPage}
								page={page}
								onPageChange={onPageChange}
							/>

							<Text
								fontSize="xs"
								fontWeight="medium"
								textTransform="uppercase"
							>
								{paginationInfo.total} registros
							</Text>
						</Flex>
					)}
				</>
			) : (
				<Center flex={1}>
					<Text>
						Busque pelo nome do paciente ou pela data para obter
						resultados
					</Text>
				</Center>
			)}
		</>
	)
}

interface PaymentsTableRowProps {
	payment: Payment
}

const PaymentsTableRow = React.memo(function PaymentsTableRow({
	payment: it,
}: PaymentsTableRowProps) {
	return (
		<tr key={it.id}>
			<Td>
				<Text
					_hover={{
						textDecoration: 'underline',
					}}
					as={Link}
					to={`/financeiro/pacientes/${it.customer?.id}`}
				>
					{it.customer?.name}
				</Text>
			</Td>
			<Td>{it.treatment ? 'Tratamento' : 'Consulta'}</Td>
			<Td>
				{it.value?.toLocaleString([], {
					style: 'currency',
					currency: 'BRL',
				})}
			</Td>
			<Td>
				{PAYMENT_METHODS.find((m) => m.value === it.paymentMethod)
					?.name ?? '-'}
			</Td>
			<Td>
				{it.dueDate
					? dayjs(it.dueDate).utc().format('DD/MM/YYYY')
					: '-'}
			</Td>
			<Td>
				{(it.fine || 0)?.toLocaleString([], {
					style: 'currency',
					currency: 'BRL',
				})}
			</Td>
			<Td>
				{it.status === 'paid' ? (
					<Text textTransform="uppercase" color="#00CC66">
						Pago
					</Text>
				) : (
					<Can I="update" this={it} passThrough>
						{(allowed) =>
							allowed ? (
								<PaymentModal
									payment={it}
									trigger={
										<Text
											color="#EE4444"
											textTransform="uppercase"
											_hover={{
												textDecoration: 'underline',
												cursor: 'pointer',
											}}
										>
											Pendente
										</Text>
									}
								/>
							) : (
								<Text color="#EE4444" textTransform="uppercase">
									Pendente
								</Text>
							)
						}
					</Can>
				)}
			</Td>

			<Td>
				<Flex gap="3" justifyContent="end">
					{it.file?.url && (
						<IconButton
							display="flex"
							variant="unstyled"
							color="#0C6"
							bg="#f9f9f9"
							_dark={{
								bg: '#223344',
							}}
							aria-label="Baixar recibo"
							icon={<DownloadIcon size={20} />}
							as="a"
							download={it.file.url
								.split('/')
								.at(-1)
								.split('?')
								.at(0)}
							href={it.file.url}
							target="_blank"
						/>
					)}
					<Can I="update" this={it}>
						<PaymentModal
							payment={it}
							trigger={
								<IconButton
									colorScheme="primary"
									variant="unstyled"
									bg="#f9f9f9"
									_dark={{ bg: '#223344' }}
									aria-label="Editar pagamento"
									icon={<EditIcon />}
								/>
							}
						/>
					</Can>
					<Can I="delete" this={it}>
						<DeletePayment payment={it} />
					</Can>
				</Flex>
			</Td>
		</tr>
	)
})

// Info Card Icons

function PendingIcon() {
	return (
		<svg
			width="21"
			height="20"
			viewBox="0 0 21 20"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M16.75 8.75H18C18.6904 8.75 19.25 9.30964 19.25 10C19.25 10.6904 18.6904 11.25 18 11.25H16.75C16.0596 11.25 15.5 10.6904 15.5 10C15.5 9.30964 16.0596 8.75 16.75 8.75ZM13.8839 4.89277L14.7678 4.00888C15.2559 3.52073 16.0474 3.52073 16.5355 4.00888C17.0237 4.49704 17.0237 5.2885 16.5355 5.77665L15.6517 6.66053C15.1635 7.14869 14.372 7.14869 13.8839 6.66053C13.3957 6.17238 13.3957 5.38092 13.8839 4.89277ZM13.8839 15.1517C13.3957 14.6635 13.3957 13.872 13.8839 13.3839C14.372 12.8957 15.1635 12.8957 15.6517 13.3839L16.5355 14.2678C17.0237 14.7559 17.0237 15.5474 16.5355 16.0355C16.0474 16.5237 15.2559 16.5237 14.7678 16.0355L13.8839 15.1517ZM3 8.75H4.25C4.94036 8.75 5.5 9.30964 5.5 10C5.5 10.6904 4.94036 11.25 4.25 11.25H3C2.30964 11.25 1.75 10.6904 1.75 10C1.75 9.30964 2.30964 8.75 3 8.75ZM10.5 1.25C11.1904 1.25 11.75 1.80964 11.75 2.5V3.75C11.75 4.44036 11.1904 5 10.5 5C9.80964 5 9.25 4.44036 9.25 3.75V2.5C9.25 1.80964 9.80964 1.25 10.5 1.25ZM10.5 15C11.1904 15 11.75 15.5596 11.75 16.25V17.5C11.75 18.1904 11.1904 18.75 10.5 18.75C9.80964 18.75 9.25 18.1904 9.25 17.5V16.25C9.25 15.5596 9.80964 15 10.5 15ZM4.50888 4.00888C4.99704 3.52073 5.7885 3.52073 6.27665 4.00888L7.16053 4.89277C7.64869 5.38092 7.64869 6.17238 7.16053 6.66053C6.67238 7.14869 5.88092 7.14869 5.39277 6.66053L4.50888 5.77665C4.02073 5.2885 4.02073 4.49704 4.50888 4.00888ZM4.50888 16.0355C4.02073 15.5474 4.02073 14.7559 4.50888 14.2678L5.39277 13.3839C5.88092 12.8957 6.67238 12.8957 7.16053 13.3839C7.64869 13.872 7.64869 14.6635 7.16053 15.1517L6.27665 16.0355C5.7885 16.5237 4.99704 16.5237 4.50888 16.0355Z"
				fill="currentColor"
			/>
		</svg>
	)
}

function CalendarDaysIcon() {
	return (
		<svg
			width="24"
			height="24"
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M8 1C8.55228 1 9 1.44772 9 2V3H15V2C15 1.44772 15.4477 1 16 1C16.5523 1 17 1.44772 17 2V3H19C20.6569 3 22 4.34315 22 6V20C22 21.6569 20.6569 23 19 23H5C3.34315 23 2 21.6569 2 20V6C2 4.34315 3.34315 3 5 3H7V2C7 1.44772 7.44772 1 8 1ZM7 5H5C4.44772 5 4 5.44772 4 6V9H20V6C20 5.44771 19.5523 5 19 5H17V6C17 6.55228 16.5523 7 16 7C15.4477 7 15 6.55228 15 6V5H9V6C9 6.55228 8.55228 7 8 7C7.44772 7 7 6.55228 7 6V5ZM20 11H4V20C4 20.5523 4.44771 21 5 21H19C19.5523 21 20 20.5523 20 20V11ZM7 14C7 13.4477 7.44772 13 8 13H8.01C8.56228 13 9.01 13.4477 9.01 14C9.01 14.5523 8.56228 15 8.01 15H8C7.44772 15 7 14.5523 7 14ZM11 14C11 13.4477 11.4477 13 12 13H12.01C12.5623 13 13.01 13.4477 13.01 14C13.01 14.5523 12.5623 15 12.01 15H12C11.4477 15 11 14.5523 11 14ZM15 14C15 13.4477 15.4477 13 16 13H16.01C16.5623 13 17.01 13.4477 17.01 14C17.01 14.5523 16.5623 15 16.01 15H16C15.4477 15 15 14.5523 15 14ZM7 18C7 17.4477 7.44772 17 8 17H8.01C8.56228 17 9.01 17.4477 9.01 18C9.01 18.5523 8.56228 19 8.01 19H8C7.44772 19 7 18.5523 7 18ZM11 18C11 17.4477 11.4477 17 12 17H12.01C12.5623 17 13.01 17.4477 13.01 18C13.01 18.5523 12.5623 19 12.01 19H12C11.4477 19 11 18.5523 11 18ZM15 18C15 17.4477 15.4477 17 16 17H16.01C16.5623 17 17.01 17.4477 17.01 18C17.01 18.5523 16.5623 19 16.01 19H16C15.4477 19 15 18.5523 15 18Z"
				fill="currentColor"
			/>
		</svg>
	)
}

function ClockIcon() {
	return (
		<svg
			width="21"
			height="20"
			viewBox="0 0 21 20"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M10.5 1C5.532 1 1.5 5.032 1.5 10C1.5 14.968 5.532 19 10.5 19C15.468 19 19.5 14.968 19.5 10C19.5 5.032 15.468 1 10.5 1ZM14.316 6.184C13.263 5.131 11.886 4.6 10.5 4.6V10L6.68401 13.816C8.79001 15.922 12.21 15.922 14.325 13.816C16.431 11.71 16.431 8.29 14.316 6.184ZM3.3 10C3.3 13.978 6.522 17.2 10.5 17.2C14.478 17.2 17.7 13.978 17.7 10C17.7 6.022 14.478 2.8 10.5 2.8C6.522 2.8 3.3 6.022 3.3 10Z"
				fill="currentColor"
			/>
		</svg>
	)
}

function AlertIcon() {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="20"
			height="20"
			viewBox="0 0 20 20"
			fill="none"
		>
			<path
				d="M10.8335 12.1928H9.16683V7.80686H10.8335V12.1928ZM10.8335 15.7016H9.16683V13.9472H10.8335V15.7016ZM0.833496 18.3332H19.1668L10.0002 1.6665L0.833496 18.3332Z"
				fill="currentColor"
			/>
		</svg>
	)
}
