import { Heading } from 'app/components/Heading'
import { RadioGroup, Stack, Radio, Box } from '@chakra-ui/react'

interface ItemsProps {
	[key: string]: string | boolean
}

interface RadiosProps {
	items: ItemsProps[]
	title: string
	onChange: (props: any) => void
	value: any
}

export const Radios = ({ items, title, onChange, value }: RadiosProps) => {
	return (
		<Box mt="16px">
			<Heading type="h4">{title}</Heading>
			<RadioGroup onChange={onChange} value={value} mt="8px">
				<Stack direction={{ sm: 'column', md: 'row', lg: 'row' }}>
					{items?.map((item: ItemsProps) => (
						<Radio
							key={`radio-${item.value}`}
							value={String(item.value)}
						>
							{item.label}
						</Radio>
					))}
				</Stack>
			</RadioGroup>
		</Box>
	)
}
