export const TOKEN_KEY = '@consult-data-token'
export const isAuthenticated = () =>
	Boolean(getAuthTokenInStorage() && getUserInfoInStorage())
export const getAuthTokenInStorage = () => sessionStorage.getItem(TOKEN_KEY)

export const setAuthTokenInStorage = (token: string) =>
	sessionStorage.setItem(TOKEN_KEY, token)

export const removeAuthTokenInStorage = () =>
	sessionStorage.removeItem(TOKEN_KEY)

export const USER_INFO_KEY = '@consult-data-user'
export const getUserInfoInStorage = () => {
	try {
		return JSON.parse(sessionStorage.getItem(USER_INFO_KEY))
	} catch (error) {
		return null
	}
}

export const setUserInfoInStorage = (user: {
	id: string
	type?: string
	roles?: { name: string }[]
	permissions?: Record<string, boolean | undefined>
	activeClinic?: string
}) => sessionStorage.setItem(USER_INFO_KEY, JSON.stringify(user))

export const removeUserInfoInStorage = () =>
	sessionStorage.removeItem(USER_INFO_KEY)
