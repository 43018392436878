import axios, { AxiosResponse } from 'axios'
import React, { useEffect, useState } from 'react'
import { toast } from 'react-hot-toast'

const useCep = (cepValue: string) => {
	const [cep, setCep] = useState<{
		logradouro: string
		complemento: string
		bairro: string
		localidade: string
		uf: string
	} | null>(null)

	const previousCepValue = React.useRef(undefined)

	const mounted = React.useRef(false)
	useEffect(() => {
		if (!mounted.current) {
			mounted.current = true
			return
		}
		const getCep = async () => {
			try {
				cepValue = cepValue.trim().replace('-', '')
				const { data } = await axios.get(
					`https://viacep.com.br/ws/${cepValue}/json`
				)
				setCep(data)
			} catch (error) {
				toast.error('FALHA AO CONSULTAR CEP!')
			}
		}
		if (previousCepValue.current !== undefined && cepValue) getCep()

		previousCepValue.current = cepValue
	}, [cepValue])

	return cep
}

export default useCep
