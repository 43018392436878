import api from 'services/api'
import { Treatment } from './Treatment'

export class TreatmentsRepository {
	async getTreatments({
		signal,
		...args
	}: GetTreatmentArgs): Promise<PaginatedTreatments> {
		const searchParams = new URLSearchParams(
			Object.entries(args).filter(([, v]) => Boolean(v))
		)
		const response = await api.get(`/treatments?${searchParams}`, {
			signal,
		})

		const result: PaginatedTreatments = {
			data: response.data?.data.map(Treatment.fromJson),
			meta: response.data?.meta,
		}

		return result
	}

	async getTreatment(treatmentId: string): Promise<Treatment> {
		const response = await api.get(`/treatments/${treatmentId}`)
		const treatment = Treatment.fromJson(response.data.treatment)
		return treatment
	}

	async createTreatment(data: CreateTreatmentArgs) {
		const response = await api.post('/treatments', data)
		return response.data
	}
	async updateTreatment(treatmentId: string, data: UpdateTreatmentArgs) {
		const response = await api.put(`/treatments/${treatmentId}`, data)
		return response.data
	}

	async approveTreatment(
		treatmentId: Treatment['id'],
		data: ApproveTreatmentArgs
	) {
		await api.patch(`/treatments/${treatmentId}/approve`, data)
	}
}

interface GetTreatmentArgs {
	page: number
	search?: string
	startDate?: string
	endDate?: string
	customer?: string

	signal?: AbortSignal
}

interface PaginatedTreatments {
	data: Treatment[]
	meta: { total: number; perPage: number }
}

export interface CreateTreatmentArgs {
	customer: string
	dentist: string
	startDate: string
	description: string
	items: { procedure: string; teeth: string; quantity: number }[]
	discount: number
}

export interface UpdateTreatmentArgs {
	customer: string
	dentist: string
	startDate: string
	description: string
	items: { procedure: string; teeth: string; quantity: number }[]
	discount: number
}

export interface ApproveTreatmentArgs {
	customer: string
	dentist: string
	startDate: string
	description: string
	items: { procedure: string; teeth: string; quantity: number }[]
	discount: number
	payment?: {
		downpayment?: { value: number; date: string; method: string }
		installments: { quantity: number; dueDate: string; method: string }
	}
}
