export class Product {
	static modelName = 'Product'

	_id: string
	name: string
	unit?: string
	stockQuantity?: number
	clinic?: string

	constructor(object: Product) {
		Object.assign(this, object)
	}

	static fromJson(json: any) {
		return new Product(json)
	}
}
