import { queryOptions, useQuery } from '@tanstack/react-query'
import { ProfessionalsRepository } from '../../../data/ProfessionalsRepository'

export const professionalsQueryOptions = queryOptions({
	queryKey: ['professionals'],
	queryFn() {
		return new ProfessionalsRepository().getProfessionals()
	},
})

export function useProfessionalsQuery() {
	return useQuery({
		queryKey: ['professionals'],
		queryFn() {
			return new ProfessionalsRepository().getProfessionals()
		},
	})
}
