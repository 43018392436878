import { useSearchParams, useParams } from 'react-router-dom'
import { Container, Flex, Td, Tr, useMediaQuery } from '@chakra-ui/react'
import dayjs from 'dayjs'
import React from 'react'

import { range } from 'app/utils/range'
import { capitalize } from 'app/utils/capitalize'
import { useProfessionalSchedule } from 'modules/schedule/queries/useProfessionalSchedule'
import { useSettingsDefault } from 'modules/schedule/queries/useSettingsDefault'
import { useSettingsByProfessional } from 'modules/schedule/queries/useSettingsByProfessional'
import { createSchedules } from 'modules/schedule/helpers/createSchedules'
import { ScheduleTableRoot as Table } from './ScheduleTableRoot'
import { ScheduleCell } from './ScheduleCell'
import { ScheduleTime } from './ScheduleTime'
import { isDisabledDay } from './isDisabledDay'

const WEEK_RANGE = range(1, 7)

export function ScheduleTableWeek() {
	const { data, isLoading } = useProfessionalSchedule('week')
	const { professionalId } = useParams()

	const [isLargeScreen] = useMediaQuery('(min-width: 48em)')

	const [searchParams, setSearchParams] = useSearchParams()

	const dateEndParam = searchParams.get('date')

	const dateEndHandle = dayjs(dateEndParam ?? Date.now())
		.set('day', 1)
		.add(7, 'day')

	const dateStartHandle = dayjs(dateEndHandle.add(-7, 'day'))

	const dateStart = dateStartHandle.toDate()

	const currentDateString = `${dateStartHandle.format(
		'DD/MM'
	)} - ${dateEndHandle.add(-1, 'day').format('DD/MM')}`

	const handlePreviousWeek = () => {
		const newSearchParams = new URLSearchParams(searchParams)
		const nextDate = dateStartHandle.add(-7, 'day').toJSON()
		newSearchParams.set('date', nextDate)
		setSearchParams(newSearchParams)
		sessionStorage.setItem('agenda:date', nextDate)
	}
	const handleNextWeek = () => {
		const newSearchParams = new URLSearchParams(searchParams)
		const nextDate = dateStartHandle.add(7, 'day').toJSON()
		newSearchParams.set('date', nextDate)
		setSearchParams(newSearchParams)
		sessionStorage.setItem('agenda:date', nextDate)
	}

	const columnsStyles = {
		first: { width: isLargeScreen ? 54 : 40 },
	}

	const settingsByProfessional = useSettingsByProfessional(professionalId)
	const settingsDefault = useSettingsDefault()
	const settings = settingsByProfessional.data ?? settingsDefault.data
	// const isLoadingSettings =
	// 	settingsByProfessional.isLoading || settingsDefault.isLoading

	const schedules = React.useMemo(
		() =>
			settings
				? createSchedules(
						dateStartHandle,
						settings.agenda.startTime,
						settings.agenda.endTime,
						settings.agenda.sessionDurationMinutes
				  )
				: [],
		[dateStartHandle, settings?.agenda]
	)
	return (
		<>
			<Table>
				<colgroup>
					<col style={columnsStyles.first} />
					<col style={{ width: '100%' }} />
					<col style={{ width: '100%' }} />
					<col style={{ width: '100%' }} />
					<col style={{ width: '100%' }} />
					<col style={{ width: '100%' }} />
					<col style={{ width: '100%' }} />
					<col style={{ width: '100%' }} />
				</colgroup>

				<Table.Head>
					<Tr>
						<Td as="th" colSpan={8} p="11px 16px">
							<Flex justify="center" align="center" gap="12px">
								<Table.Control
									current={currentDateString}
									onPrevious={handlePreviousWeek}
									onNext={handleNextWeek}
								/>
							</Flex>
						</Td>
					</Tr>
					<tr>
						<th style={columnsStyles.first}> </th>
						{WEEK_RANGE.map((_, i) => {
							const weekDay = dayjs(dateStart).add(i, 'day')
							const key = `week-th-${i}`
							return (
								<th
									key={key}
									style={{
										padding: '8px 4px',
										textAlign: 'center',
										fontSize: 12,
									}}
								>
									{capitalize(
										weekDay
											.format(
												isLargeScreen ? 'dddd' : 'ddd'
											)
											.split('-')[0]
									)}
									{isLargeScreen &&
										` ${weekDay.format('DD')}`}
								</th>
							)
						})}
					</tr>
				</Table.Head>
				{data && (
					<tbody style={{ position: 'sticky' }}>
						{data &&
							settings &&
							schedules.map((schedule) => {
								const scheduleString = schedule.format('HH:mm')
								const tz = 'America/Sao_Paulo'
								const format = 'HH:mm dd/MM/yyyy'

								return (
									<tr key={scheduleString}>
										<ScheduleTime
											scheduleString={scheduleString}
											style={columnsStyles.first}
										/>
										{WEEK_RANGE.map((day, i) => {
											const disabled =
												isDisabledDay(
													settings.agenda
														.availableDays,
													day
												) ||
												isLunchTime(
													schedule,
													settings.agenda
														.lunchStartTime,
													settings.agenda.lunchEndTime
												)

											const startDate = schedule.add(
												i,
												'day'
											)
											const endDate = startDate.add(
												settings.agenda
													.sessionDurationMinutes,
												'minute'
											)
											const events = data?.events.filter(
												(ev) => {
													const eventStart = dayjs(
														ev.start
													).tz(tz)
													return (
														eventStart.format(
															format
														) ===
														startDate.format(format)
													)
												}
											)

											return (
												<ScheduleCell
													key={`week-${day}-${schedule.hour()}-${schedule.minute()}`}
													events={events}
													settings={settings}
													disabled={disabled}
													allEvents={data.events}
													start={startDate.format(
														'YYYY-MM-DDTHH:mm:ss'
													)}
													end={endDate.format(
														'YYYY-MM-DDTHH:mm:ss'
													)}
													useCellWidth
												/>
											)
										})}
									</tr>
								)
							})}
					</tbody>
				)}
			</Table>
			{isLoading && !data && (
				<Container
					as="div"
					display="flex"
					alignItems="center"
					justifyContent="center"
					height="full"
				>
					Carregando...
				</Container>
			)}
		</>
	)
}

function isLunchTime(now: dayjs.Dayjs, startTime: string, endTime: string) {
	const [startHour, startMinute] = startTime.split(':').map(Number)
	const [endHour, endMinute] = endTime.split(':').map(Number)
	const start = now.hour(startHour).minute(startMinute)
	return (
		(now.isSame(start) || now.isAfter(start)) &&
		now.isBefore(now.hour(endHour).minute(endMinute))
	)
}
