/* eslint-disable @typescript-eslint/no-explicit-any */
import { objectToFormData } from 'app/utils/objectToFormData'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import api from 'services/api'

interface UpdateRecordProps {
	params: {
		recordId: string
		recordType: string
	}
	values: any
}

const updateRecord = async ({
	params: { recordId, recordType },
	values,
}: UpdateRecordProps) => {
	if (values.legalRepresentative && values.legalRepresentative.name) {
		values.legalRepresentatives = [values.legalRepresentative]
	}

	const formData = objectToFormData(values)
	const { data } = await api.put(`/${recordType}/${recordId}`, formData)
	return data
}

export const useUpdateRecord = (params: UpdateRecordProps['params']) => {
	const client = useQueryClient()

	return useMutation({
		mutationKey: ['update-record', params.recordType, params.recordId],
		mutationFn: (values) => updateRecord({ params, values }),
		onSuccess() {
			client.invalidateQueries({
				queryKey: ['useGetAllRecords'],
			})
		},
	})
}

export type UpdateRecordMutation = ReturnType<typeof useUpdateRecord>
