import { AxiosError, AxiosResponse } from 'axios'
import { useMutation } from '@tanstack/react-query'
import api from 'services/api'

export interface credentialsType {
	access: string
	password: string
}

interface useUserAuthenticationQueryProps {
	onSuccess: (data: AxiosResponse) => void
	onError: (error: AxiosError) => void
}

export const useUserAuthenticationQuery = ({
	onSuccess,
	onError,
}: useUserAuthenticationQueryProps) => {
	return useMutation({
		async mutationFn(credentials: credentialsType) {
			const data = await api.post('/login', credentials)
			return data
		},
		onSuccess: (data) => onSuccess(data),
		onError: (error) => onError(error as AxiosError),
	})
}
