import React, { useRef, useId, useState } from 'react'
import {
	Box,
	ButtonGroup,
	Button,
	Flex,
	Heading,
	Text,
	useColorModeValue,
} from '@chakra-ui/react'
import { useBreakpointsMediaQuery } from 'app/hooks/useMediaQuery'
import { useFormContext } from 'react-hook-form'
import { CloseIcon } from '@chakra-ui/icons'
import { FileIcon } from 'app/icons/FileIcon'
import { ImagesViewer } from 'app/components/ImagesViewer'
import { ImageView } from './Image'

interface UploadProps {
	imagesEdit?: string[]
}

export const ImageUpload = ({ imagesEdit }: UploadProps) => {
	const { isDesktop } = useBreakpointsMediaQuery()
	const form = useFormContext()
	const [images, setImages] = useState<any[]>()
	const [filesState, setFilesState] = useState<
		{ url: string; file?: File }[]
	>(imagesEdit ? imagesEdit?.map((url) => ({ url })) : [])
	const key = useId()
	const inputFile = useRef<HTMLInputElement | null>(null)
	const [error, setError] = useState('')
	const handleImageUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
		if (e?.target?.files) {
			setError('')
			const selected = e.target.files[0]

			if (selected) {
				const files = Array.from(e.target.files)

				if (files.find((file) => file.size > 5000000)) {
					setError('Arquivo deve ter tamanho máximo de 5MB')
					return
				}
				setImages(files)
				const newFilesState = filesState.concat(
					Array.from(files, (item) => {
						return { url: URL.createObjectURL(item), file: item }
					})
				)
				setFilesState(newFilesState)
				form.setValue(
					'files',
					newFilesState
						.filter((item) => !!item.file)
						.map((file) => file.file)
				)
			} else {
				console.log('ERROR')
			}
		}
	}

	const removeImage = (url: string) => {
		const newFilesState = filesState.filter((img) => img.url !== url)
		setFilesState(filesState.filter((img) => img.url !== url))
		form.setValue(
			'files',
			newFilesState.filter((item) => !!item.file).map((file) => file.file)
		)
		if (inputFile.current) inputFile.current.value = ''
	}

	const openImageSelection = () => {
		if (inputFile?.current) {
			inputFile?.current.click()
		}
	}

	const viewer = ImagesViewer.useImagesViewer(filesState)
	const isViewerOpen = !!viewer.currentPhotoId
	const text = useColorModeValue('#223344', '#FFF')
	return (
		<>
			{isViewerOpen && (
				<ImagesViewer
					photos={filesState}
					currentPhotoId={viewer.currentPhotoId}
					onBack={viewer.handleBackPhoto}
					onNext={viewer.handleNextPhoto}
					onClose={viewer.closePhoto}
				/>
			)}

			<Flex
				height="auto"
				alignItems="center"
				justifyContent={{
					sm: 'center',
					md: 'flex-start',
					lg: 'flex-start',
				}}
			>
				<Flex
					width="100%"
					height="100%"
					flexDirection="column"
					justifyContent="center"
				>
					<Box>
						<ButtonGroup
							variant="outline"
							flexDirection="column"
							display="flex"
							alignItems="center"
							justifyContent="center"
							border="1px dashed #F4F4F4"
							width="100%"
							paddingY="20px"
							rowGap="8px"
							borderRadius="10px"
						>
							<Button
								height="32px"
								width="157px"
								onClick={openImageSelection}
								background="#55BBDD"
								border="none"
								disabled={!!imagesEdit}
							>
								<Heading
									as="h4"
									fontWeight="600"
									fontSize="12px"
									textTransform="uppercase"
									color="white"
								>
									{isDesktop ? 'Escolher arquivo' : 'Arquivo'}
								</Heading>
							</Button>
							<Text as="span" fontSize="14px" color={text}>
								PNG, JPG, GIF até 5MB
							</Text>
						</ButtonGroup>
					</Box>
					{filesState && (
						<Box
							marginTop="16px"
							display="grid"
							width={isDesktop ? '50%' : '100%'}
							marginX="auto"
							// gridAutoFlow="column"
							gridTemplateColumns="repeat(auto-fit, minmax(max(100px, 100px), 1fr))"
							rowGap="16px"
							columnGap="24px"
							justifyContent="center"
						>
							{filesState?.map((image: any) => (
								<Box
									key={'image:' + image.url}
									width={
										image?.file?.type?.includes('video')
											? '200px'
											: '100px'
									}
									position="relative"
									margin="0 auto"
								>
									{/* {image.file?.type?.includes('image') && */}
									{imagesEdit && (
										<ImageView
											url={image.url}
											onClick={() =>
												viewer.openPhoto(image.url)
											}
										/>
									)}
									{!imagesEdit &&
										image.file?.type?.includes('image') && (
											<ImageView
												url={image.url}
												onClick={() =>
													viewer.openPhoto(image.url)
												}
											/>
										)}
									{!imagesEdit &&
										(image.file?.type?.includes(
											'document'
										) ||
											image.file?.type?.includes(
												'pdf'
											)) && (
											<div
												role="button"
												onClick={() =>
													viewer.openPhoto(image.url)
												}
											>
												<FileIcon size={64} />
												<Text
													overflow="hidden"
													textOverflow="ellipsis"
													whiteSpace="nowrap"
													fontSize="12px"
												>
													{image.file.name}
												</Text>
											</div>
										)}
									{!imagesEdit &&
										image?.file?.type?.includes(
											'video'
										) && (
											<video
												width="400"
												height="350"
												src={image.url}
												controls
												onClick={() =>
													viewer.openPhoto(image.url)
												}
											>
												<track />
											</video>
										)}
									{/* } */}
									{/* {(image.file?.type?.includes('document') || image.file?.type?.includes('pdf')) &&
									<div>
										<FileIcon size={64}/>
										<Text overflow="hidden" textOverflow="ellipsis" whiteSpace="nowrap" fontSize="12px">{image.file.name}</Text>
									</div>
									} */}

									{image.file && (
										<ButtonGroup
											// variant="outline"
											display="flex"
											alignItems="center"
											justifyContent="center"
											mt="5px"
										>
											<Button
												height="24px"
												width="24px"
												minWidth="24px"
												padding="6px"
												borderRadius="30px"
												onClick={() =>
													removeImage(image.url)
												}
												position="absolute"
												top={
													image.file.type.includes(
														'document'
													) ||
													image.file.type.includes(
														'pdf'
													)
														? '0'
														: '4px'
												}
												right={
													image.file.type.includes(
														'document'
													) ||
													image.file.type.includes(
														'pdf'
													)
														? '20px'
														: '4px'
												}
											>
												<CloseIcon
													width="12px"
													height="12px"
												/>
											</Button>
										</ButtonGroup>
									)}
								</Box>
							))}
						</Box>
					)}
				</Flex>
			</Flex>
			<input
				type="file"
				multiple
				ref={inputFile}
				style={{ display: 'none' }}
				onChange={handleImageUpload}
				key={`file-${key}`}
				accept=".doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,.pdf,image/jpeg,image/jpg,image/png,video/mp4"
			/>
			{error && (
				<Text margin="0 auto" textColor="red.400" fontWeight="500">
					{error}
				</Text>
			)}
		</>
	)
}
