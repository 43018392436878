/* eslint-disable react/no-array-index-key */
/* eslint-disable no-use-before-define */
import {
	Box,
	Flex,
	Input,
	ListItem,
	OrderedList,
	Popover,
	PopoverContent,
	PopoverTrigger,
	Text,
} from '@chakra-ui/react'
import React from 'react'
import { AriaDateRangePickerProps, DateValue } from 'react-aria'
import { useDateRangePickerState } from 'react-stately'
import { getLocalTimeZone, Time } from '@internationalized/date'
import dayjs from 'dayjs'

import { Calendar } from 'app/components/Calendar'
import type { ScheduleSettings } from '../queries/useSettingsByProfessional'
import { createSchedules } from '../helpers/createSchedules'

interface Props extends AriaDateRangePickerProps<DateValue> {
	settings?: ScheduleSettings
}

export function DateTimePicker({ settings, ...props }: Props) {
	const isDateUnavailable = (value: DateValue) => {
		if (!settings) return true
		const TZ = 'America/Sao_Paulo'
		const date = value.toDate(TZ)
		const isAvailableDay = settings.agenda.availableDays.includes(
			[
				'sunday',
				'monday',
				'tuesday',
				'wednesday',
				'thursday',
				'friday',
				'saturday',
			][date.getDay()]
		)
		if (!isAvailableDay) return true
		return false
	}
	const state = useDateRangePickerState({ ...props, isDateUnavailable })

	const startDate = state.dateRange.start.toDate(getLocalTimeZone())
	const formattedPickedDate = dayjs(startDate).format('DD/MM/YYYY')
	const schedules = React.useMemo(
		() =>
			settings
				? createSchedules(
						dayjs(startDate),
						settings.agenda.startTime,
						settings.agenda.endTime,
						settings.agenda.sessionDurationMinutes
				  )
				: [],
		[settings]
	)
	const step = settings?.agenda.sessionDurationMinutes

	const [inputValue, setInputValue] = React.useState(formattedPickedDate)
	if (formattedPickedDate !== inputValue) setInputValue(formattedPickedDate)

	const parseLunchStart = (d: dayjs.Dayjs) =>
		parseTimeString(d, settings.agenda.lunchStartTime)
	const parseLunchEnd = (d: dayjs.Dayjs) =>
		parseTimeString(d, settings.agenda.lunchEndTime)
	const isValidTimeCombination = (start: dayjs.Dayjs, end: dayjs.Dayjs) => {
		if (!end.isAfter(start)) return false

		const lunchStart = parseTimeString(
			start,
			settings.agenda.lunchStartTime
		)
		const lunchEnd = parseTimeString(start, settings.agenda.lunchEndTime)

		const areBothBeforeLunch = () =>
			start.isBefore(lunchStart) && end.isSameOrBefore(lunchStart)
		const areBothAfterLunch = () =>
			(start.isSame(lunchEnd) || start.isAfter(lunchEnd)) &&
			end.isAfter(lunchEnd)

		return areBothBeforeLunch() || areBothAfterLunch()
	}

	const startTimeOptions = React.useMemo(
		() =>
			schedules.filter((it) => {
				const lunchStart = parseLunchStart(it)
				const lunchEnd = parseLunchEnd(it)
				return (
					it.isBefore(lunchStart) ||
					it.isSame(lunchEnd) ||
					it.isAfter(lunchEnd)
				)
			}),
		[schedules]
	)
	const endTimeOptions = React.useMemo(() => {
		let options = schedules.filter((it) => {
			const start = dayjs(
				state.dateRange.start.toDate(getLocalTimeZone())
			)
			const isAfterStartTime = it.set('date', start.date()).isAfter(start)
			if (!isAfterStartTime) return false

			const lunchStart = parseLunchStart(it)
			const lunchEnd = parseLunchEnd(it)
			return it.isSameOrBefore(lunchStart) || it.isAfter(lunchEnd)
		})

		const lastSchedule = schedules.at(-1)
		if (lastSchedule) {
			options = options.concat([dayjs(lastSchedule.add(step, 'minutes'))])
		}
		return options
	}, [schedules, state])

	return (
		<Box maxW="100%">
			<Popover placement="bottom-start">
				{({ onClose }) => (
					<>
						<Flex alignItems="center" flexWrap="wrap" gap={3}>
							<PopoverTrigger>
								<Flex direction="column" flex={1} w="full">
									<Text
										fontSize="sm"
										fontWeight={700}
										mb={1}
										as="label"
										htmlFor="datetime"
									>
										Data
									</Text>
									<Input
										id="datetime"
										value={inputValue}
										readOnly
									/>
								</Flex>
							</PopoverTrigger>

							<Box
								height={0}
								mr={-3}
								flexBasis={{ sm: '100%', md: 0 }}
							/>

							<Flex direction="column">
								<Text
									fontSize="sm"
									fontWeight={700}
									mb={1}
									as="label"
									htmlFor="datetime"
								>
									Hora inicial
								</Text>
								<TimeInput
									options={startTimeOptions}
									time={dayjs(startDate)}
									onTimeChange={(datetime) => {
										if (!step) return

										state.setTimeRange({
											start: new Time(
												datetime.hour(),
												datetime.minute()
											),
											end: isValidTimeCombination(
												datetime,
												dayjs(
													state.timeRange.end.toString()
												)
											)
												? state.timeRange.end
												: getFirstValidEndTime(),
										})

										function getFirstValidEndTime() {
											const endDayjsTime = datetime.add(
												step,
												'minutes'
											)
											return new Time(
												endDayjsTime.hour(),
												endDayjsTime.minute()
											)
										}
									}}
								/>
							</Flex>

							<Flex direction="column">
								<Text
									fontSize="sm"
									fontWeight={700}
									mb={1}
									as="label"
									htmlFor="datetime"
								>
									Hora final
								</Text>
								<TimeInput
									options={
										schedules.length ? endTimeOptions : []
									}
									time={dayjs(
										state.dateRange.end.toDate(
											getLocalTimeZone()
										)
									)}
									onTimeChange={
										step
											? (datetime) => {
													state.setTimeRange({
														start: isValidTimeCombination(
															dayjs(
																state.timeRange.start.toString()
															),
															datetime
														)
															? state.timeRange
																	.start
															: getStartTime(),
														end: new Time(
															datetime.hour(),
															datetime.minute()
														),
													})

													function getStartTime() {
														const startDayjsTime =
															datetime.add(
																-step,
																'minutes'
															)
														return new Time(
															startDayjsTime.hour(),
															startDayjsTime.minute()
														)
													}
											  }
											: undefined
									}
								/>
							</Flex>
						</Flex>

						<PopoverContent>
							<Calendar
								onChange={(value) => {
									state.setDateRange({
										start: value,
										end: value,
									})
									onClose()
								}}
							/>
						</PopoverContent>
					</>
				)}
			</Popover>
		</Box>
	)
}

type TimeInputProps = {
	time: dayjs.Dayjs
	onTimeChange: (datetime: dayjs.Dayjs) => void
	options?: dayjs.Dayjs[]
}

function TimeInput({ time, onTimeChange, options }: TimeInputProps) {
	return (
		<Popover placement="bottom-start">
			{({ onClose }) => (
				<>
					<PopoverTrigger>
						<Input
							w="100px"
							value={time.hour() ? time.format('HH:mm') : ''}
							readOnly
						/>
					</PopoverTrigger>
					<PopoverContent maxW="100px">
						<OrderedList
							style={{
								maxHeight: 200,
								overflowY: 'auto',
							}}
							ml={0}
						>
							{options.map((option, i) => {
								return (
									<ListItem key={`option-${i}`} px={2} py={2}>
										<button
											type="button"
											onClick={() => {
												onTimeChange(option)
												onClose()
											}}
										>
											{option.format('HH:mm')}
										</button>
									</ListItem>
								)
							})}
						</OrderedList>
					</PopoverContent>
				</>
			)}
		</Popover>
	)
}

function parseTimeString(currentDate: dayjs.Dayjs, timeString: string) {
	// timeString follows pattern HH:mm
	const [hourString, minuteString] = timeString.split(':')
	return dayjs(currentDate)
		.set('hour', parseInt(hourString, 10))
		.set('minute', parseInt(minuteString, 10))
		.set('second', 0)
}
