import React from 'react'
import { Tr, Th, Thead } from '@chakra-ui/react'

const thead = ['NOME', 'CPF/CNPJ', 'TELEFONE', '']

export const Head = () => {
	return (
		<Thead>
			<Tr>
				{thead?.map((th) => (
					<Th key={`th${th}`}>{th}</Th>
				))}
			</Tr>
		</Thead>
	)
}
