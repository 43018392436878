import { Outlet } from 'react-router-dom'
import { useForm, FormProvider } from 'react-hook-form'

import { getDefaultValues } from 'modules/records/contexts/ManageRegistrationFormContext/defaultValues'
import { recordsFormSchema } from 'modules/records/validations/recordsFormSchema'
import { yupResolver } from '@hookform/resolvers/yup'
import { FormProps } from 'modules/records/contexts/ManageRegistrationFormContext/types'
import { ModuleRecordGrid } from './layout/Grid'
import { RecordsManagementContextProvider } from './contexts/RecordsManagementContext'

export default function Records() {
	const defaultValues = getDefaultValues()
	const methods = useForm<FormProps>({
		defaultValues,
		mode: 'all',
		resolver: yupResolver(recordsFormSchema),
	})

	return (
		<>
			<FormProvider {...methods}>
				<RecordsManagementContextProvider>
					<ModuleRecordGrid />
				</RecordsManagementContextProvider>
			</FormProvider>
			<Outlet />
		</>
	)
}
