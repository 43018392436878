import React, { useId } from 'react'
import { Input, InputProps, Flex, Text } from '@chakra-ui/react'
import { Heading } from '../Heading'

interface InputTextProps extends InputProps {
	title: string
	isDisabled?: boolean
	margin?: string | object
	hasError?: boolean
	errorMessage?: string
}

export const InputText = (props: InputTextProps) => {
	const {
		title,
		placeholder,
		size,
		width,
		isDisabled,
		margin,
		hasError,
		errorMessage,
		flex,
		...rest
	} = props

	const id = useId()

	return (
		<Flex direction="column" margin={margin} flex={flex}>
			<label htmlFor={id}>
				<Heading type="h4" mb="4px">
					{title}
				</Heading>
			</label>
			<Input
				{...rest}
				placeholder={placeholder}
				size={size}
				width={width}
				isDisabled={isDisabled}
				errorBorderColor="red.400"
				isInvalid={hasError}
				maskChar={null}
				id={id}
			/>
			{hasError && (
				<Text fontSize="xs" padding={1} color="red.400">
					{errorMessage}
				</Text>
			)}
		</Flex>
	)
}

InputText.defaultProps = {
	isDisabled: false,
	margin: 0,
	hasError: false,
	errorMessage: 'Este campo é obrigatório',
}
