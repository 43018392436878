import { Flex, Heading, Text, useColorModeValue } from '@chakra-ui/react'
import { useParams } from 'react-router-dom'

import { useBreakpointsMediaQuery } from 'app/hooks/useMediaQuery'
import { FiltersContainerDesktop } from '../FiltersContainer/FiltersContainerDesktop'
import { PatientHeader } from '../PatientHeader'
import { PacientDesktopTable } from '../PacientTable/PacientDesktopTable'
import { PacientMobileTableContainer } from '../PacientTable/PacientMobileTableContainer'
import { DesktopTable } from '../Table/DesktopTable'
import { MobileTableContainer } from '../Table/MobileTableContainer'

export function ProcedureMain() {
	const { isMobile, isTablet, isDesktop } = useBreakpointsMediaQuery()
	const { customerId } = useParams()
	const bg = useColorModeValue('#FFF', '#223344')
	const border = useColorModeValue('#F2F2F2', 'transparent')
	return (
		<Flex
			direction="column"
			// h="100vh"
			// maxHeight="100vh"
			// bg="#F9F9F9"
			rowGap="24px"
			paddingX={isMobile ? '0' : '24px'}
			paddingY="40px"
		>
			{customerId && (
				<>
					<Heading
						as="h2"
						fontSize="24px"
						marginX={isMobile ? '24px' : '0'}
					>
						Histórico do Paciente
					</Heading>

					<PatientHeader />
					{/* {isMobile && <PacientHeaderMobile />}
					{(isTablet || isDesktop) && <PacientHeaderDesktop />} */}

					{(isDesktop || isTablet) && (
						<Flex
							padding="16px"
							flexDirection="column"
							// justifyContent="center"
							alignItems="center"
							as="main"
							minHeight="656px"
							bg={bg}
							borderRadius="12px"
							border={`1px solid ${border}`}
						>
							<PacientDesktopTable />
						</Flex>
					)}
					{isMobile && <PacientMobileTableContainer />}
				</>
			)}
			{!customerId && (
				<>
					<FiltersContainerDesktop />

					{isMobile && <MobileTableContainer />}
					{(isDesktop || isTablet) && (
						<Flex
							padding="16px"
							flexDirection="column"
							// justifyContent="center"
							alignItems="center"
							as="main"
							height="656px"
							bg={bg}
							borderRadius="12px"
							border={`1px solid ${border}`}
						>
							<DesktopTable />
						</Flex>
					)}
				</>
			)}
		</Flex>
	)
}
