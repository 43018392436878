import IMask from 'imask'

function masker({
	masked,
	transform,
	maskDefault,
}: {
	masked: IMask.AnyMasked | IMask.AnyMaskedOptions
	transform?: any
	maskDefault?: any
}) {
	const mask = IMask.createPipe(
		masked,
		IMask.PIPE_TYPE.UNMASKED,
		IMask.PIPE_TYPE.MASKED
	)

	const unmask = IMask.createPipe(
		masked,
		IMask.PIPE_TYPE.MASKED,
		IMask.PIPE_TYPE.UNMASKED
	)

	const onChange = (e: any) => {
		const unmasked = unmask(e.target.value)
		const newValue = mask(unmasked)
		e.target.value = newValue
	}

	return {
		mask,
		onChange,
		transform: transform || unmask,
		unmask,
		maskDefault: maskDefault || mask,
	}
}

export const phoneMask = masker({
	masked: {
		mask: [
			{
				mask: '(00) 0000-0000',
				phone: 'landline',
			},
			{
				mask: '(00) 00000-0000',
				phone: 'mobile',
			},
		],
		dispatch: (
			appended: any,
			dynamicMasked: { compiledMasks: any[]; value: any }
		) => {
			const landlineMask = dynamicMasked.compiledMasks.find(
				({ phone }) => phone === 'landline'
			)

			const mobileMask = dynamicMasked.compiledMasks.find(
				({ phone }) => phone === 'mobile'
			)

			if (
				`${dynamicMasked.value}${appended}`.length >
				landlineMask.mask.length
			) {
				return mobileMask
			}

			return landlineMask
		},
	} as any,
})
