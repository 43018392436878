import { useQuery } from '@tanstack/react-query'
import { useParams } from 'react-router-dom'
import api from 'services/api'

export function useGetDocuments() {
	const params = useParams()

	function getType() {
		if (['anamnesis', 'radiograph', 'document'].includes(params.type))
			return params.type
		return 'document'
	}
	const type = getType()

	return useQuery({
		queryKey: ['documents', { type, customer: params.customerId }],
		queryFn: async () => {
			const response = await api.get<DocumentsResponse>(
				`/documents?type=${type}&customer=${params.customerId}`
			)
			return response.data

			interface DocumentsResponse {
				data: {
					_id: string
					type: string
					title?: string
					customer?: string
					files?: { url: string }[]
					createdAt: string
				}[]
				meta: { total: number }
			}
		},
		staleTime: 10 * 60 * 1000,
	})
}
