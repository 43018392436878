import React from 'react'
import { EditIcon, Search2Icon } from '@chakra-ui/icons'
import {
	Stack,
	TableContainer,
	Table,
	Thead,
	Tbody,
	Th,
	Td,
	Flex,
	IconButton,
	Center,
	Spinner,
	Text,
	Heading,
	Button,
	useToast,
	Modal,
	ModalOverlay,
	ModalContent,
	ModalCloseButton,
	Checkbox,
	Grid,
} from '@chakra-ui/react'
import { useSearchParams } from 'react-router-dom'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'

import { Can } from 'ability'
import { TablePagination } from 'app/components/TablePagination'
import api from 'services/api'
import { FormProvider, useForm } from 'react-hook-form'
import { Slot } from '@radix-ui/react-slot'

export function PermissionsRoute() {
	const [searchParams, setSearchParams] = useSearchParams()
	const page = Math.abs(Number(searchParams.get('page')) || 1)
	const onPageChange = (nextPage: number) => {
		setSearchParams(
			(init) => new URLSearchParams({ ...init, page: String(nextPage) })
		)
	}

	// const [search, setSearch] = React.useState('')
	// const onSearchChange = (e: React.ChangeEvent<HTMLInputElement>) =>
	// 	setSearch(e.currentTarget.value)

	const query = useQuery({
		queryKey: ['users_with_permissions'],
		async queryFn() {
			const response = await api.get('/workers')
			return {
				data: response.data.rows,
				meta: { total: response.data.count, perPage: 10 },
			}
		},
	})
	const rolesQuery = useQuery({
		queryKey: ['roles'],
		queryFn: async () => {
			const response = await api.get('/roles')
			return response.data.roles as { _id: string; name: string }[]
		},
	})
	const roles = rolesQuery.data ?? []

	const users = query.data?.data
	const paginationInfo = query.data?.meta

	const isEmpty = users?.length <= 0

	return (
		<Stack h="100%" gap="6">
			<Flex justify="space-between" alignItems="center">
				<Heading fontSize="2xl">Permissões</Heading>
			</Flex>

			{/* <Flex gap="4" alignItems="center">
				<InputGroup size="sm" maxW="543px">
					<InputLeftElement>
						<Search2Icon />
					</InputLeftElement>
					<Input
						bg="white"
						_dark={{ bg: '#182533' }}
						placeholder="Buscar por Nome"
						defaultValue={search}
						onChange={onSearchChange}
					/>
				</InputGroup>
			</Flex> */}

			<TableContainer
				bg="white"
				_dark={{ bg: '#182533' }}
				p="4"
				rounded="12"
				h="100%"
				mb="6"
				position="relative"
			>
				<Table variant="striped">
					<Thead>
						<tr>
							<Th>Nome</Th>
							<Th>Papéis</Th>
							<Th></Th>
						</tr>
					</Thead>
					<Tbody>
						{users &&
							!isEmpty &&
							users.map((it: any) => {
								const mapRoleName = (it: { name: string }) => {
									switch (it.name) {
										case 'secretary':
											return 'Secretário(a)'
										case 'owner':
											return 'Dono'
										default:
											return it.name
									}
								}
								return (
									<tr key={it._id}>
										<Td>{it.name}</Td>
										<Td>
											{it.user?.roles
												?.map(mapRoleName)
												.join(', ') || 'Nenhum'}
										</Td>
										<Td>
											<Flex gap="3" justifyContent="end">
												<Can I="update" a="User">
													<PermissionsModal
														user={it.user}
														roles={roles}
														trigger={
															<IconButton
																bg="#f9f9f9"
																_dark={{
																	bg: '#223344',
																}}
																aria-label="Editar Permissões"
																icon={
																	<EditIcon
																		fontSize={
																			20
																		}
																	/>
																}
															/>
														}
													/>
												</Can>
											</Flex>
										</Td>
									</tr>
								)
							})}

						{!query.data && query.isLoading && (
							<tr style={{ display: 'block' }}>
								<td>
									<Center
										position="absolute"
										h="100%"
										top="50%"
										left="50%"
										transform="translate(-50%,-50%)"
									>
										<Spinner />
									</Center>
								</td>
							</tr>
						)}

						{isEmpty && (
							<tr style={{ display: 'block' }}>
								<td>
									<Center
										flexDirection="column"
										position="absolute"
										h="100%"
										top="50%"
										left="50%"
										transform="translate(-50%,-50%)"
									>
										<Text>Nenhum usuário ainda</Text>
									</Center>
								</td>
							</tr>
						)}
						{query.isLoadingError && (
							<tr style={{ display: 'block' }}>
								<td>
									<Center
										flexDirection="column"
										position="absolute"
										h="100%"
										top="50%"
										left="50%"
										transform="translate(-50%,-50%)"
									>
										<Text>
											Algo deu errado ao carregar esses
											dados...
										</Text>
										<Text>{String(query.error)}</Text>
									</Center>
								</td>
							</tr>
						)}
					</Tbody>
				</Table>
			</TableContainer>

			{paginationInfo && paginationInfo.total > 0 && (
				<Flex alignItems="center" justify="end">
					<TablePagination
						total={paginationInfo.total}
						perPage={paginationInfo.perPage}
						page={page}
						onPageChange={onPageChange}
					/>

					<Text
						ml="5"
						fontSize="xs"
						fontWeight="medium"
						textTransform="uppercase"
					>
						{paginationInfo.total} registros
					</Text>
				</Flex>
			)}
		</Stack>
	)
}

interface Props extends PermissionsFormProps {
	trigger: JSX.Element
}

export function PermissionsModal({ trigger, ...props }: Props) {
	const [isOpen, setIsOpen] = React.useState(false)
	const onClose = () => setIsOpen(false)

	return (
		<>
			<Slot onClick={() => setIsOpen(true)}>{trigger}</Slot>

			<Modal isOpen={isOpen} onClose={onClose} isCentered>
				<ModalOverlay />
				<ModalContent px="6" py="6">
					<Flex alignItems="center" justify="space-between" mb="5">
						<Heading fontSize="xl">Editar Permissões</Heading>
						<ModalCloseButton size="md" top="5" />
					</Flex>

					<PermissionsForm {...props} onClose={onClose} />
				</ModalContent>
			</Modal>
		</>
	)
}

interface PermissionsFormProps {
	user: {
		_id: string
		permissions?: Record<string, boolean>
		roles?: { _id: string; name: string }[]
	}
	roles: { _id: string; name: string }[]
	onClose?: () => void
}

function PermissionsForm({ user, roles, onClose }: PermissionsFormProps) {
	type Form = {
		permissions: Record<string, boolean>
		roles: string[]
	}
	const form = useForm<Form>({
		defaultValues: {
			permissions: user.permissions ?? {},
			roles: user.roles?.map((it) => it._id) ?? [],
		},
	})
	const client = useQueryClient()
	const mutation = useMutation({
		async mutationFn(data: Form) {
			await api.patch(`/users/${user._id}/roles`, data)
		},
		onSuccess: () =>
			client.invalidateQueries({ queryKey: ['users_with_permissions'] }),
	})
	const toast = useToast()
	const onSave = form.handleSubmit(
		(data) => {
			mutation.mutate(data, {
				onSuccess: () => {
					toast({
						title: 'Salvo!',
						status: 'success',
						duration: 1500,
						isClosable: true,
					})
					onClose()
				},
				onError: (error) =>
					toast({
						title: 'Erro ao salvar',
						description: error.message,
						status: 'error',
					}),
			})
		},
		() =>
			toast({
				title: 'Erro de validação',
				description: 'Preencha todos os campos corretamente',
				status: 'error',
			})
	)

	const mapRoleName = (it: { name: string }) => {
		switch (it.name) {
			case 'secretary':
				return 'Secretário(a)'
			case 'owner':
				return 'Dono'
			default:
				return it.name
		}
	}

	const permissions = form.watch('permissions')
	const permissionsValues = Object.values(permissions)
	const allPermissionsEnabled =
		permissionsValues.length && permissionsValues.every(Boolean)
	const allPermissionsIndeterminate =
		!allPermissionsEnabled && permissionsValues.some(Boolean)

	return (
		<FormProvider {...form}>
			<form onSubmit={onSave}>
				<Stack mb="4">
					<Heading as="h3" size="sm" mb="1">
						Papéis
					</Heading>
					<Flex flexWrap="wrap" gap="3">
						{roles.map((it) => (
							<Checkbox
								key={it._id}
								defaultChecked={user.roles.some(
									(r) => r._id === it._id
								)}
								{...form.register('roles')}
								value={it._id}
							>
								{mapRoleName(it)}
							</Checkbox>
						))}
					</Flex>
				</Stack>

				<Stack mb="6">
					<Heading as="h3" size="sm" mb="1">
						Customizar permissões
					</Heading>
					<Grid gap="1">
						<Checkbox
							isChecked={allPermissionsEnabled}
							isIndeterminate={allPermissionsIndeterminate}
							onChange={(e) => {
								const keys = EDITABLE_PERMISSIONS.map(
									(it) => it.name
								)
								form.setValue(
									'permissions',
									Object.fromEntries(
										keys.map((key) => [
											key,
											e.target.checked,
										])
									)
								)
							}}
						>
							{allPermissionsEnabled
								? 'Desmarcar todos'
								: 'Marcar todos'}
						</Checkbox>
						<Stack pl={6} mt={1} spacing={1}>
							{EDITABLE_PERMISSIONS.map((it) => (
								<Checkbox
									key={it.name}
									isChecked={permissions[it.name]}
									{...form.register(`permissions.${it.name}`)}
								>
									{it.label}
								</Checkbox>
							))}
						</Stack>
					</Grid>
				</Stack>

				<Flex justify="space-evenly" flexDir="row-reverse">
					<Button
						type="submit"
						size="sm"
						w="130px"
						h="32px"
						colorScheme="primary"
						textTransform="uppercase"
						isLoading={mutation.isPending}
					>
						Salvar
					</Button>
					<Button
						type="button"
						size="sm"
						w="130px"
						h="32px"
						colorScheme="primary"
						variant="outline"
						textTransform="uppercase"
						onClick={onClose}
					>
						Cancelar
					</Button>
				</Flex>
			</form>
		</FormProvider>
	)
}

const EDITABLE_PERMISSIONS = [
	{ name: 'readCustomer', label: 'Ler Cadastro' },
	{ name: 'createCustomer', label: 'Criar Cadastro' },
	{ name: 'updateCustomer', label: 'Editar Cadastro' },

	{ name: 'readAppointment', label: 'Ler Agendamento' },
	{ name: 'createAppointment', label: 'Criar Agendamento' },
	{ name: 'updateAppointment', label: 'Editar Agendamento' },
	{ name: 'manageSettings', label: 'Configurar Agenda' },

	{ name: 'readProcedure', label: 'Ler Procedimento' },
	{ name: 'createProcedure', label: 'Criar Procedimento' },
	{ name: 'updateProcedure', label: 'Editar Procedimento' },

	{ name: 'readTreatment', label: 'Ler Tratamento' },
	{ name: 'createTreatment', label: 'Criar Tratamento' },
	{ name: 'updateTreatment', label: 'Editar/Aprovar Tratamento' },
	{ name: 'deleteTreatment', label: 'Excluir Tratamento' },

	{ name: 'readProduct', label: 'Ler Produto' },
	{ name: 'createProduct', label: 'Criar Produto' },
	{ name: 'updateProduct', label: 'Editar Produto' },

	{ name: 'readPayment', label: 'Ler Pagamento' },
	{ name: 'createPayment', label: 'Criar Pagamento' },
	{ name: 'updatePayment', label: 'Editar Pagamento' },
	{ name: 'deletePayment', label: 'Excluir Pagamento' },
	
	{ name: 'readBill', label: 'Ler Conta' },
	{ name: 'createBill', label: 'Criar Conta' },
	{ name: 'updateBill', label: 'Editar Conta' },
	{ name: 'deleteBill', label: 'Excluir Conta' },

	{ name: 'readReports', label: 'Relatórios' },
]
