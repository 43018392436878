import { useQuery } from '@tanstack/react-query'
import { TreatmentsRepository } from 'data/TreatmentsRepository'

export function useTreatmentQuery(treatmentId: string) {
	return useQuery({
		queryKey: ['treatments', treatmentId],
		async queryFn() {
			const treatmentsRepository = new TreatmentsRepository()
			return await treatmentsRepository.getTreatment(treatmentId)
		},
	})
}
