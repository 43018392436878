import { IconButton } from '@chakra-ui/react'

import { SettingsIcon } from 'app/icons/SettingsIcon'
import { useModalState } from 'app/utils/useModalState'
import { Modal } from 'app/components/Modal'
import { ScheduleSettingsBody } from './ScheduleSettingsBody'
import { Can } from 'ability'

export function ScheduleSettings() {
	const modal = useModalState()
	return (
		<>
			<Can I="manage" a="Settings">
				<IconButton
					width="32px"
					justifySelf="end"
					aria-label="Configurar Agenda"
					title="Configurar Agenda"
					icon={<SettingsIcon />}
					onClick={modal.open}
				/>
			</Can>

			<Modal
				isOpen={modal.isOpen}
				onClose={modal.close}
				title="Configurações"
				body={<ScheduleSettingsBody onCancel={modal.close} />}
				footer={null}
				dividers
				size="3xl"
			/>
		</>
	)
}
