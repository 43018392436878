/* eslint-disable no-underscore-dangle */
import {
	Checkbox,
	Stack,
	Textarea,
	FormControl,
	FormLabel,
} from '@chakra-ui/react'
import React, { ChangeEvent } from 'react'
import { useFormContext } from 'react-hook-form'
import { useSearchParams, useParams } from 'react-router-dom'
import { CalendarDateTime, getLocalTimeZone } from '@internationalized/date'
import dayjs from 'dayjs'

import { InputTextController } from 'app/components/InputTextController'
import { phoneMask } from 'app/utils/masks'

import type { ScheduleForm } from 'modules/schedule/validations/scheduleFormSchema'
import { getValidDate } from 'app/utils/getValidDate'
import { useSettingsByProfessional } from 'modules/schedule/queries/useSettingsByProfessional'
import { useSettingsDefault } from 'modules/schedule/queries/useSettingsDefault'
import { DateTimePicker } from '../DateTimePicker'
import { Combobox } from 'app/components/Combobox'
import { useInfiniteCustomersQuery } from 'modules/schedule/queries/useInfiniteCustomersQuery'

const ScheduleBodyModal = () => {
	const form = useFormContext<ScheduleForm>()
	const {
		control,
		formState: { errors },
		setValue,
		watch,
		getValues,
	} = form

	const [customerSearch, setCustomerSearch] = React.useState('')
	const customersQuery = useInfiniteCustomersQuery({ name: customerSearch })

	const handleScheduleTypeChange = (e: ChangeEvent<HTMLInputElement>) => {
		setValue('isPreRegister', e.target.checked)
		setValue('cellphoneNumber', '')
		setValue('customerId', '')
	}
	const isFirstSchedule = watch('isPreRegister')

	const customerId = form.watch('customerId')
	const customerData = customersQuery.data?.find((c) => c.id === customerId)
	React.useLayoutEffect(() => {
		if (!isFirstSchedule) {
			form.setValue(
				'cellphoneNumber',
				customerData ? customerData.cellphoneNumber : ''
			)
		}
	}, [customerData, isFirstSchedule])

	const [searchParams] = useSearchParams()
	const date = getValidDate(searchParams.get('date'))

	const { professionalId } = useParams()

	const settingsByProfessional = useSettingsByProfessional(professionalId)
	const settingsDefault = useSettingsDefault()
	const settings = settingsByProfessional.data ?? settingsDefault.data

	const defaultDateTimeRangeRef = React.useRef<
		{ start: CalendarDateTime; end: CalendarDateTime } | undefined
	>()
	if (settings && !defaultDateTimeRangeRef.current) {
		const defaultValues = getValues()
		if (defaultValues.start && defaultValues.end) {
			const start = new Date(defaultValues.start)
			const end = new Date(defaultValues.end)
			defaultDateTimeRangeRef.current = {
				start: new CalendarDateTime(
					start.getFullYear(),
					start.getMonth() + 1,
					start.getDate(),
					start.getHours(),
					start.getMinutes()
				),
				end: new CalendarDateTime(
					end.getFullYear(),
					end.getMonth() + 1,
					end.getDate(),
					end.getHours(),
					end.getMinutes()
				),
			}
		} else {
			defaultDateTimeRangeRef.current = {
				start: new CalendarDateTime(
					date.getFullYear(),
					date.getMonth() + 1,
					date.getDate()
				),
				end: new CalendarDateTime(
					date.getFullYear(),
					date.getMonth() + 1,
					date.getDate()
				),
			}
		}
	}

	return (
		<>
			<Stack spacing={5} direction="column">
				<Checkbox
					value={String(isFirstSchedule)}
					defaultChecked={isFirstSchedule}
					onChange={handleScheduleTypeChange}
					mt="16px"
				>
					Primeiro agendamento
				</Checkbox>

				<DateTimePicker
					defaultValue={defaultDateTimeRangeRef.current}
					settings={settings}
					onChange={(value) => {
						const start = dayjs(
							value.start.toDate(getLocalTimeZone())
						).format('YYYY-MM-DDTHH:mm')
						const end = dayjs(
							value.end.toDate(getLocalTimeZone())
						).format('YYYY-MM-DDTHH:mm')
						setValue('start', start)
						setValue('end', end)
					}}
				/>

				{isFirstSchedule ? (
					<InputTextController
						title="Nome *"
						placeholder=""
						type="text"
						width="100%"
						name="customerId"
						control={control}
						hasError={!!errors?.customerId}
						errorMessage={errors?.customerId?.message}
						isRequired
					/>
				) : (
					<FormControl>
						<FormLabel
							htmlFor="customer_combobox"
							fontSize="sm"
							fontWeight="600"
							color="WindowText"
						>
							Selecione o paciente
						</FormLabel>
						<Combobox
							id="customer_combobox"
							name="customerId"
							items={customersQuery.data ?? []}
							loading={
								(!customersQuery.data ||
									customersQuery.isPlaceholderData) &&
								customersQuery.isFetching
							}
							inputValue={customerSearch}
							onInputChange={setCustomerSearch}
							loadingMore={customersQuery.isFetchingNextPage}
							onLoadMore={() => {
								if (
									customersQuery.hasNextPage &&
									!customersQuery.isFetching
								)
									customersQuery.fetchNextPage()
							}}
						>
							{(item) => (
								<Combobox.Item key={item.id}>
									{item.name}
								</Combobox.Item>
							)}
						</Combobox>
					</FormControl>
				)}
				<InputTextController
					title="Telefone *"
					placeholder=""
					type="text"
					onChange={phoneMask.onChange}
					width="100%"
					name="cellphoneNumber"
					control={control}
					hasError={!!errors?.cellphoneNumber}
					errorMessage={errors?.cellphoneNumber?.message}
					isReadOnly={!isFirstSchedule}
				/>
				<Textarea
					placeholder="Observações"
					mb="24px"
					resize="none"
					value={watch('notes')}
					onChange={(e) => setValue('notes', e.target.value)}
				/>
			</Stack>
		</>
	)
}
export default ScheduleBodyModal
