import {
	Accordion,
	AccordionButton,
	AccordionIcon,
	AccordionItem,
	AccordionPanel,
	Box,
	Flex,
	FormControl,
	FormLabel,
	Heading,
	useColorModeValue,
} from '@chakra-ui/react'
import { FormProvider, useForm } from 'react-hook-form'
import { InputTextController } from 'app/components/InputTextController'
import { ImageUpload } from './ImagesUpload'
import { TextAreaController } from '../TextareaController'
import React from 'react'
import { Slot } from '@radix-ui/react-slot'
import { useCustomerQuery } from 'modules/history/queries/useCustomerQuery'
import { useParams } from 'react-router-dom'
import { SelectController } from 'app/components/SelectController'
import { useInfiniteCustomersQuery } from 'modules/history/queries/useInfiniteCustomersQuery'
import { Combobox } from 'app/components/Combobox'
import { useProfessionals } from 'modules/schedule/queries/useProfessionals'
import { FormFooter } from '../NewProcedureForm/FormFooter'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import api from 'services/api'
import dayjs from 'dayjs'
import { Modal } from 'app/components/Modal'

interface Props extends ProcedureFormProps {
	trigger: JSX.Element
}

export function ProcedureFormModal({ trigger, procedure }: Props) {
	const editing = !!procedure

	const [isOpen, setIsOpen] = React.useState(false)
	const onClose = () => setIsOpen(false)

	return (
		<>
			<Slot onClick={() => setIsOpen(true)}>{trigger}</Slot>

			<Modal
				isOpen={isOpen}
				onClose={onClose}
				title={`${editing ? 'Editar' : 'Novo'} Procedimento`}
				handleForm={() => undefined}
				body={<ProcedureForm procedure={procedure} onClose={onClose} />}
				footer={<></>}
				dividers={false}
			/>
		</>
	)
}

interface ProcedureFormProps {
	procedure?: any
	onClose?: () => void
}

function ProcedureForm({ onClose }: ProcedureFormProps) {
	const params = useParams()
	const { searchParams } = new URL(location.href)
	const dentist = searchParams.get('dentist')

	const form = useForm<Record<string, any>>({
		defaultValues: { date: dayjs().format('YYYY-MM-DD') },
	})
	const control = form.control
	const errors = form.formState.errors

	const customerQuery = useCustomerQuery(params.customerId)
	const customer = customerQuery.data

	if (customer) form.setValue('customer', customer._id)
	if (dentist) form.setValue('dentist', dentist)

	const { data: professionals } = useProfessionals()

	const color = useColorModeValue('#223344', '#fff')
	const btnBg = useColorModeValue('#F9F9F9', 'whiteAlpha.50')
	const border = useColorModeValue('#F4F4F4', '#777')

	const [customerSearch, setCustomerSearch] = React.useState(
		customer?.name ?? ''
	)

	const customersQuery = useInfiniteCustomersQuery({ name: customerSearch })

	const client = useQueryClient()
	const mutation = useMutation({
		async mutationFn({ formData }: any) {
			const data = await api.post(`/procedures`, formData, {
				headers: { 'Content-Type': 'multipart/form-data' },
			})
			return data
		},
		onSuccess() {
			onClose()
			client.invalidateQueries({ queryKey: ['getAllProcedures'] })
			return client.invalidateQueries({ queryKey: ['history'] })
		},
	})
	const onSave = form.handleSubmit((data) => {
		const formData = new FormData()

		formData.set('name', data.name)
		formData.set('date', dayjs(data.date).toISOString())
		if (data.observation) formData.set('observation', data.observation)
		formData.set('dentist', data.dentist)
		formData.set('customer', data.customer)
		if (data.files) {
			const filesArray: File[] = Array.from(data.files)
			filesArray?.forEach((file) => {
				formData.append('files', file)
			})
		}
		mutation.mutate({ formData })
	})

	return (
		<FormProvider {...form}>
			<Box height="100%">
				<Heading
					as="h3"
					fontSize="20px"
					fontWeight="700"
					color="#1188EE"
				>
					{customer?.name}
				</Heading>
				<form
					method="post"
					style={{ height: '90%' }}
					onSubmit={onSave}
					autoComplete="off"
					id="newProcedureForm"
				>
					<Flex
						flexDirection="column"
						height="95%"
						marginTop="16px"
						justifyContent="space-between"
					>
						<Accordion
							width="100%"
							display="flex"
							flexDirection="column"
							rowGap="24px"
							defaultIndex={[0, 1]}
							allowMultiple
						>
							<AccordionItem
								width="100%"
								border={`1px solid ${border}`}
								borderRadius="4px"
							>
								{({ isExpanded }) => (
									<>
										<AccordionButton
											_expanded={{ color: '#3388CC' }}
											color={color}
										>
											<Flex
												alignItems="center"
												width="100%"
												justifyContent="space-between"
											>
												<Heading
													as="h4"
													fontSize="16px"
													fontWeight="600"
												>
													Dados Gerais
												</Heading>
												{/* <Box width="24px" height="24px" display="flex" alignItems="center" justifyContent="center" borderRadius="4px" background="#55BBDD"> */}
												<AccordionIcon
													width="24px"
													height="24px"
													borderRadius="4px"
													color="#223344"
													background={
														isExpanded
															? '#55BBDD'
															: btnBg
													}
												/>
												{/* </Box>                             */}
											</Flex>
										</AccordionButton>
										<AccordionPanel
											pb={4}
											display="flex"
											flexDirection="column"
											rowGap="16px"
										>
											<InputTextController
												title="Nome do procedimento*"
												type="string"
												width="100%"
												name="name"
												control={control}
												hasError={errors?.name}
												errorMessage={
													errors?.name?.message
												}
												isReadOnly={false}
												// isRequired
											/>
											<InputTextController
												title="Data*"
												type="date"
												width="100%"
												name="date"
												control={control}
												hasError={errors?.date}
												errorMessage={
													errors?.date?.message
												}
												isReadOnly={false}
												isRequired
											/>
											<FormControl>
												<FormLabel
													htmlFor="customer_combobox"
													fontSize="sm"
													fontWeight="600"
													color="WindowText"
												>
													Selecione o paciente
												</FormLabel>
												<Combobox
													id="customer_combobox"
													name="customer"
													items={
														customersQuery.data ??
														[]
													}
													loading={
														(!customersQuery.data ||
															customersQuery.isPlaceholderData) &&
														customersQuery.isFetching
													}
													inputValue={customerSearch}
													onInputChange={
														setCustomerSearch
													}
													loadingMore={
														customersQuery.isFetchingNextPage
													}
													onLoadMore={React.useCallback(() => {
														if (
															customersQuery.hasNextPage &&
															!customersQuery.isFetching
														)
															customersQuery.fetchNextPage()
													}, [
														customersQuery.hasNextPage,
														customersQuery.isFetching,
													])}
												>
													{(item) => (
														<Combobox.Item
															key={item.id}
														>
															{item.name}
														</Combobox.Item>
													)}
												</Combobox>
											</FormControl>
											<SelectController
												items={
													professionals?.map(
														(professional) => ({
															value: professional.id,
															label: professional.name,
														})
													) ?? []
												}
												title="Profissional*"
												width="100%"
												name="dentist"
												control={control}
												hasError={errors?.dentist}
												errorMessage={
													errors?.dentist?.message
												}
												placeholder="Selecione um profissional"
											/>
											<FormLabel
												fontSize="14px"
												fontWeight="600"
												// color="#223344"
											>
												Observações
											</FormLabel>
											<TextAreaController
												title="Observações"
												// type="string"
												width="100%"
												height="200px"
												name="observation"
												control={control}
												hasError={errors?.observation}
												errorMessage={
													errors?.observation?.message
												}
												isReadOnly={false}
												// isRequired
											/>
										</AccordionPanel>
									</>
								)}
							</AccordionItem>
							<AccordionItem
								width="100%"
								border={`1px solid ${border}`}
								borderRadius="4px"
								mb="20px"
							>
								{({ isExpanded }) => (
									<>
										<AccordionButton
											_expanded={{ color: '#3388CC' }}
											color={color}
										>
											<Flex
												alignItems="center"
												width="100%"
												justifyContent="space-between"
											>
												<Heading
													as="h4"
													fontSize="16px"
													fontWeight="600"
												>
													Arquivo imagem
												</Heading>
												{/* <Box width="24px" height="24px"  display="flex" alignItems="center" justifyContent="center" borderRadius="4px" background="#55BBDD"> */}
												<AccordionIcon
													width="24px"
													height="24px"
													borderRadius="4px"
													color="#223344"
													background={
														isExpanded
															? '#55BBDD'
															: btnBg
													}
												/>
												{/* </Box>                             */}
											</Flex>
										</AccordionButton>
										<AccordionPanel pb={4}>
											<ImageUpload />
										</AccordionPanel>
									</>
								)}
							</AccordionItem>
						</Accordion>
						<FormFooter />
					</Flex>
				</form>
			</Box>
		</FormProvider>
	)
}
