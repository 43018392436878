//  ´[sm, md, lg, xl]
// [small, medium, large, extralarge ]

export const textStyles = {
	h1: {
		fontSize: ['36px', '36px', '36px', '36px'],
		fontWeight: 'bold',
		color: '#000000',
		lineHeight: ['43.2px', '43.2px', '43.2px', '43.2px'],
	},
	h2: {
		fontSize: ['18px', '20px', '20px', '20px'],
		fontWeight: 'bold',
		lineHeight: ['23.4px', '26px', '26px', '26px'],
	},
	h3: {
		fontSize: ['14px', '16px', '16px', '16px'],
		fontWeight: 'bold',
		lineHeight: ['18.2px', '20.8px', '20.8px', '20.8px'],
	},
	h4: {
		fontSize: ['12px', '14px', '14px', '14px'],
		fontWeight: 'bold',
		lineHeight: ['15.6px', '18.2px', '18.2px', '18.2px'],
	},
}
