import { Box, Flex } from '@chakra-ui/react'
import { useSearchParams } from 'react-router-dom'

import { Heading } from 'app/components/Heading'
import { TablePagination } from 'app/components/TablePagination'
import { useHistoryQuery } from 'modules/history/queries/useHistoryQuery'

export const Footer = () => {
	const query = useHistoryQuery()
	const meta = query.data?.meta

	const [searchParams, setSearchParams] = useSearchParams()
	const page = Math.abs(Number(searchParams.get('page')) || 1)
	const onPageChange = (nextPage: number) => {
		setSearchParams(
			(init) => new URLSearchParams({ ...init, page: String(nextPage) })
		)
	}

	if (!meta) return null

	return (
		<Flex
			alignItems="center"
			justifyContent="space-between"
			height={45}
			width="100%"
			mt={2}
		>
			<Box>
				<></>
			</Box>
			<Box>
				<TablePagination
					total={meta.total}
					perPage={meta.perPage}
					page={page}
					onPageChange={onPageChange}
				/>
			</Box>
			<Box>
				<Heading
					type="h3"
					fontSize={{ sm: '12px', md: '13px', lg: '15px' }}
				>
					{meta?.total} procedimentos
				</Heading>
			</Box>
		</Flex>
	)
}
