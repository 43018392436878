import {
	itemsGenre,
	itemsMaritalStatus,
} from 'modules/records/components/Form/itemsToSelect'
import { FormProps } from './types'

export const getDefaultValues: () => FormProps = () => {
	return {
		registrationType: 'customers',
		personType: 'PF',
		name: '',
		birthday: null,
		rg: '',
		cpf: null,
		cnpj: null,
		isLegalEntity: false,
		gender: itemsGenre[0].value,
		cellphoneNumber: undefined,
		secondaryCellphoneNumber: undefined,
		street: undefined,
		neighborhood: undefined,
		city: undefined,
		uf: undefined,
		number: undefined,
		complement: undefined,
		zipCode: undefined,
		instagram: undefined,
		facebook: undefined,
		maritalStatus: itemsMaritalStatus[0].value,
		occupation: undefined,
		preRegister: undefined,
		observation: undefined,
		user: {
			login: undefined,
			email: undefined,
			password: undefined,
			picture: undefined,
		},
		legalRepresentative: {
			name: undefined,
			birthday: undefined,
			rg: undefined,
			cpf: undefined,
			cnpj: undefined,
			email: undefined,
			gender: undefined,
			cellphoneNumber: undefined,
			secondaryCellphoneNumber: undefined,
			street: undefined,
			neighborhood: undefined,
			city: undefined,
			uf: undefined,
			number: undefined,
			complement: undefined,
			zipCode: undefined,
			maritalStatus: undefined,
			occupation: undefined,
			config: undefined,
		},
	}
}

export const mock = [
	{
		birthday: '2022-04-27',
		cellphoneNumber: '3333333',
		city: 'Juiz de Fora',
		cnpj: '',
		complement: 'Casa',
		cpf: '09843552601',
		facebook: '',
		gender: 'Yes',
		instagram: '',
		isLegalEntity: false,
		legalRepresentative: {
			birthday: '2022-04-20',
			cellphoneNumber: '32998075213',
			city: 'Guarará',
			complement: 'Casa',
			cpf: '112.343.454-54',
			email: 'alvesronald@yahoo.com',
			gender: 'Yes',
			maritalStatus: 'Solteiro(a)',
			name: 'Jakeline',
			neighborhood: 'Gilson Lamha',
			number: '',
			occupation: 'Programador',
			rg: '333333333333333',
			secondaryCellphoneNumber: '',
			street: 'Rua A',
			uf: 'MG',
			zipCode: '36606-000',
		},
		maritalStatus: 'Solteiro(a)',
		name: 'Ronald ',
		neighborhood: 'Paineiras',
		number: '',
		observation: 'Teste',
		occupation: 'Programador',
		preRegister: '',
		rg: '333333333333333',
		secondaryCellphoneNumber: '',
		street: 'Rua Olegário Maciel - de 1613/1614 a 2218/2219',
		uf: 'MG',
		user: '',
		email: 'alvesronald@yahoo.com',
		login: '',
		password: '09843552601',
		picture: '',
		zipCode: '36016-011',
	},
]
