import React from 'react'
import {
	Button,
	Flex,
	FormControl,
	FormErrorMessage,
	FormLabel,
	Heading,
	Input,
	Modal,
	ModalCloseButton,
	ModalContent,
	ModalOverlay,
	useToast,
} from '@chakra-ui/react'
import { Slot } from '@radix-ui/react-slot'
import { Controller, useForm } from 'react-hook-form'
import { useMutation, useQueryClient } from '@tanstack/react-query'

import { ProceduresRepository } from 'data/ProceduresRepository'
import { Procedure } from 'data/Procedure'

interface Props extends ProcedureFormProps {
	trigger: JSX.Element
}

export function ProcedureFormModal({ trigger, procedure }: Props) {
	const editing = !!procedure

	const [isOpen, setIsOpen] = React.useState(false)
	const onClose = () => setIsOpen(false)

	return (
		<>
			<Slot onClick={() => setIsOpen(true)}>{trigger}</Slot>

			<Modal isOpen={isOpen} onClose={onClose} isCentered>
				<ModalOverlay />
				<ModalContent px="6" py="6">
					<Flex alignItems="center" justify="space-between" mb="5">
						<Heading fontSize="xl">
							{editing ? 'Editar' : 'Novo'} Procedimento
						</Heading>
						<ModalCloseButton size="md" top="5" />
					</Flex>

					<ProcedureForm procedure={procedure} onClose={onClose} />
				</ModalContent>
			</Modal>
		</>
	)
}

interface ProcedureFormProps {
	procedure?: Procedure
	onClose?: () => void
}

function ProcedureForm({ procedure, onClose }: ProcedureFormProps) {
	const form = useForm<Procedure>({ defaultValues: procedure })

	const client = useQueryClient()
	const mutation = useMutation({
		async mutationFn(values: Procedure) {
			const proceduresRepository = new ProceduresRepository()
			if (procedure) {
				return await proceduresRepository.updateProcedure(
					procedure.id,
					values
				)
			}
			await proceduresRepository.createProcedure(values)
		},
		onSuccess() {
			return client.invalidateQueries({ queryKey: ['procedures'] })
		},
	})

	const toast = useToast()

	const onSave = form.handleSubmit((values) =>
		mutation.mutate(values, {
			onError(error) {
				toast({
					status: 'error',
					title: 'Algo deu errado ao salvar...',
					description: String(error),
					duration: 5000,
				})
			},
			onSuccess() {
				onClose?.()
			},
		})
	)

	function formatAsCurrency(rawValue: number) {
		const currency = 'BRL'
		const formattedValue = new Intl.NumberFormat(undefined, {
			style: 'currency',
			currency,
		})
			.format(rawValue)
			.trim()
		return formattedValue
	}

	return (
		<form onSubmit={onSave} noValidate>
			<FormControl
				isInvalid={!!form.formState.errors.name}
				isRequired
				mb="5"
			>
				<FormLabel fontSize="sm">Nome do Procedimento</FormLabel>
				<Input
					fontSize="sm"
					placeholder="Insira o nome do procedimento"
					{...form.register('name', {
						required: 'Preencha esse campo',
					})}
				/>
				<FormErrorMessage position="absolute" right="0" mt="1">
					{form.formState.errors.name?.message}
				</FormErrorMessage>
			</FormControl>
			<FormControl
				isInvalid={!!form.formState.errors.value}
				isRequired
				mb="10"
			>
				<FormLabel fontSize="sm">Valor Unitário</FormLabel>

				<Controller
					name="value"
					control={form.control}
					render={({ field }) => {
						const formattedValue = field.value
							? formatAsCurrency(field.value)
							: ''

						return (
							<Input
								fontSize="sm"
								placeholder="R$ 500,00"
								{...field}
								value={formattedValue}
								onBlur={field.onBlur}
								onChange={(e) => {
									const value = e.target.value
									if (!value || typeof value === 'number') {
										field.onChange(value)
										return
									}

									const valueAsNumber = Number(
										value.replace(/[^\d]/g, '')
									)
									field.onChange(valueAsNumber / 100)
								}}
								onInput={(e) => {
									const input = e.target as HTMLInputElement
									const value = input.value.trim()
									if (value === '') {
										input.value = ''
										return
									}
									const valueAsNumber = Number(
										value.replace(/[^\d]/g, '')
									)
									const rawNumber = valueAsNumber / 100
									const nextFormattedValue =
										formatAsCurrency(rawNumber)
									input.value = nextFormattedValue
								}}
							/>
						)
					}}
					rules={{
						required: 'Preencha esse campo',
					}}
				/>

				<FormErrorMessage position="absolute" right="0" mt="1">
					{form.formState.errors.value?.message}
				</FormErrorMessage>
			</FormControl>
			<Flex justify="space-evenly" flexDir="row-reverse">
				<Button
					type="submit"
					size="sm"
					w="130px"
					h="32px"
					colorScheme="primary"
					textTransform="uppercase"
					isLoading={mutation.isPending}
				>
					Salvar
				</Button>
				<Button
					size="sm"
					w="130px"
					h="32px"
					colorScheme="primary"
					variant="outline"
					textTransform="uppercase"
				>
					Cancelar
				</Button>
			</Flex>
		</form>
	)
}
