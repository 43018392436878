import { DeleteIcon } from '@chakra-ui/icons'
import { IconButton, useToast } from '@chakra-ui/react'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { ConfirmAlert } from 'app/components/ConfirmAlert'
import { Treatment } from 'data/Treatment'

import api from 'services/api'

export function DeleteTreatment({ treatment }: { treatment: Treatment }) {
	const queryClient = useQueryClient()
	const mutation = useMutation({
		mutationFn: () => api.delete(`/treatments/${treatment.id}`),
		onSuccess: () =>
			queryClient.invalidateQueries({ queryKey: ['treatments'] }),
	})
	const toast = useToast()
	const onDelete = () => {
		const deletePromise = mutation.mutateAsync()
		toast.promise(deletePromise, {
			success: { title: 'Deletado!', duration: 2000, isClosable: true },
			error: { title: 'Erro ao deletar', duration: 5000 },
			loading: { title: 'Deletando...' },
		})
	}

	const label = treatment.status === 'approved' ? 'tratamento' : 'orçamento'

	return (
		<ConfirmAlert
			title={`Quer mesmo deletar esse ${label}?`}
			body="Todos os pagamentos relacionados a ele também serão deletados"
			trigger={
				<IconButton
					bg="#f9f9f9"
					_dark={{
						bg: '#223344',
					}}
					aria-label={`Deletar ${label}`}
					icon={<DeleteIcon />}
				/>
			}
			onConfirm={onDelete}
		/>
	)
}
