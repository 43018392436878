/* eslint-disable no-use-before-define */
/* eslint-disable no-underscore-dangle */
import { useParams, useSearchParams } from 'react-router-dom'
import {
	Td,
	Tr,
	useMediaQuery,
	Container,
	Box,
	Grid,
	useColorModeValue,
} from '@chakra-ui/react'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore'
import React from 'react'
import { ArrowBackIcon, ArrowForwardIcon } from '@chakra-ui/icons'

import { GenerateReport } from 'app/components/GenerateReport'
import { useBreakpointsMediaQuery } from 'app/hooks/useMediaQuery'
import { useProfessionalSchedule } from 'modules/schedule/queries/useProfessionalSchedule'
import { useSettingsDefault } from 'modules/schedule/queries/useSettingsDefault'
import { useSettingsByProfessional } from 'modules/schedule/queries/useSettingsByProfessional'
import { createSchedules } from 'modules/schedule/helpers/createSchedules'
import { FileIcon } from 'app/icons/FileIcon'
import { UserIcon } from 'app/icons/UserIcon'

import { ScheduleTableRoot as Table } from './ScheduleTableRoot'
import { ScheduleRow } from './ScheduleRow'
import { SchedulePdfLink } from '../SchedulePdfLink'
import { useProfessionals } from 'modules/schedule/queries/useProfessionals'

dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(isSameOrBefore)

const ENABLE_REPORT = true // informe true para mostrar o link de gerar relatório
function generateDateRangeSearchParams(dateString?: string) {
	const dayjsStart = dayjs(dateString ?? new Date())
		.hour(0)
		.minute(0)
	const dayjsEnd = dayjsStart.add(1, 'day')
	const targetZone = 'Europe/London'
	const start = dayjsStart.tz(targetZone).toString()
	const end = dayjsEnd.tz(targetZone).toString()
	const sp = new URLSearchParams()
	sp.set('start', start)
	sp.set('end', end)
	return sp
}

export function ScheduleTableDay() {
	const { professionalId } = useParams()

	const settingsByProfessional = useSettingsByProfessional(professionalId)
	const settingsDefault = useSettingsDefault()
	const settings = settingsByProfessional.data ?? settingsDefault.data
	const isLoadingSettings =
		settingsByProfessional.isLoading || settingsDefault.isLoading

	const { data, isLoading } = useProfessionalSchedule('day')

	const [isLargeScreen] = useMediaQuery('(min-width: 48em)')

	const [searchParams, setSearchParams] = useSearchParams()

	const currentDateParam = searchParams.get('date')
	const currentDate = React.useMemo(
		() =>
			dayjs(currentDateParam ? Date.parse(currentDateParam) : undefined),
		[currentDateParam]
	)
	const currentDateString = currentDate.format('DD/MM')

	const handlePreviousDay = () => {
		const nextDate = currentDate.add(-1, 'day').toJSON()
		setSearchParams(new URLSearchParams([['date', nextDate]]))
		sessionStorage.setItem('agenda:date', nextDate)
	}
	const handleNextDay = () => {
		const nextDate = currentDate.add(1, 'day').toJSON()
		setSearchParams(new URLSearchParams([['date', nextDate]]))
		sessionStorage.setItem('agenda:date', nextDate)
	}

	const schedules = React.useMemo(
		() =>
			settings
				? createSchedules(
						currentDate,
						settings.agenda.startTime,
						settings.agenda.endTime,
						settings.agenda.sessionDurationMinutes
				  )
				: [],
		[currentDate, settings?.agenda]
	)

	const [view, updateView] = React.useReducer(
		(state) => (state === 'schedule' ? 'annotations' : 'schedule'),
		'schedule'
	)

	const { isMobile } = useBreakpointsMediaQuery()

	const isScheduleOnly = isMobile && view === 'schedule'
	const isAnnotationOnly = isMobile && view === 'annotations'
	const tableHeader = useColorModeValue('#F2F2F2', '#182533')

	const professionals = useProfessionals()
	const selectedProfessional = professionals.data?.find(
		(it) => it.id === professionalId
	)

	return (
		<>
			<Table>
				<colgroup>
					<col style={{ width: 54 }} />
					{isMobile ? (
						<col style={{ width: '100%' }} />
					) : (
						<>
							<col />
							<col style={{ width: 12 }} />
							<col style={{ width: 340 }} />
						</>
					)}
				</colgroup>

				<Table.Head>
					<Tr bg={tableHeader}>
						<Td
							as="th"
							colSpan={isLargeScreen ? 2 : 4}
							p="11px 16px"
						>
							<Grid
								gridTemplateColumns="36px 1fr 36px"
								alignItems="center"
								gap="12px"
							>
								{ENABLE_REPORT && (
									<SchedulePdfLink
										data={{
											dentist: {
												name: selectedProfessional?.name,
											},
											date: currentDateString,
											events: data?.events ?? [],
										}}
									/>
									// <GenerateReport
									// 	url={`/dentists/${professionalId}/events/report?${generateDateRangeSearchParams(
									// 		searchParams.get('date')
									// 	)}`}
									// 	name="relatorio.xlsx"
									// 	key={`report-${professionalId}`}
									// />
								)}
								{isAnnotationOnly && (
									<Box>
										<button
											type="button"
											onClick={updateView}
											aria-label="Ver agendamentos"
											style={{
												display: 'flex',
												justifyContent: 'space-between',
												width: '100%',
											}}
										>
											<ArrowBackIcon />
											<UserIcon />
										</button>
									</Box>
								)}
								<Table.Control
									mx="auto"
									current={currentDateString}
									onPrevious={handlePreviousDay}
									onNext={handleNextDay}
								/>
								<Box>
									{isScheduleOnly && (
										<button
											type="button"
											onClick={updateView}
											aria-label="Ver anotações"
											style={{
												display: 'flex',
												justifyContent: 'space-between',
												width: '100%',
											}}
										>
											<FileIcon />
											<ArrowForwardIcon />
										</button>
									)}
								</Box>
							</Grid>
						</Td>
						{isLargeScreen && (
							<>
								<th aria-label="isAvailable?" />
								<Td as="th" textAlign="center" fontWeight={600}>
									Anotações
								</Td>
							</>
						)}
					</Tr>
				</Table.Head>
				{data && settings && (
					<tbody
						style={{
							position: 'sticky',
							height: '100%',
							width: '100%',
						}}
					>
						{schedules.map((schedule, i) => {
							const key = `s${i}`
							return (
								<ScheduleRow
									key={key}
									schedule={schedule}
									data={data}
									settings={settings}
									view={view}
									onChangeView={() => updateView()}
								/>
							)
						})}
					</tbody>
				)}
			</Table>
			{isLoading && !data && (
				<Container
					as="div"
					display="flex"
					alignItems="center"
					justifyContent="center"
					height="full"
				>
					Carregando...
				</Container>
			)}
		</>
	)
}
