import { Document, Page, StyleSheet, Text, View } from '@react-pdf/renderer'

import { Treatment } from 'data/Treatment'

interface Props {
	treatment: Treatment
}

const styles = StyleSheet.create({
	container: { paddingHorizontal: 40, paddingVertical: 24 },
	title: {
		fontSize: 20,
		fontFamily: 'Open Sans',
		fontWeight: 600,
		textAlign: 'center',
		marginBottom: 24,
	},
	list: { gap: 12 },
	line: {
		fontSize: 14,
	},
	row: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		gap: 16,
		fontSize: 14,
	},
	highlight: { fontFamily: 'Open Sans', fontWeight: 600 },
	alignEnd: { display: 'flex', alignItems: 'flex-end' },
	alignCenter: { display: 'flex', alignItems: 'center' },
})

export function TreatmentPdf({ treatment }: Props) {
	const subtotal =
		treatment.items?.reduce((result, item) => {
			result += item.procedure.value * item.quantity
			return result
		}, 0) ?? 0

	const discount = treatment.discount ?? 0
	const discountPercentage = Math.floor((discount / subtotal) * 100) || 0
	const total = subtotal - discount

	return (
		<Document>
			<Page style={styles.container}>
				<Text style={styles.title}>Orçamento do Tratamento</Text>

				<Text style={styles.line}>
					<Text style={styles.highlight}>Paciente:{'  '}</Text>
					<Text>{treatment.customer?.name}</Text>
				</Text>

				<Text style={styles.line}>
					<Text style={styles.highlight}>Profissional:{'  '}</Text>
					<Text>{treatment.professional?.name}</Text>
				</Text>

				{treatment.description && (
					<Text style={styles.line}>
						<Text style={styles.highlight}>Descrição:{'  '}</Text>
						<Text>{treatment.description}</Text>
					</Text>
				)}

				<View
					style={[
						styles.line,
						{ fontSize: 16, marginTop: 20, marginBottom: 8 },
					]}
				>
					<Text style={styles.highlight}>Procedimentos</Text>
				</View>
				<View style={styles.list}>
					<View>
						<View style={styles.row}>
							<Text style={[styles.highlight, { flex: 1 }]}>
								Nome
							</Text>
							<Text style={styles.highlight}>
								Dente/Arcada/Face
							</Text>
							<Text style={styles.highlight}>Valor Unitário</Text>
							<Text style={styles.highlight}>Qtd</Text>
						</View>
					</View>
					{treatment.items?.map((it, i) => {
						return (
							<View key={`${it.procedure.id}:${i}`}>
								<View style={styles.row}>
									<Text style={{ flex: 1 }}>
										{it.procedure.name}
									</Text>
									<View style={{ minWidth: 128 }}>
										<Text>{it.teeth ?? ''}</Text>
									</View>
									<View
										style={[
											styles.alignEnd,
											{ minWidth: 96 },
										]}
									>
										<Text>
											{formatAsCurrency(
												it.procedure.value
											)}
										</Text>
									</View>
									<View
										style={[
											styles.alignCenter,
											{ minWidth: 24 },
										]}
									>
										<Text>{it.quantity ?? 1}</Text>
									</View>
								</View>
							</View>
						)
					})}
				</View>

				<View
					style={[
						styles.line,
						{ fontSize: 16, marginTop: 24, marginBottom: 8 },
					]}
				>
					<Text style={styles.highlight}>Resumo</Text>
				</View>
				<View style={[styles.row, { justifyContent: 'space-between' }]}>
					<Text style={styles.highlight}>Subtotal{'  '}</Text>
					<Text>{formatAsCurrency(subtotal)}</Text>
				</View>
				<View style={[styles.row, { justifyContent: 'space-between' }]}>
					<Text style={styles.highlight}>Desconto (R$){'  '}</Text>
					<Text> {formatAsCurrency(discount)}</Text>
				</View>
				<View style={[styles.row, { justifyContent: 'space-between' }]}>
					<Text style={styles.highlight}>Desconto (%){'  '}</Text>
					<Text>{discountPercentage}</Text>
				</View>
				<View
					style={[
						styles.row,
						{ justifyContent: 'space-between' },
						styles.highlight,
						{ fontSize: 16 },
					]}
				>
					<Text>Total{'  '}</Text>
					<Text>{formatAsCurrency(total)}</Text>
				</View>
			</Page>
		</Document>
	)
}

function formatAsCurrency(rawValue: number) {
	const currency = 'BRL'
	const formattedValue = new Intl.NumberFormat(undefined, {
		style: 'currency',
		currency,
	})
		.format(rawValue)
		.trim()
	return formattedValue
}
