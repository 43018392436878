import { AddIcon, EditIcon } from '@chakra-ui/icons'
import {
	TableContainer,
	Table,
	Tbody,
	Thead,
	Th,
	Td,
	IconButton,
	Text,
	Flex,
	Center,
	Spinner,
	Heading,
	Button,
	Stack,
	Grid,
	Box,
} from '@chakra-ui/react'
import { Link, useParams, useSearchParams } from 'react-router-dom'
import dayjs from 'dayjs'

import { TablePagination } from 'app/components/TablePagination'
import { usePaymentsQuery } from '../queries/usePaymentsQuery'
import { PaymentModal } from './PaymentModal'
import { Can } from 'ability'
import { DownloadIcon } from 'app/icons/DownloadIcon'

export function CustomerPaymentsRoute() {
	const params = useParams()
	const [searchParams, setSearchParams] = useSearchParams()
	const page = Math.abs(Number(searchParams.get('page')) || 1)
	const onPageChange = (nextPage: number) => {
		setSearchParams(
			(init) => new URLSearchParams({ ...init, page: String(nextPage) })
		)
	}
	const customer = params.id

	const query = usePaymentsQuery({ page, customer })

	const payments = query.data?.data
	const paginationInfo = query.data?.meta

	const isEmpty = payments?.length <= 0

	return (
		<Stack px="6" py="10" h="100%" gap="6">
			<Flex justify="space-between" flexWrap="wrap" gap="5">
				<Heading fontSize="2xl">Listagem de Pagamentos</Heading>

				<Can I="create" a="Payment">
					<PaymentModal
						trigger={
							<Button
								size="sm"
								fontSize="xs"
								colorScheme="primary"
								textTransform="uppercase"
								leftIcon={<AddIcon />}
							>
								Pagamento
							</Button>
						}
					/>
				</Can>
			</Flex>

			<Flex>
				<Stack>
					<Text fontWeight="600">Paciente</Text>
					<Grid
						templateColumns="32px 1fr"
						alignItems="center"
						bg="white"
						gap="3"
						px="4"
						py="3"
						rounded="md"
						border="1px solid"
						borderColor="inherit"
						_dark={{ bg: '#182533' }}
						w="240px"
						maxW="100%"
					>
						<Box
							w="8"
							h="8"
							rounded="full"
							bg="gray.300"
							_dark={{ bg: 'gray.700' }}
						/>
						<Text
							fontWeight="600"
							fontSize="sm"
							whiteSpace="nowrap"
							overflow="hidden"
							textOverflow="ellipsis"
							_hover={{ textDecoration: 'underline' }}
							as={Link}
							to={`/registros/customers/${payments?.[0]?.customer?.id}`}
						>
							{payments?.[0]?.customer?.name}
						</Text>
					</Grid>
				</Stack>
			</Flex>

			<TableContainer
				bg="white"
				_dark={{ bg: '#182533' }}
				p="4"
				rounded="12"
				h="100%"
				mb="6"
				position="relative"
			>
				<Table variant="striped">
					<Thead>
						<tr>
							<Th fontSize="sm">Consulta/Tratamento</Th>
							<Th fontSize="sm">Tipo</Th>
							<Th fontSize="sm">Valor Final</Th>
							<Th fontSize="sm">Vencimento</Th>
							<Th fontSize="sm">Status</Th>
							<Th fontSize="sm"></Th>
						</tr>
					</Thead>
					<Tbody>
						{payments &&
							!isEmpty &&
							payments.map((it) => {
								return (
									<tr key={it.id}>
										<Td>
											{it.treatment
												? 'Tratamento'
												: 'Consulta'}
										</Td>
										<Td>
											{it.treatment
												? 'Tratamento - ENTRADA'
												: 'Consulta'}
										</Td>
										<Td>
											{it.value?.toLocaleString([], {
												style: 'currency',
												currency: 'BRL',
											})}
										</Td>
										<Td>
											{it.dueDate
												? dayjs(it.dueDate)
														.utc()
														.format('DD/MM/YYYY')
												: '-'}
										</Td>
										<Td>
											<Text
												textTransform="uppercase"
												color={
													it.status === 'paid'
														? '#00CC66'
														: '#EE4444'
												}
											>
												{it.status === 'paid'
													? 'Pago'
													: 'Pendente'}
											</Text>
										</Td>

										<Td>
											<Flex gap="3" justifyContent="end">
												{it.file?.url && (
													<IconButton
														display="flex"
														variant="unstyled"
														color="#0C6"
														bg="#f9f9f9"
														_dark={{
															bg: '#223344',
														}}
														aria-label="Baixar recibo"
														icon={
															<DownloadIcon
																size={20}
															/>
														}
														as="a"
														download={it.file.url
															.split('/')
															.at(-1)
															.split('?')
															.at(0)}
														href={it.file.url}
														target="_blank"
													/>
												)}
												<Can I="update" a="Payment">
													<PaymentModal
														payment={it}
														trigger={
															<IconButton
																colorScheme="primary"
																variant="unstyled"
																bg="#f9f9f9"
																_dark={{
																	bg: '#223344',
																}}
																aria-label="Editar pagamento"
																icon={
																	<EditIcon />
																}
															/>
														}
													/>
												</Can>
											</Flex>
										</Td>
									</tr>
								)
							})}

						{!query.data && query.isLoading && (
							<tr style={{ display: 'block' }}>
								<td>
									<Center
										position="absolute"
										h="100%"
										top="50%"
										left="50%"
										transform="translate(-50%,-50%)"
									>
										<Spinner />
									</Center>
								</td>
							</tr>
						)}

						{isEmpty && (
							<tr style={{ display: 'block' }}>
								<td>
									<Center
										flexDirection="column"
										position="absolute"
										h="100%"
										top="50%"
										left="50%"
										transform="translate(-50%,-50%)"
									>
										<Text>
											Nenhum pagamento salvo ainda
										</Text>
										<Text>
											Clique no botão {'"+ Pagamento"'}{' '}
											para criar um novo
										</Text>
									</Center>
								</td>
							</tr>
						)}
						{query.isLoadingError && (
							<tr style={{ display: 'block' }}>
								<td>
									<Center
										flexDirection="column"
										position="absolute"
										h="100%"
										top="50%"
										left="50%"
										transform="translate(-50%,-50%)"
									>
										<Text>
											Algo deu errado ao carregar esses
											dados...
										</Text>
										<Text>{String(query.error)}</Text>
									</Center>
								</td>
							</tr>
						)}
					</Tbody>
				</Table>
			</TableContainer>

			{paginationInfo && paginationInfo.total > 0 && (
				<Flex
					alignItems="center"
					justify="end"
					flexDirection={{ base: 'column', md: 'row' }}
					columnGap="5"
					rowGap="3"
				>
					<TablePagination
						total={paginationInfo.total}
						perPage={paginationInfo.perPage}
						page={page}
						onPageChange={onPageChange}
					/>

					<Text
						fontSize="xs"
						fontWeight="medium"
						textTransform="uppercase"
					>
						{paginationInfo.total} registros
					</Text>
				</Flex>
			)}
		</Stack>
	)
}
