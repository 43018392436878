import type { AriaListBoxOptions } from '@react-aria/listbox'
import type { Node } from '@react-types/shared'
import { Item, ListState, OverlayTriggerState } from 'react-stately'
import { CheckIcon } from '@chakra-ui/icons'
import {
	Box,
	Input,
	InputGroup,
	InputProps,
	InputRightElement,
	List,
	ListItem,
	Spinner,
} from '@chakra-ui/react'
import { useComboBoxState } from 'react-stately'
import {
	useListBox,
	useOption,
	useComboBox,
	useFilter,
	DismissButton,
	Overlay,
	AriaPopoverProps,
	usePopover,
	AriaComboBoxProps,
} from 'react-aria'
import { useFormContext } from 'react-hook-form'
import React from 'react'

type Props<T> = AriaComboBoxProps<T> &
	Omit<InputProps, 'children'> & {
		name: string

		loading?: boolean
		loadingMore?: boolean
		onLoadMore?: () => void
	}

export function Combobox<T extends object>({
	name,

	items,
	children,
	inputValue,
	onInputChange,

	loading,
	loadingMore,
	onLoadMore,
	...props
}: Props<T>) {
	const form = useFormContext()

	const filter = useFilter({ sensitivity: 'base' })
	const state = useComboBoxState({
		...props,
		items,
		children,
		inputValue,
		onInputChange,
		onSelectionChange(key) {
			form.setValue(name, key)
		},
		defaultFilter: filter.contains,
	})
	const inputRef = React.useRef<HTMLInputElement>(null)
	const listBoxRef = React.useRef(null)
	const popoverRef = React.useRef(null)
	const combobox = useComboBox(
		{ ...props, items, inputRef, listBoxRef, popoverRef },
		state
	)

	React.useEffect(() => {
		if (!loading && inputRef.current === document.activeElement) {
			state.open()
		}
	}, [loading])

	React.useLayoutEffect(() => {
		const field = form.register(name, { required: true })
		field.ref(inputRef.current)
	}, [])

	return (
		<InputGroup position="relative" size="sm">
			<Input
				{...combobox.inputProps}
				{...props}
				ref={inputRef}
				size="sm"
				onFocus={() => state.open()}
			/>
			{loading && (
				<InputRightElement>
					<Spinner color="primary.800" size="sm" />
				</InputRightElement>
			)}

			{state.isOpen && (
				<Popover
					popoverRef={popoverRef}
					triggerRef={inputRef}
					state={state}
					isNonModal
					placement="bottom start"
				>
					<ListBox
						{...combobox.listBoxProps}
						listBoxRef={listBoxRef}
						state={state}
						loadingMore={loadingMore}
						onLoadMore={onLoadMore}
					/>
				</Popover>
			)}
		</InputGroup>
	)
}

Combobox.Item = Item

/* POPOVER */

interface PopoverProps extends Omit<AriaPopoverProps, 'popoverRef'> {
	children: React.ReactNode
	state: OverlayTriggerState
	popoverRef?: React.RefObject<HTMLDivElement>
}

export function Popover(props: PopoverProps) {
	const ref = React.useRef<HTMLDivElement>(null)
	const { popoverRef = ref, state, children, isNonModal } = props

	const { popoverProps, underlayProps } = usePopover(
		{
			...props,
			popoverRef,
		},
		state
	)

	return (
		<Overlay>
			{!isNonModal && (
				<div
					{...underlayProps}
					style={{ position: 'fixed', inset: 0 }}
				/>
			)}
			<Box
				{...popoverProps}
				ref={popoverRef}
				background="white"
				border="1px solid"
				borderColor="gray.200"
				borderRadius="md"
				zIndex="10"
				width={
					(props.triggerRef.current as HTMLElement)?.offsetWidth ||
					'auto'
				}
				// width="100%"
				boxShadow="lg"
				transform="translateY(0.25rem)"
				_dark={{ bg: 'gray.700', borderColor: 'gray.800' }}
			>
				{!isNonModal && <DismissButton onDismiss={state.close} />}
				{children}
				<DismissButton onDismiss={state.close} />
			</Box>
		</Overlay>
	)
}

/* LISTBOX */

interface ListBoxProps extends AriaListBoxOptions<unknown> {
	listBoxRef?: React.RefObject<HTMLUListElement>
	state: ListState<unknown>
	loadingMore?: boolean
	onLoadMore?: () => void
}

interface OptionProps {
	item: Node<unknown>
	state: ListState<unknown>
}

export function ListBox(props: ListBoxProps) {
	const ref = React.useRef<HTMLUListElement>(null)
	const { listBoxRef = ref, state } = props
	const { listBoxProps } = useListBox(props, state, listBoxRef)

	const onScroll = (e: React.UIEvent) => {
		const scrollOffset =
			e.currentTarget.scrollHeight - e.currentTarget.clientHeight * 2
		if (e.currentTarget.scrollTop > scrollOffset && props.onLoadMore) {
			props.onLoadMore()
		}
	}

	return (
		<List
			{...listBoxProps}
			ref={listBoxRef}
			overflow="auto"
			width="100%"
			maxHeight="300"
			my="1"
			display="flex"
			flexDirection="column"
			onScroll={onScroll}
		>
			{Array.from(state.collection, (item) => (
				<Option key={item.key} item={item} state={state} />
			))}
			{props.loadingMore && (
				// Display a spinner at the bottom of the list if we're loading more.
				// role="option" is required for valid ARIA semantics since
				// we're inside a role="listbox".
				<Box
					role="option"
					pt="4"
					pb="2"
					display="flex"
					justifyContent="center"
				>
					<Spinner color="primary.800" size="sm" />
				</Box>
			)}
		</List>
	)
}

function Option({ item, state }: OptionProps) {
	const ref = React.useRef<HTMLLIElement>(null)
	const { optionProps, isSelected, isFocused } = useOption(
		{ key: item.key },
		state,
		ref
	)

	return (
		<ListItem
			{...optionProps}
			as="li"
			ref={ref}
			px="2"
			py="2"
			background={isFocused ? 'primary.50' : 'white'}
			color={isFocused ? 'primary.700' : 'gray.700'}
			fontWeight={isSelected ? 'bold' : 'normal'}
			cursor="default"
			display="flex"
			alignItems="center"
			justifyContent="space-between"
			fontSize="sm"
			_dark={{
				background: isFocused ? 'primary.900' : 'transparent',
				color: isFocused ? 'primary.100' : 'gray.200',
			}}
		>
			{item.rendered}
			{isSelected && <CheckIcon />}
		</ListItem>
	)
}
