export const itemsTypePerson = [
	{ value: 'PF', label: 'Física' },
	{ value: 'PJ', label: 'Jurídica' },
]

export const itemsHasResponsible = [
	{ value: 'Yes', label: 'Sim' },
	{ value: 'No', label: 'Nao' },
]

export const itemsGenre = [
	{ value: 'masculino', label: 'Masculino' },
	{ value: 'feminino', label: 'Feminino' },
]

export const itemsMaritalStatus = [
	{ value: 'solteiro(a)', label: 'Solteiro(a)' },
	{ value: 'casado(a)', label: 'Casado(a)' },
	{ value: 'separado(a)', label: 'Separado(a)' },
	{ value: 'divorciado(a)', label: 'Divorciado(a)' },
	{ value: 'viúvo(a)', label: 'Viúvo(a)' },
]
