/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @typescript-eslint/ban-ts-comment */
import { Grid, GridItem } from '@chakra-ui/react'
import { Modal } from 'app/components/Modal'
import { useBreakpointsMediaQuery } from 'app/hooks/useMediaQuery'
import { Form } from 'modules/records/components/Form'
import { FormActions } from 'modules/records/components/FormActions'
import { Table } from 'modules/records/components/Table'
import { DesktopTableFilters } from 'modules/records/components/TableFilters/DesktopTableFilters'
import { MobileTableFilters } from 'modules/records/components/TableFilters/MobileTableFilters'
import { useRecordsManagement } from 'modules/records/hooks/useRecordsManagement'

// TO-DO
//  1- Melhorar componentização do Modal

export const ModuleRecordGrid = () => {
	const { isMobile, isTablet, isDesktop } = useBreakpointsMediaQuery()

	const {
		isOpenRecordFormModal,
		closeRegistrationFormModal,
		handleSubmit,
		onSubmit,
		isCreateRecordLoading,
	} = useRecordsManagement()

	return (
		<>
			<Grid
				h="100%"
				maxHeight="100vh"
				templateRows="48px 48px auto"
				templateColumns="100%"
				mt="48px"
			>
				{isMobile && <MobileTableFilters />}
				{(isDesktop || isTablet) && <DesktopTableFilters />}

				<GridItem
					colSpan={1}
					rowSpan={1}
					height="auto"
					width="90%"
					margin="32px auto"
				>
					<Table />
				</GridItem>
			</Grid>

			<Modal
				isOpen={isOpenRecordFormModal}
				onClose={() => {
					if (window.confirm('Gostaria mesmo de sair?')) {
						closeRegistrationFormModal()
					}
				}}
				title="Cadastro"
				body={
					<form id="register-form" onSubmit={handleSubmit(onSubmit)}>
						<Form />
					</form>
				}
				footer={
					<FormActions
						isLoading={isCreateRecordLoading}
						form="register-form"
					/>
				}
				dividers
			/>
		</>
	)
}
