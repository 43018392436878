/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unused-expressions */
/* eslint-disable @typescript-eslint/ban-ts-comment */
import { useToast } from '@chakra-ui/react'
import useDebounce from 'app/hooks/useDebounce'
import { useGetAllProcedures } from 'modules/history/queries/useGetAllProcedures'
import { useGetCustomer } from 'modules/history/queries/useGetCustomer'
import { useGetAllProfessionals } from 'modules/history/queries/useGettAllProfessionals'
import React, { createContext, useEffect, useMemo, useState } from 'react'
import { UseFormHandleSubmit, useFormContext } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import Swal from 'sweetalert2'
import { useCreateProcedure } from 'modules/history/queries/useCreateProcedure'
import { useEditProcedure } from 'modules/history/queries/useEditProcedure'
import dayjs from 'dayjs'
import { defaultValuesProps } from '../ProceduresFormManagementContext/defaultValues'
import { useCreateDocument } from 'modules/history/queries/useCreateDocument'

interface ProceduresManagementContextProvider {
	children: React.ReactNode
}

interface ProceduresManagementContextProps {
	allProfessionals: any
	customer: any
	customerId: string
	setCustomerId: React.Dispatch<React.SetStateAction<string>>
	getAllProfessionalsIsLoading: boolean
	allProcedures: any
	totalOfAllProcedures: number
	getAllProceduresIsLoading: boolean
	setFilterByTypeInfo: React.Dispatch<React.SetStateAction<string>>
	setOffsetPage: React.Dispatch<React.SetStateAction<number>>
	setInfoSearch: React.Dispatch<React.SetStateAction<string>>
	filterByTypeInfo: string
	offsetPage: number
	infoSearch: string
	setSearch: React.Dispatch<React.SetStateAction<string>>
	search: string
	setInfoFilter: React.Dispatch<React.SetStateAction<string>>
	infoFilter: string
	isOpenNewProcedureForm: boolean
	setIsOpenNewProcedureForm: React.Dispatch<React.SetStateAction<boolean>>
	isOpenProcedureForm: boolean
	setIsOpenProcedureForm: React.Dispatch<React.SetStateAction<boolean>>
	handleSubmit: UseFormHandleSubmit<defaultValuesProps>
	onSubmit: (data: defaultValuesProps) => void
	isEdit: boolean
	setIsEdit: React.Dispatch<React.SetStateAction<boolean>>
	handleEditSubmit: UseFormHandleSubmit<defaultValuesProps>
	onEditSubmit: (data: defaultValuesProps) => void
	handleAnamnesisSubmit: UseFormHandleSubmit<{ id: string; files: FileList }>
	onAnamnesisSubmit: (data: { id: string; files: FileList }) => void
	isOpenAnamnesisForm: boolean
	setIsOpenAnamnesisForm: React.Dispatch<React.SetStateAction<boolean>>
	isCreateProcedureLoading: boolean
	isEditProcedureLoading: boolean
	isCreateAnamnesisLoading: boolean
	procedureId: string
	setProcedureId: React.Dispatch<React.SetStateAction<string>>
}

const ProceduresManagementContext = createContext(
	{} as ProceduresManagementContextProps
)

export const ProceduresManagementContextProvider = ({
	children,
}: {
	children: React.ReactNode
}) => {
	const navigate = useNavigate()
	const [allProfessionals, setAllProfessionals] = useState([])
	const { handleSubmit, reset } = useFormContext<defaultValuesProps>()
	const { handleSubmit: handleEditSubmit } =
		useFormContext<defaultValuesProps>()
	const { handleSubmit: handleAnamnesisSubmit } = useFormContext<{
		id: string
		files: FileList
	}>()

	const [allProcedures, setAllProcedures] = useState([])
	const [procedureId, setProcedureId] = useState('')

	// const [allCustomers, setAllCustomers] = useState([])

	const [customer, setCustomer] = useState([])
	const [customerId, setCustomerId] = useState('')

	const [totalOfAllProcedures, setTotalOfAllProcedures] = useState(0)
	const [filterByTypeInfo, setFilterByTypeInfo] = useState('')
	const [offsetPage, setOffsetPage] = useState(0)
	const [infoSearch, setInfoSearch] = useState('')
	const [infoFilter, setInfoFilter] = useState('')
	const [search, setSearch] = useState('')
	const [isOpenProcedureForm, setIsOpenProcedureForm] = useState(false)
	const [isOpenNewProcedureForm, setIsOpenNewProcedureForm] = useState(false)
	const [isOpenAnamnesisForm, setIsOpenAnamnesisForm] = useState(false)

	const [isEdit, setIsEdit] = useState(false)
	const debouncedInfoSearch = useDebounce(infoSearch, 2000)
	const toast = useToast()

	const filtersForRequestAllProcedures = {
		typeInfo: filterByTypeInfo,
		typeSearch: search,
		offset: offsetPage,
		search: debouncedInfoSearch,
		infoFilter,
	}

	useEffect(() => {
		setOffsetPage(0)
	}, [debouncedInfoSearch, filterByTypeInfo])

	const {
		data,
		refetch: refetchProcedures,
		isLoading: getAllProceduresIsLoading,
	} = useGetAllProcedures({
		params: filtersForRequestAllProcedures,
		onError: () => {
			toast({
				title: 'Ops...',
				description: 'Ocorreu um erro ao listar registros!',
				status: 'error',
				duration: 9000,
				isClosable: false,
				position: 'top-right',
			})
		},
	})
	React.useEffect(() => {
		if (data) {
			setAllProcedures(data as any)
			setTotalOfAllProcedures(data?.count)
		}
	}, [data])
	const { isLoading: getAllProfessionalsIsLoading } = useGetAllProfessionals({
		onSuccess: (data) => {
			// @ts-ignore
			setAllProfessionals(data)

			// @ts-ignore
		},
		onError: () => {
			toast({
				title: 'Ops...',
				description: 'Ocorreu um erro ao listar profissionais!',
				status: 'error',
				duration: 9000,
				isClosable: false,
				position: 'top-right',
			})
		},
	})
	const { isLoading: getCustomerLoading } = useGetCustomer({
		params: { customerId },
		onSuccess: (data: any) => {
			// @ts-ignore
			setCustomer(data)
			// @ts-ignore
			// setTotalOfAllProcedures(data.count)
		},
		onError: () => {
			toast({
				title: 'Ops...',
				description: 'Ocorreu um erro ao listar registros!',
				status: 'error',
				duration: 9000,
				isClosable: false,
				position: 'top-right',
			})
		},
	})

	const {
		mutate: handleCreateProcedure,
		isPending: isCreateProcedureLoading,
	} = useCreateProcedure({
		onSuccess: () => {
			Swal.fire({
				title: 'Sucesso!',
				text: 'Registro criado com sucesso!',
				icon: 'success',
				confirmButtonText: 'Ok',
			})
			setIsOpenNewProcedureForm(false)

			refetchProcedures()
			reset()
		},
		onError: () => {
			toast({
				title: 'Ops...',
				description: 'Erro ao cadastrar registro!',
				status: 'error',
				duration: 9000,
				isClosable: false,
				position: 'top-right',
			})
		},
	})
	const { mutate: handleEditProcedure, isPending: isEditProcedureLoading } =
		useEditProcedure({
			onSuccess: () => {
				setIsOpenProcedureForm(false)
				Swal.fire({
					title: 'Sucesso!',
					text: 'Registro criado com sucesso!',
					icon: 'success',
					confirmButtonText: 'Ok',
				})
				refetchProcedures()
			},
			onError: () => {
				toast({
					title: 'Ops...',
					description: 'Erro ao cadastrar registro!',
					status: 'error',
					duration: 9000,
					isClosable: false,
					position: 'top-right',
				})
			},
		})
	const onSubmit = (data: defaultValuesProps) => {
		const formData = new FormData()

		formData.set('name', data.name)
		formData.set('date', dayjs(data.date).toISOString())
		formData.set('observation', data.observation ?? '')
		formData.set('dentist', data.dentist)
		formData.set('customer', data.customer)
		if (data.files) {
			const filesArray = Array.from(data.files)
			filesArray?.forEach((file) => {
				formData.append('files', file)
			})
		}

		handleCreateProcedure({
			formData,
		})
	}
	const onEditSubmit = (data: defaultValuesProps) => {
		const formData = new FormData()
		formData.set('name', data.name)
		formData.set('date', dayjs(data.date).toISOString())
		formData.set('observation', data.observation)
		if (data.observation === '') {
			formData.set('observation', ' ')
		}
		if (data.files) {
			const filesArray = Array.from(data.files)
			filesArray?.forEach((file) => {
				formData.append('files', file)
			})
		}
		handleEditProcedure({
			formData,
			procedure: data.procedure,
		})
	}

	const {
		mutate: handleCreateAnamnesis,
		isPending: isCreateAnamnesisLoading,
	} = useCreateDocument()

	const onAnamnesisSubmit = (data: { files: FileList; id: string }) => {
		const formData = new FormData()
		formData.set('customer', data.id)
		if (data.files) {
			const filesArray = Array.from(data.files)
			filesArray?.forEach((file) => {
				formData.append('files', file)
			})
		}

		handleCreateAnamnesis(formData, {
			onSuccess: () => {
				Swal.fire({
					title: 'Sucesso!',
					text: 'Registro criado com sucesso!',
					icon: 'success',
					confirmButtonText: 'Ok',
				})
				setIsOpenAnamnesisForm(false)
			},
			onError: () => {
				toast({
					title: 'Ops...',
					description: 'Erro ao cadastrar registro!',
					status: 'error',
					duration: 9000,
					isClosable: false,
					position: 'top-right',
				})
			},
		})
	}

	const value: ProceduresManagementContextProps = useMemo(() => {
		return {
			allProfessionals,
			getAllProfessionalsIsLoading,
			allProcedures,
			totalOfAllProcedures,
			getAllProceduresIsLoading,
			filterByTypeInfo,
			offsetPage,
			infoSearch,
			setFilterByTypeInfo,
			setOffsetPage,
			setInfoSearch,
			isOpenProcedureForm,
			setIsOpenProcedureForm,
			isOpenNewProcedureForm,
			setIsOpenNewProcedureForm,
			customer,
			customerId,
			setCustomerId,
			onSubmit,
			handleSubmit,
			isEdit,
			setIsEdit,
			onEditSubmit,
			handleEditSubmit,
			search,
			setSearch,
			infoFilter,
			setInfoFilter,
			handleAnamnesisSubmit,
			onAnamnesisSubmit,
			isOpenAnamnesisForm,
			setIsOpenAnamnesisForm,
			isCreateProcedureLoading,
			isEditProcedureLoading,
			isCreateAnamnesisLoading,
			procedureId,
			setProcedureId,
		}
	}, [
		allProfessionals,
		getAllProfessionalsIsLoading,
		allProcedures,
		totalOfAllProcedures,
		getAllProceduresIsLoading,
		filterByTypeInfo,
		offsetPage,
		infoSearch,
		isOpenProcedureForm,
		setIsOpenProcedureForm,
		isOpenNewProcedureForm,
		setIsOpenNewProcedureForm,
		customer,
		customerId,
		setCustomerId,
		onSubmit,
		handleSubmit,
		isEdit,
		setIsEdit,
		onEditSubmit,
		handleEditSubmit,
		search,
		setSearch,
		infoFilter,
		setInfoFilter,
		handleAnamnesisSubmit,
		onAnamnesisSubmit,
		isOpenAnamnesisForm,
		setIsOpenAnamnesisForm,
		isCreateProcedureLoading,
		isEditProcedureLoading,
		isCreateAnamnesisLoading,
		procedureId,
		setProcedureId,
	])

	return (
		<ProceduresManagementContext.Provider value={value}>
			{children}
		</ProceduresManagementContext.Provider>
	)
}

export default ProceduresManagementContext
