import { useToast } from '@chakra-ui/react'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { PaymentsRepository } from 'data/PaymentsRepository'

export function useUpdatePaymentMutation() {
	const client = useQueryClient()
	const toast = useToast()

	return useMutation({
		async mutationFn(data: any) {
			const paymentsRepository = new PaymentsRepository()
			return await paymentsRepository.updatePayment(data)
		},
		onSuccess: () => {
			toast({
				status: 'success',
				title: 'Sucesso',
				description: 'Pagamento salvo!',
			})
			return client.invalidateQueries({ queryKey: ['payments'] })
		},
		onError: (error) =>
			toast({
				status: 'error',
				title: 'Erro ao salvar',
				description: error.message,
			}),
	})
}
