import React from 'react'
import { defineAbility } from '@casl/ability'
import { getUserInfoInStorage } from 'services/api/auth'
import { createContextualCan, useAbility as useCaslAbility } from '@casl/react'

export const createAbility = (user: any) =>
	defineAbility((can) => {
		if (user) {
			const permissions = user.permissions ?? {}
			const roles = (user.roles ?? []).map((it: any) => it.name)
			if (roles.includes('professional')) {
				can('manage', 'Dentist', { user: user.id })
				can('manage', 'Appointment', { clinic: user.activeClinic })
			}
			if (roles.includes('secretary')) {
				can('manage', 'Dentist', { clinic: user.activeClinic })
			}
			if (roles.includes('financial')) {
				can('manage', 'Payment', { clinic: user.activeClinic })
				can('manage', 'Bill', { clinic: user.activeClinic })
			}
			if (roles.includes('owner')) {
				can('manage', 'Customer', { clinic: user.activeClinic })
				can('manage', 'Worker', { clinic: user.activeClinic })
				can('manage', 'Appointment', { clinic: user.activeClinic })
				can('manage', 'Payment', { clinic: user.activeClinic })
				can('manage', 'Bill', { clinic: user.activeClinic })
				can('manage', 'Treatment', { clinic: user.activeClinic })
				can('manage', 'Product', { clinic: user.activeClinic })
				can('manage', 'User', { clinics: { $in: user.clinics } })
				can('manage', 'ProcedureType', { clinic: user.activeClinic })
				can('manage', 'Settings', { clinic: user.activeClinic })
				can('read', 'Report', { clinic: user.activeClinic })
			}

			if (permissions.readCustomer)
				can('read', 'Customer', { clinic: user.activeClinic })
			if (permissions.createCustomer)
				can('create', 'Customer', { clinic: user.activeClinic })
			if (permissions.updateCustomer)
				can('update', 'Customer', { clinic: user.activeClinic })

			if (permissions.readWorker)
				can('read', 'Worker', { clinic: user.activeClinic })
			if (permissions.createWorker)
				can('create', 'Worker', { clinic: user.activeClinic })
			if (permissions.updateWorker)
				can('update', 'Worker', { clinic: user.activeClinic })

			if (permissions.readAppointment)
				can('read', 'Appointment', { clinic: user.activeClinic })
			if (permissions.createAppointment)
				can('create', 'Appointment', { clinic: user.activeClinic })
			if (permissions.updateAppointment)
				can('update', 'Appointment', { clinic: user.activeClinic })

			if (permissions.readProcedure)
				can('read', 'ProcedureType', ['name', 'value'], {
					clinic: user.activeClinic,
				})
			if (permissions.createProcedure)
				can('create', 'ProcedureType', ['name', 'value'], {
					clinic: user.activeClinic,
				})
			if (permissions.updateProcedure)
				can('update', 'ProcedureType', ['name', 'value'], {
					clinic: user.activeClinic,
				})

			if (permissions.manageSettings)
				can('manage', 'Settings', { clinic: user.activeClinic })

			if (permissions.readReports)
				can('read', 'Report', { clinic: user.activeClinic })

			if (permissions.readPayment)
				can('read', 'Payment', { clinic: user.activeClinic })
			if (permissions.createPayment)
				can('create', 'Payment', { clinic: user.activeClinic })
			if (permissions.updatePayment)
				can('update', 'Payment', { clinic: user.activeClinic })
			if (permissions.deletePayment)
				can('delete', 'Payment', { clinic: user.activeClinic })

			if (permissions.readBill)
				can('read', 'Bill', { clinic: user.activeClinic })
			if (permissions.createBill)
				can('create', 'Bill', { clinic: user.activeClinic })
			if (permissions.updateBill)
				can('update', 'Bill', { clinic: user.activeClinic })
			if (permissions.deleteBill)
				can('delete', 'Bill', { clinic: user.activeClinic })

			if (permissions.readTreatment)
				can('read', 'Treatment', { clinic: user.activeClinic })
			if (permissions.createTreatment)
				can('create', 'Treatment', { clinic: user.activeClinic })
			if (permissions.updateTreatment)
				can('update', 'Treatment', { clinic: user.activeClinic })

			if (permissions.readProduct)
				can('read', 'Product', { clinic: user.activeClinic })
			if (permissions.createProduct)
				can('create', 'Product', { clinic: user.activeClinic })
			if (permissions.updateProduct)
				can('update', 'Product', { clinic: user.activeClinic })
		}
	})

export const AbilityContext = React.createContext(getUserInfoInStorage())

export const Can = createContextualCan(AbilityContext.Consumer)

export const useAbility = () => useCaslAbility(AbilityContext)
