/* eslint-disable no-underscore-dangle */
import { EditIcon, ViewIcon } from '@chakra-ui/icons'
import {
	Button,
	Flex,
	Heading,
	Menu,
	MenuButton,
	MenuItem,
	MenuList,
	Skeleton,
	Table,
	TableContainer,
	Td,
	Th,
	Thead,
	useColorModeValue,
} from '@chakra-ui/react'
import dayjs from 'dayjs'
import { Link, Outlet, useParams } from 'react-router-dom'

import { useGetDocuments } from './queries/useGetDocuments'
import { useGetCustomer } from './queries/useGetCustomer'

export default function DocumentsList() {
	const { customerId, type } = useParams()
	const customerQuery = useGetCustomer({ params: { customerId } })
	const customer = customerQuery.data

	const documentsQuery = useGetDocuments()
	const documents = documentsQuery.data

	const btnBg = useColorModeValue('#F9F9F9', 'whiteAlpha.50')
	const procedureBtn = useColorModeValue('#f2f2f2', 'whiteAlpha.50')

	const renderHeading = () => {
		switch (type) {
			case 'anamnesis':
				return 'Anamneses do Paciente'
			case 'radiograph':
				return 'Radiografias do Paciente'
			default:
				return 'Outros Documentos do Paciente'
		}
	}

	const renderTitle = () => {
		switch (type) {
			case 'anamnesis':
				return 'Anamnese'
			case 'radiograph':
				return 'Radiografia'
			default:
				return 'Documento'
		}
	}

	return (
		<Flex
			direction="column"
			h="100vh"
			maxHeight="100vh"
			// bg="#F9F9F9"
			rowGap="24px"
			paddingX={6}
			paddingY="40px"
		>
			<Heading as="h2" fontSize="24px">
				{renderHeading()}
			</Heading>
			<Flex
				as="header"
				gap={6}
				alignItems="center"
				justifyContent="space-between"
				flexWrap="wrap"
			>
				<Flex alignItems="center" columnGap="10px">
					<Heading as="h3" fontSize="20px" color="#1188EE">
						{customer?.name}
					</Heading>
					<Link
						to={`/registros/customers/${customerId}`}
						style={{
							padding: '10px 0',
							fontWeight: '600',
							color: '#1188EE',
							borderRadius: '6px',
						}}
					>
						<EditIcon color="#55BBDD" boxSize={5} />
					</Link>
				</Flex>

				<Flex
					flexDirection={{ base: 'column', md: 'row' }}
					justifyContent="flex-end"
					w="100%"
					gap={6}
				>
					<Button
						bg={procedureBtn}
						color="#55BBDD"
						as={Link}
						to={`/historico/${customerId}`}
					>
						Procedimentos
					</Button>

					<Menu>
						<MenuButton
							as={Button}
							bg={procedureBtn}
							color="#55BBDD"
						>
							Documentos
						</MenuButton>
						<MenuList>
							<MenuItem
								as={Link}
								to={`/historico/${customerId}/documentos/anamnesis`}
								replace
							>
								Anamneses
							</MenuItem>
							<MenuItem
								as={Link}
								to={`/historico/${customerId}/documentos/radiograph`}
								replace
							>
								Radiografia
							</MenuItem>
							<MenuItem
								as={Link}
								to={`/historico/${customerId}/documentos/document`}
								replace
							>
								Documentos
							</MenuItem>
						</MenuList>
					</Menu>

					<Button
						bg="#55BBDD"
						color="white"
						as={Link}
						to={`/historico/${customerId}/documentos/${type}/novo`}
						replace
					>
						+ {renderTitle()}
					</Button>
				</Flex>
			</Flex>
			{documentsQuery.isLoading && !documents && (
				<Skeleton
					height="60vh"
					width="100%"
					mt={{ sm: '0px', md: '32px', lg: '32px' }}
				/>
			)}
			{documents && (
				<>
					<TableContainer width="100%">
						<Table variant="striped">
							<colgroup>
								<col style={{ width: '90%' }} />
								<col style={{ width: '10%' }} />
							</colgroup>
							<Thead>
								<tr>
									<Th>Data</Th>
									<Th>Título</Th>
									<Th>Detalhes</Th>
								</tr>
							</Thead>
							<tbody>
								{documents.data.map((row) => (
									<tr key={row._id}>
										<Td>
											{dayjs(row.createdAt).format(
												'DD/MM/YYYY'
											)}
										</Td>
										<Td>{row.title || ''}</Td>
										<Td>
											<Button
												display="flex"
												margin="0 auto"
												width="32px"
												height="32px"
												borderRadius="4px"
												bg={btnBg}
												as={Link}
												to={row._id}
												replace
											>
												<ViewIcon />
											</Button>
										</Td>
									</tr>
								))}
							</tbody>
						</Table>
					</TableContainer>
				</>
			)}
			<Outlet />
		</Flex>
	)
}
