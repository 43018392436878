import { Skeleton, Table, TableContainer } from '@chakra-ui/react'
import { PacientTHeadDesktop } from './PacientTHeadDesktop'
import { PacientTBodyDesktop } from './PacientTBodyDesktop'
import { Footer } from './Footer'
import { useHistoryQuery } from 'modules/history/queries/useHistoryQuery'

export function PacientDesktopTable() {
	const query = useHistoryQuery()

	return (
		<>
			{query.isLoading ? (
				<Skeleton
					height="60vh"
					width="100%"
					mt={{ sm: '0px', md: '32px', lg: '32px' }}
				/>
			) : (
				<>
					<TableContainer width="100%">
						<Table variant="striped">
							<PacientTHeadDesktop />
							<PacientTBodyDesktop />
						</Table>
					</TableContainer>
					<Footer />
				</>
			)}
		</>
	)
}
