import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons'
import { Flex, Button } from '@chakra-ui/react'
import { usePagination } from '@mantine/hooks'

export function TablePagination({
	total,
	perPage,
	page,
	onPageChange,
}: {
	perPage: number
	total: number
	page: number
	onPageChange: (page: number) => void
}) {
	const totalPages = Math.ceil(total / perPage)
	const pagination = usePagination({
		total: totalPages,
		page,
		onChange: onPageChange,
	})

	return (
		<Flex as="nav" alignItems="center">
			<Button variant="unstyled" onClick={() => pagination.previous()}>
				<ChevronLeftIcon />
			</Button>

			{pagination.range.map((it, i) => {
				if (it === 'dots')
					return (
						<Flex
							key={`dots:${i}`}
							w="32px"
							h="32px"
							justify="center"
							alignItems="center"
						>
							...
						</Flex>
					)
				const isActive = it === pagination.active
				return (
					<Button
						key={it}
						size="sm"
						variant="unstyled"
						rounded="none"
						borderBottomWidth="2px"
						borderBottomColor={
							isActive ? 'primary.500' : 'transparent'
						}
						color={isActive ? 'primary.500' : undefined}
						onClick={() => pagination.setPage(it)}
					>
						{it}
					</Button>
				)
			})}

			<Button variant="unstyled" onClick={() => pagination.next()}>
				<ChevronRightIcon />
			</Button>
		</Flex>
	)
}
