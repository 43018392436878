import { useQuery } from '@tanstack/react-query'
import api from 'services/api'
import { settingsSchema } from '../validations/settingsSchema'

export function useSettingsByProfessional(professionalId: string) {
	return useQuery({
		queryKey: ['settings', professionalId],
		async queryFn() {
			const response = await api.get(`/settings/${professionalId}`)
			const data = await settingsSchema.validate(response.data, {
				stripUnknown: true,
			})
			return data
		},
		enabled: !!professionalId,
	})
}

export type ScheduleSettings = ReturnType<
	typeof useSettingsByProfessional
>['data']
