import { useSearchParams } from 'react-router-dom'
import dayjs from 'dayjs'
import { ScheduleTableRoot as Table } from './ScheduleTableRoot'
import { capitalize } from './ScheduleTableMonth'

export function TableMonthControl() {
	const [searchParams, setSearchParams] = useSearchParams()

	const dateHandle = dayjs(searchParams.get('date') || Date.now())
	const currentDateString = capitalize(dateHandle.format('MMMM [de] YYYY'))

	const handlePreviousMonth = () => {
		const newSearchParams = new URLSearchParams(searchParams)
		const nextDate = dateHandle.add(-1, 'month').toJSON()
		newSearchParams.set('date', nextDate)
		setSearchParams(newSearchParams)
		sessionStorage.setItem('agenda:date', nextDate)
	}
	const handleNextMonth = () => {
		const newSearchParams = new URLSearchParams(searchParams)
		const nextDate = dateHandle.add(1, 'month').toJSON()
		newSearchParams.set('date', nextDate)
		setSearchParams(newSearchParams)
		sessionStorage.setItem('agenda:date', nextDate)
	}

	return (
		<Table.Control
			current={currentDateString}
			onPrevious={handlePreviousMonth}
			onNext={handleNextMonth}
			margin={{ sm: '0 auto', md: 0 }}
		/>
	)
}
