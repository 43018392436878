import { Thead, Th, useColorModeValue } from '@chakra-ui/react'

export function THeadMobile() {
	const bg = useColorModeValue('#F2F2F2', '#182533')
	const border = useColorModeValue('#C6C6C6', '#777777')
	return (
		<Thead
			display="grid"
			gridTemplateRows="repeat(4, 34px)"
			bg={bg}
			maxHeight="136px"
		>
			<Th
				paddingX="10px"
				paddingY="0"
				display="flex"
				flexDirection="column"
				justifyContent="center"
			>
				Data
			</Th>
			<Th
				paddingX="10px"
				borderTop={`1px solid ${border}`}
				paddingY="0"
				display="flex"
				flexDirection="column"
				justifyContent="center"
			>
				Procedimentos
			</Th>
			<Th
				paddingX="10px"
				borderTop={`1px solid ${border}`}
				paddingY="0"
				display="flex"
				flexDirection="column"
				justifyContent="center"
			>
				Nome
			</Th>
			<Th
				paddingX="10px"
				borderTop={`1px solid ${border}`}
				paddingY="0"
				display="flex"
				flexDirection="column"
				justifyContent="center"
			>
				Profissional
			</Th>
		</Thead>
	)
}
