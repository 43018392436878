/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable consistent-return */
import * as yup from 'yup'
import { object } from 'yup'
import 'yup-phone'
import { cpf as cpfValid, cnpj as cnpjValid } from 'cpf-cnpj-validator'

const fieldRequiredMessage = 'Este campo é obrigátorio!'

export const recordsFormSchema = yup
	.object({
		name: yup.string().nullable().required(fieldRequiredMessage),
		birthday: yup
			.string()
			.nullable()
			.notRequired()
			// @ts-ignore
			.when('personType', (personType) => {
				if (personType === 'PJ') {
					return yup.string().notRequired()
				}
			}),
		maritalStatus: yup
			.string()
			.nullable()
			.notRequired() // @ts-ignore
			.when('personType', (personType) => {
				if (personType === 'PJ') {
					return yup.string().notRequired()
				}
			}),
		gender: yup
			.string()
			.nullable()
			.notRequired() // @ts-ignore
			.when('personType', (personType) => {
				if (personType === 'PJ') {
					return yup.string().notRequired()
				}
			}),
		rg: yup.string().nullable(),
		cpf: yup
			.string()
			.notRequired()
			.nullable()
			.when('personType', (personType) => {
				if (personType === 'PJ') {
					return yup.string().notRequired().nullable()
				}
			}),
		cnpj: yup
			.string()
			.notRequired()
			.test('test-invalid-cnpj', 'CNPJ inválido!', (cnpj) =>
				cnpjValid.isValid(String(cnpj))
			)
			// @ts-ignore
			.when('personType', (personType) => {
				if (personType === 'PF') {
					return yup.string().notRequired().nullable()
				}
			}),
		cellphoneNumber: yup.string().required(fieldRequiredMessage),
		// .phone('BR', true, 'Número inválido!')
		secondaryCellphoneNumber: yup.string().nullable(),
		// .phone('BR', true, 'Número inválido!')
		observation: yup.string().nullable(),
		zipCode: yup.string().nullable(),
		street: yup.string().nullable(),
		neighborhood: yup.string().nullable(),
		complement: yup.string().nullable(),
		city: yup.string().nullable(),
		uf: yup.string().nullable(),
		user: object().shape({
			email: yup
				.string()
				.notRequired()
				.nullable()
				.email('E-mail inválido!'),
		}),

		// LEGAL REPRESENTATIVE
		legalRepresentative: object().shape({
			email: yup
				.string()
				.email('E-mail inválido!')
				.notRequired()
				.nullable()
				.when(
					'config',
					// @ts-ignore
					(config) => {
						if (
							config.hasResponsible === 'Yes' &&
							config.responsibleTypeOfPerson === 'PJ'
						) {
							return yup
								.string()
								.required(fieldRequiredMessage)
								.email('E-mail inválido!')
						}
					}
				),
			name: yup
				.string()
				.notRequired()
				.nullable()
				.when(
					'config',
					// @ts-ignore
					(config) => {
						if (config.hasResponsible === 'Yes') {
							return yup.string().required(fieldRequiredMessage)
						}
					}
				),
			birthday: yup
				.string()
				.notRequired()
				.nullable()
				.when(
					'config',
					// @ts-ignore
					(config) => {
						if (
							config.hasResponsible === 'Yes' &&
							config.responsibleTypeOfPerson === 'PF'
						) {
							return yup.string().required()
						}
					}
				),
			maritalStatus: yup
				.string()
				.notRequired()
				.nullable()
				.when(
					'config',
					// @ts-ignore
					(config) => {
						if (
							config.hasResponsible === 'Yes' &&
							config.responsibleTypeOfPerson === 'PF'
						) {
							return yup.string().required()
						}
					}
				),
			gender: yup
				.string()
				.notRequired()
				.nullable()
				.when(
					'config',
					// @ts-ignore
					(config) => {
						if (
							config.hasResponsible === 'Yes' &&
							config.responsibleTypeOfPerson === 'PF'
						) {
							return yup.string().required()
						}
					}
				),
			rg: yup.string().nullable(),
			cpf: yup
				.string()
				.notRequired()
				.nullable()
				.when(
					'config',
					// @ts-ignore
					(config) => {
						if (
							config.hasResponsible === 'Yes' &&
							config.responsibleTypeOfPerson === 'PF'
						) {
							return yup
								.string()
								.required(fieldRequiredMessage)
								.test(
									'test-invalid-cpf',
									'CPF inválido!',
									(cpf) => cpfValid.isValid(String(cpf))
								)
						}
					}
				),
			cnpj: yup
				.string()
				.notRequired()
				.nullable()
				.when(
					'config',
					// @ts-ignore
					(config) => {
						if (
							config.hasResponsible === 'Yes' &&
							config.responsibleTypeOfPerson === 'PJ'
						) {
							return yup
								.string()
								.required(fieldRequiredMessage)
								.test(
									'test-invalid-cnpj',
									'CNPJ inválido!',
									(cnpj) => cnpjValid.isValid(String(cnpj))
								)
						}
					}
				),
			cellphoneNumber: yup
				.string()
				.notRequired()
				.nullable()
				// @ts-ignore
				.when('config', (config) => {
					if (config.hasResponsible === 'Yes') {
						return yup.string().required(fieldRequiredMessage)
						// .phone('BR', true, 'Número inválido!')
					}
				}),
			secondaryCellphoneNumber: yup.string().notRequired().nullable(),
			zipCode: yup
				.string()
				.notRequired()
				.nullable()
				// @ts-ignore
				.when('config', (config) => {
					if (config.hasResponsible === 'Yes') {
						return yup.string().required(fieldRequiredMessage)
					}
				}),
			street: yup
				.string()
				.notRequired()
				.nullable()
				// @ts-ignore
				.when('config', (config) => {
					if (config.hasResponsible === 'Yes') {
						return yup.string().required(fieldRequiredMessage)
					}
				}),
			neighborhood: yup
				.string()
				.notRequired()
				.nullable()
				// @ts-ignore
				.when('config', (config) => {
					if (config.hasResponsible === 'Yes') {
						return yup.string().required(fieldRequiredMessage)
					}
				}),
			complement: yup.string().notRequired().nullable(),
			city: yup
				.string()
				.notRequired()
				.nullable()
				// @ts-ignore
				.when('config', (config) => {
					if (config.hasResponsible === 'Yes') {
						return yup.string().required(fieldRequiredMessage)
					}
				}),
			uf: yup
				.string()
				.notRequired()
				.nullable()
				// @ts-ignore
				.when('config', (config) => {
					if (config.hasResponsible === 'Yes') {
						return yup.string().required(fieldRequiredMessage)
					}
				}),
		}),
	})
	.required()

// TODO - VALIDAR MELHOR O secondaryCellphoneNumber
