/* eslint-disable @typescript-eslint/no-explicit-any */
import { useQuery } from '@tanstack/react-query'
import { AxiosResponse } from 'axios'
import api from 'services/api'

interface useGetProfessionalsProps {
	onSuccess?: (data: AxiosResponse) => void
	onError?: () => void
}

const getAllProfessionals = async () => {
	const { data } = await api.get(`/dentists/?offset=0&limit=20`)
	return data.rows.map((p: any) => ({
		// eslint-disable-next-line no-underscore-dangle
		id: p._id,
		name: p.name,
		imageSrc: '',
	}))
}

export const useGetAllProfessionals = ({
	// onSuccess,
	// onError,
	...params
}: useGetProfessionalsProps) =>
	useQuery({
		queryKey: ['useGetAllProfessionals', params],
		queryFn: () => getAllProfessionals(),
		// onSuccess,
		// onError,
		refetchOnWindowFocus: false,
	})

// TODO - ADD TIPAGEM
