import { useQuery } from '@tanstack/react-query'
import { ProceduresRepository } from 'data/ProceduresRepository'

interface ProceduresQueryArgs {
	page: number
	search: string
}

export function useProceduresQuery({ page, search }: ProceduresQueryArgs) {
	return useQuery({
		queryKey: ['procedures', { page, search }],
		async queryFn() {
			const proceduresRepository = new ProceduresRepository()
			return await proceduresRepository.getProcedures({
				page,
				name: search,
			})
		},
		placeholderData(previousData) {
			if (!previousData) return
			return {
				...previousData,
				data: previousData.data.filter(
					(it) => it.name.localeCompare(search) !== -1
				),
			}
		},
	})
}
