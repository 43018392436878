/* eslint-disable no-underscore-dangle */
import { Tr, Td, Tooltip, Tbody, IconButton, Button } from '@chakra-ui/react'
import { EditIcon } from '@chakra-ui/icons'
import { useRecordsManagement } from 'modules/records/hooks/useRecordsManagement'
import { cnpj, cpf } from 'cpf-cnpj-validator'
import { useNavigate } from 'react-router-dom'
import { GenerateReport } from 'app/components/GenerateReport'
import { Can } from 'ability'

export const Body = () => {
	const { allRecords, filterByTypeRecord: typeRecord } =
		useRecordsManagement()
	const navigate = useNavigate()

	const goToEdit = (id: number) => {
		navigate(`/registros/${typeRecord}/${id}`)
	}

	return (
		<Tbody overflowX="auto">
			{allRecords?.map((item: any) => {
				return (
					<Tr key={item._id}>
						<Td>
							<Button
								onClick={() => goToEdit(item._id)}
								backgroundColor="transparent"
								fontWeight="normal"
								color="#5CB9DF"
								textDecoration="underline"
							>
								{item.name}
							</Button>
						</Td>
						<Td>
							{cpf.format(item.cpf) ||
								cnpj.format(item.cnpj) ||
								'-'}
						</Td>
						<Td>{item.cellphoneNumber || '-'}</Td>
						<Td
							cursor="pointer"
							display="flex"
							columnGap="8px"
							alignItems="center"
							paddingY="24px"
						>
							<Can I="update" a="Customer">
								<Tooltip label="Editar">
									<IconButton
										aria-label="Search database"
										icon={<EditIcon />}
										variant="outline"
										border={0}
										onClick={() => goToEdit(item._id)}
										height="auto"
										padding={1}
									/>
								</Tooltip>
							</Can>
							<GenerateReport
								url={`/${typeRecord}/${item._id}/report`}
								name="relatorio.xlsx"
							/>
						</Td>
					</Tr>
				)
			})}
		</Tbody>
	)
}
