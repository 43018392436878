import React from 'react'
import {
	Button,
	Flex,
	FormControl,
	FormErrorMessage,
	FormLabel,
	Heading,
	Input,
	Modal,
	ModalCloseButton,
	ModalContent,
	ModalOverlay,
	useToast,
} from '@chakra-ui/react'
import { Slot } from '@radix-ui/react-slot'
import { FormProvider, useForm } from 'react-hook-form'
import IMask from 'imask'

import { useSaveProductMutation } from '../queries/useSaveProductMutation'

interface Props extends ProductFormProps {
	trigger: JSX.Element
}

export function ProductModal({ trigger, product }: Props) {
	const editing = !!product

	const [isOpen, setIsOpen] = React.useState(false)
	const onClose = () => setIsOpen(false)

	return (
		<>
			<Slot onClick={() => setIsOpen(true)}>{trigger}</Slot>

			<Modal isOpen={isOpen} onClose={onClose} isCentered>
				<ModalOverlay />
				<ModalContent px="6" py="6">
					<Flex alignItems="center" justify="space-between" mb="5">
						<Heading fontSize="xl">
							{editing ? 'Editar' : 'Novo'} Produto
						</Heading>
						<ModalCloseButton size="md" top="5" />
					</Flex>

					<ProductForm product={product} onClose={onClose} />
				</ModalContent>
			</Modal>
		</>
	)
}

interface ProductFormProps {
	product?: {
		_id: string
		name: string
		unit?: string
		stockQuantity?: number
	}
	onClose?: () => void
}

interface FormShape {
	name: string
	unit: string
	add?: number
	remove?: number
}

function ProductForm({ product, onClose }: ProductFormProps) {
	const form = useForm<FormShape>({ defaultValues: product })
	const mutation = useSaveProductMutation()
	const toast = useToast()
	const onSave = form.handleSubmit(
		(data) => {
			mutation.mutate(data, {
				onSuccess: () => {
					toast({
						title: 'Salvo!',
						description: 'Produto salvo com sucesso',
						status: 'success',
					})
					onClose()
				},
				onError: (error) =>
					toast({
						title: 'Erro ao salvar',
						description: error.message,
						status: 'error',
					}),
			})
		},
		() =>
			toast({
				title: 'Erro de validação',
				description: 'Preencha todos os campos corretamente',
				status: 'error',
			})
	)

	const isUpdate = !!product
	const isCreate = !isUpdate

	return (
		<FormProvider {...form}>
			<form onSubmit={onSave}>
				{isCreate && (
					<>
						<FormControl
							isInvalid={!!form.formState.errors.name}
							isRequired
							mb="6"
						>
							<FormLabel fontSize="sm">Nome do Produto</FormLabel>
							<Input
								autoFocus
								fontSize="sm"
								placeholder="Insira o nome do produto"
								{...form.register('name', {
									required: 'Preencha esse campo',
								})}
							/>
							<FormErrorMessage
								position="absolute"
								right="0"
								mt="1"
							>
								{form.formState.errors.name?.message}
							</FormErrorMessage>
						</FormControl>
						<FormControl
							isInvalid={!!form.formState.errors.unit}
							isRequired
							mb="6"
						>
							<FormLabel fontSize="sm">Unidade</FormLabel>
							<Input
								fontSize="sm"
								placeholder="Insira a unidade"
								{...form.register('unit', {
									required: 'Preencha esse campo',
								})}
							/>
							<FormErrorMessage
								position="absolute"
								right="0"
								mt="1"
							>
								{form.formState.errors.unit?.message}
							</FormErrorMessage>
						</FormControl>
					</>
				)}

				{isUpdate && (
					<Flex mb="8" gap="3">
						<FormControl isDisabled={!!form.watch('remove')}>
							<FormLabel fontSize="sm">Adicionar</FormLabel>
							<Input
								fontSize="sm"
								placeholder="Adicionar ao estoque"
								{...form.register('add')}
								onInput={(e) =>
									IMask(e.currentTarget, {
										mask: Number,
										min: 0,
									})
								}
							/>
							<FormErrorMessage
								position="absolute"
								right="0"
								mt="1"
							>
								{form.formState.errors.add?.message}
							</FormErrorMessage>
						</FormControl>

						<FormControl isDisabled={!!form.watch('add')}>
							<FormLabel fontSize="sm">Retirar</FormLabel>
							<Input
								fontSize="sm"
								placeholder="Retirar do estoque"
								{...form.register('remove')}
								onInput={(e) =>
									IMask(e.currentTarget, {
										mask: Number,
										min: 0,
									})
								}
							/>
						</FormControl>
					</Flex>
				)}

				<Flex justify="space-evenly" flexDir="row-reverse">
					<Button
						type="submit"
						size="sm"
						w="130px"
						h="32px"
						colorScheme="primary"
						textTransform="uppercase"
						isLoading={mutation.isPending}
					>
						Salvar
					</Button>
					<Button
						type="button"
						size="sm"
						w="130px"
						h="32px"
						colorScheme="primary"
						variant="outline"
						textTransform="uppercase"
						onClick={onClose}
					>
						Cancelar
					</Button>
				</Flex>
			</form>
		</FormProvider>
	)
}
