interface Props {
	size: number
}

export function CreditCardIcon({ size }: Props) {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width={size}
			height={size}
			viewBox="0 0 20 20"
			fill="none"
		>
			<path
				d="M3.37187 3.69767L4.69375 4.87155C7.61875 2.2657 12.3719 2.2657 15.3062 4.87155L16.6281 3.69767C12.9719 0.43411 7.0375 0.43411 3.37187 3.69767ZM6.025 6.05376L7.34688 7.22764C8.05123 6.60394 9.00532 6.2537 10 6.2537C10.9947 6.2537 11.9488 6.60394 12.6531 7.22764L13.975 6.05376C12.9203 5.11831 11.4906 4.59288 10 4.59288C8.50937 4.59288 7.07969 5.11831 6.025 6.05376ZM15.625 8.75951H4.375C3.87772 8.75951 3.40081 8.93494 3.04917 9.24721C2.69754 9.55947 2.5 9.98299 2.5 10.4246V17.0849C2.5 18.009 3.33437 18.75 4.375 18.75H15.625C16.6656 18.75 17.5 18.009 17.5 17.0849V10.4246C17.5 9.98299 17.3025 9.55947 16.9508 9.24721C16.5992 8.93494 16.1223 8.75951 15.625 8.75951ZM15.625 13.7548H4.375V11.2571H15.625V13.7548Z"
				fill="currentColor"
			/>
		</svg>
	)
}
