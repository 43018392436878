interface Props {
	size?: number
}

export function FileIcon({ size = 16 }: Props) {
	return (
		<svg
			width={size}
			height={size}
			viewBox="0 0 16 16"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M2.58579 1.25253C2.96086 0.877462 3.46957 0.666748 4 0.666748H9.66667C9.84348 0.666748 10.013 0.736986 10.1381 0.86201L13.8047 4.52868C13.9298 4.6537 14 4.82327 14 5.00008V13.3334C14 13.8638 13.7893 14.3726 13.4142 14.7476C13.0391 15.1227 12.5304 15.3334 12 15.3334H4C3.46957 15.3334 2.96086 15.1227 2.58579 14.7476C2.21071 14.3726 2 13.8638 2 13.3334V2.66675C2 2.13632 2.21071 1.62761 2.58579 1.25253ZM12.0572 4.66675L10 2.60956V4.66675H12.0572ZM8.66667 2.00008V5.33341C8.66667 5.7016 8.96514 6.00008 9.33333 6.00008H12.6667V13.3334C12.6667 13.5102 12.5964 13.6798 12.4714 13.8048C12.3464 13.9298 12.1768 14.0001 12 14.0001H4C3.82319 14.0001 3.65362 13.9298 3.5286 13.8048C3.40357 13.6798 3.33333 13.5102 3.33333 13.3334V2.66675C3.33333 2.48994 3.40357 2.32037 3.5286 2.19534C3.65362 2.07032 3.82319 2.00008 4 2.00008H8.66667ZM4.66667 6.00008C4.66667 5.63189 4.96514 5.33341 5.33333 5.33341H6.66667C7.03486 5.33341 7.33333 5.63189 7.33333 6.00008C7.33333 6.36827 7.03486 6.66675 6.66667 6.66675H5.33333C4.96514 6.66675 4.66667 6.36827 4.66667 6.00008ZM4.66667 8.66675C4.66667 8.29856 4.96514 8.00008 5.33333 8.00008H10.6667C11.0349 8.00008 11.3333 8.29856 11.3333 8.66675C11.3333 9.03494 11.0349 9.33341 10.6667 9.33341H5.33333C4.96514 9.33341 4.66667 9.03494 4.66667 8.66675ZM4.66667 11.3334C4.66667 10.9652 4.96514 10.6667 5.33333 10.6667H10.6667C11.0349 10.6667 11.3333 10.9652 11.3333 11.3334C11.3333 11.7016 11.0349 12.0001 10.6667 12.0001H5.33333C4.96514 12.0001 4.66667 11.7016 4.66667 11.3334Z"
				fill="currentColor"
			/>
		</svg>
	)
}
