import toast from 'react-hot-toast'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import api from 'services/api'
import { Settings } from '../validations/settingsSchema'

export function useSaveSettingsByProfessional(professionalId: string) {
	const client = useQueryClient()

	return useMutation({
		mutationKey: ['save_settings', professionalId],
		async mutationFn(data: Settings) {
			await api.put(`/settings/${professionalId}`, data)
		},
		onSuccess() {
			client.invalidateQueries({
                queryKey: ['settings', professionalId]
            })
			toast.success('Salvo!')
		},
		onError() {
			toast.error('Erro ao salvar!')
		},
	});
}
