import { Input, InputProps } from '@chakra-ui/react'
import { Controller, ControllerProps } from 'react-hook-form'

interface Props extends InputProps {
	name: string
	rules?: ControllerProps['rules']
}

export function CurrencyInput({ name, rules, ...props }: Props) {
	return (
		<Controller
			name={name}
			render={({ field }) => {
				const formattedValue = field.value
					? formatAsCurrency(field.value)
					: ''

				return (
					<Input
						{...field}
						value={formattedValue}
						onChange={(e) => {
							const value = e.target.value
							if (!value || typeof value === 'number') {
								field.onChange(value)
								return
							}

							const valueAsNumber = Number(
								value.replace(/[^\d]/g, '')
							)
							field.onChange(valueAsNumber / 100 || undefined)
						}}
						onInput={(e) => {
							const input = e.target as HTMLInputElement
							const value = input.value.trim()
							if (value === '') {
								input.value = ''
								return
							}
							const valueAsNumber = Number(
								value.replace(/[^\d]/g, '')
							)
							const rawNumber = valueAsNumber / 100
							const nextFormattedValue =
								formatAsCurrency(rawNumber)
							input.value = nextFormattedValue
						}}
						fontSize="sm"
						{...props}
					/>
				)
			}}
			rules={rules}
		/>
	)
}

export function formatAsCurrency(rawValue: number) {
	const currency = 'BRL'
	const formattedValue = new Intl.NumberFormat(undefined, {
		style: 'currency',
		currency,
	})
		.format(rawValue)
		.trim()
	return formattedValue
}
