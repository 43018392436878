import { useEffect } from 'react'
import { Box, Flex, Stack } from '@chakra-ui/react'
import { Accordion } from 'app/components/Accordion'
import useCep from 'app/hooks/useCep'
import useDebounce from 'app/hooks/useDebounce'
import { useFormContext } from 'react-hook-form'
import { InputTextController } from 'app/components/InputTextController'
import { MaskedInputController } from 'app/components/MaskedInputController'

export const Address = () => {
	const {
		control,
		formState: { errors },
		watch,
		setValue,
		clearErrors,
	} = useFormContext()

	const zipCode = watch('zipCode')
	const debouncedCep = useDebounce(zipCode, 800)
	const data = useCep(debouncedCep)

	const clearAllAddressErrors = () => {
		clearErrors('zipCode')
		clearErrors('street')
		clearErrors('neighborhood')
		clearErrors('city')
		clearErrors('uf')
	}

	const clearAllAddressValues = () => {
		setValue('zipCode', '')
		setValue('street', '')
		setValue('neighborhood', '')
		setValue('city', '')
		setValue('uf', '')
		setValue('complement', '')
	}

	useEffect(() => {
		if (data) {
			setValue('street', data.logradouro)
			setValue('neighborhood', data.bairro)
			setValue('city', data.localidade)
			setValue('uf', data.uf)
			setValue('complement', data.complemento)
			clearAllAddressErrors()
		}
	}, [data])

	useEffect(() => {
		if (zipCode === '') {
			clearAllAddressValues()
		}
	}, [zipCode])

	return (
		<Box mt="24px">
			<Accordion title="Endereço" isOpenAccordion>
				<Flex direction={{ sm: 'column', md: 'row', lg: 'row' }}>
					<Stack
						spacing={5}
						direction={{ sm: 'column', md: 'row', lg: 'row' }}
					>
						<MaskedInputController
							title="CEP"
							placeholder=""
							size="md"
							width="100%"
							name="zipCode"
							control={control}
							hasError={errors.zipCode}
							mask="99999-999"
						/>

						<InputTextController
							title="Logradouro"
							placeholder=""
							size="md"
							type="text"
							width="100%"
							name="street"
							control={control}
							hasError={errors.street}
						/>
						<InputTextController
							title="Número"
							placeholder=""
							size="md"
							type="text"
							width="100%"
							name="number"
							control={control}
							hasError={errors.number}
						/>
						<InputTextController
							title="Complemento"
							placeholder=""
							size="md"
							type="text"
							width="100%"
							name="complement"
							control={control}
							hasError={errors.complement}
						/>
						<InputTextController
							title="Bairro"
							placeholder=""
							size="md"
							type="text"
							width="100%"
							name="neighborhood"
							control={control}
							hasError={errors.neighborhood}
						/>
					</Stack>
				</Flex>
				<Flex
					mt="16px"
					direction={{ sm: 'column', md: 'row', lg: 'row' }}
				>
					<Stack
						spacing={5}
						direction={{ sm: 'column', md: 'row', lg: 'row' }}
					>
						<InputTextController
							title="Cidade"
							placeholder=""
							size="md"
							type="text"
							width="100%"
							name="city"
							control={control}
							hasError={errors.city}
						/>
						<InputTextController
							title="UF"
							placeholder=""
							size="md"
							type="text"
							width="100%"
							name="uf"
							control={control}
							hasError={errors.uf}
						/>
					</Stack>
				</Flex>
			</Accordion>
		</Box>
	)
}
