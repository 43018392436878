/* eslint-disable @typescript-eslint/ban-ts-comment */
import { useEffect, useMemo } from 'react'
import { Box, Flex, Stack } from '@chakra-ui/react'
import { Accordion } from 'app/components/Accordion'
import { useFormContext } from 'react-hook-form'

import { InputTextController } from 'app/components/InputTextController'
import { MaskedInputController } from 'app/components/MaskedInputController'
import { SelectController } from 'app/components/SelectController'
import { phoneMask } from 'app/utils/masks'
import { itemsGenre, itemsMaritalStatus } from './itemsToSelect'

const titleRegistrationType = (registrationTypeProp: string) => {
	const registrationType = {
		customers: 'Paciente',
		dentists: 'Profissional',
		workers: 'Colaborador',
	}

	// @ts-ignore
	return registrationType[registrationTypeProp] || registrationType.customers
}

export const RegistrationInformation = () => {
	const {
		control,
		formState: { errors },
		watch,
		resetField,
	} = useFormContext()

	const personType = watch('personType')
	const registrationType = watch('registrationType')

	const title = useMemo(() => {
		return `Informações do ${titleRegistrationType(registrationType)}`
	}, [registrationType])

	useEffect(() => {
		if (personType === 'PF') {
			resetField('cnpj', { defaultValue: null })
		}
		if (personType === 'PJ') {
			resetField('cpf', { defaultValue: null })
		}
	}, [personType])

	return (
		<Box mt="24px">
			<Accordion title={title} isOpenAccordion>
				<Flex direction={{ sm: 'column', md: 'row', lg: 'row' }}>
					<Stack
						spacing={5}
						direction={{ sm: 'column', md: 'row', lg: 'row' }}
					>
						<InputTextController
							title="Nome"
							name="name"
							control={control}
							hasError={errors.name}
						/>

						{personType === 'PF' && (
							<MaskedInputController
								name="cpf"
								title="CPF"
								mask="999.999.999-99"
								width="100%"
								control={control}
								hasError={errors.cpf}
								errorMessage={errors?.cpf?.message}
								inputMode="numeric"
							/>
						)}
						{personType === 'PJ' && (
							<MaskedInputController
								name="cnpj"
								title="CNPJ"
								mask="99.999.999/9999-99"
								width="100%"
								control={control}
								hasError={errors.cnpj}
								errorMessage={errors?.cnpj?.message}
								inputMode="numeric"
							/>
						)}

						<InputTextController
							title="Data de Nascimento"
							placeholder=""
							size="md"
							type="date"
							width="100%"
							name="birthday"
							control={control}
							hasError={errors.birthday}
						/>

						<SelectController
							items={itemsMaritalStatus}
							title="Estado Civil"
							width="100%"
							name="maritalStatus"
							control={control}
							hasError={errors.maritalStatus}
							placeholder="Selecione"
						/>

						<SelectController
							items={itemsGenre}
							title="Gênero"
							width="100%"
							name="gender"
							control={control}
							hasError={errors.gender}
							placeholder="Selecione"
						/>
					</Stack>
				</Flex>
				<Flex
					mt="16px"
					direction={{ sm: 'column', md: 'row', lg: 'row' }}
				>
					<Stack
						spacing={5}
						direction={{ sm: 'column', md: 'row', lg: 'row' }}
					>
						<InputTextController
							title="RG"
							name="rg"
							size="md"
							control={control}
							hasError={errors.rg}
							inputMode="numeric"
							maxLength={16}
						/>
						<InputTextController
							title="Telefone"
							name="cellphoneNumber"
							onChange={phoneMask.onChange}
							size="md"
							control={control}
							hasError={errors.cellphoneNumber}
							errorMessage={errors?.cellphoneNumber?.message}
							type="tel"
						/>

						<InputTextController
							title="Telefone 2"
							name="secondaryCellphoneNumber"
							onChange={phoneMask.onChange}
							size="md"
							control={control}
							hasError={errors.secondaryCellphoneNumber}
							errorMessage={
								errors?.secondaryCellphoneNumber?.message
							}
							type="tel"
						/>

						<InputTextController
							title="E-mail"
							name="user.email"
							size="md"
							control={control}
							hasError={errors?.user?.email}
							errorMessage={errors?.user?.email?.message}
						/>

						<InputTextController
							title="Profissão"
							name="occupation"
							size="md"
							width="100%"
							control={control}
							hasError={errors.occupation}
						/>

						<InputTextController
							title="Observação"
							name="observation"
							size="md"
							width="100%"
							control={control}
						/>
					</Stack>
				</Flex>
			</Accordion>
		</Box>
	)
}
