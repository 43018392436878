import { useToast } from '@chakra-ui/react'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { BillsRepository } from 'data/BillsRepository'

export function useCreateBillMutation() {
	const client = useQueryClient()
	const toast = useToast()

	return useMutation({
		async mutationFn(data: any) {
			const billsRepository = new BillsRepository()
			return await billsRepository.createBill(data)
		},
		onSuccess: () => {
			toast({
				status: 'success',
				title: 'Sucesso',
				description: 'Pagamento salvo!',
			})
			client.invalidateQueries({ queryKey: ['bills'] })
		},
		onError: (error) =>
			toast({
				status: 'error',
				title: 'Erro ao salvar',
				description: error.message,
			}),
	})
}
