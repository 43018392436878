import { keepPreviousData, useQuery } from '@tanstack/react-query'
import { BillsRepository } from 'data/BillsRepository'

interface Props {
	page: number
	search?: string
	startDate?: string
	endDate?: string
	status?: string
	isTaxable?: boolean
}

export function useBillsQuery(
	{ page, search, startDate, endDate, status, isTaxable }: Props,
	options?: { enabled?: boolean }
) {
	return useQuery({
		queryKey: ['bills', { page, search, startDate, endDate, status }],
		async queryFn({ signal }) {
			const repository = new BillsRepository()
			return await repository.getBills({
				page,
				search,
				startDate,
				endDate,
				status,
				isTaxable,
				signal,
			})
		},
		placeholderData: keepPreviousData,
		enabled: options?.enabled,
	})
}
