/* eslint-disable @typescript-eslint/ban-ts-comment */

import { Modal } from 'app/components/Modal'

import React from 'react'

function WebCam({ onCapture }: { onCapture: (blob: Blob) => void }) {
	const streamRef = React.useRef<MediaStream>(null)
	const videoRef = React.useRef<HTMLVideoElement>(null)
	const canvasRef = React.useRef<HTMLCanvasElement>(null)

	const handleStop = () => {
		streamRef.current?.getTracks().forEach((t) => t.stop())
		videoRef.current.srcObject = null
	}

	const handleStart = () =>
		requestAnimationFrame(async () => {
			const stream = await navigator.mediaDevices.getUserMedia({
				video: true,
			})
			streamRef.current = stream
			videoRef.current.srcObject = stream
			videoRef.current.play()
		})

	const handleCapture = () => {
		const video = videoRef.current
		const canvas = canvasRef.current
		const context = canvas.getContext('2d')

		canvas.width = video.videoWidth
		canvas.height = video.videoHeight
		context.drawImage(video, 0, 0, video.videoWidth, video.videoHeight)
		canvas.toBlob(onCapture)
	}

	React.useEffect(() => {
		handleStart()
		return () => handleStop()
	}, [])

	return (
		<div>
			<button type="button" onClick={handleStart}>
				Start
			</button>
			<button type="button" onClick={handleStop}>
				Stop
			</button>

			<video ref={videoRef} height={192} width={192} />
			<canvas ref={canvasRef} style={{ display: 'none' }} />

			<button type="button" onClick={handleCapture}>
				Capturar
			</button>
		</div>
	)
}

const Playground = () => {
	const [src, setSrc] = React.useState('')

	return (
		<Modal
			size="xl"
			isOpen
			body={
				<>
					<WebCam
						onCapture={(blob) => setSrc(URL.createObjectURL(blob))}
					/>
					<img width={192} height={192} src={src} alt="" />{' '}
				</>
			}
			title=""
			footer={null}
			dividers={false}
			onClose={() => {
				return {}
			}}
		/>
	)
}

export default Playground
