import { DeleteIcon } from '@chakra-ui/icons'
import { IconButton, useToast } from '@chakra-ui/react'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { ConfirmAlert } from 'app/components/ConfirmAlert'
import { Bill } from 'data/Bill'
import { BillsRepository } from 'data/BillsRepository'

export function DeleteBill({ bill }: { bill: Bill }) {
	const queryClient = useQueryClient()
	const mutation = useMutation({
		mutationFn: () => new BillsRepository().deleteBill(bill.id),
		onSuccess: () => queryClient.invalidateQueries({ queryKey: ['bills'] }),
	})
	const toast = useToast()
	const onDelete = () => {
		const deletePromise = mutation.mutateAsync()
		toast.promise(deletePromise, {
			success: { title: 'Deletado!', duration: 2000, isClosable: true },
			error: { title: 'Erro ao deletar', duration: 5000 },
			loading: { title: 'Deletando...' },
		})
	}

	return (
		<ConfirmAlert
			title="Quer mesmo deletar essa conta?"
			body="Essa ação é irreversível"
			trigger={
				<IconButton
					bg="#f9f9f9"
					_dark={{
						bg: '#223344',
					}}
					aria-label="Deletar Conta"
					icon={<DeleteIcon />}
				/>
			}
			onConfirm={onDelete}
		/>
	)
}
