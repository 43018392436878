import { Document, Page, StyleSheet, Text, View } from '@react-pdf/renderer'
import dayjs from 'dayjs'
import { PdfLink } from 'app/components/PdfLink'

interface Procedure {
	id: string
	date: string
	customer?: { name: string }
	dentist?: { name: string }
	name: string
	observation: string
}

interface Props {
	data: Procedure | Procedure[]
}

const styles = StyleSheet.create({
	container: { paddingHorizontal: 16, paddingVertical: 16 },
	title: {
		fontSize: 20,
		fontFamily: 'Open Sans',
		fontWeight: 600,
		textAlign: 'center',
		marginBottom: 16,
	},
	list: { gap: 12 },
	line: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
	},
	highlight: { fontFamily: 'Open Sans', fontWeight: 600 },
	value: { textAlign: 'right', maxWidth: '70%' },
})

export function HistoryPdfLink({ data }: Props) {
	return (
		<PdfLink
			name="historico.pdf"
			document={
				<Document>
					<Page style={styles.container}>
						<Text style={styles.title}>Histórico</Text>

						{data && (
							<View style={styles.list}>
								{(Array.isArray(data) ? data : [data]).map(
									(procedure) => {
										return (
											<View key={procedure.id}>
												<View style={styles.line}>
													<Text
														style={styles.highlight}
													>
														Data
													</Text>
													<Text>
														{dayjs(
															procedure.date
														).format('DD/MM/YYYY')}
													</Text>
												</View>
												<View style={styles.line}>
													<Text
														style={styles.highlight}
													>
														Procedimento
													</Text>
													<Text>
														{procedure.name}
													</Text>
												</View>
												<View style={styles.line}>
													<Text
														style={styles.highlight}
													>
														Profissional
													</Text>
													<Text>
														{procedure.dentist
															?.name ?? '-'}
													</Text>
												</View>
												<View style={styles.line}>
													<Text
														style={styles.highlight}
													>
														Paciente
													</Text>
													<Text style={styles.value}>
														{procedure.customer
															?.name ?? '-'}
													</Text>
												</View>
												<View style={styles.line}>
													<Text
														style={styles.highlight}
													>
														Observação
													</Text>
													<Text style={styles.value}>
														{procedure.observation}
													</Text>
												</View>
											</View>
										)
									}
								)}
							</View>
						)}
					</Page>
				</Document>
			}
		/>
	)
}
