import { useQuery } from '@tanstack/react-query'
import { useParams, useSearchParams } from 'react-router-dom'
import api from 'services/api'

interface HistoryEvent {
	_id: string
	customer?: { _id: string; name: string }
	dentist?: { _id: string; name: string }
	procedure?: {
		_id: string
		name: string
		date: string
		observation: string
		files: any[]
		deleted?: boolean
	}
	treatment?: { _id: string; createdAt?: string }
	targetType?: string;
}

interface Result {
	data: HistoryEvent[]
	meta: { total: number; perPage: number }
}

export function useHistoryQuery() {
	const params = useParams()
	const customer = params.customerId

	const [searchParams] = useSearchParams()
	const search = searchParams.get('search')
	const dentist = searchParams.get('dentist')
	const page = Math.abs(Number(searchParams.get('page')) || 1)

	return useQuery({
		queryKey: ['history', { search, dentist, customer, page }],
		async queryFn(context) {
			if (customer) searchParams.set('customer', customer)
			const response = await api.get(`/history?${searchParams}`, {
				signal: context.signal,
			})
			return response.data as Result
		},
		placeholderData: (previous) => previous,
		staleTime: 5 * 1000,
	})
}
