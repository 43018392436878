import { queryOptions, useQuery } from '@tanstack/react-query'
import { ProceduresRepository } from 'data/ProceduresRepository'

export const proceduresQueryOptions = queryOptions({
	queryKey: ['procedures'],
	async queryFn() {
		const paginated = await new ProceduresRepository().getProcedures({
			page: 1,
			limit: 1000,
		})
		return paginated.data
	},
})

export function useProceduresQuery() {
	return useQuery(proceduresQueryOptions)
}
