import { AddIcon, EditIcon, Search2Icon } from '@chakra-ui/icons'
import {
	Stack,
	TableContainer,
	Table,
	Thead,
	Tbody,
	Th,
	Td,
	Flex,
	IconButton,
	Center,
	Spinner,
	Text,
	Heading,
	Button,
	InputGroup,
	InputLeftElement,
	Input,
} from '@chakra-ui/react'
import { useSearchParams } from 'react-router-dom'
import React from 'react'

import { Can } from 'ability'
import { TablePagination } from 'app/components/TablePagination'
import { useProductsQuery } from '../queries/useProductsQuery'
import { ProductModal } from './ProductModal'
import { DeleteProduct } from './DeleteProduct'

export function ProductsRoute() {
	const [searchParams, setSearchParams] = useSearchParams()
	const page = Math.abs(Number(searchParams.get('page')) || 1)
	const onPageChange = (nextPage: number) => {
		setSearchParams(
			(init) => new URLSearchParams({ ...init, page: String(nextPage) })
		)
	}

	const [search, setSearch] = React.useState('')
	const onSearchChange = (e: React.ChangeEvent<HTMLInputElement>) =>
		setSearch(e.currentTarget.value)

	const query = useProductsQuery({ page, search })
	const products = query.data?.data
	const paginationInfo = query.data?.meta

	const isEmpty = products?.length <= 0

	return (
		<Stack h="100%" gap="6">
			<Flex justify="space-between" alignItems="center">
				<Heading fontSize="2xl">Produtos</Heading>

				<Can I="create" a="Product">
					<ProductModal
						trigger={
							<Button
								size="sm"
								fontSize="xs"
								colorScheme="primary"
								textTransform="uppercase"
								leftIcon={<AddIcon />}
							>
								Produto
							</Button>
						}
					/>
				</Can>
			</Flex>

			<Flex gap="4" alignItems="center">
				<InputGroup size="sm" maxW="543px">
					<InputLeftElement>
						<Search2Icon />
					</InputLeftElement>
					<Input
						bg="white"
						_dark={{ bg: '#182533' }}
						placeholder="Buscar por Nome"
						defaultValue={search}
						onChange={onSearchChange}
					/>
				</InputGroup>
			</Flex>

			<TableContainer
				bg="white"
				_dark={{ bg: '#182533' }}
				p="4"
				rounded="12"
				h="100%"
				mb="6"
				position="relative"
			>
				<Table variant="striped">
					<Thead>
						<tr>
							<Th>Nome</Th>
							<Th>Unidade</Th>
							<Th textAlign="center">Quantidade em estoque</Th>
							<Th></Th>
						</tr>
					</Thead>
					<Tbody>
						{products &&
							!isEmpty &&
							products.map((it) => (
								<tr key={it._id}>
									<Td>{it.name}</Td>
									<Td>{it.unit ?? '-'}</Td>
									<Td textAlign="center">
										{it.stockQuantity ?? '-'}
									</Td>
									<Td>
										<Flex gap="3" justifyContent="end">
											<Can I="update" this={it}>
												<ProductModal
													product={it}
													trigger={
														<IconButton
															bg="#f9f9f9"
															_dark={{
																bg: '#223344',
															}}
															aria-label="Editar Produto"
															icon={
																<EditIcon
																	fontSize={
																		20
																	}
																/>
															}
														/>
													}
												/>
											</Can>
											<Can I="delete" this={it}>
												<DeleteProduct product={it} />
											</Can>
										</Flex>
									</Td>
								</tr>
							))}

						{!query.data && query.isLoading && (
							<tr style={{ display: 'block' }}>
								<td>
									<Center
										position="absolute"
										h="100%"
										top="50%"
										left="50%"
										transform="translate(-50%,-50%)"
									>
										<Spinner />
									</Center>
								</td>
							</tr>
						)}

						{isEmpty && (
							<tr style={{ display: 'block' }}>
								<td>
									<Center
										flexDirection="column"
										position="absolute"
										h="100%"
										top="50%"
										left="50%"
										transform="translate(-50%,-50%)"
									>
										<Text>Nenhum produto salvo ainda</Text>
										<Text>
											Clique no botão {'"+ Produto"'} para
											criar um novo
										</Text>
									</Center>
								</td>
							</tr>
						)}
						{query.isLoadingError && (
							<tr style={{ display: 'block' }}>
								<td>
									<Center
										flexDirection="column"
										position="absolute"
										h="100%"
										top="50%"
										left="50%"
										transform="translate(-50%,-50%)"
									>
										<Text>
											Algo deu errado ao carregar esses
											dados...
										</Text>
										<Text>{String(query.error)}</Text>
									</Center>
								</td>
							</tr>
						)}
					</Tbody>
				</Table>
			</TableContainer>

			{paginationInfo && paginationInfo.total > 0 && (
				<Flex alignItems="center" justify="end">
					<TablePagination
						total={paginationInfo.total}
						perPage={paginationInfo.perPage}
						page={page}
						onPageChange={onPageChange}
					/>

					<Text
						ml="5"
						fontSize="xs"
						fontWeight="medium"
						textTransform="uppercase"
					>
						{paginationInfo.total} registros
					</Text>
				</Flex>
			)}
		</Stack>
	)
}


