import { Tbody, Tr, Td, Text } from '@chakra-ui/react'
import dayjs from 'dayjs'
import { useHistoryQuery } from 'modules/history/queries/useHistoryQuery'
import { useNavigate } from 'react-router-dom'

export function TBodyDesktop() {
	const navigate = useNavigate()
	const query = useHistoryQuery()
	const history = query.data?.data

	return (
		<Tbody>
			{history?.map((item) => (
				<Tr
					key={item._id}
					opacity={item.procedure?.deleted ? '0.3' : '1'}
				>
					<Td>
						{dayjs(
							item.treatment?.createdAt ?? item.procedure?.date
						).format('DD/MM/YYYY')}
					</Td>
					<Td>
						{item.treatment ? 'Orçamento' : item.procedure?.name}
					</Td>
					<Td>
						{item.customer?.name ? (
							<Text
								color="#1188EE"
								textDecoration="underline"
								fontSize="14px"
								fontWeight="500"
								_hover={{ cursor: 'pointer' }}
								onClick={() => {
									navigate(`/historico/${item.customer?._id}`)
								}}
							>
								{item.customer?.name}
							</Text>
						) : (
							<Text fontSize="14px" fontWeight="500">
								-
							</Text>
						)}
					</Td>
					<Td>{item.dentist?.name ?? '-'}</Td>
				</Tr>
			))}
		</Tbody>
	)
}
