import { AddIcon } from '@chakra-ui/icons'
import {
	Button,
	Flex,
	Heading,
	Stack,
	useMultiStyleConfig,
} from '@chakra-ui/react'
import {
	Link,
	LinkProps,
	Outlet,
	useLocation,
	useResolvedPath,
} from 'react-router-dom'

import { PaymentModal } from './PaymentModal'
import { Can } from 'ability'

export function FinancialRoute() {
	return (
		<Stack px="6" py="10" h="100%" gap="6">
			<Flex justify="space-between" alignItems="center">
				<Flex gap="5" alignItems="center">
					<Heading fontSize="2xl">Financeiro</Heading>

					<Flex
						__css={{
							borderBottom: '2px solid',
							borderColor: 'inherit',
							flexDirection: 'row',
							justifyContent: 'flex-start',
						}}
					>
						<NavTab to="/financeiro">Contas a Receber</NavTab>
						<NavTab to="/financeiro?paid">Contas Recebidas</NavTab>
					</Flex>
				</Flex>

				<Can I="create" a="Payment">
					<PaymentModal
						trigger={
							<Button
								size="sm"
								fontSize="xs"
								colorScheme="primary"
								textTransform="uppercase"
								leftIcon={<AddIcon />}
							>
								Pagamento
							</Button>
						}
					/>
				</Can>
			</Flex>

			<Outlet />
		</Stack>
	)
}

function NavTab({ to, children }: LinkProps) {
	const location = useLocation()
	const resolvedPath = useResolvedPath(to)

	const searchMatches =
		new URLSearchParams(resolvedPath.search).get('paid') ===
		new URLSearchParams(location.search).get('paid')
	const isSelected =
		location.pathname === resolvedPath.pathname && searchMatches

	const tabProps = {
		colorScheme: 'primary',
		'aria-selected': isSelected,
	}
	const styles = useMultiStyleConfig('Tabs', tabProps)

	return (
		<Button
			__css={styles.tab}
			{...tabProps}
			display="inline-block"
			fontWeight="600"
			as={Link}
			to={to}
			replace
		>
			{children}
		</Button>
	)
}
