interface IPayment {
	id: string
	value: number
	status?: string
	dueDate?: string
	customer?: PaymentCustomer
	professional?: PaymentProfessional
	treatment?: PaymentTreatment
	targetModel?: string
	paymentMethod?: string
	paymentDate?: string
	payerCpf?: string
	file?: { url: string }
	type?: string
	fine?: number
	clinic?: string
	withNote?: boolean
}

export class Payment implements IPayment {
	static modelName = 'Payment'

	id: string
	value: number
	status?: string
	dueDate?: string
	customer?: PaymentCustomer
	professional?: PaymentProfessional
	targetModel?: string
	treatment?: PaymentTreatment
	paymentMethod?: string
	paymentDate?: string
	payerCpf?: string
	file?: { url: string }
	type?: string
	fine?: number
	clinic?: string
	withNote?: boolean

	constructor(object: IPayment) {
		Object.assign(this, object)
	}

	static fromJson(json: Record<any, any>) {
		return new Payment({
			id: json._id,
			withNote: json.withNote,
			value: json.value,
			status: json.status,
			type: json.type,
			fine: json.fine,
			dueDate: json.dueDate,
			paymentDate: json.paymentDate,
			payerCpf: json.payerCpf,
			file: json.file,
			paymentMethod: json.paymentMethod,
			targetModel: json.targetModel,
			customer: json.customer && {
				id: json.customer._id,
				name: json.customer.name,
				cpf: json.customer.cpf,
			},
			professional: json.dentist && {
				id: json.dentist._id,
				name: json.dentist.name,
			},
			treatment: json.treatment && { id: json.treatment._id },
			clinic: json.clinic,
		})
	}
}

interface PaymentProfessional {
	id: string
	name: string
}

interface PaymentCustomer {
	id: string
	name: string
	cpf: string
}

interface PaymentTreatment {
	id: string
}
