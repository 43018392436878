import dayjs from 'dayjs'

export function createSchedules(
	currentDate: dayjs.Dayjs,
	startString: string,
	endString: string,
	durationInMinutes: number,
	shouldRemoveSchedule?: (schedule: dayjs.Dayjs) => boolean
) {
	const schedules: dayjs.Dayjs[] = []

	const [hourStartString, minuteStartString] = startString.split(':')
	const [hourEndString, minuteEndString] = endString.split(':')

	let schedule = dayjs(currentDate)
		.set('hour', parseInt(hourStartString, 10))
		.set('minute', parseInt(minuteStartString, 10))
		.set('second', 0)
	const end = dayjs(currentDate)
		.set('hour', parseInt(hourEndString, 10))
		.set('minute', parseInt(minuteEndString, 10))
		.set('second', 0)

	while (schedule.isBefore(end)) {
		if (!shouldRemoveSchedule?.(schedule)) {
			schedules.push(schedule)
		}
		schedule = schedule.add(durationInMinutes, 'minute')
	}

	return schedules
}
