export const Modal = {
	sizes: {
		base: {
			dialog: { maxWidth: '95vw', height: '90vh' },
		},
		full: {
			dialog: { maxWidth: '100vw', height: '100vh' },
		},
	},
}
