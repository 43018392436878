import { OverlayTriggerState } from 'react-stately';
import { Box } from '@chakra-ui/react';
import {
	DismissButton,
	Overlay,
	AriaPopoverProps,
	usePopover
} from 'react-aria';
import React from 'react';

/* POPOVER */

export function Popover(props: PopoverProps) {
	const ref = React.useRef<HTMLDivElement>(null);
	const { popoverRef = ref, state, children, isNonModal } = props;

	const { popoverProps, underlayProps } = usePopover(
		{
			...props,
			popoverRef,
		},
		state
	);

	return (
		<Overlay>
			{!isNonModal && (
				<div
					{...underlayProps}
					style={{ position: 'fixed', inset: 0 }} />
			)}
			<Box
				{...popoverProps}
				ref={popoverRef}
				background="white"
				border="1px solid"
				borderColor="gray.200"
				borderRadius="md"
				zIndex="10"
				width={(props.triggerRef.current as HTMLElement)?.offsetWidth ||
					'auto'}
				// width="100%"
				boxShadow="lg"
				transform="translateY(0.25rem)"
				_dark={{ bg: 'gray.700', borderColor: 'gray.800' }}
			>
				{!isNonModal && <DismissButton onDismiss={state.close} />}
				{children}
				<DismissButton onDismiss={state.close} />
			</Box>
		</Overlay>
	);
}
interface PopoverProps extends Omit<AriaPopoverProps, 'popoverRef'> {
	children: React.ReactNode;
	state: OverlayTriggerState;
	popoverRef?: React.RefObject<HTMLDivElement>;
}
