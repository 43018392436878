import * as yup from 'yup'

export const settingsSchema = yup.object({
	agenda: yup.object({
		sessionDurationMinutes: yup.number().positive().required(),
		startTime: yup.string().required(),
		endTime: yup.string().required(),
		lunchStartTime: yup.string().optional(),
		lunchEndTime: yup.string().optional(),
		availableDays: yup.array(yup.string()).required(),
	}),
	annotations: yup.object({
		sessionDurationMinutes: yup.number().positive().required(),
		startTime: yup.string().required(),
		endTime: yup.string().required(),
	}),
})

export type Settings = yup.InferType<typeof settingsSchema>
