import {
	Flex,
	Heading,
	Modal,
	ModalCloseButton,
	ModalContent,
	ModalOverlay,
	useDisclosure,
	FormControl,
	FormLabel,
	Input,
	Checkbox,
	Select,
	Button,
	useModalContext,
	useToast,
} from '@chakra-ui/react'
import { Slot } from '@radix-ui/react-slot'
import { FormProvider, useForm } from 'react-hook-form'
import dayjs from 'dayjs'

import { Bill } from 'data/Bill'
import { CurrencyInput } from 'app/components/CurrencyInput'
import { useCreateBillMutation } from '../queries/useCreateBillMutation'
import { useUpdateBillMutation } from '../queries/useUpdateBillMutation'
import { UploadInput } from 'app/components/UploadInput'

interface BillModalProps {
	bill?: Bill
	trigger: JSX.Element
}

export function BillModal({ bill, trigger }: BillModalProps) {
	const state = useDisclosure()

	return (
		<>
			<Slot onClick={state.onOpen}>{trigger}</Slot>

			<Modal
				isOpen={state.isOpen}
				onClose={state.onClose}
				isCentered
				size="xl"
			>
				<ModalOverlay />
				<ModalContent px="6" py="6">
					<Flex alignItems="center" justify="space-between" mb="5">
						<Heading fontSize="xl">
							{bill ? 'Editar' : 'Nova'} Conta
						</Heading>
						<ModalCloseButton size="md" top="5" />
					</Flex>

					<BillForm bill={bill} />
				</ModalContent>
			</Modal>
		</>
	)
}

interface Props {
	bill?: Bill
}

function BillForm({ bill }: Props) {
	const modal = useModalContext()
	const toast = useToast()
	const form = useForm({
		defaultValues: {
			...bill,
			dueDate: bill
				? bill.dueDate?.slice(0, 10)
				: dayjs().format('YYYY-MM-DD'),
			paymentDate: bill ? bill.paymentDate?.slice(0, 10) : '',
		},
	})
	const createBillMutation = useCreateBillMutation()
	const updateBillMutation = useUpdateBillMutation()

	const { errors } = form.formState
	const mutation = bill?.id ? updateBillMutation : createBillMutation
	const onSave = form.handleSubmit(
		(data) => {
			if (bill) Object.assign(data, { id: bill.id })
			mutation.mutate(data, { onSuccess: () => modal.onClose() })
		},
		() =>
			toast({
				status: 'error',
				title: 'Erro de validação',
				description: 'Preencha todos os campos corretamente',
			})
	)

	const computedTotalValue: number =
		(form.watch('value') || 0) + (form.watch('fine') || 0)
	const paymentDate = !!form.watch('paymentDate')

	return (
		<FormProvider {...form}>
			<form onSubmit={onSave} noValidate>
				<FormControl mb="5" isRequired isInvalid={!!errors.name}>
					<FormLabel fontSize="sm" fontWeight="600">
						Nome da conta
					</FormLabel>
					<Input
						{...form.register('name', { required: true })}
						size="sm"
					/>
				</FormControl>
				<FormControl mb="5" isRequired isInvalid={!!errors.dueDate}>
					<FormLabel fontSize="sm" fontWeight="600">
						Data de vencimento
					</FormLabel>
					<Input
						{...form.register('dueDate', { required: true })}
						size="sm"
						type="date"
					/>
				</FormControl>
				<FormControl mb="5" isRequired isInvalid={!!errors.value}>
					<FormLabel fontSize="sm" fontWeight="600">
						Valor
					</FormLabel>
					<CurrencyInput
						name="value"
						rules={{ required: true }}
						size="sm"
					/>
				</FormControl>
				<Checkbox
					{...form.register('isTaxable')}
					colorScheme="primary"
					mb="5"
				>
					Tributável
				</Checkbox>
				<FormControl
					mb="5"
					isRequired={!!paymentDate}
					isInvalid={!!errors.paymentMethod}
				>
					<FormLabel fontSize="sm" fontWeight="600">
						Método de pagamento
					</FormLabel>
					<Select
						{...form.register('paymentMethod', {
							required: !!paymentDate,
						})}
						size="sm"
						placeholder="Selecione"
					>
						{PAYMENT_METHODS.map((it) => (
							<option key={it.value} value={it.value}>
								{it.name}
							</option>
						))}
					</Select>
				</FormControl>
				<FormControl mb="5" isInvalid={!!errors.paymentDate}>
					<FormLabel fontSize="sm" fontWeight="600">
						Data de pagamento
					</FormLabel>
					<Input
						{...form.register('paymentDate')}
						size="sm"
						type="date"
					/>
				</FormControl>
				<FormControl mb="5">
					<FormLabel fontSize="sm" fontWeight="600">
						Multa
					</FormLabel>
					<CurrencyInput name="fine" size="sm" />
				</FormControl>
				<FormControl mb="5" isReadOnly>
					<FormLabel fontSize="sm" fontWeight="600">
						Valor Total
					</FormLabel>
					<Input
						value={computedTotalValue.toLocaleString([], {
							style: 'currency',
							currency: 'BRL',
						})}
						size="sm"
					/>
				</FormControl>
				<FormControl mb="5">
					<FormLabel fontSize="sm" fontWeight="600">
						Upload recibo
					</FormLabel>
					<UploadInput name="file" />
				</FormControl>

				<Flex justify="space-evenly" flexDir="row-reverse" mt="8">
					<Button
						type="submit"
						size="sm"
						w="130px"
						h="32px"
						colorScheme="primary"
						textTransform="uppercase"
						isLoading={mutation.isPending}
					>
						Salvar
					</Button>
					<Button
						size="sm"
						w="130px"
						h="32px"
						colorScheme="primary"
						variant="outline"
						textTransform="uppercase"
						onClick={modal.onClose}
					>
						Cancelar
					</Button>
				</Flex>
			</form>
		</FormProvider>
	)
}

const PAYMENT_METHODS = [
	{ name: 'Pix', value: 'pix' },
	{ name: 'Crédito', value: 'credit_card' },
	{ name: 'Débito', value: 'debit_card' },
	{ name: 'Dinheiro', value: 'cash' },
	{ name: 'Boleto', value: 'bank_slip' },
	{ name: 'Depósito', value: 'deposit' },
	{ name: 'Cheque', value: 'check' },
]
