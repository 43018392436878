import { useProceduresManagement } from 'modules/history/hooks/useProceduresManagement'
import {
	Button,
	Flex,
	Skeleton,
	Spinner,
	Table,
	TableContainer,
	useColorModeValue,
} from '@chakra-ui/react'
import { useNavigate, useParams } from 'react-router-dom'
import { DownloadIcon, ViewIcon } from '@chakra-ui/icons'
import { useFormContext } from 'react-hook-form'
import * as Pdf from '@react-pdf/renderer'

import { Footer } from './Footer'
import { useHistoryQuery } from 'modules/history/queries/useHistoryQuery'
import { PacientTHeadMobile } from './PacientTHeadMobile'
import { PacientTBodyMobile } from './PacientTBodyMobile'
import { HistoryPdfLink } from '../HistoryPdfLink'
import { FormModal } from '../ProcedureForm/FormModal'
import { TreatmentPdf } from 'modules/treatments/components/TreatmentPdf'

export function PacientMobileTableContainer() {
	const { customerId } = useParams()
	const {
		setFilterByTypeInfo,
		setInfoFilter,
		setIsOpenProcedureForm,
		setProcedureId,
	} = useProceduresManagement()
	const { reset } = useFormContext()
	setFilterByTypeInfo('customer')
	setInfoFilter(customerId)
	const border = useColorModeValue('#F2F2F2', 'transparent')
	const bg = useColorModeValue('#FFF', '#223344')

	const query = useHistoryQuery()
	const history = query.data?.data

	const navigate = useNavigate()

	return (
		<Flex
			paddingY="24px"
			paddingX="28px"
			flexDirection="column"
			rowGap="16px"
			alignItems="center"
			as="main"
			bg={bg}
			borderRadius="12px"
			border={`1px solid ${border}`}
		>
			{query.isLoading ? (
				<Skeleton
					height="60vh"
					width="100%"
					mt={{ sm: '0px', md: '32px', lg: '32px' }}
				/>
			) : (
				<>
					{history?.map((item) => (
						<TableContainer
							key={item._id}
							width="100%"
							border="1px solid #c6c6c6"
							borderRadius="4px"
							opacity={item.procedure?.deleted ? '0.3' : '1'}
						>
							<Table display="flex">
								<PacientTHeadMobile />
								<PacientTBodyMobile
									date={
										item.procedure?.date ??
										item.treatment?.createdAt
									}
									procedure={
										item.targetType === 'Treatment'
											? 'Orçamento'
											: item?.procedure?.name ?? '-'
									}
									professional={item.dentist?.name ?? ''}
								/>
							</Table>
							<Flex
								width="100%"
								justifyContent="center"
								bg="#F2F2F2"
								_dark={{ bg: '#182533' }}
								paddingY="8px"
								borderTop="1px solid #C6C6C6"
								gap={3}
							>
								<Button
									width="32px"
									height="32px"
									borderRadius="4px"
									bg="#F9F9F9"
									_dark={{ bg: 'whiteAlpha.50' }}
									onClick={() => {
										const treatment = item.treatment
										const procedure = item.procedure
										if (procedure) {
											setIsOpenProcedureForm(true)
											setProcedureId(procedure._id)
											reset({
												name: procedure?.name,
												date: procedure?.date?.split(
													'T'
												)[0],
												observation:
													procedure?.observation,
												files: procedure?.files,
											})
										}
										if (treatment) {
											navigate(
												`/tratamentos/${treatment._id}`
											)
										}
									}}
								>
									<ViewIcon />
								</Button>

								{item.treatment && (
									<Pdf.PDFDownloadLink
										fileName="orcamento.pdf"
										document={
											<TreatmentPdf
												treatment={
													item.treatment as any
												}
											/>
										}
									>
										{(state) => (
											<Button
												type="button"
												boxSizing="border-box"
												width="24px"
												height="30px"
												p="0"
												borderRadius="5px"
												display="flex"
												alignItems="center"
												justifyContent="center"
												bg="white"
												_dark={{ bg: 'whiteAlpha.50' }}
											>
												{state.loading ? (
													<Spinner
														size="sm"
														color="#1188EE"
													/>
												) : (
													<DownloadIcon color="#1188EE" />
												)}
											</Button>
										)}
									</Pdf.PDFDownloadLink>
								)}

								{item.procedure && (
									<HistoryPdfLink
										data={{
											...item.procedure,
											id: item.procedure._id,
										}}
									/>
								)}
							</Flex>
							{item.procedure && (
								<FormModal procedureId={item.procedure?._id} />
							)}
						</TableContainer>
					))}
					<Footer />
				</>
			)}
		</Flex>
	)
}
