import {
	Checkbox,
	FormControl,
	FormLabel,
	Grid,
	Input,
	Select,
	Text,
} from '@chakra-ui/react'
import { useFormContext } from 'react-hook-form'
import React from 'react'

import { CurrencyInput } from './CurrencyInput'

export function Payments() {
	const form = useFormContext()
	const { errors } = form.formState

	const hasDownPayment = form.watch('hasDownPayment', false)

	React.useLayoutEffect(() => {
		if (!hasDownPayment) form.setValue('payment.downpayment', undefined)
	}, [hasDownPayment])

	return (
		<div>
			<Checkbox
				{...form.register('hasDownPayment')}
				colorScheme="primary"
				fontSize="sm"
				mb="4"
			>
				Possui entrada?
			</Checkbox>

			{hasDownPayment && (
				<Grid gap="4" templateColumns={{ md: '1fr 160px 2fr' }} mb="4">
					<FormControl
						isInvalid={!!errors.payment?.downpayment?.value}
					>
						<FormLabel fontSize="sm">Valor</FormLabel>
						<CurrencyInput
							name="payment.downpayment.value"
							rules={{ required: true, shouldUnregister: true }}
							size="sm"
						/>
					</FormControl>
					<FormControl
						isInvalid={!!errors.payment?.downpayment?.date}
					>
						<FormLabel fontSize="sm">Data de Pagamento</FormLabel>
						<Input
							{...form.register('payment.downpayment.date', {
								required: true,
								shouldUnregister: true,
							})}
							type="date"
							size="sm"
						/>
					</FormControl>
					<FormControl
						isInvalid={!!errors.payment?.downpayment?.method}
					>
						<FormLabel fontSize="sm">Método de pagamento</FormLabel>
						<Select
							{...form.register('payment.downpayment.method', {
								required: true,
								shouldUnregister: true,
							})}
							size="sm"
							placeholder="Selecione"
						>
							{PAYMENT_METHODS.map((it) => (
								<option key={it.value} value={it.value}>
									{it.name}
								</option>
							))}
						</Select>
					</FormControl>
				</Grid>
			)}

			<Text fontSize="lg" fontWeight="600" mb="4">
				Parcelas
			</Text>

			<Grid gap="4" templateColumns={{ md: '40px 1fr 1fr' }}>
				<FormControl
					isInvalid={!!errors.payment?.installments?.quantity}
				>
					<FormLabel fontSize="sm">Qtd</FormLabel>
					<Input
						{...form.register(`payment.installments.quantity`, {
							valueAsNumber: true,
							required: true,
						})}
						onInput={(e) => {
							const target = e.currentTarget
							if (!target.value) return
							target.value = target.value.replace(/[^\d]/g, '')
						}}
						size="sm"
					/>
				</FormControl>
				<FormControl
					isInvalid={!!errors.payment?.installments?.dueDate}
				>
					<FormLabel fontSize="sm">
						Primeira Data de Vencimento
					</FormLabel>
					<Input
						{...form.register(`payment.installments.dueDate`, {
							required: true,
						})}
						type="date"
						size="sm"
					/>
				</FormControl>
				<FormControl isInvalid={!!errors.payment?.installments?.method}>
					<FormLabel fontSize="sm">Método de Pagamento</FormLabel>
					<Select
						{...form.register('payment.installments.method', {
							required: true,
						})}
						size="sm"
						placeholder="Selecione"
					>
						{PAYMENT_METHODS.map((it) => (
							<option key={it.value} value={it.value}>
								{it.name}
							</option>
						))}
					</Select>
				</FormControl>
			</Grid>
		</div>
	)
}

const PAYMENT_METHODS = [
	{ name: 'Pix', value: 'pix' },
	{ name: 'Crédito', value: 'credit_card' },
	{ name: 'Débito', value: 'debit_card' },
	{ name: 'Dinheiro', value: 'cash' },
	{ name: 'Boleto', value: 'bank_slip' },
	{ name: 'Depósito', value: 'deposit' },
	{ name: 'Cheque', value: 'check' },
]
