/* eslint-disable react/prop-types */
import {
	Flex,
	Icon,
	Link,
	Menu,
	MenuButton,
	Text,
	useColorModeValue,
} from '@chakra-ui/react'
import { IconType } from 'react-icons'
import { LinkProps, NavLink } from 'react-router-dom'

interface NavItemProps {
	icon: IconType
	title: string
	active?: boolean
	navSize: string
	// description?: string]
	to: LinkProps['to']
}

export const NavItem = ({ icon, to, title, active, navSize }: NavItemProps) => {
	const flexBg = useColorModeValue('#F9F9F9', 'transparent')
	const bgHover = useColorModeValue('#AEC8CA', 'whiteAlpha.100')

	return (
		<Flex mt={30} flexDir="column" w="full">
			<Menu placement="right">
				<Link
					backgroundColor="transparent"
					p={3}
					borderRadius={8}
					_hover={{
						textDecor: 'none',
						backgroundColor: bgHover,
					}}
					width="100%"
					as={NavLink}
					to={to}
				>
					<MenuButton w="100%" flex="1">
						<Flex
							margin="0 auto"
							justifyContent="left"
							alignItems="center"
							columnGap="8px"
						>
							<Flex
								alignItems="center"
								justifyContent="center"
								w="32px"
								h="32px"
								borderRadius="4px"
								bg={active ? '#55BBDD' : flexBg}
								color={active ? '#fff' : 'gray.500'}
							>
								<Icon
									as={icon}
									fontSize="xl"
									color={active ? '#FFF' : 'gray.500'}
								/>
							</Flex>
							<Text
								fontWeight="600"
								fontSize="16px"
								display={navSize === 'small' ? 'none' : 'flex'}
								color={active ? '#55BBDD' : 'gray.500'}
							>
								{title}
							</Text>
						</Flex>
					</MenuButton>
				</Link>
			</Menu>
		</Flex>
	)
}

NavItem.defaultProps = {
	active: false,
	// description: '',
}
